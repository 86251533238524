import gql from 'graphql-tag';

export const SME_COMPANY_EDIT_EMPLOYERS_PENSION_QUERY = gql`
  query smeCompanyEditEmployersPensionQuery($benefitId: ID!, $proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      benefit(id: $benefitId) {
        id
        benefitPackages {
          id
        }
        configuration {
          __typename
          ... on ProposalEmployersPensionBenefitConfig {
            defaultManagementType
            extraFixedPremium
            premiumCalculationMethod
            premiumDeductions
            premiumWaiver
            premiumMatrix {
              age
              intervals
              baseAmount
              baseAmountType
              salaryMultiplier
            }
            retirementAge
          }
        }
      }
      benefitPackages {
        id
        name
      }
    }
  }
`;
