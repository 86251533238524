import { ApolloError, useMutation } from '@apollo/client';
import { SelectField, TextField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import {
  addCustomTypeMutation,
  addCustomTypeMutationVariables,
  SUPER_TYPE,
} from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { UnitCodeOptions } from 'app/utils/constants';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { useNotification } from 'features/notifications';
import { Form, Formik } from 'formik';
import qs from 'query-string';
import React, { useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';

import { unitCodeMessages } from '../../utils/constants';
import { ADD_CUSTOM_TYPE_MUTATION } from '../graphql/mutations';
import {
  accountPlanMessages,
  createAccountPlanSuccessMessages,
  createAccountPlanTitleMessages,
} from '../messages';

export interface LocationState {
  refetch: SUPER_TYPE;
}

interface FormValues {
  code: string;
  currency: string;
  name: string;
  unitCode: string;
}

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    code: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export const CreateCustomTypeModal: React.FC = () => {
  const { send } = useNotification();
  const intl = useIntl();
  const { formatMessage } = intl;
  const { push } = useHistory();
  const { search, pathname } = useLocation();
  const [error, setError] = useState<ApolloError | undefined>();

  const { create: superType } = qs.parse(search);
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const [addCustomType] = useMutation<
    addCustomTypeMutation,
    addCustomTypeMutationVariables
  >(ADD_CUSTOM_TYPE_MUTATION, {
    onError: err => setError(err),
    onCompleted: () => {
      send({
        type: 'success',
        message: formatMessage({
          messages: createAccountPlanSuccessMessages,
          select: superType,
        }),
      });
      push(pathname, {
        refetch: superType,
      });
    },
  });

  if (!superType) {
    return null;
  }

  return (
    <Modal
      size="medium"
      isOpen
      onRequestClose={() => {
        push(pathname);
        setError(undefined);
      }}
    >
      <Formik<FormValues>
        initialValues={{
          code: '',
          name: '',
          unitCode:
            superType === SUPER_TYPE.REMUNERATION ? UnitCodeOptions.MON : '',
          currency: 'SEK',
        }}
        validationSchema={validationSchema(intl)}
        onSubmit={values => {
          addCustomType({
            variables: {
              input: {
                superType,
                companyId,
                typeId: values.code,
                typeName: values.name ? values.name : undefined,
                currency: values.currency ? values.currency : undefined,
                unitCode: values.unitCode ? values.unitCode : undefined,
              },
            },
          });
        }}
      >
        <Form>
          <ModalHeader>
            <FormattedMessage
              messages={createAccountPlanTitleMessages}
              select={superType}
            />
          </ModalHeader>
          <ModalBody>
            {error && <GraphQlError inModal error={error} />}
            <TextField
              dense
              name="code"
              required
              label={
                <FormattedMessage {...accountPlanMessages.codeColumnLabel} />
              }
              helperText={
                <FormattedMessage
                  {...accountPlanMessages.codeColumnHelperText}
                />
              }
            />
            <TextField
              dense
              name="name"
              label={
                <FormattedMessage {...accountPlanMessages.nameColumnLabel} />
              }
            />
            <SelectField
              dense
              fixed
              name="unitCode"
              type="select"
              label={
                <FormattedMessage
                  {...accountPlanMessages.unitCodeColumnLabel}
                />
              }
              options={Object.keys(UnitCodeOptions).map(value => ({
                label: formatMessage(unitCodeMessages[value]),
                value,
              }))}
              helperText={
                <FormattedMessage
                  {...accountPlanMessages.unitCodeColumnHelperText}
                />
              }
            />
          </ModalBody>
          <ModalFooter>
            <ButtonLayout align="right">
              <Button text onClick={() => push(pathname)}>
                <FormattedMessage {...formMessages.cancel} />
              </Button>
              <Button text type="submit">
                <FormattedMessage {...formMessages.save} />
              </Button>
            </ButtonLayout>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
};
