import { useLazyQuery } from '@apollo/client';
import { download } from '@frontend/ui/icons';
import {
  downloadPriceProposalFileQuery,
  downloadPriceProposalFileQueryVariables,
  downloadPriceProposalLayoutQuery,
  downloadPriceProposalLayoutQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as ProposalMatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { FormattedMessage } from 'components/formats';
import { smeCompilationMessages } from 'features/sme/messages/sme';
import React from 'react';
import { useParams } from 'react-router';

import { useProposalReadonly } from '../../../utils/use-proposal-readonly';
import {
  DOWNLOAD_PRICE_PROPOSAL_FILE_QUERY,
  DOWNLOAD_PRICE_PROPOSAL_LAYOUT_QUERY,
} from './graphql/queries';

export const DownloadPriceProposalChip: React.FC = () => {
  const params = useParams<ProposalMatchParams>();
  const isReadonly = useProposalReadonly();

  const { data } = useQuery<
    downloadPriceProposalLayoutQuery,
    downloadPriceProposalLayoutQueryVariables
  >(DOWNLOAD_PRICE_PROPOSAL_LAYOUT_QUERY, {
    suspend: true,
    variables: { id: params.proposalId },
  });

  const [getUrl, { loading }] = useLazyQuery<
    downloadPriceProposalFileQuery,
    downloadPriceProposalFileQueryVariables
  >(DOWNLOAD_PRICE_PROPOSAL_FILE_QUERY, {
    variables: { input: { proposalId: params.proposalId } },
    errorPolicy: 'all',
  });

  const handleDownload = async () => {
    const res = await getUrl();
    if (!res.data?.getPriceProposal) {
      return;
    }
    const { url, name } = res.data.getPriceProposal;
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.target = '_blank';
    link.click();
    link.parentNode?.removeChild(link);
  };

  if (!data?.proposal) {
    return null;
  }

  const {
    validity: { isPriceProposalDownloadAllowed },
  } = data.proposal;

  const disabled = isReadonly || !isPriceProposalDownloadAllowed;

  return (
    <AssistChip
      text={<FormattedMessage {...smeCompilationMessages.downloadQuote} />}
      leadingIcon={download}
      onClick={handleDownload}
      loading={loading}
      disabled={disabled}
    />
  );
};
