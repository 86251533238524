// eslint-disable-next-line
import { WizardHeader as UiWizardHeader, WizardHeaderProps } from '@frontend/ui';
import React from 'react';
import { useIntl } from 'components/formats';
import { useHistory, useLocation } from 'react-router';
import { useAppBarNavigationStack } from 'components/NavigationDrawer/lib/use-app-bar-navigation-stack';
import { a11yMessages } from 'app/messages/a11y';

interface Props
  extends Omit<WizardHeaderProps, 'closeButtonLabel' | 'onRequestClose'> {
  parentLink: string;
  onConfirm?: () => Promise<boolean>;
}

/**
 *
 * Enhances the `WizardHeader` component from `@frontend/ui`.
 *
 * - Provides default a11y label for the close button.
 *
 * - Manages `onRequestClose` to automatically navigate
 * to the previous route according to the internal navigation stack.
 *
 * For more information about the internal navigation stack, see: `useAppBarNavigationStack`.
 */
export const WizardHeader: React.FC<Props> = props => {
  const { onConfirm } = props;
  const { state } = useLocation();
  const { push } = useHistory();
  const { formatMessage } = useIntl();
  const { peek } = useAppBarNavigationStack();

  const onRequestClose = async () => {
    const close = () => {
      const path = peek() ?? props.parentLink;
      push(path, state);
    };

    if (!onConfirm || (await onConfirm())) {
      close();
    }
  };

  return (
    <UiWizardHeader
      closeButtonLabel={formatMessage(a11yMessages.exit)}
      onRequestClose={onRequestClose}
      {...props}
    />
  );
};
