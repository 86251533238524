import { Icon } from '@frontend/ui';
import { download } from '@frontend/ui/icons';
import { a11yMessages } from 'app/messages/a11y';
import { DefaultA } from 'components/DefaultA';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import React from 'react';

export interface Downloadable {
  displayName: React.ReactNode;
  url: string;
}
interface Props {
  downloadables: Downloadable[];
}

export const TableDownloadMenu: React.FC<Props> = ({ downloadables }) => (
  <KebabMenu label={a11yMessages.downloadMenu} fixed>
    {downloadables.map((downloadable, index) => (
      <DefaultA key={`downloadable-${index}`} href={downloadable.url} download>
        <MenuItem
          title={downloadable.displayName}
          icon={<Icon icon={download} />}
        />
      </DefaultA>
    ))}
  </KebabMenu>
);
