import { Figure, Icon, SnapshotCardLayout } from '@frontend/ui';
import { arrowRightAlt } from '@frontend/ui/icons';
import {
  FlexSalaryExchangeRequestStatus,
  pendingSalaryExchangeRequestsCardQuery,
  pendingSalaryExchangeRequestsCardQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { CardGridCell } from 'components/GridCell';
import { LinkedCard } from 'components/LinkedCard';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { companySalaryExchangeMessages } from '../../messages';
import { PENDING_SALARY_EXCHANGE_REQUESTS_CARD_QUERY } from './graphql/queries';

export const PendingSalaryExchangeRequestsCard: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const { data } = useQuery<
    pendingSalaryExchangeRequestsCardQuery,
    pendingSalaryExchangeRequestsCardQueryVariables
  >(PENDING_SALARY_EXCHANGE_REQUESTS_CARD_QUERY, {
    errorPolicy: 'all',
    suspend: true,
    variables: {
      companyId,
      statuses: [FlexSalaryExchangeRequestStatus.REQUESTED],
    },
  });

  const ongoingCount = data?.salaryExchangeRequests?.totalCount;
  const bonusCount = data?.bonusSalaryExchangeRequests?.totalCount;

  if (ongoingCount == null || bonusCount == null) {
    return null;
  }

  const count = ongoingCount + bonusCount;

  return (
    <CardGridCell>
      <LinkedCard
        fullHeight
        to={`/companies/${companyId}/salary-exchanges/requests`}
      >
        <SnapshotCardLayout
          icon={<Icon icon={arrowRightAlt} color="primary" size="medium" />}
          title={<FormattedMessage {...commonMessages.requests} />}
          figure1={
            <Figure
              title={
                <FormattedMessage
                  {...companySalaryExchangeMessages.salaryExchangeRequestsCount}
                  values={{ count }}
                />
              }
              description={<FormattedMessage {...commonMessages.toHandle} />}
            />
          }
        />
      </LinkedCard>
    </CardGridCell>
  );
};
