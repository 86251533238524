import { Figure, Icon } from '@frontend/ui';
import { handshake } from '@frontend/ui/icons';
import {
  variableCompensationCardQuery,
  variableCompensationCardQueryVariables,
} from 'app/apollo/graphql/types';
import { totalCompensationMessages } from 'app/messages/total-compensation';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { CardGridCell } from 'components/GridCell';
import { SnapshotCard } from 'components/SnapshotCard';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { VARIABLE_COMPENSATION_CARD_QUERY } from './graphql/queries';
import { getAnnualCompensation } from './utils/get-annual-compensation';

export const VariableCompensationCard: React.FC = () => {
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const { data } = useQuery<
    variableCompensationCardQuery,
    variableCompensationCardQueryVariables
  >(VARIABLE_COMPENSATION_CARD_QUERY, {
    suspend: true,
    errorPolicy: 'all',
    variables: {
      userAccountId,
      companyId,
    },
    isEmpty: d => !getAnnualCompensation(d),
  });

  if (!data?.membership?.totalCompensation) {
    return null;
  }

  const annualCompensation = getAnnualCompensation(data);

  if (!annualCompensation) {
    return null;
  }
  return (
    <CardGridCell>
      <SnapshotCard
        icon={<Icon icon={handshake} color="primary" size="medium" />}
        title={
          <FormattedMessage
            {...totalCompensationMessages.variableCompensation}
          />
        }
        figure1={
          <Figure
            size="medium"
            title={
              <FormattedCurrency value={annualCompensation} currency="SEK" />
            }
            description={
              <FormattedMessage {...totalCompensationMessages.annualBonus} />
            }
          />
        }
      />
    </CardGridCell>
  );
};
