import {
  AppBar as AppBarUi,
  AppBarContent,
  AppBarMeta,
  AppBarTitle,
  IconButton,
} from '@frontend/ui';
import { arrowBack, menu, menuOpen } from '@frontend/ui/icons';
import { useIntl } from 'components/formats';
import { useNavigationContext } from 'components/NavigationDrawer/containers/NavigationContext';
import { useAppBarNavigationStack } from 'components/NavigationDrawer/lib/use-app-bar-navigation-stack';
import React from 'react';
import { useLocation } from 'react-router';

import { messages } from '../messages';
import { SkipToMainLink } from '../SkipToMainLink';
import { UserMenu } from '../user-menu';

export const AppBar: React.FC = () => {
  const { state } = useLocation();
  const { formatMessage } = useIntl();
  const {
    isDrawerOpen,
    page: { parentLink },
    setIsDrawerOpen,
  } = useNavigationContext();

  const { peek, push } = useAppBarNavigationStack();

  return (
    <AppBarUi>
      <SkipToMainLink />
      <AppBarContent>
        <>
          {!parentLink && (
            <IconButton
              icon={isDrawerOpen ? menuOpen : menu}
              label={formatMessage(messages.openDrawer)}
              onClick={() => setIsDrawerOpen(_isDrawerOpen => !_isDrawerOpen)}
            />
          )}
          {parentLink && (
            <IconButton
              icon={arrowBack}
              label={formatMessage(messages.back)}
              onClick={() => {
                const link = peek();
                push(link ?? parentLink, state);
              }}
            />
          )}
          <AppBarTitle>Backstage</AppBarTitle>
        </>
        <AppBarMeta>
          <UserMenu />
        </AppBarMeta>
      </AppBarContent>
    </AppBarUi>
  );
};
