import { useMutation } from '@apollo/client';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import {
  assert,
  getSubmitErrors,
  toDecimalFraction,
  toEffectiveThrough,
  toEffectiveUntil,
  toPercent,
} from '@frontend/utils';
import {
  employmentActivityDetails,
  updateEmploymentActivityMutation,
  updateEmploymentActivityMutationVariables,
} from 'app/apollo/graphql/types';
import { employmentMessages } from 'app/messages/employees';
import { formMessages, formSubmitMessages } from 'app/messages/form';
import { EmploymentRouteMatchParams } from 'app/pages/companies/company/employees/employee/employments/employment';
import { FormattedMessage, useIntl } from 'components/formats';
import { Modal } from 'components/Modal';
import { NotificationCard } from 'components/NotificationCard';
import { useNotification } from 'features/notifications';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { DeleteEmploymentActivityButton } from '../delete';
import { updateEmploymentActivityValidationSchema } from '../employment-activity-validation-schema';
import { EmploymentActivityFormFields } from '../EmploymentActivityFormFields';
import { EMPLOYMENT_ACTIVITIES_QUERY } from '../graphql/queries';
import { UPDATE_EMPLOYMENT_ACTIVITY_MUTATION } from './graphql/mutations';

interface FormValues {
  effectiveDate: string;
  effectiveThrough?: string;
  rate?: number;
}

interface Props {
  activity: employmentActivityDetails;
  employeeName: string;
  isOpen: boolean;
  onRequestClose: () => void;
}

export const EditEmploymentActivityModal: React.FC<Props> = ({
  activity,
  employeeName,
  isOpen,
  onRequestClose,
}) => {
  const {
    params: { employmentId },
  } = useRouteMatch<EmploymentRouteMatchParams>();
  const { send } = useNotification();
  const intl = useIntl();

  const [deleteSubmitting, setDeleteSubmitting] = useState<boolean>(false);
  const [formError, setFormError] = useState<string | undefined>();

  const [updateEmploymentActivity] = useMutation<
    updateEmploymentActivityMutation,
    updateEmploymentActivityMutationVariables
  >(UPDATE_EMPLOYMENT_ACTIVITY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: EMPLOYMENT_ACTIVITIES_QUERY,
        variables: { id: employmentId },
      },
    ],
  });

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Formik<FormValues>
        initialValues={{
          rate: activity.rate ? toPercent(activity.rate) : undefined,
          effectiveDate: activity.period[0],
          effectiveThrough: activity.period[1]
            ? toEffectiveThrough(activity.period[1])
            : undefined,
        }}
        isInitialValid={false}
        validationSchema={updateEmploymentActivityValidationSchema(intl)}
        onSubmit={async ({ effectiveThrough, effectiveDate, rate }) => {
          try {
            assert(effectiveDate != null);

            await updateEmploymentActivity({
              variables: {
                input: {
                  id: activity.id,
                  // XXX: formik NumberField value's type is string, so `0` string works here
                  rate: rate ? toDecimalFraction(rate) : undefined,
                  effectiveDate,
                  effectiveUntil: effectiveThrough
                    ? toEffectiveUntil(effectiveThrough)
                    : null,
                },
              },
            });
            send({
              message: intl.formatMessage(
                formSubmitMessages.editEmploymentActivitySuccess,
                { type: activity.type },
              ),
              type: 'success',
            });
            onRequestClose();
          } catch (error) {
            setFormError(getSubmitErrors({ intl, error }));
          }
        }}
      >
        {({ handleSubmit, isValid, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader>
              <FormattedMessage
                {...employmentMessages.editEmploymentActivityForName}
                values={{ employeeName, type: activity.type }}
              />
            </ModalHeader>
            <ModalBody>
              <EmploymentActivityFormFields edit />
              {formError && (
                <NotificationCard inModal type="error">
                  {formError}
                </NotificationCard>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="left">
                <DeleteEmploymentActivityButton
                  activity={activity}
                  employmentId={employmentId}
                  disabled={isSubmitting}
                  onSubmit={() => {
                    setFormError(undefined);
                    setDeleteSubmitting(true);
                  }}
                  onError={error => {
                    setDeleteSubmitting(false);
                    setFormError(error);
                  }}
                  onDeleteComplete={() => {
                    onRequestClose();
                  }}
                />
              </ButtonLayout>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button
                  type="submit"
                  filled
                  disabled={!isValid || deleteSubmitting}
                  loading={isSubmitting}
                >
                  <FormattedMessage {...formMessages.approve} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
