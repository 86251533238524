import gql from 'graphql-tag';

export const UPDATE_PROPOSAL_MUTATION = gql`
  mutation updateProposalMutation(
    $input: UpdateProposalInput!
    $skipAdviceData: Boolean!
  ) {
    updateProposal(input: $input) {
      __typename
      id
      companySize
      updatedAt
      lastEditedBy {
        id
        firstName
        lastName
      }
      fees {
        annualFeeFund
        annualFeePortfolio
        annualFeeTraditional
        capitalFeeFund
        capitalFeePortfolio
        capitalFeeTraditional
        fundDiscountPermitted
        fundDiscountValue
        # we refetch fundRange for the proposal compilation tab
        # since it's affected by fundDiscountPermitted and fundDiscountValue
        fundRange
        provisionWaiverAllowed
        provisionWaiverHealthcare
        provisionWaiverPremiumWaiver
        provisionWaiverSickness
        riskBucket
      }
      need @skip(if: $skipAdviceData) {
        collectiveAgreementBlueCollar
        collectiveAgreementWhiteCollar
        documentationProvided
        text
      }
      productKnowledge @skip(if: $skipAdviceData) {
        adviceSavingsInsurance
        recentExperiencePension
        professionalExperiencePension
        pensionInstrumentKnowledge
        notes
      }
      summary @skip(if: $skipAdviceData) {
        checkNeed
        checkExistingCoverage
        checkPlannedDevelopment
        checkDeductionSpace
        checkFinancialSituation
        format
        text
      }
      validity {
        validProposal
        validNeed
        validProductKnowledge
        validBenefits
        validEmployees
        validFees
        validCompilation
        validSummary
      }
    }
  }
`;

export const EDIT_PROPOSAL_NAME_MUTATION = gql`
  mutation editProposalNameMutation($input: UpdateProposalInput!) {
    updateProposal(input: $input) {
      id
      name
    }
  }
`;
