import { useMutation } from '@apollo/client';
import {
  updateAccountingDimensionMutation,
  updateAccountingDimensionMutationVariables,
} from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import { useNotification } from 'features/notifications';
import { Formik } from 'formik';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';

import { CostReportingModal } from '../components/Modal';
import { UPDATE_ACCOUNTING_DIMENSION_MUTATION } from '../graphql/mutations';
import { ACCOUNTING_DIMENSIONS_QUERY } from '../graphql/queries';
import { costReportingMessages } from '../messages';
import { useAccountingDimension } from '../utils/use-accounting-dimension';

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

interface FormValues {
  identifier: string;
  name: string;
}

export const EditDimensionModal: React.FC = () => {
  const intl = useIntl();
  const { send } = useNotification();
  const { push } = useHistory();
  const { search, pathname } = useLocation();
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const { edit: dimensionId } = qs.parse(search);

  const onCompleted = () => {
    send({
      type: 'success',
      message: (
        <FormattedMessage {...costReportingMessages.editDimensionSuccess} />
      ),
    });
    push(pathname);
  };

  const [updateAccountingDimension, { error }] = useMutation<
    updateAccountingDimensionMutation,
    updateAccountingDimensionMutationVariables
  >(UPDATE_ACCOUNTING_DIMENSION_MUTATION, {
    onCompleted,
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    refetchQueries: [
      {
        query: ACCOUNTING_DIMENSIONS_QUERY,
        variables: {
          companyId,
        },
      },
    ],
  });

  const {
    loading: dimensionLoading,
    error: dimensionError,
    identifier: dimensionIdentifier,
    name: dimensionName,
  } = useAccountingDimension(dimensionId);

  if (dimensionLoading) {
    return <TopLoading />;
  }

  if (dimensionError || !dimensionName) {
    return null;
  }

  return (
    <Formik<FormValues>
      validationSchema={validationSchema(intl)}
      initialValues={{
        identifier: dimensionIdentifier ?? '',
        name: dimensionName,
      }}
      onSubmit={({ name }) => {
        updateAccountingDimension({
          variables: { input: { id: dimensionId, name, companyId } },
        });
      }}
    >
      {({ isValid }) => (
        <CostReportingModal
          title={<FormattedMessage {...costReportingMessages.editDimension} />}
          onClose={() => push(pathname)}
          code="identifier"
          codeFieldDisabled
          name="name"
          isValid={isValid}
          error={error}
        />
      )}
    </Formik>
  );
};
