import {
  Button,
  ButtonLayout,
  CheckboxField as CheckboxFieldUI,
  Section,
  SectionHeader,
  Table,
  Th,
  Tr,
} from '@frontend/ui';
import { companyFullyCapableForWorkRequestsQuery_company_Company_fcfwRequests_FlexFcfwRequestConnection_edges_FlexFcfwRequestEdge_node_FlexFcfwRequest as FullyCapableForWorkRequest } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import { Formik } from 'formik';
import React, { useState } from 'react';

import { TableRow } from './TableRow';
import { FcfwCheckboxGroupStatus, RequestEmployee } from './utils/constants';
import { getFcfwSelectStatus } from './utils/get-fcfw-select-status';
import { uniqueEmployeesFromRequests } from './utils/unique-employees-from-request';
import { useSubmit } from './utils/use-submit';

const getInitialEmployeeEffectiveDates = (
  employees: RequestEmployee[],
): Record<string, string> =>
  employees.reduce(
    (acc, { userAccountId, requestedDate }) => ({
      ...acc,
      [userAccountId]: requestedDate,
    }),
    {},
  );

export interface FormValues {
  employeeEffectiveDates: Record<string, string>;
  includedEmployees: Record<string, boolean>;
}

interface Props {
  companyId: string;
  infoText: string;
  requests: readonly FullyCapableForWorkRequest[];
  onSuccess?: () => void;
  tableHeader?: React.ReactNode;
}

export const AssertWorkCapabilityForm: React.FC<Props> = ({
  requests,
  companyId,
  onSuccess,
  tableHeader,
  infoText,
}) => {
  const [submissionErrors, setSubmissionErrors] = useState<string[]>([]);

  const employees = uniqueEmployeesFromRequests(requests);

  const { submit } = useSubmit({
    employees,
    infoText,
    companyId,
    onSuccess,
    setSubmissionErrors,
  });

  const initialValues = {
    includedEmployees: {},
    employeeEffectiveDates: getInitialEmployeeEffectiveDates(employees),
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submit}
      validateOnMount
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
        setFieldValue,
        values: { employeeEffectiveDates, includedEmployees },
      }) => {
        const checkboxGroupStatus = getFcfwSelectStatus({
          employees,
          includedEmployees,
        });
        const handleSelectAll = () => {
          const allChecked =
            checkboxGroupStatus === FcfwCheckboxGroupStatus.CHECKED;
          employees.forEach(employee => {
            setFieldValue(
              `includedEmployees[${employee.userAccountId}]`,
              !allChecked,
            );
          });
        };

        return (
          <form onSubmit={handleSubmit}>
            <Section>
              {tableHeader && <SectionHeader>{tableHeader}</SectionHeader>}
              <Table fixed size="small">
                <colgroup>
                  <col style={{ width: '6%' }} />
                  <col style={{ width: '18%' }} />
                  <col style={{ width: '19%' }} />
                  <col style={{ width: '17%' }} />
                  <col style={{ width: '40%' }} />
                </colgroup>
                <thead>
                  <Tr>
                    <Th type="input">
                      <CheckboxFieldUI
                        checked={
                          checkboxGroupStatus ===
                          FcfwCheckboxGroupStatus.CHECKED
                        }
                        onChange={handleSelectAll}
                        indeterminate={
                          checkboxGroupStatus ===
                          FcfwCheckboxGroupStatus.INDETERMINATE
                        }
                      />
                    </Th>
                    <Th type="number" align="left">
                      <FormattedMessage
                        {...commonMessages.personalIdentityNumber}
                      />
                    </Th>
                    <Th>
                      <FormattedMessage {...commonMessages.name} />
                    </Th>
                    <Th align="left">
                      <FormattedMessage {...commonMessages.date} />
                    </Th>
                    <Th />
                  </Tr>
                </thead>
                <tbody>
                  {employees.map(employee => (
                    <TableRow
                      key={employee.userAccountId}
                      employee={employee}
                      checked={!!includedEmployees[employee.userAccountId]}
                      requests={requests}
                      employeeEffectiveDates={employeeEffectiveDates}
                    />
                  ))}
                </tbody>
              </Table>
            </Section>
            {submissionErrors.map((error, index) => (
              <NotificationCard key={index} type="error">
                {error}
              </NotificationCard>
            ))}
            <Section>
              <ButtonLayout>
                <Button
                  type="submit"
                  filled
                  disabled={
                    !isValid ||
                    !Object.values(includedEmployees).some(include => include)
                  }
                  loading={isSubmitting}
                >
                  <FormattedMessage {...formMessages.approve} />
                </Button>
              </ButtonLayout>
            </Section>
          </form>
        );
      }}
    </Formik>
  );
};
