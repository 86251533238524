import { ApolloError } from '@apollo/client';
import {
  agreementDetails,
  agreementsListQuery,
  agreementsListQueryVariables,
} from 'app/apollo/graphql/types';
import { useQuery } from 'app/utils/use-query';

import { AGREEMENTS_LIST_QUERY } from '../graphql/queries';
import { filterActiveAgreements } from './filter-active-agreements';

interface AgreementsItem {
  agreements: agreementDetails[];
  loading: boolean;
  error?: ApolloError;
}

export const useAgreements = (companyId: string): AgreementsItem => {
  const { loading, error, data } = useQuery<
    agreementsListQuery,
    agreementsListQueryVariables
  >(AGREEMENTS_LIST_QUERY, {
    variables: { id: companyId },
    errorPolicy: 'all',
  });

  return {
    loading,
    error,
    agreements: data?.company
      ? filterActiveAgreements(data.company.agreements)
      : [],
  };
};
