import { CheckboxGroupField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import {
  MembershipRole,
  membershipRolesQuery_membership_Membership as Membership,
} from 'app/apollo/graphql/types';
import {
  employeeFormMessages,
  workInformationMessages,
} from 'app/messages/employees';
import { formMessages } from 'app/messages/form';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { Form, Formik } from 'formik';
import React from 'react';

import { useSubmit } from './utils/use-submit';

interface Props {
  isOpen: boolean;
  membership: Membership;
  onRequestClose: () => void;
}

export interface FormValues {
  roles: readonly MembershipRole[];
  submissionError?: string;
}

export const UpdateMembershipRolesModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  membership,
}) => {
  const { submit, error } = useSubmit({ onRequestClose, membership });
  const { formatMessage } = useIntl();

  return (
    <Modal size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
      <Formik<FormValues>
        onSubmit={submit}
        initialValues={{ roles: membership.roles }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalHeader>
              <FormattedMessage {...employeeFormMessages.updateRole} />
            </ModalHeader>
            <ModalBody>
              <p>
                <FormattedMessage
                  {...employeeFormMessages.updateRoleDescription}
                />
              </p>
              <CheckboxGroupField
                name="roles"
                options={[
                  {
                    label: formatMessage(workInformationMessages.HRAdmin),
                    value: MembershipRole.HR,
                    content: formatMessage(
                      workInformationMessages.HRAdminDescription,
                    ),
                  },
                  {
                    label: formatMessage(workInformationMessages.contentAdmin),
                    value: MembershipRole.CONTENT,
                    content: formatMessage(
                      workInformationMessages.contentAdminDescription,
                    ),
                  },
                  {
                    label: formatMessage(workInformationMessages.financeAdmin),
                    value: MembershipRole.FINANCE,
                    content: formatMessage(
                      workInformationMessages.financeAdminDescr,
                    ),
                  },
                ]}
              />
              {error && <GraphQlError error={error} inModal />}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button loading={isSubmitting} text type="submit">
                  <FormattedMessage {...formMessages.approve} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
