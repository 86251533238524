import { useMutation } from '@apollo/client';
import { TextField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import {
  BookkeepingAccountKey,
  companyBookkeepingAccountsQuery,
  companyBookkeepingAccountsQueryVariables,
  UpdateBookkeepingAccountInput,
  updateBookkeepingAccountMutation,
  updateBookkeepingAccountMutationVariables,
} from 'app/apollo/graphql/types';
import { formMessages } from 'app/messages/form';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { TopLoading } from 'components/TopLoading';
import { useNotification } from 'features/notifications';
import { Form, Formik } from 'formik';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { UPDATE_BOOKKEEPING_ACCOUNT_MUTATION } from '../graphql/mutations';
import { COMPANY_BOOKKEEPING_ACCOUNTS_QUERY } from '../graphql/queries';
import { accountPlanMessages } from '../messages';

export const BOOKKEEPING_ACCOUNTS = 'accounts';

export interface LocationState {
  refetch?: typeof BOOKKEEPING_ACCOUNTS;
}

interface FormValues {
  name: string;
  number: string;
}

export const UpdateBookkeepingAccountModal: React.FC = () => {
  const { search, pathname } = useLocation();
  const { push } = useHistory();
  const { send } = useNotification();
  const { formatMessage } = useIntl();
  const { edit: accountKey } = qs.parse(search);
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const { data, loading, error } = useQuery<
    companyBookkeepingAccountsQuery,
    companyBookkeepingAccountsQueryVariables
  >(COMPANY_BOOKKEEPING_ACCOUNTS_QUERY, {
    skip: !accountKey,
    errorPolicy: 'all',
    variables: {
      companyId,
      includeDefault: true,
    },
  });

  const bookkeepingAccounts = data?.company?.bookkeepingAccounts?.edges.map(
    ({ node }) => node,
  );

  const account = bookkeepingAccounts?.find(
    _account => _account.accountKey === accountKey,
  );

  const [updateBookkeepingAccount, { error: updateError }] = useMutation<
    updateBookkeepingAccountMutation,
    updateBookkeepingAccountMutationVariables
  >(UPDATE_BOOKKEEPING_ACCOUNT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [COMPANY_BOOKKEEPING_ACCOUNTS_QUERY],
    errorPolicy: 'all',
  });

  const submit = async (values: FormValues) => {
    const input: UpdateBookkeepingAccountInput = {
      companyId,
      accountKey: BookkeepingAccountKey[accountKey],
      customAccountNumber: values.number,
      customAccountName: values.name,
    };
    try {
      const res = await updateBookkeepingAccount({ variables: { input } });

      if (!res.data?.updateBookkeepingAccount) {
        throw new Error();
      }

      send({
        type: 'success',
        message: formatMessage(accountPlanMessages.accountUpdateSuccess, {
          account: account?.customAccountName ?? '',
        }),
      });
      push(pathname, {
        refetch: BOOKKEEPING_ACCOUNTS,
      });
    } catch {
      // Do nothing
    }

    return undefined;
  };

  if (loading) {
    return <TopLoading />;
  }

  if (!account) {
    return null;
  }

  return (
    <Formik<FormValues>
      initialValues={{
        name: account.customAccountName ?? '',
        number: account.customAccountNumber ?? '',
      }}
      onSubmit={submit}
    >
      {({ isSubmitting }) => (
        <Modal size="medium" isOpen onRequestClose={() => push(pathname)}>
          <Form>
            <ModalHeader>
              <FormattedMessage {...accountPlanMessages.editBookkeepingTitle} />
            </ModalHeader>
            <ModalBody>
              <TextField
                dense
                name="name"
                required
                label={
                  <FormattedMessage {...accountPlanMessages.transactionLabel} />
                }
              />
              <TextField
                dense
                name="number"
                required
                label={
                  <FormattedMessage {...accountPlanMessages.accountLabel} />
                }
              />
              {(!!error || !!updateError) && (
                <GraphQlError inModal error={error ?? updateError} />
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={() => push(pathname)}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button loading={isSubmitting} text type="submit">
                  <FormattedMessage {...formMessages.save} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
