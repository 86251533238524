import {
  benefitPackagesOptionsQuery,
  benefitPackagesOptionsQueryVariables,
  benefitsQuery,
  benefitsQuery_company_Company_benefitCategories_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_categories_FlexBenefitCategory as Category,
  benefitsQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { MatchParams as BenefitPackageMatchParams } from 'app/pages/companies/company/benefit-packages/benefit-package';
import { useQuery } from 'app/utils/use-query';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { GraphQlError } from 'components/GraphQlError';
import { ScrollableSet } from 'components/scrollable/Set';
import { TopLoading } from 'components/TopLoading';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import {
  BENEFIT_PACKAGES_OPTIONS_QUERY,
  BENEFITS_QUERY,
} from 'features/companies/graphql/queries';
import qs from 'query-string';
import React, { useRef } from 'react';
import { RouteComponentProps } from 'react-router';

import { BenefitsTable } from './components/BenefitsTable';
import { Filters } from './components/BenefitsTable/Filters';

type MatchParams = CompanyMatchParams | BenefitPackageMatchParams;

interface Props extends RouteComponentProps<MatchParams> {
  noFilters?: boolean;
  suspend?: boolean;
}

export const Benefits: React.FC<Props> = ({ suspend, noFilters, ...props }) => {
  const {
    match: { params },
  } = props;
  const { companyId } = params;
  const benefitPackageId =
    'benefitPackageId' in params ? params.benefitPackageId : undefined;

  const _suspend = useRef(!!suspend);
  const { categoryIds, benefitTypeNames } = qs.parse(props.location?.search);

  const { locale } = useIntlContext();

  const {
    data: benefitsData,
    previousData: previousBenefitsData,
    loading: benefitsLoading,
    error: benefitsError,
  } = useQuery<benefitsQuery, benefitsQueryVariables>(BENEFITS_QUERY, {
    errorPolicy: 'all',
    variables: {
      companyId,
      locale,
      benefitPackageIds: benefitPackageId ? [benefitPackageId] : undefined,
      onlyGetBenefits: true,
      categoryIds,
      benefitTypeNames,
    },
    suspend: _suspend.current,
    onCompleted: () => {
      _suspend.current = false;
    },
  });

  const {
    data: benefitPackageData,
    loading: benefiPackageLoading,
    error: benefitPackageError,
  } = useQuery<
    benefitPackagesOptionsQuery,
    benefitPackagesOptionsQueryVariables
  >(BENEFIT_PACKAGES_OPTIONS_QUERY, {
    errorPolicy: 'all',
    variables: {
      companyId,
    },
    suspend,
  });

  const _benefitsData = benefitsData ?? previousBenefitsData;

  if (!_benefitsData?.company?.benefits) {
    return !suspend ? (
      benefitsLoading || benefiPackageLoading ? (
        <TopLoading />
      ) : (
        <GraphQlError error={benefitsError} />
      )
    ) : null;
  }

  const availableCategoryOptions =
    _benefitsData.company?.benefitCategories?.edges
      .flatMap(({ node }) => node.categories)
      .reduce<
        Category[]
      >((categories, category) => (categories.some(c => c.id === category.id) ? categories : [...categories, category]), []) ??
    [];

  const availableTypeOptions = Array.from(
    new Set(
      _benefitsData.company?.benefitCategories?.edges.map(
        ({ node }) => node.type,
      ),
    ),
  );

  const benefits = _benefitsData.company?.benefits.edges.map(
    ({ node }) => node,
  );

  const benefitPackageOptions =
    benefitPackageData?.company?.benefitPackages?.edges.map(({ node }) => node);

  return (
    <>
      {(benefitsLoading || benefiPackageLoading) && !suspend && <TopLoading />}
      {(benefitsError || benefitPackageError) && (
        <GraphQlError error={benefitsError ?? benefitPackageError} />
      )}
      <ChipsWrapper>
        <ScrollableSet type="chip">
          {!noFilters && !benefitPackageId && (
            <Filters
              availableCategoryOptions={availableCategoryOptions}
              availableTypeOptions={availableTypeOptions}
            />
          )}
        </ScrollableSet>
      </ChipsWrapper>

      <BenefitsTable
        benefits={benefits}
        benefitPackageId={benefitPackageId}
        benefitPackageOptions={benefitPackageOptions}
        companyId={companyId}
      />
    </>
  );
};
