import { ContentContainer, Section } from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import { EditCompanyDetails } from 'features/companies/company/details/containers/EditCompanyDetails';
import { Page } from 'features/page';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { MatchParams as CompanyMatchParams } from '../..';

export const EditCompany: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = props => (
  <Page
    parentLink={`/companies/${props.match.params.companyId}/settings`}
    title={<FormattedMessage {...commonMessages.updateInformationDetails} />}
  >
    <ContentContainer>
      <Section>
        <EditCompanyDetails {...props} />
      </Section>
    </ContentContainer>
  </Page>
);
