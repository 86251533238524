import {
  allBenefitPackagesQuery,
  allBenefitPackagesQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import { ALL_BENEFIT_PACKAGES_QUERY } from 'features/companies/graphql/queries';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { BenefitPackagesTable } from './components/BenefitPackagesTable';

export const BenefitPackages: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = props => {
  const { locale } = useIntlContext();

  const {
    match: {
      params: { companyId },
    },
  } = props;

  const { data, loading, error } = useQuery<
    allBenefitPackagesQuery,
    allBenefitPackagesQueryVariables
  >(ALL_BENEFIT_PACKAGES_QUERY, {
    variables: {
      locale,
      companyId,
      includeTerminated: false,
    },
    errorPolicy: 'all',
  });

  if (loading) {
    return <TopLoading />;
  }
  if (!data?.companyBenefitPackages) {
    return <GraphQlError error={error} />;
  }

  const benefitPackages =
    data.companyBenefitPackages.benefitPackages?.edges.map(
      ({ node }) => node,
    ) ?? [];

  return (
    <>
      {error && <GraphQlError error={error} />}
      <BenefitPackagesTable
        {...props}
        companyId={companyId}
        benefitPackages={benefitPackages}
      />
    </>
  );
};
