/* eslint-disable */
import { Icon } from '@frontend/ui';
import React from 'react';
import { Option } from '../Option';

interface Inline {
  onChange: (input: any) => void;
  config?: { [key: string]: any };
  currentState?: { [key: string]: any };
  translations?: { [key: string]: any };
}

export const Inline: React.FC<Inline> = ({
  config,
  currentState,
  onChange,
  translations,
}) => (
  <div className="rdw-inline-wrapper" aria-label="rdw-inline-control">
    {config?.options.map((style, index) => (
      <Option
        key={index}
        value={style}
        onClick={e => !!onChange && onChange(e)}
        active={
          (currentState && currentState[style] === true) ||
          (style === 'MONOSPACE' && currentState?.CODE)
        }
        title={
          config[style].title ||
          (!!translations &&
            translations[`components.controls.inline.${style}`])
        }
      >
        {typeof config[style].icon === 'string' ? (
          <img alt="icon" src={config[style].icon} />
        ) : (
          <Icon icon={config[style].icon} size="small" />
        )}
      </Option>
    ))}
  </div>
);
