import { ApolloError, useMutation } from '@apollo/client';
import {
  deleteProposalMutation,
  deleteProposalMutationVariables,
  smeCompanyProposalsQuery_proposals_ProposalConnection_edges_ProposalEdge_node_Proposal as Proposal,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { useConfirm } from 'contexts/confirmation';
import { useNotification } from 'features/notifications';
import { smeCompanyProposalsMessages } from 'features/sme/messages/sme';

import { DELETE_PROPOSAL_MUTATION } from '../graphql/mutations';
import { SME_COMPANY_PROPOSALS_QUERY } from '../graphql/queries';

interface Submit {
  _delete: (proposal: Proposal) => Promise<void>;
  deleteLoading: boolean;
  deleteError?: ApolloError;
}

export const useDelete = (): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();
  const { confirm } = useConfirm();

  const [deleteProposal, { loading: deleteLoading, error: deleteError }] =
    useMutation<deleteProposalMutation, deleteProposalMutationVariables>(
      DELETE_PROPOSAL_MUTATION,
      {
        refetchQueries: [SME_COMPANY_PROPOSALS_QUERY],
        awaitRefetchQueries: true,
      },
    );

  const _delete = async (proposal: Proposal) => {
    const confirmed = await confirm({
      description: formatMessage(
        smeCompanyProposalsMessages.confirmDeleteProposal,
      ),
    });

    if (!confirmed) {
      return;
    }

    try {
      await deleteProposal({
        variables: {
          input: { proposalId: proposal.id },
        },
      });
      send({
        message: formatMessage(
          smeCompanyProposalsMessages.deleteProposalNotification,
        ),
        type: 'success',
      });
    } catch {
      // Do nothing
    }
  };

  return {
    _delete,
    deleteLoading,
    deleteError,
  };
};
