import {
  companyLayoutQuery,
  companyLayoutQueryVariables,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { companyMessages, companySettingsMessages } from 'app/messages/company';
import { employeeMessages } from 'app/messages/employees';
import { COMPANY_LAYOUT_QUERY } from 'app/queries';
import { companyName } from 'app/utils/company-name';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { invoiceMessages } from 'features/companies/company/invoices/messages';
import { jobOfferMessages } from 'features/companies/company/job-offers/messages';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { Company } from './_index';
import { BenefitPackages } from './benefit-packages';
import { Employees } from './employees';
import { Invoices } from './invoices';
import { JobOffers } from './job-offers';
import { Reports } from './reports';
import { SalaryExchange } from './salary-exchange';
import { Settings } from './settings';

export interface MatchParams {
  companyId: string;
}

export const CompanyRoutes: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const { companyId } = match.params;

  const { data, loading, error } = useQuery<
    companyLayoutQuery,
    companyLayoutQueryVariables
  >(COMPANY_LAYOUT_QUERY, {
    variables: {
      companyId,
    },
    skip: !companyId,
    errorPolicy: 'all',
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.company) {
    return <EmptyStatePage error={error} parentLink="/companies" />;
  }

  const { company } = data;
  const title = companyName(company);

  return (
    <NavigationAnchor title={title} path={match.url}>
      <Switch>
        <Route exact path={match.path}>
          <Company title={title} />
        </Route>
        <NavigationAnchor
          title={companyMessages.reports}
          path={`${match.url}/reports`}
        >
          <Route path={`${match.path}/reports`} component={Reports} />
        </NavigationAnchor>
        <NavigationAnchor
          title={companySettingsMessages.settings}
          path={`${match.url}/settings`}
        >
          <Route path={`${match.path}/settings`} component={Settings} />
        </NavigationAnchor>
        <NavigationAnchor
          title={invoiceMessages.invoices}
          path={`${match.url}/invoices`}
        >
          <Route path={`${match.path}/invoices`} component={Invoices} />
        </NavigationAnchor>
        <NavigationAnchor
          title={commonMessages.benefits}
          path={`${match.url}/benefit-packages`}
        >
          <Route
            path={`${match.path}/benefit-packages`}
            component={BenefitPackages}
          />
        </NavigationAnchor>
        <NavigationAnchor
          title={employeeMessages.employees}
          path={`${match.url}/employees`}
        >
          <Route path={`${match.path}/employees`} component={Employees} />
        </NavigationAnchor>
        <NavigationAnchor
          title={commonBenefitMessages.salaryExchange}
          path={`${match.url}/salary-exchanges`}
        >
          <Route
            path={`${match.path}/salary-exchanges`}
            component={SalaryExchange}
          />
        </NavigationAnchor>
        <NavigationAnchor
          title={jobOfferMessages.job}
          path={`${match.url}/job-offers`}
        >
          <Route path={`${match.path}/job-offers`} component={JobOffers} />
        </NavigationAnchor>
      </Switch>
    </NavigationAnchor>
  );
};
