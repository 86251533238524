import {
  RichTextEditor as Editor,
  RichTextEditorProps,
} from 'features/rich-text-editor';
import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

interface Props
  extends Omit<RichTextEditorProps, 'value' | 'setValue' | 'initialValue'> {
  name: string;
}

export const RichTextEditor: React.FC<Props> = ({ name, ...props }) => {
  const [{ value }, { initialValue }, { setValue }] = useField<string>({
    name,
  });

  return (
    <Wrapper>
      <Editor
        value={value}
        setValue={setValue}
        initialValue={initialValue}
        {...props}
      />
    </Wrapper>
  );
};
