import gql from 'graphql-tag';

const occupationalPensionBenefitDetails = gql`
  fragment occupationalPensionBenefitDetails on FlexBenefit {
    id
    type
    content {
      locale
    }
    configuration {
      data
    }
  }
`;

export const occupationalPensionChangeDetails = gql`
  fragment occupationalPensionChangeDetails on FlexOccupationalPensionChange {
    action
    effectiveDate
    premium
    status
    type
  }
`;

export const PENSION_TABLE_QUERY = gql`
  query pensionTableQuery(
    $companyId: ID!
    $userAccountId: ID!
    $benefitTypeNames: [String!]
    $entitlementDate: Date
  ) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      insurances {
        edges {
          node {
            id
            institute {
              id
            }
            effectiveDate
            premiumStatus
            nextPremiumPayment {
              value
            }
            previousPremiumPayment {
              value
            }
            insuranceNumber
            components {
              __typename
              id
              typeCode
              status
              insuranceNumber
              ... on InsuranceComponentRetirement {
                capital {
                  id
                  managementType
                }
              }
            }
          }
        }
      }
    }
    changes(companyId: $companyId, userAccountId: $userAccountId) {
      __typename
      ... on FlexOccupationalPensionChange {
        ...occupationalPensionChangeDetails
      }
    }
    entitlements(
      companyId: $companyId
      userAccountId: $userAccountId
      benefitTypeNames: $benefitTypeNames
      showAll: true
    ) {
      edges {
        node {
          benefit {
            ...occupationalPensionBenefitDetails
          }
        }
      }
    }
    upcomingEntitlements: entitlements(
      companyId: $companyId
      userAccountId: $userAccountId
      benefitTypeNames: $benefitTypeNames
      showAll: true
      date: $entitlementDate
    ) {
      edges {
        node {
          benefit {
            ...occupationalPensionBenefitDetails
          }
        }
      }
    }
  }
  ${occupationalPensionChangeDetails}
  ${occupationalPensionBenefitDetails}
`;
