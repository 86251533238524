import { formatIdentityNumber } from '@advinans/belt-se-identity-number';
import { NumberField, TextField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Subsection,
  SubsectionHeader,
  TextField as TextFieldUI,
} from '@frontend/ui';
import {
  companyDetailsQuery,
  companyDetailsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import { COMPANY_DETAILS_QUERY } from 'features/companies/graphql/queries';
import { Form, Formik } from 'formik';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { validateEmail, ValidationErrorCode } from 'validations';
import * as Yup from 'yup';

import { useSubmit } from './lib/use-submit';

export interface FormValues {
  city: string;
  co: string | null;
  displayName: string;
  email: string | null;
  id: string;
  line1: string;
  // logo: Image | null;
  phone: string | null;
  postalCode: string;
  submissionError?: string;
}

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required(ValidationErrorCode.MANDATORY),
  line1: Yup.string().required(ValidationErrorCode.MANDATORY),
  postalCode: Yup.string().required(ValidationErrorCode.MANDATORY),
  city: Yup.string().required(ValidationErrorCode.MANDATORY),
  email: Yup.string().test(
    'valid email',
    ValidationErrorCode.IS_VALID_EMAIL,
    value => !validateEmail(value),
  ),
});

export const EditCompanyDetails: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = props => {
  const {
    match: {
      params: { companyId },
    },
  } = props;
  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  const { submit, error: submissionError } = useSubmit(props);

  const { data, loading, error } = useQuery<
    companyDetailsQuery,
    companyDetailsQueryVariables
  >(COMPANY_DETAILS_QUERY, {
    variables: { id: companyId },
    errorPolicy: 'all',
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.company) {
    return <EmptyStatePage error={error} parentLink="/" />;
  }

  const { company } = data;

  const initialValues = {
    displayName: company.displayName,
    line1: company.address.line1 ? company.address.line1 : '',
    co: company.address.co,
    postalCode: company.address.postalCode ? company.address.postalCode : '',
    city: company.address.city ? company.address.city : '',
    phone: company.phone,
    email: company.email,
    id: company.id,
    logo: company.logoUrl ? { previewUrl: company.logoUrl } : null,
  };
  const registrationNumber = formatIdentityNumber(
    data.company.registrationNumber,
  );

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={submit}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...commonMessages.company} />
            </SubsectionHeader>
            {error && <GraphQlError error={error} />}
            <TextFieldUI
              disabled
              label={formatMessage(commonMessages.registrationNumber)}
              value={registrationNumber}
            />
            <TextField
              name="displayName"
              label={formatMessage(commonMessages.displayName)}
              required
            />
          </Subsection>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...commonMessages.companyContact} />
            </SubsectionHeader>
            <TextField
              name="line1"
              label={formatMessage(commonMessages.address)}
              required
            />
            <TextField name="co" label={formatMessage(commonMessages.co)} />
            <NumberField
              name="postalCode"
              thousandSeparator={false}
              label={formatMessage(commonMessages.postalCode)}
              required
              locale={locale}
            />
            <TextField
              name="city"
              label={formatMessage(commonMessages.city)}
              required
            />
            <TextField
              name="phone"
              type="tel"
              label={formatMessage(commonMessages.phone)}
            />
            <TextField
              name="email"
              type="email"
              label={formatMessage(commonMessages.email)}
            />
          </Subsection>
          {submissionError && <GraphQlError error={submissionError} />}
          <ButtonLayout>
            <Button
              filled
              disabled={!dirty || !isValid}
              type="submit"
              loading={isSubmitting}
            >
              <FormattedMessage {...formMessages.save} />
            </Button>
          </ButtonLayout>
        </Form>
      )}
    </Formik>
  );
};
