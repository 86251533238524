import { ContentContainer, PageLede, Section } from '@frontend/ui';
import { isNotNullOrUndefined } from '@frontend/utils';
import { EmptyState } from 'components/EmptyState';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { companySalaryExchangeMessages } from 'features/companies/company/salary-exchanges/messages';
import { ApproveSalaryExchangeRequests } from 'features/companies/company/salary-exchanges/requests/approve';
import { ApproveBonusSalaryExchangeRequests } from 'features/companies/company/salary-exchanges/requests/approve/bonus';
import { ApproveForm } from 'features/companies/company/salary-exchanges/requests/approve/components/Form';
import { Page } from 'features/page';
import { Suspense } from 'features/Suspense';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';

import { SalaryExchangeRouteMatchParams } from '../..';
import { CollectumInfo } from '../CollectumInfo';

export const Approve: React.FC<RouteComponentProps> = props => {
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();
  const { formatMessage } = useIntl();
  const { location } = props;
  const search = qs.parse(location.search);

  const requestIds: Array<string | null> = Array.isArray(search.requestIds)
    ? search.requestIds
    : [search.requestIds];

  const _requestIds = requestIds.filter(isNotNullOrUndefined);

  if (!_requestIds.length) {
    return <EmptyStatePage parentLink={`/companies/${companyId}`} />;
  }

  const title = formatMessage(companySalaryExchangeMessages.accept);

  return (
    <NavigationAnchor
      path={`${location.pathname}${location.search}`}
      title={title}
    >
      <Page
        parentLink={`/companies/${companyId}/salary-exchanges/requests`}
        title={title}
      >
        <Suspense fallback={<TopLoading />} batch emptyState={<EmptyState />}>
          {({ isEmpty, errors }) => (
            <ContentContainer>
              <Section>
                {errors && <GraphQlErrors errors={errors} />}
                {!isEmpty() && (
                  <PageLede>
                    <FormattedMessage
                      {...companySalaryExchangeMessages.approvalAdvinansDesc}
                    />
                    <CollectumInfo />
                  </PageLede>
                )}
                <ApproveForm
                  omitForm={isEmpty()}
                  requestIds={_requestIds}
                  {...props}
                >
                  <ApproveSalaryExchangeRequests requestIds={_requestIds} />
                  <ApproveBonusSalaryExchangeRequests
                    requestIds={_requestIds}
                  />
                </ApproveForm>
              </Section>
            </ContentContainer>
          )}
        </Suspense>
      </Page>
    </NavigationAnchor>
  );
};
