import { TopLoading } from 'components/TopLoading';
import {
  Confirmation,
  ConfirmationContextProvider,
} from 'contexts/confirmation';
import { CurrentUserContextProvider } from 'contexts/current-permissions';
import { Layout } from 'features/layout';
import { Logout } from 'features/logout';
import { NotificationCenter } from 'features/notifications/containers';
import { TransitionOverlay } from 'features/transition-overlay';
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import styled from 'styled-components';

import { permissionsQuery } from './apollo/graphql/types';
import { IndexRoute } from './pages';
import { AdvisorRouteWithSuspense } from './pages/advisor';
import { CompaniesRoute } from './pages/companies';
import { EmployeesRoute } from './pages/employees';
import { InsurancesRoute } from './pages/insurances';
import { LoginRoute } from './pages/login';
import { SmeRoutes } from './pages/sme';
import { PERMISSIONS_QUERY } from './queries';
import { useQuery } from './utils/use-query';

// Idle time of 1 800 000 ms = 30 min
const IDLE_TIME = 1800000;

// Poll roles once every minute
const PERMISSIONS_POLL_INTERVAL = 60000;

const BackstageRoute: React.FC<RouteComponentProps> = ({
  location: { pathname },
}) => {
  const [idle, setIdle] = useState(false);
  useIdleTimer({ onIdle: () => setIdle(true), timeout: IDLE_TIME });

  if (idle) {
    return (
      <Redirect
        to={{
          pathname: '/logout',
          state: {
            from: pathname,
            message: 'goodbye',
          },
        }}
      />
    );
  }

  return (
    <ConfirmationContextProvider>
      <CurrentUserContextProvider>
        <Confirmation />
        <Layout>
          <NotificationCenter />
          <TransitionOverlay pathname={pathname} />
          <Switch>
            <Route path="/companies" component={CompaniesRoute} />
            <Route path="/advisor" component={AdvisorRouteWithSuspense} />
            <Route path="/employees" component={EmployeesRoute} />
            <Route path="/insurances" component={InsurancesRoute} />
            <Route path="/sme" component={SmeRoutes} />
            <Route path="/" exact component={IndexRoute} />
          </Switch>
        </Layout>
      </CurrentUserContextProvider>
    </ConfirmationContextProvider>
  );
};

const AdaptableRootContainer = styled.div`
  height: 100%;
  padding: env(safe-area-inset-top, 0.75rem) env(safe-area-inset-right, 0.75rem)
    env(safe-area-inset-bottom, 0.75rem) env(safe-area-inset-left, 0.75rem);
`;

interface AdaptableRootProps {
  children: React.ReactNode;
}

const AdaptableRoot: React.FC<AdaptableRootProps> = ({ children }) => (
  <AdaptableRootContainer>{children}</AdaptableRootContainer>
);

export const Root: React.FC<RouteComponentProps> = ({
  location: { pathname, state },
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [shouldPoll, setShouldPoll] = useState(false);

  const { data, loading } = useQuery<permissionsQuery>(PERMISSIONS_QUERY, {
    pollInterval: shouldPoll ? PERMISSIONS_POLL_INTERVAL : undefined,
  });

  const isLoggedIn = !!data?.permissions;

  useEffect(() => {
    setShouldPoll(isLoggedIn);
  }, [isLoggedIn]);

  if (loading) {
    return <TopLoading />;
  }

  return (
    <AdaptableRoot>
      {!isLoggedIn ? (
        <Switch>
          <Route path="/login" component={LoginRoute} />
          <Route path="/logout" component={Logout} />
          <Redirect
            to={{
              pathname: '/login',
              state,
            }}
          />
        </Switch>
      ) : (
        <Switch>
          <Route path="/logout" component={Logout} />
          <Route path="/" component={BackstageRoute} />
        </Switch>
      )}
    </AdaptableRoot>
  );
};
