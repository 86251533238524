import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import startOfMonth from 'date-fns/startOfMonth';

/**
 * Ensures that the proposal start date is the current month or later.
 *
 * @param startDate - The proposal start date.
 * @param today - The current date.
 */
export const isValidProposalStartDate = (startDate: string, today: string) => {
  const firstOfcurrentMonth = startOfMonth(new Date(today));
  const firstOfStartDateMonth = startOfMonth(new Date(startDate));

  return (
    isEqual(firstOfcurrentMonth, firstOfStartDateMonth) ||
    isBefore(firstOfcurrentMonth, firstOfStartDateMonth)
  );
};
