import { ContentContainer, Grid } from '@frontend/ui';
import {
  analytics,
  loyalty,
  payments,
  people,
  settings,
  swapHorizontalCircle,
  work,
} from '@frontend/ui/icons';
import { companyFeatureMessages } from 'app/messages/company';
import { FormattedMessage } from 'components/formats';
import { GridCell33 } from 'components/GridCell';
import { NavigationCard } from 'components/NavigationCard';
import { Page } from 'features/page';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

export interface Props {
  title: string;
}

export const Company: React.FC<Props> = ({ title }) => {
  const match = useRouteMatch();
  return (
    <Page title={title} parentLink="/companies">
      <ContentContainer>
        <Grid>
          <GridCell33>
            <NavigationCard
              title={
                <FormattedMessage {...companyFeatureMessages.employeesTitle} />
              }
              description={
                <FormattedMessage
                  {...companyFeatureMessages.employeesDescription}
                />
              }
              icon={people}
              link={{ to: `${match.url}/employees` }}
            />
          </GridCell33>
          <GridCell33>
            <NavigationCard
              title={
                <FormattedMessage {...companyFeatureMessages.benefitsTitle} />
              }
              description={
                <FormattedMessage
                  {...companyFeatureMessages.benefitsDescription}
                />
              }
              icon={loyalty}
              link={{ to: `${match.url}/benefit-packages` }}
            />
          </GridCell33>
          <GridCell33>
            <NavigationCard
              title={
                <FormattedMessage
                  {...companyFeatureMessages.salaryExchangesTitle}
                />
              }
              description={
                <FormattedMessage
                  {...companyFeatureMessages.salaryExchangesDescription}
                />
              }
              icon={swapHorizontalCircle}
              link={{ to: `${match.url}/salary-exchanges` }}
            />
          </GridCell33>
          <GridCell33>
            <NavigationCard
              title={
                <FormattedMessage {...companyFeatureMessages.invoicesTitle} />
              }
              description={
                <FormattedMessage
                  {...companyFeatureMessages.invoicesDescription}
                />
              }
              icon={payments}
              link={{ to: `${match.url}/invoices` }}
            />
          </GridCell33>
          <GridCell33>
            <NavigationCard
              title={
                <FormattedMessage {...companyFeatureMessages.jobOffersTitle} />
              }
              description={
                <FormattedMessage
                  {...companyFeatureMessages.jobOffersDescription}
                />
              }
              icon={work}
              link={{ to: `${match.url}/job-offers` }}
            />
          </GridCell33>
          <GridCell33>
            <NavigationCard
              title={
                <FormattedMessage {...companyFeatureMessages.reportsTitle} />
              }
              description={
                <FormattedMessage
                  {...companyFeatureMessages.reportsDescription}
                />
              }
              icon={analytics}
              link={{ to: `${match.url}/reports` }}
            />
          </GridCell33>
          <GridCell33>
            <NavigationCard
              title={
                <FormattedMessage {...companyFeatureMessages.settingsTitle} />
              }
              description={
                <FormattedMessage
                  {...companyFeatureMessages.settingsDescription}
                />
              }
              icon={settings}
              link={{ to: `${match.url}/settings` }}
            />
          </GridCell33>
        </Grid>
      </ContentContainer>
    </Page>
  );
};
