import { CheckboxField, DatePickerField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import { stripSearchParams } from '@frontend/utils';
import { commonMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { smeCompanyProposalsMessages } from 'features/sme/messages/sme';
import { Form, Formik } from 'formik';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import * as Yup from 'yup';

import { useSubmit } from './utils/use-submit';

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    signedByRelevantParties: Yup.boolean().required(
      intl.formatMessage(commonMessages.requiredField),
    ),
    dateOfSigning: Yup.string().required(
      intl.formatMessage(commonMessages.requiredField),
    ),
  });

export interface FormValues {
  dateOfSigning: string;
  signedByRelevantParties: boolean;
}

export const initialValues: FormValues = {
  dateOfSigning: '',
  signedByRelevantParties: false,
};

export const ExecuteProposalModal: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const intl = useIntl();

  const { 'execute-proposal': proposalId } = qs.parse(location.search);

  const executeProposal = !!proposalId;

  const onRequestClose = () => {
    stripSearchParams(history, location, ['execute-proposal']);
  };

  const { submit, submissionError } = useSubmit({ onRequestClose, proposalId });

  return (
    <Modal isOpen={executeProposal} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage
          {...smeCompanyProposalsMessages.confirmSignedProposal}
        />
      </ModalHeader>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={submit}
        validateOnMount
        validationSchema={validationSchema(intl)}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ModalBody>
              <DatePickerField
                dense
                label={
                  <FormattedMessage
                    {...smeCompanyProposalsMessages.dateOfSigning}
                  />
                }
                name="dateOfSigning"
                required
              />
              <CheckboxField
                label={
                  <FormattedMessage
                    {...smeCompanyProposalsMessages.signedByAllPartiesConfirmation}
                  />
                }
                required
                name="signedByRelevantParties"
              />
              {submissionError && (
                <GraphQlError inModal error={submissionError} />
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button
                  text
                  type="submit"
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  <FormattedMessage
                    {...smeCompanyProposalsMessages.sendToProduction}
                  />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

interface Args {
  location: RouteComponentProps['location'];
  proposalId: string;
}

export const getExecuteProposalLink = ({
  location,
  proposalId,
}: Args): RouteComponentProps['location'] => ({
  ...location,
  search: qs.stringify({ 'execute-proposal': proposalId }),
});
