import { NumberField, SelectField as _SelectField } from '@frontend/formik';
import { Button, IconButton, media } from '@frontend/ui';
import { _delete, add } from '@frontend/ui/icons';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages, validationMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/companies/company';
import { formatRemunerationSuffix } from 'app/utils/format-remuneration-suffix';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import { MONTHLY_SALARY_REMUNERATION_TYPE_ID } from 'features/companies/company/utils/constants';
import { useRemunerationTypes } from 'features/companies/company/utils/use-remuneration-types';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';
import * as Yup from 'yup';

import { jobOfferFormMessages } from '../../../messages';

interface Remuneration {
  idCode: string;
  value?: string;
}

export interface FormValues {
  monthlySalary?: string;
  remunerations?: Remuneration[];
}

const remunerations = 'remunerations';

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    monthlySalary: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    [`${remunerations}`]: Yup.array()
      .of(
        Yup.object().shape({
          idCode: Yup.string().required(
            intl.formatMessage(validationMessages.mandatoryField),
          ),
          value: Yup.string().required(
            intl.formatMessage(validationMessages.mandatoryField),
          ),
        }),
      )
      .test({
        name: 'Unique select',
        message: intl.formatMessage(validationMessages.uniqueField),
        test: (value?: Remuneration[]) =>
          (value ?? []).length === new Set(value?.map(v => v.idCode)).size,
      }),
  });

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

const RemovableFieldContainer = styled.div`
  display: flex;
  button {
    margin-top: 0.125rem;
  }
  > :first-child {
    width: 100%;
    margin-right: 0.5rem;
  }
  > * {
    flex-shrink: 0;
  }
  ${media.lessThan('tablet')`
    width: 85%;
  `}
`;

const SelectField = styled(_SelectField)`
  margin-bottom: 0.5rem;
`;

export const RemunerationFields: React.FC = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { locale } = useIntlContext();
  const { values, errors } = useFormikContext<FormValues>();
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();
  const { remunerationTypes } = useRemunerationTypes({
    suspend: true,
    companyId,
  });

  const remunerationError = errors[remunerations];

  const options = remunerationTypes.reduce(
    (_options, type) =>
      type.id !== MONTHLY_SALARY_REMUNERATION_TYPE_ID
        ? [
            ..._options,
            {
              label: `${type.id} ${type.name ?? ''}`,
              value: type.id,
            },
          ]
        : _options,
    [],
  );

  const getRemunerationSuffix = (idCode?: string) => {
    const _remuneration = remunerationTypes.find(type => type.id === idCode);
    return formatRemunerationSuffix(
      intl,
      _remuneration?.currency,
      _remuneration?.unitCode,
    );
  };

  return (
    <FieldArray
      name={remunerations}
      render={({ push, remove }) => {
        const monthlySalarySuffixMessage = getRemunerationSuffix(
          MONTHLY_SALARY_REMUNERATION_TYPE_ID,
        );
        return (
          <Wrapper>
            <NumberField
              dense
              name="monthlySalary"
              label={<FormattedMessage {...commonMessages.monthlySalary} />}
              affix={monthlySalarySuffixMessage}
              required
              decimalScale={0}
              locale={locale}
            />

            {values.remunerations?.map((r, i) => {
              const unitCodeSuffixMessage = getRemunerationSuffix(r.idCode);

              return (
                <div key={i}>
                  <RemovableFieldContainer>
                    <SelectField
                      fixed
                      dense
                      name={`${remunerations}.${i}.idCode`}
                      options={options}
                      label={
                        <FormattedMessage
                          {...commonBenefitMessages.remunerationType}
                        />
                      }
                      required
                    />
                    <IconButton
                      size="small"
                      label={formatMessage(commonMessages.remove)}
                      icon={_delete}
                      onClick={() => remove(i)}
                    />
                  </RemovableFieldContainer>
                  <RemovableFieldContainer>
                    <NumberField
                      dense
                      name={`${remunerations}.${i}.value`}
                      label={<FormattedMessage {...commonMessages.value} />}
                      affix={unitCodeSuffixMessage}
                      required
                      decimalScale={0}
                      locale={locale}
                    />
                  </RemovableFieldContainer>
                </div>
              );
            })}

            {remunerationError && typeof remunerationError === 'string' && (
              <NotificationCard type="error">
                {remunerationError}
              </NotificationCard>
            )}

            <Button text icon={add} onClick={() => push({})}>
              <FormattedMessage {...jobOfferFormMessages.remunerationAdd} />
            </Button>
          </Wrapper>
        );
      }}
    />
  );
};
