import { defineMessages } from 'react-intl';

import { ValidAgreementStatus } from '..';

export const agreementMessages = defineMessages({
  poa: {
    id: 'agreementMessages.poa',
    defaultMessage: 'Fullmakt',
  },
  status: {
    id: 'agreementMessages.status',
    defaultMessage: 'Status',
  },
  other: {
    id: 'agreementMessages.other',
    defaultMessage: 'Övrigt',
  },
  document: {
    id: 'agreementMessages.document',
    defaultMessage: 'Dokument',
  },
  agreement: {
    id: 'agreementMessages.agreement',
    defaultMessage: 'Avtal',
  },
  noAgreements: {
    id: 'agreementMessages.noAgreements',
    defaultMessage: 'Det finns inga avtal att visa',
  },
  noAgreementsText: {
    id: 'agreementMessages.noAgreementsText',
    defaultMessage: 'När avtal lagts till kommer de att visas här.',
  },
  danicaAgreement: {
    id: 'agreementMessages.danicaAgreement',
    defaultMessage: 'Advinans Autopilot',
  },
  agreementNeededToAddEmployees: {
    id: 'agreementMessages.agreementNeededToAddEmployees',
    defaultMessage:
      'För att kunna lägga till anställda behöver ert företag ha ett avtal med Nordea Node',
  },
  euroAccidentGrupp: {
    id: 'agreementMessages.euroAccidentGrupp',
    defaultMessage: 'Euro Accident gruppavtal',
  },
  euroAccidentTjp: {
    id: 'agreementMessages.euroAccidentTjp',
    defaultMessage: 'Euro Accident tjp',
  },
  agreementsCreated: {
    id: 'agreementMessages.agreementsCreated',
    defaultMessage: 'Avtal har skapats',
  },
});

export const agreementStatusMessages = defineMessages<ValidAgreementStatus>({
  ACTIVE: {
    id: 'agreementStatusMessages.ACTIVE',
    defaultMessage: 'Aktivt',
  },
  INTERNAL_ERROR: {
    id: 'agreementStatusMessages.INTERNAL_ERROR',
    defaultMessage: 'Fel',
  },
  PREPARATION: {
    id: 'agreementStatusMessages.PREPARATION',
    defaultMessage: 'Inväntar svar',
  },
});
