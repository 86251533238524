import gql from 'graphql-tag';

export const DELETE_PROPOSAL_MUTATION = gql`
  mutation deleteProposalMutation($input: DeleteProposalInput!) {
    deleteProposal(input: $input) {
      clientMutationId
    }
  }
`;

export const DUPLICATE_PROPOSAL_MUTATION = gql`
  mutation duplicateProposalMutation($input: DuplicateProposalInput!) {
    duplicateProposal(input: $input) {
      id
      name
    }
  }
`;
