import { permissionsQuery } from 'app/apollo/graphql/types';
import { PERMISSIONS_QUERY } from 'app/queries';
import { useQuery } from 'app/utils/use-query';
import { TopLoading } from 'components/TopLoading';
import React, { useContext, useEffect, useState } from 'react';

export type Permissions = Record<string, Record<string, string[]>>;

export interface CurrentUser {
  firstName: string;
  permissions?: Permissions;
}

export const CurrentUserContext = React.createContext<CurrentUser>({
  firstName: '',
  permissions: {},
});

export const useCurrentUser = (): CurrentUser => useContext(CurrentUserContext);

const isValidCurrentUser = (
  currentUser?: CurrentUser,
): currentUser is CurrentUser => !!currentUser?.permissions;

interface State {
  firstName: string;
  currentUser?: Permissions;
}

interface Props {
  children: React.ReactNode;
}

export const CurrentUserContextProvider: React.FC<Props> = ({ children }) => {
  const [state, set] = useState<State>();

  const { data, loading } = useQuery<permissionsQuery>(PERMISSIONS_QUERY);

  useEffect(() => {
    const viewer = data?.adminViewer;
    if (viewer) {
      set(() => ({
        firstName: viewer.firstName,
        permissions: data.permissions,
      }));
    }
  }, [data]);

  if (loading) {
    return <TopLoading />;
  }

  if (!isValidCurrentUser(state)) {
    return null;
  }

  return (
    <CurrentUserContext.Provider value={state}>
      {children}
    </CurrentUserContext.Provider>
  );
};
