import { NumberField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import {
  singlePremiumDepositModalQuery,
  singlePremiumDepositModalQueryVariables,
} from 'app/apollo/graphql/types';
import {
  commonMessages,
  suffixMessages,
  validationMessages,
} from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { pensionMessages } from 'app/messages/pension';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { TopLoading } from 'components/TopLoading';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import { Form, Formik } from 'formik';
import qs from 'query-string';
import React from 'react';
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';
import * as Yup from 'yup';

import { getPensionInsurance } from '../utils/get-pension-insurance';
import { SINGLE_PREMIUM_DEPOSIT_MODAL_QUERY } from './graphql/queries';
import { useSubmit } from './use-submit';

export interface FormValues {
  insuranceInstituteId: string | null;
  insuranceNumber: string | null;
  premium?: string;
}

const singlePremiumDepositValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    insuranceNumber: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    premium: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export const SinglePremiumDepositModal: React.FC = () => {
  const intl = useIntl();
  const { locale } = useIntlContext();
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();
  const { push } = useHistory();
  const { search } = useLocation();
  const { single_premium_deposit: isOpen } = qs.parse(search);

  const { submit, submissionError } = useSubmit();

  const { data, loading } = useQuery<
    singlePremiumDepositModalQuery,
    singlePremiumDepositModalQueryVariables
  >(SINGLE_PREMIUM_DEPOSIT_MODAL_QUERY, {
    errorPolicy: 'all',
    skip: !isOpen,
    variables: { companyId, userAccountId },
  });

  if (!isOpen) {
    return null;
  }

  if (loading) {
    return <TopLoading />;
  }

  const onRequestClose = () =>
    push(`/companies/${companyId}/employees/${userAccountId}/occupational`);

  const pensionInsurance = getPensionInsurance(
    data?.membership?.insurances?.edges.map(({ node }) => node) ?? [],
  );

  if (!pensionInsurance) {
    return null;
  }

  const _insuranceNumber = pensionInsurance?.insuranceNumber;
  const _insuranceInstituteId = pensionInsurance?.institute.id;

  if (!_insuranceNumber) {
    return null;
  }

  return (
    <Modal isOpen onRequestClose={onRequestClose}>
      <Formik<FormValues>
        initialValues={{
          insuranceNumber: _insuranceNumber,
          insuranceInstituteId: _insuranceInstituteId,
        }}
        isInitialValid={false}
        validationSchema={singlePremiumDepositValidationSchema(intl)}
        onSubmit={submit}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <ModalHeader>
              <FormattedMessage {...pensionMessages.singlePremiumDeposit} />
            </ModalHeader>
            <ModalBody>
              <p>
                <FormattedMessage
                  {...pensionMessages.singlePremiumDepositDescription}
                />
              </p>
              <NumberField
                name="premium"
                label={intl.formatMessage(commonMessages.amountLabel)}
                affix={intl.formatMessage(suffixMessages.kr)}
                decimalScale={0}
                required
                locale={locale}
              />
              {submissionError && (
                <GraphQlError inModal error={submissionError} />
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button
                  type="submit"
                  filled
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  <FormattedMessage {...commonMessages.confirm} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const getUpdateSinglePremiumLink = (
  location: RouteComponentProps['location'],
): RouteComponentProps['location'] => ({
  ...location,
  search: qs.stringify({ single_premium_deposit: true }),
});
