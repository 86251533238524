import gql from 'graphql-tag';

export const SME_COMPANY_EMPLOYEES_QUERY = gql`
  query smeCompanyEmployeesQuery($id: ID!) {
    proposal(id: $id) {
      id
      memberships {
        id
        benefitPackage {
          id
          name
        }
        firstName
        isFcfw
        isOwner
        lastName
        monthlySalary
        personalIdentityNumber
      }
    }
  }
`;

export const employersPensionBenefitConfigWithFixedPremium = gql`
  fragment employersPensionBenefitConfigWithFixedPremium on ProposalEmployersPensionBenefitConfig {
    premiumCalculationMethod
    extraFixedPremium
    __typename
  }
`;

export const occupationalPensionBenefitConfigWithFixedPremium = gql`
  fragment occupationalPensionBenefitConfigWithFixedPremium on ProposalOccupationalPensionBenefitConfig {
    premiumCalculationMethod
    extraFixedPremium
    __typename
  }
`;

export const SME_COMPANY_CREATE_EMPLOYEE_QUERY = gql`
  query smeCompanyCreateEmployeeQuery($id: ID!) {
    proposal(id: $id) {
      id
      benefitPackages {
        id
        name
        benefits {
          id
          type
          configuration {
            __typename
            ...employersPensionBenefitConfigWithFixedPremium
            ...occupationalPensionBenefitConfigWithFixedPremium
          }
        }
      }
    }
  }
  ${employersPensionBenefitConfigWithFixedPremium}
  ${occupationalPensionBenefitConfigWithFixedPremium}
`;
