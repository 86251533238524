import {
  CardPrimaryAction,
  Figure,
  Icon,
  SnapshotCardLayout,
} from '@frontend/ui';
import { moneyHand } from '@frontend/ui/icons/legacy';
import {
  FlexSalaryExchangeRequestStatus,
  upcomingSalaryExchangesCardQuery,
  upcomingSalaryExchangesCardQueryVariables,
} from 'app/apollo/graphql/types';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { CardGridCell } from 'components/GridCell';
import { Card } from 'components/LinkedCard';
import { LinkScrollTo } from 'components/LinkScrollTo';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { companySalaryExchangeMessages } from '../../messages';
import { UPCOMING_SX_TABLE_ID } from '../components/Table';
import { UPCOMING_SALARY_EXCHANGES_CARD_QUERY } from './graphql/queries';

export const UpcomingSalaryExchangesCard: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const { data } = useQuery<
    upcomingSalaryExchangesCardQuery,
    upcomingSalaryExchangesCardQueryVariables
  >(UPCOMING_SALARY_EXCHANGES_CARD_QUERY, {
    errorPolicy: 'all',
    suspend: true,
    variables: {
      companyId,
      statuses: [FlexSalaryExchangeRequestStatus.APPROVED],
    },
  });
  const count = data?.salaryExchangeRequests?.totalCount;

  if (count == null) {
    return null;
  }

  return (
    <CardGridCell>
      <Card fullHeight>
        <LinkScrollTo to={`#${UPCOMING_SX_TABLE_ID}`} fullWidth>
          <CardPrimaryAction>
            <SnapshotCardLayout
              icon={<Icon icon={moneyHand} color="primary" size="medium" />}
              title={
                <FormattedMessage
                  {...companySalaryExchangeMessages.upcomingSalaryExchanges}
                />
              }
              figure1={
                <Figure
                  title={
                    <FormattedMessage
                      {...companySalaryExchangeMessages.salaryExchangesCount}
                      values={{ count }}
                    />
                  }
                  description={
                    <FormattedMessage
                      {...companySalaryExchangeMessages.upcomingSalaryExchangesDesc}
                    />
                  }
                />
              }
            />
          </CardPrimaryAction>
        </LinkScrollTo>
      </Card>
    </CardGridCell>
  );
};
