import { useMutation } from '@apollo/client';
import {
  addAccountingDimensionMutation,
  addAccountingDimensionMutationVariables,
} from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { Formik } from 'formik';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';

import { CostReportingModal } from '../components/Modal';
import { ADD_ACCOUNTING_DIMENSION_MUTATION } from '../graphql/mutations';
import { ACCOUNTING_DIMENSIONS_QUERY } from '../graphql/queries';
import { costReportingMessages } from '../messages';

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    identifier: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid identifier',
        intl.formatMessage(validationMessages.isAbove20),
        value => !value || parseFloat(value) >= 20,
      ),
  });

interface FormValues {
  identifier: string;
  name: string;
}

export const CreateDimensionModal: React.FC = () => {
  const intl = useIntl();
  const { push } = useHistory();
  const { search, pathname } = useLocation();
  const { send } = useNotification();
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const onCompleted = () => {
    send({
      type: 'success',
      message: (
        <FormattedMessage {...costReportingMessages.createDimensionSuccess} />
      ),
    });
    push(pathname);
  };

  const [addAccountingDimension, { error }] = useMutation<
    addAccountingDimensionMutation,
    addAccountingDimensionMutationVariables
  >(ADD_ACCOUNTING_DIMENSION_MUTATION, {
    onCompleted,
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    refetchQueries: [
      {
        query: ACCOUNTING_DIMENSIONS_QUERY,
        variables: {
          companyId,
        },
      },
    ],
  });

  const { create: isOpen } = qs.parse(search);

  if (!isOpen) {
    return null;
  }

  return (
    <Formik<FormValues>
      validationSchema={validationSchema(intl)}
      initialValues={{
        identifier: '',
        name: '',
      }}
      onSubmit={({ identifier, name }) => {
        addAccountingDimension({
          variables: { input: { identifier, name, companyId } },
        });
      }}
    >
      {({ isValid }) => (
        <CostReportingModal
          title={
            <FormattedMessage {...costReportingMessages.createDimension} />
          }
          onClose={() => push(pathname)}
          code="identifier"
          name="name"
          isValid={isValid}
          error={error}
        />
      )}
    </Formik>
  );
};
