import {
  CompanyRepresentativeIdentificationType,
  CompanyRepresentativeRole,
  LegalEntityType,
  PaymentMethod,
  ProposalAccidentLevel,
  ProposalAdviceOption,
  ProposalBaseAmountType,
  ProposalBenefitType,
  ProposalCompanySize,
  ProposalDefaultBenefitPackage,
  ProposalDefaultManagementType,
  ProposalHealthcareLevel,
  ProposalLifeLevel,
  ProposalPremiumCalculationMethod,
  ProposalPremiumDeductions,
  ProposalSicknessLevel,
  ProposalStatus,
  ProposalStatusFilter,
  RiskBucket,
} from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

export const smeCompanyMessages = defineMessages({
  proposal: {
    id: 'smeCompanyMessages.proposal',
    defaultMessage: 'Offert',
  },
  searchCompanies: {
    id: 'smeCompanyMessages.searchCompanies',
    defaultMessage: 'Sök företag',
  },
  all: {
    id: 'smeCompanyMessages.all',
    defaultMessage: 'Alla',
  },
  myCompanies: {
    id: 'smeCompanyMessages.myCompanies',
    defaultMessage: 'Mina företag',
  },
  companyName: {
    id: 'smeCompanyMessages.companyName',
    defaultMessage: 'Företagsnamn',
  },
  seller: {
    id: 'smeCompanyMessages.seller',
    defaultMessage: 'Säljare',
  },
  latestQuote: {
    id: 'smeCompanyMessages.latestQuote',
    defaultMessage: 'Senaste offert',
  },
  chooseSeller: {
    id: 'smeCompanyMessages.chooseSeller',
    defaultMessage: 'Välj säljare',
  },
  status: {
    id: 'smeCompanyMessages.status',
    defaultMessage: 'Status',
  },
  emptyStateHeader: {
    id: 'smeCompanyMessages.emptyStateHeader',
    defaultMessage: 'Det finns inga företag än',
  },
  emptyStateDescription: {
    id: 'smeCompanyMessages.emptyStateDescription',
    defaultMessage:
      'När du lägger till ett nytt företag kommer det att visas här',
  },
  noSearchResults: {
    id: 'smeCompanyMessages.noSearchResults',
    defaultMessage: 'Inga företag matchar sökningen',
  },
  organisationInformation: {
    id: 'smeCompanyMessages.organisationInformation',
    defaultMessage: 'Uppgifter om organisationen',
  },
  createNewCompany: {
    id: 'smeMessages.createNewCompany',
    defaultMessage: 'Lägg till nytt företag',
  },
  editOrganisationInformation: {
    id: 'smeCompanyMessages.editOrganisationInformation',
    defaultMessage: ' Ändra företagsuppgifter',
  },
  editOrganisationInformationSubmitSuccess: {
    id: 'formSubmitMessages.editOrganisationInformationSubmitSuccess',
    defaultMessage: 'Uppgifterna har sparats',
  },
  registrationNumber: {
    id: 'smeCompanyMessages.registrationNumber',
    defaultMessage: 'Person-/Organisationsnummer',
  },
  salesRep: {
    id: 'smeCompanyMessages.salesRep',
    defaultMessage: 'Ansvarig säljare',
  },
  name: {
    id: 'smeCompanyMessages.name',
    defaultMessage: 'Företagets namn',
  },
  legalEntityType: {
    id: 'smeCompanyMessages.legalEntityType',
    defaultMessage: 'Bolagsform',
  },
  partOfGroup: {
    id: 'smeCompanyMessages.partOfGroup',
    defaultMessage: 'Del i koncern',
  },
  accountNumber: {
    id: 'smeCompanyMessages.accountNumber',
    defaultMessage: 'Kontonummer',
  },
  address: {
    id: 'smeCompanyMessages.address',
    defaultMessage: 'Företagsadress',
  },
  clearingSalesOffice: {
    id: 'smeCompanyMessages.clearingSalesOffice',
    defaultMessage: 'Clearing ansvarigt kontor',
  },
  contactName: {
    id: 'smeCompanyMessages.contactName',
    defaultMessage: 'Namn kontaktperson',
  },
  contactFirstName: {
    id: 'smeCompanyMessages.contactFirstName',
    defaultMessage: 'Förnamn kontaktperson',
  },
  contactLastName: {
    id: 'smeCompanyMessages.contactLastName',
    defaultMessage: 'Efternamn kontaktperson',
  },
  contactPid: {
    id: 'smeCompanyMessages.contactPid',
    defaultMessage: 'Personnummer kontaktperson',
  },
  contactEmail: {
    id: 'smeCompanyMessages.contactEmail',
    defaultMessage: 'E-post kontaktperson',
  },
  contactPhone: {
    id: 'smeCompanyMessages.contactPhone',
    defaultMessage: 'Telefonnummer kontaktperson',
  },
  adminName: {
    id: 'smeCompanyMessages.adminName',
    defaultMessage: 'Namn huvudadministratör',
  },
  adminFirstName: {
    id: 'smeCompanyMessages.adminFirstName',
    defaultMessage: 'Förnamn huvudadministratör',
  },
  adminLastName: {
    id: 'smeCompanyMessages.adminLastName',
    defaultMessage: 'Efternamn huvudadministratör',
  },
  adminPid: {
    id: 'smeCompanyMessages.adminPid',
    defaultMessage: 'Personnummer huvudadministratör',
  },
  adminEmail: {
    id: 'smeCompanyMessages.adminEmail',
    defaultMessage: 'E-post huvudadministratör',
  },
  adminPhone: {
    id: 'smeCompanyMessages.adminPhone',
    defaultMessage: 'Telefonnummer huvudadministratör',
  },
  accountClosureMonth: {
    id: 'smeCompanyMessages.accountClosureMonth',
    defaultMessage: 'Bokslutsmånad',
  },
  paymentMethod: {
    id: 'smeCompanyMessages.paymentMethod',
    defaultMessage: 'Betalningsmetod',
  },
  salaryReviewMonth: {
    id: 'smeCompanyMessages.salaryReviewMonth',
    defaultMessage: 'Avvikande lönerevisionsmånad',
  },
  separateContactAndAdmin: {
    id: 'smeCompanyMessages.separateContactAndAdmin',
    defaultMessage: 'Ange en annan person som kontaktperson',
  },
});

export const smeAccountNumberMessages = defineMessages<
  Exclude<PaymentMethod, 'INVOICE'>
>({
  AUTOGIRO: smeCompanyMessages.accountNumber,
  HAMT_ACCOUNT: smeCompanyMessages.accountNumber,
  HAMT_PLUSGIRO: {
    id: 'smeAccountNumberMessages.HAMT_PLUSGIRO',
    defaultMessage: 'Plusgiro',
  },
});

export const smePaymentMethodMessages = defineMessages<PaymentMethod>({
  AUTOGIRO: {
    id: 'smePaymentMethodMessages.AUTOGIRO',
    defaultMessage: 'Autogiro',
  },
  HAMT_ACCOUNT: {
    id: 'smePaymentMethodMessages.HAMT_ACCOUNT',
    defaultMessage: 'Hämt, konto',
  },
  HAMT_PLUSGIRO: {
    id: 'smePaymentMethodMessages.HAMT_PLUSGIRO',
    defaultMessage: 'Hämt, plusgiro',
  },
  INVOICE: {
    id: 'smePaymentMethodMessages.INVOICE',
    defaultMessage: 'Faktura',
  },
});

export const smeLatestProposalStatusMessages =
  defineMessages<ProposalStatusFilter>({
    NO_PROPOSALS: {
      id: 'smelatestProposalStatusMessages.NO_PROPOSALS',
      defaultMessage: 'Offert saknas',
    },
    STARTED: {
      id: 'smelatestProposalStatusMessages.STARTED',
      defaultMessage: 'Påbörjad offert',
    },
    GENERATED: {
      id: 'smelatestProposalStatusMessages.GENERATED',
      defaultMessage: 'Utskriven offert',
    },
    IN_PRODUCTION: {
      id: 'smelatestProposalStatusMessages.IN_PRODUCTION',
      defaultMessage: 'I produktion',
    },
  });

export const smeCompanyLegalEntityTypeMessages =
  defineMessages<LegalEntityType>({
    BRANCH: {
      id: 'smeCompanyLegalEntityTypeMessages.BRANCH',
      defaultMessage: 'Filial',
    },
    ECONOMIC_ASSOCIATION: {
      id: 'smeCompanyLegalEntityTypeMessages.ECONOMIC_ASSOCIATION',
      defaultMessage: 'Ekonomisk förening',
    },
    FOUNDATION: {
      id: 'smeCompanyLegalEntityTypeMessages.FOUNDATION',
      defaultMessage: 'Stiftelse',
    },
    LIMITED_COMPANY: {
      id: 'smeCompanyLegalEntityTypeMessages.LIMITED_COMPANY',
      defaultMessage: 'Aktiebolag',
    },
    LIMITED_PARTNERSHIP: {
      id: 'smeCompanyLegalEntityTypeMessages.LIMITED_PARTNERSHIP',
      defaultMessage: 'Kommanditbolag',
    },
    NONPROFIT_ASSOCIATION: {
      id: 'smeCompanyLegalEntityTypeMessages.NONPROFIT_ASSOCIATION',
      defaultMessage: 'Ideell förening',
    },
    SOLE_TRADER: {
      id: 'smeCompanyLegalEntityTypeMessages.SOLE_TRADER',
      defaultMessage: 'Enskild näringsidkare',
    },
    TRADING_PARTNERSHIP: {
      id: 'smeCompanyLegalEntityTypeMessages.TRADING_PARTNERSHIP',
      defaultMessage: 'Handelsbolag',
    },
  });

export const smeCreateCompanyMessages = defineMessages({
  addCompany: {
    id: 'smeCreateCompanyMessages.addCompany',
    defaultMessage: 'Lägg till företag',
  },
  customerInformation: {
    id: 'smeCreateCompanyMessages.customerInformation',
    defaultMessage: 'Kunduppgifter',
  },
  customerInformationDescription: {
    id: 'smeCreateCompanyMessages.customerInformationDescription',
    defaultMessage:
      'Du kommer ha möjlighet att redigera eller komplettera dessa uppgifter i ett senare skede.',
  },
  created: {
    id: 'smeCreateCompanyMessages.created',
    defaultMessage: 'Företag har skapats',
  },
});

export const smeCompanyProposalsMessages = defineMessages({
  proposals: {
    id: 'smeCompanyProposalsMessages.proposals',
    defaultMessage: 'Offerter',
  },
  confirmDeleteProposal: {
    id: 'smeCompanyProposalsMessages.confirmDeleteProposal',
    defaultMessage: 'Radera {proposalName}?',
  },
  downloadAttachment: {
    id: 'smeCompanyProposalsMessages.downloadAttachment',
    defaultMessage: 'Ladda ned {attachmentName}',
  },
  downloadProposal: {
    id: 'smeCompanyProposalsMessages.downloadProposal',
    defaultMessage: 'Ladda ned offert',
  },
  noProposalsTitle: {
    id: 'smeCompanyProposalsMessages.noProposalsTitle',
    defaultMessage: 'Det finns inga påbörjade offerter att visa',
  },
  noProposalsDescription: {
    id: 'smeCompanyProposalsMessages.noProposalsDescription',
    defaultMessage:
      'När du skapar en ny offert kommer du hitta den här, samt se status.',
  },
  proposalName: {
    id: 'smeCompanyProposalsMessages.proposalName',
    defaultMessage: 'Namn på offert',
  },
  createdBy: {
    id: 'smeCompanyProposalsMessages.createdBy',
    defaultMessage: 'Skapad av',
  },
  lastEditedAt: {
    id: 'smeCompanyProposalsMessages.lastEditedAt',
    defaultMessage: 'Senast ändrad',
  },
  lastEditedBy: {
    id: 'smeCompanyProposalsMessages.lastEditedBy',
    defaultMessage: 'Senast ändrad av',
  },
  editOffer: {
    id: 'smeCompanyProposalsMessages.editOffer',
    defaultMessage: 'Redigera offert',
  },
  editProposal: {
    id: 'smeCompanyProposalsMessages.editProposal',
    defaultMessage: 'Redigera {name}',
  },
  changeName: {
    id: 'smeCompanyProposalsMessages.changeName',
    defaultMessage: 'Ändra namn',
  },
  editProposalName: {
    id: 'smeCompanyProposalsMessages.editProposalName',
    defaultMessage: 'Ändra namn på offert',
  },
  nameUpdated: {
    id: 'smeCompanyProposalsMessages.nameUpdated',
    defaultMessage: 'Uppgifterna har sparats',
  },
  deleteProposal: {
    id: 'smeCompanyProposalsMessages.deleteProposal',
    defaultMessage: 'Radera utkast',
  },
  deleteProposalNotification: {
    id: 'smeCompanyProposalsMessages.deleteProposalNotification',
    defaultMessage: 'Utkast raderat',
  },
  duplicateProposal: {
    id: 'smeCompanyProposalsMessages.duplicateProposal',
    defaultMessage: 'Duplicera offert',
  },
  duplicateProposalNotification: {
    id: 'smeCompanyProposalsMessages.duplicateProposalNotification',
    defaultMessage: 'Offert duplicerad.',
  },
  viewDuplicatedProposal: {
    id: 'smeCompanyProposalsMessages.viewDuplicatedProposal',
    defaultMessage: 'Visa "{name}".',
  },
  sendToProduction: {
    id: 'smeCompanyProposalsMessages.sendToProduction',
    defaultMessage: 'Skicka till produktion',
  },
  confirmSignedProposal: {
    id: 'smeCompanyProposalsMessages.confirmSignedProposal',
    defaultMessage: 'Bekräfta signerad offert',
  },
  dateOfSigning: {
    id: 'smeCompanyProposalsMessages.dateOfSigning',
    defaultMessage: 'Datum för signering',
  },
  signedByAllPartiesConfirmation: {
    id: 'smeCompanyProposalsMessages.signedByAllPartiesConfirmation',
    defaultMessage:
      'Jag bekräftar att offerten signerats av alla parter och kan skickas till produktion.',
  },
  proposalSentToProduction: {
    id: 'smeCompanyProposalsMessages.proposalExecuted',
    defaultMessage: '"{name}" skickas till production',
  },
});

export const smeProposalStatusMessages = defineMessages<ProposalStatus>({
  STARTED: {
    id: 'smeProposalStatusMessages.STARTED',
    defaultMessage: 'Påbörjad offert',
  },
  GENERATED: {
    id: 'smeProposalStatusMessages.GENERATED',
    defaultMessage: 'Utskriven offert',
  },
  IN_PRODUCTION: {
    id: 'smeProposalStatusMessages.IN_PRODUCTION',
    defaultMessage: 'I produktion',
  },
});

export const smeCreateProposalMessages = defineMessages({
  create: {
    id: 'smeCreateProposalMessages.create',
    defaultMessage: 'Skapa ny offert',
  },
  nInsured: {
    id: 'smeCreateProposalMessages.nInsured',
    defaultMessage:
      'Antal försäkrade (om koncern, ange alla som ska försäkras)',
  },
  proposalCreated: {
    id: 'smeCreateProposalMessages.proposalCreated',
    defaultMessage: 'Offert skapad',
  },
});

export const smeProposalCompanySizeMessages = defineMessages<
  Exclude<ProposalCompanySize, 'GTE_1000'>
>({
  LT_10: {
    id: 'smeProposalCompanySizeMessages.LTE_5',
    defaultMessage: '1-9',
  },
  LT_50: {
    id: 'smeProposalCompanySizeMessages.LTE_50',
    defaultMessage: '10-49',
  },
  GTE_50: {
    id: 'smeProposalCompanySizeMessages.GTE_50',
    defaultMessage: '50-249',
  },
  GTE_250: {
    id: 'smeProposalCompanySizeMessages.GTE_250',
    defaultMessage: '250-999',
  },
});

export const smeProposalAdviceOptionMessages =
  defineMessages<ProposalAdviceOption>({
    ADVICE_REQUIRED: {
      id: 'smeProposalAdviceOptionMessages.ADVICE_REQUIRED',
      defaultMessage: 'Skapa offert med rådgivning',
    },
    ADVICE_WAIVED_CUSTOMER_INITIATED: {
      id: 'smeProposalAdviceOptionMessages.ADVICE_WAIVED_CUSTOMER_INITIATED',
      defaultMessage:
        'Skapa offert som är på uppdrag av kunden, ingen rådgivning lämnas.',
    },
    ADVICE_WAIVED_OWNER: {
      id: 'smeProposalAdviceOptionMessages.ADVICE_WAIVED_OWNER',
      defaultMessage:
        'Skapa offert för Egenföretagarpaket, ingen rådgivning lämnas.',
    },
  });

export const smeNeedsMessages = defineMessages({
  needs: {
    id: 'smeNeedsMessages.needs',
    defaultMessage: 'Behovsanalys',
  },
  needsTitle: {
    id: 'smeNeedsMessages.needsTitle',
    defaultMessage: 'Nuläge och behovsanalys',
  },
  needsDescription: {
    id: 'smeNeedsMessages.needsDescription',
    defaultMessage:
      'Här ska du sammanfatta kundens nuläge och vad kunden har för önskemål. De behov som uppkommit i behovsanalysen och notera vad kunden har för mål med sitt sparande. Därtill vilken information som du har tagit hänsyn till och ligger till grund för de försäkringsråd som lämnas.',
  },
  situationTemplateSelectLabel: {
    id: 'smeNeedsMessages.situationTemplateSelectLabel',
    defaultMessage: 'Välj textalternativ',
  },
  situationTemplateEditorPlaceholder: {
    id: 'smeNeedsMessages.situationTemplateEditorPlaceholder',
    defaultMessage: 'Skriv eller välj textalternativ...',
  },
  lacksExistingInsurances: {
    id: 'smeNeedsMessages.lacksExistingInsurances',
    defaultMessage: 'Saknar befintliga försäkringslösningar',
  },
  lacksExistingInsurancesPlaceholder: {
    id: 'smeNeedsMessages.lacksExistingInsurancesPlaceholder',
    defaultMessage: `
      <p>Ni har idag inga försäkringslösningar för dig som ägare i Aktiebolaget. Ert mål med mötet var att få information och rådgivning avseende företagsbetald pensionssparande.</p>
      <p>Jag ser ett behov att se över möjligheterna att teckna upp kompletterande riskförsäkringar för er som företagare. Då bolaget är nystartat vill ni inte ta på er för stora kostnader inledningsvis utan först bygga upp ett kapital i bolaget.</p>
      <p>Målet med pensionssparandet är att ge dig en avsättning motsvarande vad du haft som anställd.</p>
    `,
  },
  insurancesWithOtherCompany: {
    id: 'smeNeedsMessages.insurancesWithOtherCompany',
    defaultMessage: 'Försäkringslösning i annat bolag',
  },
  insurancesWithOtherCompanyPlaceholder: {
    id: 'smeNeedsMessages.insurancesWithOtherCompanyPlaceholder',
    defaultMessage: `
      <p>Idag ligger tjänstepensionslösningar genom x och ni önskar ha en lösning framöver som är marknadsmässig gällande både ålderspension och riskförsäkringar för dig som ägare. Önskemål att samla allt för bolaget till en och samma aktör för att underlätta vid rådgivning. Målet med sparandet är att bygga ett pensionskapital på lång sikt. Genom informationsfullmakt har vi inhämtat uppgifter gällande försäkringarnas omfattning och innehåll. Nuvarande upplägg innehåller:</p>
      <ul>
        <li>Ålderspension med fondförvaltning</li>
        <li>Premiebefrielseförsäkring</li>
        <li>Sjukförsäkring</li>
        <li>Olycksfallsförsäkring 20 pbb</li>
        <li>Privatvårdsförsäkring</li>
      </ul>
      <p>Min bedömning är att ni har ett bra försäkringsupplägg, men X ()</p>
    `,
  },
  collectiveAgreementBlueCollarDescription: {
    id: 'smeNeedsMessages.collectiveAgreementBlueCollarDescription',
    defaultMessage:
      'Företaget omfattas av kollektivavtal för arbetare eller har frivilligt försäkringsavtal med Fora för anställda arbetare.',
  },
  collectiveAgreementWhiteCollarDescription: {
    id: 'smeNeedsMessages.collectiveAgreementWhiteCollarDescription',
    defaultMessage:
      'Företaget omfattas av kollektivavtal för tjänstemän (via hängavtal eller medlemskap i arbetsgivarorganisation).',
  },
  documentationProvidedDescription: {
    id: 'smeNeedsMessages.documentationProvidedDescription',
    defaultMessage:
      'Har du säkerställt att du inför rådgivningen gått igenom företagets relevanta juridiska dokument (t.ex. pensionspolicy, kompanjonsavtal eller vd-avtal).',
  },
});

export const smeRepresentativesMessages = defineMessages({
  title: {
    id: 'smeRepresentativesMessages.title',
    defaultMessage: 'Behörig företrädare för företaget',
  },
  roleDescription: {
    id: 'smeRepresentativesMessages.roleDescription',
    defaultMessage: 'Rollbeskrivning',
  },
  noRepresentatives: {
    id: 'smeRepresentativesMessages.noRepresentatives',
    defaultMessage: 'Du har inte lagt till några företrädade ännu.',
  },
  legitimized: {
    id: 'smeRepresentativesMessages.legitimized',
    defaultMessage: 'Legitimerad',
  },
  identifier: {
    id: 'smeRepresentativesMessages.identifier',
    defaultMessage: 'Nr KK/ID/Pass',
  },
  sameContactAndRepresentative: {
    id: 'smeRepresentativesMessages.sameContactAndRepresentative',
    defaultMessage: 'Ange samma person som är kontaktperson för företaget',
  },
  createRepresentative: {
    id: 'smeRepresentativesMessages.createRepresentative',
    defaultMessage: 'Lägg till företrädare',
  },
  representativeCreated: {
    id: 'smeRepresentativesMessages.representativeCreated',
    defaultMessage: 'Företrädare tillagd',
  },
  editRepresentative: {
    id: 'smeRepresentativesMessages.editRepresentative',
    defaultMessage: 'Ändra företrädare',
  },
  representativeEdited: {
    id: 'smeRepresentativesMessages.representativeEdited',
    defaultMessage: 'Företrädare ändrad',
  },
  confirmDeleteRepresentative: {
    id: 'smeRepresentativesMessages.confirmDeleteRepresentative',
    defaultMessage: 'Ta bort företrädare?',
  },
  representativeDeleted: {
    id: 'smeRepresentativesMessages.representativeDeleted',
    defaultMessage: 'Företrädare borttagen',
  },
});

export const smeRepresentativeRolesMessages =
  defineMessages<CompanyRepresentativeRole>({
    REPRESENTATIVE: {
      id: 'smeRepresentativeRolesMessages.REPRESENTATIVE',
      defaultMessage: 'Företrädare',
    },
    SIGNATORY: {
      id: 'smeRepresentativeRolesMessages.SIGNATORY',
      defaultMessage: 'Firmatecknare',
    },
  });

export const smeRepresentativeIdentificationTypesMessages =
  defineMessages<CompanyRepresentativeIdentificationType>({
    BANKID: {
      id: 'smeRepresentativeIdentificationTypesMessages.BANKID',
      defaultMessage: 'Bank-ID',
    },
    DRIVERS_LICENSE: {
      id: 'smeRepresentativeIdentificationTypesMessages.DRIVERS_LICENSE',
      defaultMessage: 'Körkort',
    },
    EU_PASSPORT: {
      id: 'smeRepresentativeIdentificationTypesMessages.EU_PASSPORT',
      defaultMessage: 'EU Pass',
    },
    ID_BY_OTHER_ADVISOR: {
      id: 'smeRepresentativeIdentificationTypesMessages.ID_BY_OTHER_ADVISOR',
      defaultMessage: 'Legitimerad av annan rådgivare',
    },
    NATIONAL_ID_CARD: {
      id: 'smeRepresentativeIdentificationTypesMessages.NATIONAL_ID_CARD',
      defaultMessage: 'Nationellt / SKVs ID-Kort',
    },
    SSI_ID_CARD: {
      id: 'smeRepresentativeIdentificationTypesMessages.SSI_ID_CARD',
      defaultMessage: 'SSI märkt ID-kort',
    },
  });

export const smeKnowledgeMessages = defineMessages({
  knowledge: {
    id: 'smeKnowledgeMessages.knowledge',
    defaultMessage: 'Produktkunskap',
  },
  adviceSavingsInsuranceDescription: {
    id: 'smeKnowledgeMessages.adviceSavingsInsuranceDescription',
    defaultMessage: 'Råd kommer även att ges på sparförsäkringar',
  },
  recentExperiencePensionDescription: {
    id: 'smeKnowledgeMessages.recentExperiencePensionDescription',
    defaultMessage:
      'Har du inom de fem senaste åren erfarenhet av företagsbetald pensionsförsäkring?',
  },
  professionalExperiencePensionDescription: {
    id: 'smeKnowledgeMessages.professionalExperiencePensionDescription',
    defaultMessage:
      'Har du i nuvarande eller tidigare yrke arbetat med pensionsförsäkringar?',
  },
  instrumentKnowledgeDescription: {
    id: 'smeKnowledgeMessages.instrumentKnowledgeDescription',
    defaultMessage: 'Produktkunskap Tjänstepension Fond/Garanti/Depå',
  },
  ensuredRepresenativeKnowledge: {
    id: 'smeKnowledgeMessages.ensuredRepresenativeKnowledge',
    defaultMessage:
      'Vi har säkerställt att företagets företrädare har kunskap om att:',
  },
  notesHeading: {
    id: 'smeKnowledgeMessages.notesHeading',
    defaultMessage: 'Notering om produktkunskap',
  },
  notesDescription: {
    id: 'smeKnowledgeMessages.notesDescription',
    defaultMessage:
      'Innan vi ger råd om pensionslösningar måste vi försäkra oss om att listade företrädare för företaget förstår produktens egenskaper. Välj det alternativ som beskriver bedömningen av listade företrädares kunskap och erfarenhet av pensionsprodukter.',
  },
  lacksPreviousKnowledge: {
    id: 'smeKnowledgeMessages.lacksPreviousKnowledge',
    defaultMessage:
      'Saknar tidigare kunskap och erfarenhet av likvärdig produkt',
  },
  lacksPreviousKnowledgePlaceholder: {
    id: 'smeKnowledgeMessages.lacksPreviousKnowledgePlaceholder',
    defaultMessage:
      'Listade företrädare har ingen tidigare kunskap och erfarenhet av likvärdiga produkter. Nordeas rådgivare har därför förklarat och gått igenom produktens egenskaper för att säkerställa att nödvändig kunskap finns. Bedömningen därefter är att listade företrädare har tillräcklig kunskap om produkten och förståelse för dess avgifter, avkastning, risker, valbara investeringsalternativ och utbetalningsålder.',
  },
  somePreviousKnowledge: {
    id: 'smeKnowledgeMessages.somePreviousKnowledge',
    defaultMessage: 'Viss kunskap och erfarenhet av likvärdig produkt',
  },
  somePreviousKnowledgePlaceholder: {
    id: 'smeKnowledgeMessages.somePreviousKnowledgePlaceholder',
    defaultMessage:
      'Listade företrädare har viss kunskap och erfarenhet av likvärdiga produkter. Nordeas rådgivare har förklarat och gått igenom produktens egenskaper för att säkerställa att nödvändig kunskap finns. Bedömningen därefter är att listade företrädare har tillräcklig kunskap om produkten och förståelse för dess avgifter, avkastning, risker, valbara investeringsalternativ och utbetalningsålder.',
  },
  goodPreviousKnowledge: {
    id: 'smeKnowledgeMessages.goodPreviousKnowledge',
    defaultMessage: 'Stor kunskap och erfarenhet av likvärdig produkt',
  },
  goodPreviousKnowledgePlaceholder: {
    id: 'smeKnowledgeMessages.goodPreviousKnowledgePlaceholder',
    defaultMessage:
      'Listade företrädare har goda kunskaper och erfarenheter av likvärdiga produkter. Nordeas rådgivare har därför enbart kortfattat gått igenom produktens egenskaper. Detta för att säkerställa tillräcklig kunskap finns gällande produktens avgifter, avkastning, risker, valbara investeringsalternativ och utbetalningsålder.',
  },
  pensionInstrumentKnowledge: {
    id: 'smeKnowledgeMessages.pensionInstrumentKnowledge',
    defaultMessage: `
      <p>Vi har säkerställt att bolagets företrädare har kunskap om följande:</p>
      <ul>
        <li>Avkastningen i Tjänstepension Fond/Depå beror på utvecklingen i fonderna och/eller de enskilda värdepapper den försäkrade väljer och påverkas av avgifterna för dessa tillgångar.</li>
        <li>Risken i Tjänstepension Fond/Depå beror på vilka fonder och/eller enskilda värdepapper som väljs.</li>
        <li>I Tjänstepension Fond/Depå tas avgift ut för de investeringar som väljs samt en fast årsavgift, kapitalavgift, avgift motsvarande den årliga schablonskatten och courtage vid handel med enskilda värdepapper.</li>
        <li>Inom Tjänstepension Fond är det möjligt att handla med fonder och inom Tjänstepension Depå är det möjligt att handla med både fonder och enskilda värdepapper.</li>
        <li>Pensionskapitalet i Tjänstepension Garanti förvaltas i bland annat räntebärande värdepapper, aktier och fastigheter.</li>
        <li>Risken i Tjänstepension Garanti beror på de tillgångar som Nordea Liv & Pension väljer att förvalta pensionskapitalet i.</li>
        <li>I Tjänstepension Garanti tas en kapitalavgift, kapitalförvaltningskostnad, eventuell premieavgift och eventuell fast årsavgift samt en avgift motsvarande den årliga schablonskatten ut.</li>
        <li>Pensionskapitalet kan betalas ut tidigast vid 55 års ålder.</li>
        </ul>
      `,
  },
});

export const smeBenefitPackagesMessages = defineMessages({
  benefitPackage: {
    id: 'smeBenefitPackagesMessages.benefitPackage',
    defaultMessage: 'Förmånsgrupp: {name}',
  },
  benefitPackages: {
    id: 'smeBenefitPackagesMessages.benefitPackages',
    defaultMessage: 'Förmånsgrupper',
  },
  description: {
    id: 'smeBenefitPackagesMessages.description',
    defaultMessage:
      'Du kan lägga till obegränsat med förmånsgrupper. En anställd kan bara tillhöra en förmånsgrupp. Du kan enkelt byta förmånsgrupp för den anställda i systemet.',
  },
  createBenefitPackage: {
    id: 'smeBenefitPackagesMessages.createBenefitPackage',
    defaultMessage: 'Skapa förmånsgrupp',
  },
  benefitPackageCreated: {
    id: 'smeBenefitPackagesMessages.benefitPackageCreated',
    defaultMessage: 'Förmånsgrupp skapad',
  },
  selectDefaultBenefitPackage: {
    id: 'smeBenefitPackagesMessages.selectDefaultBenefitPackage',
    defaultMessage: 'Välj standardpaket',
  },
  benefitPackageDescription: {
    id: 'smeBenefitPackagesMessages.benefitPackageDescription',
    defaultMessage:
      'Alla anställda som hör till förmånsgruppen får tillgång till förmånerna i förmånsgruppen',
  },
  editName: {
    id: 'smeBenefitPackagesMessages.editName',
    defaultMessage: 'Ändra namn',
  },
  nameEdited: {
    id: 'smeBenefitPackagesMessages.nameEdited',
    defaultMessage: 'Namn ändrat',
  },
  deleteBenefitPackage: {
    id: 'smeBenefitPackagesMessages.deleteBenefitPackage',
    defaultMessage: 'Ta bort förmånsgrupp',
  },
  confirmDeleteBenefitPackage: {
    id: 'smeBenefitPackagesMessages.confirmDeleteBenefitPackage',
    defaultMessage: 'Ta bort förmånsgrupp?',
  },
  benefitPackageDeleted: {
    id: 'smeBenefitPackagesMessages.benefitPackageDeleted',
    defaultMessage: 'Förmånsgrupp borttagen',
  },
  removeBenefit: {
    id: 'smeBenefitPackagesMessages.removeBenefit',
    defaultMessage: 'Ta bort förmån från förmånsgrupp',
  },
  benefitRemoved: {
    id: 'smeBenefitsMessages.benefitRemoved',
    defaultMessage: 'Förmån borttagen från förmånsgrupp',
  },
  confirmRemoveBenefit: {
    id: 'smeBenefitPackagesMessages.confirmRemoveBenefit',
    defaultMessage: 'Ta bort förmån från förmånsgrupp?',
  },
});

export const smeBenefitsMessages = defineMessages({
  allBenefits: {
    id: 'smeBenefitsMessages.allBenefits',
    defaultMessage: 'Alla förmåner',
  },
  allBenefitsDescription: {
    id: 'smeBenefitsMessages.allBenefitsDescription',
    defaultMessage:
      'Här ser du en lista på alla skapade förmåner. En förmån kan ligga i flera förmånsgrupper.',
  },
  benefits: {
    id: 'smeBenefitsMessages.benefits',
    defaultMessage: 'Förmåner',
  },
  editLink: {
    id: 'smeBenefitsMessages.editLink',
    defaultMessage: 'Redigera förmån',
  },
  deleteBenefit: {
    id: 'smeBenefitsMessages.deleteBenefit',
    defaultMessage: 'Ta bort förmån',
  },
  confirmDeleteBenefitTitle: {
    id: 'smeBenefitsMessages.confirmDeleteBenefitTitle',
    defaultMessage: `Ta bort förmån?`,
  },
  confirmDeleteBenefit: {
    id: 'smeBenefitsMessages.confirmDeleteBenefit',
    defaultMessage: `Ta bort förmån?\nFörmånen kommer att tas bort från alla förmånsgrupper.`,
  },
  benefitDeleted: {
    id: 'smeBenefitsMessages.benefitDeleted',
    defaultMessage: 'Förmån borttagen',
  },
  emptyStateTitle: {
    id: 'smeBenefitsMessages.emptyStateTitle',
    defaultMessage: 'Det finns inga förmåner eller förmånsgrupper att visa',
  },
  emptyStateDescription: {
    id: 'smeBenefitsMessages.emptyStateDescription',
    defaultMessage:
      'När du lägger till en förmånsgrupp kommer den att visas här.',
  },
  noBenefits: {
    id: 'smeBenefitsMessages.noBenefits',
    defaultMessage: 'Du har inte skapat några förmåner ännu.',
  },
  noBenefitsInBenefitPackage: {
    id: 'smeBenefitsMessages.noBenefitsInBenefitPackage',
    defaultMessage: 'Du har inte några förmåner i förmånsgruppen ännu.',
  },
  createBenefit: {
    id: 'smeBenefitsMessages.createBenefit',
    defaultMessage: 'Skapa förmån',
  },
  createBenefitDescription: {
    id: 'smeBenefitsMessages.createBenefitDescription',
    defaultMessage:
      'Du kan välja förmåner utifrån företagets policy. Om förmånen behöver ytterligare konfiguration sker det i nästa steg.',
  },
  selectBenefitType: {
    id: 'smeBenefitsMessages.selectBenefitType',
    defaultMessage: 'Välj förmån',
  },
  tglBenefitDescription: {
    id: 'smeBenefitsMessages.tglBenefitDescription',
    defaultMessage: '6 PBB',
  },
});

export const smeHealthcareLevelMessages =
  defineMessages<ProposalHealthcareLevel>({
    BRONS_0: {
      id: 'smeHealthcareLevelMessages.BRONS_0',
      defaultMessage: 'Brons utan självrisk',
    },
    BRONS_500: {
      id: 'smeHealthcareLevelMessages.BRONS_500',
      defaultMessage: 'Brons 500 kr',
    },
    L0: {
      id: 'smeHealthcareLevelMessages.L0',
      defaultMessage: 'Silver utan självrisk',
    },
    L500: {
      id: 'smeHealthcareLevelMessages.L500',
      defaultMessage: 'Silver 500 kr',
    },
    L1000: {
      id: 'smeHealthcareLevelMessages.L1000',
      defaultMessage: 'Silver 1000 kr',
    },
    L1500: {
      id: 'smeHealthcareLevelMessages.L1500',
      defaultMessage: 'Silver 1500 kr',
    },
    GOLD: {
      id: 'smeHealthcareLevelMessages.GOLD',
      defaultMessage: 'Guld',
    },
  });

export const smePremiumDeductionMessages =
  defineMessages<ProposalPremiumDeductions>({
    PREMIUM_WAIVER: {
      id: 'smePremiumDeductionMessages.PREMIUM_WAIVER',
      defaultMessage: 'Premiebefrielseförsäkring',
    },
    SICKNESS_INSURANCE: {
      id: 'smePremiumDeductionMessages.SICKNESS_INSURANCE',
      defaultMessage: 'Sjukförsäkring',
    },
  });

export const smeSalaryExchangeMessages = defineMessages({
  ongoingMax: {
    id: 'smeSalaryExchangeMessages.ongoingMax',
    defaultMessage: 'Max. avdrag: {share, number, ::percent}',
  },
});

export const smeBenefitTypesMessages = defineMessages<ProposalBenefitType>({
  EMPLOYERS_PENSION: {
    id: 'smeBenefitTypesMessages.EMPLOYEERS_PENSION',
    defaultMessage: 'Företagarpension',
  },
  OCCUPATIONAL_PENSION: {
    id: 'smeBenefitTypesMessages.OCCUPATIONAL_PENSION',
    defaultMessage: 'Tjänstepension',
  },
  SALARY_EXCHANGE: {
    id: 'smeBenefitTypesMessages.SALARY_EXCHANGE',
    defaultMessage: 'Löneväxling',
  },
  RISK_SICKNESS: {
    id: 'smeBenefitTypesMessages.RISK_SICKNESS',
    defaultMessage: 'Sjukförsäkring',
  },
  RISK_ACCIDENT: {
    id: 'smeBenefitTypesMessages.RISK_ACCIDENT',
    defaultMessage: 'Olycksfallsförsäkring',
  },
  RISK_TGL: {
    id: 'smeBenefitTypesMessages.RISK_TGL',
    defaultMessage: 'Tjänstegrupplivförsäkring',
  },
  RISK_LIFE: {
    id: 'smeBenefitTypesMessages.RISK_LIFE',
    defaultMessage: 'Livförsäkring (Obligatorisk)',
  },
  RISK_HEALTHCARE: {
    id: 'smeBenefitTypesMessages.RISK_HEALTHCARE',
    defaultMessage: 'Sjukvårdsförsäkring',
  },
});

export const smeCreateBenefitTypesMessages =
  defineMessages<ProposalBenefitType>({
    EMPLOYERS_PENSION: {
      id: 'smeCreateBenefitTypesMessages.EMPLOYEERS_PENSION',
      defaultMessage: 'Skapa ny företagarpension',
    },
    OCCUPATIONAL_PENSION: {
      id: 'smeCreateBenefitTypesMessages.OCCUPATIONAL_PENSION',
      defaultMessage: 'Skapa ny tjänstepension',
    },
    SALARY_EXCHANGE: {
      id: 'smeCreateBenefitTypesMessages.SALARY_EXCHANGE',
      defaultMessage: 'Skapa ny löneväxling',
    },
    RISK_SICKNESS: {
      id: 'smeCreateBenefitTypesMessages.RISK_SICKNESS',
      defaultMessage: 'Skapa ny sjukförsäkring',
    },
    RISK_ACCIDENT: {
      id: 'smeCreateBenefitTypesMessages.RISK_ACCIDENT',
      defaultMessage: 'Skapa ny olycksfallsförsäkring',
    },
    RISK_TGL: {
      id: 'smeCreateBenefitTypesMessages.RISK_TGL',
      defaultMessage: 'Skapa ny tjänstegrupplivförsäkring',
    },
    RISK_LIFE: {
      id: 'smeCreateBenefitTypesMessages.RISK_LIFE',
      defaultMessage: 'Skapa ny livförsäkring',
    },
    RISK_HEALTHCARE: {
      id: 'smeCreateBenefitTypesMessages.RISK_HEALTHCARE',
      defaultMessage: 'Skapa ny sjukvårdsförsäkring',
    },
  });

export const smeEditBenefitTypesMessages = defineMessages<ProposalBenefitType>({
  EMPLOYERS_PENSION: {
    id: 'smeEditBenefitTypesMessages.EMPLOYEERS_PENSION',
    defaultMessage: 'Redigera företagarpension',
  },
  OCCUPATIONAL_PENSION: {
    id: 'smeEditBenefitTypesMessages.OCCUPATIONAL_PENSION',
    defaultMessage: 'Redigera tjänstepension',
  },
  SALARY_EXCHANGE: {
    id: 'smeEditBenefitTypesMessages.SALARY_EXCHANGE',
    defaultMessage: 'Redigera löneväxling',
  },
  RISK_SICKNESS: {
    id: 'smeEditBenefitTypesMessages.RISK_SICKNESS',
    defaultMessage: 'Redigera sjukförsäkring',
  },
  RISK_ACCIDENT: {
    id: 'smeEditBenefitTypesMessages.RISK_ACCIDENT',
    defaultMessage: 'Redigera olycksfallsförsäkring',
  },
  RISK_TGL: {
    id: 'smeEditBenefitTypesMessages.RISK_TGL',
    defaultMessage: 'Redigera tjänstegrupplivförsäkring',
  },
  RISK_LIFE: {
    id: 'smeEditBenefitTypesMessages.RISK_LIFE',
    defaultMessage: 'Redigera livförsäkring',
  },
  RISK_HEALTHCARE: {
    id: 'smeEditBenefitTypesMessages.RISK_HEALTHCARE',
    defaultMessage: 'Redigera sjukvårdsförsäkring',
  },
});

export const smeDefaultBenefitPackagesMessages =
  defineMessages<ProposalDefaultBenefitPackage>({
    EMPLOYEE_WITH_RISK: {
      id: 'smeDefaultBenefitPackagesMessages.EMPLOYEE_WITH_RISK',
      defaultMessage: 'Anställda med risk',
    },
    OWNER_FIXED_PREMIUM: {
      id: 'smeDefaultBenefitPackagesMessages.OWNER_FIXED_PREMIUM',
      defaultMessage: 'Ägare med fast premie',
    },
    OWNER_OR_CEO_FULL_PROTECTION: {
      id: 'smeDefaultBenefitPackagesMessages.OWNER_OR_CEO_FULL_PROTECTION',
      defaultMessage: 'Ägare/VD fullt skydd',
    },
    PROBATIONARY_EMPLOYEE: {
      id: 'smeDefaultBenefitPackagesMessages.PROBATIONARY_EMPLOYEE',
      defaultMessage: 'Provanställd',
    },
    SICKNESS_ONLY: {
      id: 'smeDefaultBenefitPackagesMessages.SICKNESS_ONLY',
      defaultMessage: 'Endast sjuk',
    },
  });

export const smeBenefitFormMessages = defineMessages({
  automaticApproval: {
    id: 'smeBenefitFormMessages.automaticApproval',
    defaultMessage: 'Godkänn ansökningar automatiskt',
  },
  automaticApprovalTitle: {
    id: 'smeBenefitFormMessages.automaticApprovalTitle',
    defaultMessage: 'Hantera ansökningar automatiskt',
  },
  automaticApprovalDescription: {
    id: 'smeBenefitFormMessages.automaticApprovalDescription',
    defaultMessage:
      'Ansökningar om löneväxling kan godkännas automatiskt om de faller inom arbetsgivarens policy. Utan automatiska godkännanden måste varje ansökan behandlas av en behörig administratör. Automatiska godkännanden innebär att avtal om löneavdrag och extra pensionsinbetalningar träder i kraft så snart en anställd lämnat in en godkänd ansökan.',
  },
  save: {
    id: 'smeBenefitFormMessages.save',
    defaultMessage: 'Spara förmån',
  },
  ageIntervals: {
    id: 'smeBenefitFormMessages.ageIntervals',
    defaultMessage: 'Åldersintervall',
  },
  baseAmountIntervals: {
    id: 'smeBenefitFormMessages.baseAmountIntervals',
    defaultMessage: 'Basbeloppsintervall',
  },
  benefitPackages: {
    id: 'smeBenefitFormMessages.benefitPackages',
    defaultMessage: 'Förmånsgrupper',
  },
  benefitPackagesDescription: {
    id: 'smeBenefitFormMessages.benefitPackagesDescription',
    defaultMessage:
      'Välj vilken eller vilka förmånsgrupper som denna produkt ska ingå i.',
  },
  level: {
    id: 'smeBenefitFormMessages.level',
    defaultMessage: 'Välj nivå',
  },
  benefitSaved: {
    id: 'smeBenefitFormMessages.benefitSaved',
    defaultMessage: 'Förmån sparad',
  },
  configurePremiumIntervals: {
    id: 'pensionBenefitMessages.configurePremiumIntervals',
    defaultMessage: 'Konfigurera intervall',
  },
  configurePremiumIntervalsDescription: {
    id: 'pensionBenefitMessages.configurePremiumIntervalsDescription',
    defaultMessage:
      'Premietrappan anger procentuell avsättning av lön per ålders- och basbeloppsintervall. Ett intervall gäller från och med det lägre värdet upp till nästa värde.',
  },
  conversion: {
    id: 'smeBenefitFormMessages.conversion',
    defaultMessage: 'Konvertering',
  },
  convert: {
    id: 'smeBenefitFormMessages.convert',
    defaultMessage: 'Nyteckna försäkring med konvertering',
  },
  conversionFrom: {
    id: 'smeBenefitFormMessages.conversionFrom',
    defaultMessage: 'Konvertering sker från följande bolag',
  },
  conversionExplanation: {
    id: 'smeBenefitFormMessages.conversionExplanation',
    defaultMessage: `
    <p>Konvertering gäller enbart om det är till samma eller lägre ersättningsbelopp.</p>
    <ul>
      <li>Vid konvertering av sjukvårdsförsäkring sker konvertering från 5 personer vid obligatorisk grupp (hela gruppen ska konverteras).</li>
      <li>Vid konvertering av sjukförsäkring och premiebefrielse sker konvertering från 1 person vid obligatorisk grupp (hela gruppen ska konverteras).</li>
      <li>Mer information finns på intranät.</li>
    </ul>
    `,
  },
  defaultManagementType: {
    id: 'smeBenefitFormMessages.defaultManagementType',
    defaultMessage: 'Ange förvaltningsform',
  },
  editPremiumIntervals: {
    id: 'smeBenefitFormMessages.editPremiumIntervals',
    defaultMessage: 'Ändra premieintervaller',
  },
  extendedSickPay: {
    id: 'smeBenefitFormMessages.extendedSickPay',
    defaultMessage: 'Utökad sjuklön',
  },
  extendedSickPayLabel: {
    id: 'smeBenefitFormMessages.extendedSickPayLabel',
    defaultMessage: 'Medarbetare får utökad sjuklön enligt ITP',
  },
  extraFixedPremium: {
    id: 'smeBenefitFormMessages.extraFixedPremium',
    defaultMessage: 'Fast extra premie',
  },
  extraFixedPremiumDescription: {
    id: 'smeBenefitFormMessages.extraFixedPremiumDescription',
    defaultMessage:
      'Välj om företagets anställda ska ha en fast extra premie utöver den premietrappa som valts ovan. Den extra premien anges på respektive anställd senare.',
  },
  extraFixedPremiumLabel: {
    id: 'smeBenefitFormMessages.extraFixedPremiumLabel',
    defaultMessage:
      'Företagets anställda ska ha en fast extra premie utöver ovan premietrappa.',
  },
  incomeMinIbb: {
    id: 'smeBenefitFormMessages.incomeMinIbb',
    defaultMessage: 'Lägsta årslön efter bruttolöneavdrag',
  },
  incomeMinIbbTitle: {
    id: 'smeBenefitFormMessages.incomeMinIbbTitle',
    defaultMessage: 'Allmänna pensionsavsättningar',
  },
  incomeMinIbbDescription: {
    id: 'smeBenefitFormMessages.incomeMinIbbDescription',
    defaultMessage:
      'Bruttolöneavdrag kan påverka anställdas pensionsgrundande inkomst (PGI). Om årsinkomsten blir lägre än {incomeLimitIbb, number} inkomstbasbelopp (IBB), {yearlyThreshold, number, ::currency/SEK .} för {year}, minskar avsättningarna till allmän pension. En anställd kan aldrig välja ett löneväxlingsbelopp som gör att den rapporterade månadslönen minus bruttolöneavdraget blir lägre än {monthlyThreshold, number, ::currency/SEK .} ({yearlyThreshold, number, ::currency/SEK .} / 12) om ni väljer att ha golvet vid {incomeLimitIbb, number}. Notera att det är på årsinkomsten som den allmänna pensionen baseras på, viktigt att tänka på vid exempelvis föräldraledighet eller löneförändringar som kan påverka den totala årsinkomsten.',
  },
  ongoingMax: {
    id: 'smeBenefitFormMessages.ongoingMax',
    defaultMessage: 'Högsta tillåtna bruttolöneavdrag',
  },
  ongoingMaxDescription: {
    id: 'smeBenefitFormMessages.ongoingMaxDescription',
    defaultMessage:
      'Högsta tillåtna bruttolöneavdrag kan anges i kronor och i procent av bruttolön. Den anställda kan aldrig välja ett högre avdrag än rapporterad bruttolön. Det lägsta bruttolöneavdrag som en anställd kan göra är 1000 kr.',
  },
  ongoingMaxSek: {
    id: 'smeBenefitFormMessages.ongoingMaxSek',
    defaultMessage: 'Högsta bruttolöneavdrag i kronor',
  },
  ongoingMaxShare: {
    id: 'smeBenefitFormMessages.ongoingMaxShare',
    defaultMessage: 'Högsta bruttolöneavdrag i procent',
  },
  pensionTaxBenefitShare: {
    id: 'smeBenefitFormMessages.pensionTaxBenefitShare',
    defaultMessage: 'Andel av skattefördelen',
  },
  pensionTaxBenefitShareTitle: {
    id: 'smeBenefitFormMessages.pensionTaxBenefitShareTitle',
    defaultMessage: 'Fördelning av skatteeffekten',
  },
  pensionTaxBenefitShareDescription: {
    id: 'smeBenefitFormMessages.pensionTaxBenefitShareDescription',
    defaultMessage:
      'Pensionsavsättningar belastas endast med 24,26 % i särskild löneskatt jämfört med 31,42 % i arbetsgivaravgifter på lön. Skillnaden på 5,76 % kan tilldelas den anställda i form av en högre premie eller behållas av företaget. Här väljer ni hur stor del av denna effekt som tillfaller den anställde.',
  },
  pensionTaxBenefitShareHelperText: {
    id: 'smeBenefitFormMessages.pensionTaxBenefitShareHelperText',
    defaultMessage: '= {share, number, ::percent .00} högre premie',
  },
  premiumCalculationMethod: {
    id: 'smeBenefitFormMessages.premiumCalculationMethod',
    defaultMessage: 'Premieberäkningsmetod',
  },
  premiumDeduction: {
    id: 'smeBenefitFormMessages.premiumDeduction',
    defaultMessage: 'Inom premieutrymme',
  },
  premiumDeductionDescription: {
    id: 'smeBenefitFormMessages.premiumDeductionDescription',
    defaultMessage:
      'Ange om någon av nedanstående produkter ska finansieras inom premieutrymmet',
  },
  premiumMatrix: {
    id: 'smeBenefitFormMessages.premiumMatrix',
    defaultMessage: 'Premietrappa',
  },
  premiumWaiver: {
    id: 'smeBenefitFormMessages.premiumWaiver',
    defaultMessage: 'Premiebefrielse',
  },
  premiumWaiverSalaryExchange: {
    id: 'smeBenefitFormMessages.premiumWaiverSalaryExchange',
    defaultMessage: 'Premiebefrielseförsäkring för löneväxling',
  },
  requiresBusinessCouncilDecision: {
    id: 'smeBenefitFormMessages.requiresBusinessCouncilDecision',
    defaultMessage: 'Kräver affärsrådsbeslut',
  },
  retirementAge: {
    id: 'smeBenefitFormMessages.retirementAge',
    defaultMessage: 'Pensionsålder',
  },
  salaryMultiplier: {
    id: 'smeBenefitFormMessages.salaryMultiplier',
    defaultMessage: 'Årslönefaktor',
  },
  minRetirementAge: {
    id: 'smeBenefitFormMessages.minRetirementAge',
    defaultMessage: 'Lägsta giltiga pensionsålder är 55 år',
  },
  waitingPeriod: {
    id: 'smeBenefitFormMessages.waitingPeriod',
    defaultMessage: 'Karens',
  },
  waitingPeriodYes: {
    id: 'smeBenefitFormMessages.waitingPeriodYes',
    defaultMessage: 'Med karens',
  },
  waitingPeriodNo: {
    id: 'smeBenefitFormMessages.waitingPeriodNo',
    defaultMessage: 'Utan karens',
  },
});

export const smeBenefitAccidentLevelMessages =
  defineMessages<ProposalAccidentLevel>({
    PBB20: {
      id: 'smeBenefitAccidentLevelMessages.PBB20',
      defaultMessage: '20 Prisbasbelopp',
    },
    PBB30: {
      id: 'smeBenefitAccidentLevelMessages.PBB30',
      defaultMessage: '30 Prisbasbelopp',
    },
    PBB40: {
      id: 'smeBenefitAccidentLevelMessages.PBB40',
      defaultMessage: '40 Prisbasbelopp',
    },
    PBB50: {
      id: 'smeBenefitAccidentLevelMessages.PBB50',
      defaultMessage: '50 Prisbasbelopp',
    },
  });

export const smeBenefitLifeLevelMessages = defineMessages<ProposalLifeLevel>({
  PBB5: {
    id: 'smeBenefitAccidentLevelMessages.PBB5',
    defaultMessage: '5 Prisbasbelopp',
  },
  PBB10: {
    id: 'smeBenefitAccidentLevelMessages.PBB10',
    defaultMessage: '10 Prisbasbelopp',
  },
  PBB15: {
    id: 'smeBenefitAccidentLevelMessages.PBB15',
    defaultMessage: '15 Prisbasbelopp',
  },
  PBB20: {
    id: 'smeBenefitAccidentLevelMessages.PBB20',
    defaultMessage: '20 Prisbasbelopp',
  },
  PBB25: {
    id: 'smeBenefitAccidentLevelMessages.PBB25',
    defaultMessage: '25 Prisbasbelopp',
  },
  PBB30: {
    id: 'smeBenefitAccidentLevelMessages.PBB30',
    defaultMessage: '30 Prisbasbelopp',
  },
  PBB35: {
    id: 'smeBenefitAccidentLevelMessages.PBB35',
    defaultMessage: '35 Prisbasbelopp',
  },
  PBB40: {
    id: 'smeBenefitAccidentLevelMessages.PBB40',
    defaultMessage: '40 Prisbasbelopp',
  },
  PBB45: {
    id: 'smeBenefitAccidentLevelMessages.PBB45',
    defaultMessage: '45 Prisbasbelopp',
  },
  PBB50: {
    id: 'smeBenefitAccidentLevelMessages.PBB50',
    defaultMessage: '50 Prisbasbelopp',
  },
});

export const smeBenefitDisabilityLevelMessages =
  defineMessages<ProposalSicknessLevel>({
    ITP: {
      id: 'smeBenefitAccidentLevelMessages.ITP',
      defaultMessage: 'ITP-sjuk',
    },
    KOMP: {
      id: 'smeBenefitAccidentLevelMessages.KOMP',
      defaultMessage: 'ITP-komplettering',
    },
    MAX: {
      id: 'smeBenefitAccidentLevelMessages.MAX',
      defaultMessage: 'MAX-sjuk',
    },
    CONSOLIDATION: {
      id: 'smeBenefitAccidentLevelMessages.CONSOLIDATION',
      defaultMessage: 'Konsolideringsnivå',
    },
    HYBRID: {
      id: 'smeBenefitAccidentLevelMessages.HYBRID',
      defaultMessage: 'Hybridsjuk',
    },
  });

export const smeBenefitPensionPremiumCalculationMethodMessages =
  defineMessages<ProposalPremiumCalculationMethod>({
    ITP_MATRIX: {
      id: 'smeBenefitPensionPremiumTypeMessages.ITP_MATRIX',
      defaultMessage: 'ITP-liknande trappa',
    },
    CUSTOM_MATRIX: {
      id: 'smeBenefitPensionPremiumTypeMessages.CUSTOM_MATRIX',
      defaultMessage: 'Egen premietrappa',
    },
    FIXED_PREMIUM_ONLY: {
      id: 'smeBenefitPensionPremiumTypeMessages.FIXED_PREMIUM_ONLY',
      defaultMessage: 'Fast individuell premie',
    },
  });

export const smeBenefitPensionBaseAmountTypeMessages =
  defineMessages<ProposalBaseAmountType>({
    IBB: {
      id: 'smeBenefitPensionBaseAmountTypeMessages.IBB',
      defaultMessage: 'Inkomstbasbelopp (IBB)',
    },
    PBB: {
      id: 'smeBenefitPensionBaseAmountTypeMessages.PBB',
      defaultMessage: 'Prisbasbelopp (PBB)',
    },
  });

export const smePensionPremiumDeductionMessages =
  defineMessages<ProposalPremiumDeductions>({
    PREMIUM_WAIVER: {
      id: 'smePensionPremiumDeductionMessages.PREMIUM_WAIVER',
      defaultMessage: 'Premiebefrielseförsäkring för tjänstepension',
    },
    SICKNESS_INSURANCE: {
      id: 'smePensionPremiumDeductionMessages.SICKNESS_INSURANCE',
      defaultMessage: 'Sjukförsäkring',
    },
  });

export const smeBenefitPensionManagementTypeMessages =
  defineMessages<ProposalDefaultManagementType>({
    FUND: {
      id: 'smeBenefitPensionManagementTypeMessages.FUND',
      defaultMessage: 'Fondförvaltning',
    },
    TRAD: {
      id: 'smeBenefitPensionManagementTypeMessages.TRAD',
      defaultMessage: 'Garantiförvaltning',
    },
    PORTFOLIO: {
      id: 'smeBenefitPensionManagementTypeMessages.PORTFOLIO',
      defaultMessage: 'Depåförvaltning',
    },
  });

export const smeEmployeesMessages = defineMessages({
  employees: {
    id: 'smeEmployeesMessages.employees',
    defaultMessage: 'Anställda',
  },
  createEmployees: {
    id: 'smeEmployeesMessages.createEmployees',
    defaultMessage: 'Lägg till anställda',
  },
  settings: {
    id: 'smeEmployeesMessages.settings',
    defaultMessage: 'Inställningar',
  },
  monthlySalaryDescription: {
    id: 'smeEmployeesMessages.monthlySalaryDescription',
    defaultMessage:
      'Lägg alltid in den faktiska månadslönen, ej den uppräknade.',
  },
  noEmployeesTitle: {
    id: 'smeEmployeesMessages.noEmployeesTitle',
    defaultMessage: 'Det finns inga anställda att visa',
  },
  noEmployeesDescription: {
    id: 'smeEmployeesMessages.noEmployeesDescription',
    defaultMessage: 'Anställda som läggs till kommer att visas här.',
  },
  isOwner: {
    id: 'smeEmployeesMessages.isOwner',
    defaultMessage: 'Ägarposition',
  },
  isOwnerLabel: {
    id: 'smeEmployeesMessages.isOwnerLabel',
    defaultMessage: 'Företagare med ägarposition (AB, HB, KP, EN)',
  },
  isOwnerShortLabel: {
    id: 'smeEmployeesMessages.isOwnerShortLabel',
    defaultMessage: 'Företagare',
  },
  isFcfw: {
    id: 'smeEmployeesMessages.isFcfw',
    defaultMessage: 'Fullt arbetsför',
  },
  fcfwTitle: {
    id: 'smeEmployeesMessages.fcfwTitle',
    defaultMessage: 'Ange vilka som är fullt arbetsför',
  },
  fcfwDescription: {
    id: 'smeEmployeesMessages.fcfwDescription',
    defaultMessage:
      'För att teckna eller ändra försäkringar hos Euro Accident måste du ange om någon anställd inte är fullt arbetsför. Oriktiga uppgifter kan påverka försäkringars giltighet.',
  },
  fcfwGroupSizeSmall: {
    id: 'smeEmployeesMessages.fcfwGroupSizeSmall',
    defaultMessage: 'För angiven koncernstorlek gäller FA 14/90.',
  },
  fcfwGroupSizeLarge: {
    id: 'smeEmployeesMessages.fcfwGroupSizeLarge',
    defaultMessage: 'För angiven koncernstorlek gäller FA på dagen.',
  },
  editFcfw: {
    id: 'smeEmployeesMessages.editFcfw',
    defaultMessage: 'Ange fullt arbetsför',
  },
  nInsuredInGroup: {
    id: 'smeEmployeesMessages.nInsuredInGroup',
    defaultMessage: 'Antal försäkrade i koncernen',
  },
  createEmployee: {
    id: 'smeEmployeesMessages.createEmployee',
    defaultMessage: 'Lägg till anställd',
  },
  editEmployee: {
    id: 'smeEmployeesMessages.editEmployee',
    defaultMessage: 'Ändra anställningsuppgifter',
  },
  editLink: {
    id: 'smeEmployeesMessages.editLink',
    defaultMessage: 'Ändra uppgifter',
  },
  deleteEmployee: {
    id: 'smeEmployeesMessages.deleteEmployee',
    defaultMessage: 'Ta bort anställd',
  },
  confirmDeleteEmployee: {
    id: 'smeEmployeesMessages.confirmDeleteEmployee',
    defaultMessage: 'Ta bort anställd?',
  },
  employeeDeleted: {
    id: 'smeEmployeesMessages.employeeDeleted',
    defaultMessage: 'Anställd borttagen',
  },
  personalDetails: {
    id: 'smeEmployeesMessages.personalDetails',
    defaultMessage: 'Personuppgifter',
  },
  personalIdentityNumber: {
    id: 'smeEmployeesMessages.personalIdentityNumber',
    defaultMessage: 'Person- eller samordningsnummer',
  },
  personalIdentityNumberHelperText: {
    id: 'smeEmployeesMessages.personalIdentityNumberHelperText',
    defaultMessage: '10 eller 12 siffror',
  },
  monthlyFixedPensionPremium: {
    id: 'smeEmployeesMessages.monthlyFixedPensionPremium',
    defaultMessage: 'Individuell premie per månad',
  },
  monthlyFixedPensionAlt: {
    id: 'smeEmployeesMessages.monthlyFixedPensionAlt',
    defaultMessage: 'Individuell fast premie',
  },
  monthlyFixedPensionPremiumTitle: {
    id: 'smeEmployeesMessages.monthlyFixedPensionPremiumTitle',
    defaultMessage: 'Premie på individnivå',
  },
  monthlyFixedPensionPremiumDescription: {
    id: 'smeEmployeesMessages.monthlyFixedPensionPremiumDescription',
    defaultMessage:
      'Individuell fast avsättning till pensionen eller fast extra premie utöver den avtalade premien i förmånsgruppen.',
  },
  save: {
    id: 'smeEmployeesMessages.save',
    defaultMessage: 'Spara anställd',
  },
  employeeSaved: {
    id: 'smeEmployeesMessages.employeeSaved',
    defaultMessage: 'Anställd sparad',
  },
  details: {
    id: 'smeEmployeesMessages.details',
    defaultMessage: 'Uppgifter',
  },
  editDetails: {
    id: 'smeEmployeesMessages.editDetails',
    defaultMessage: 'Ändra uppgifter',
  },
  saveFcfw: {
    id: 'smeEmployeesMessages.saveFcfw',
    defaultMessage: 'Spara ändringar',
  },
  fcfwSaved: {
    id: 'smeEmployeesMessages.fcfwSaved',
    defaultMessage: 'Ändringar sparade',
  },
  nEmployeesMarkedAsFCFW: {
    id: 'smeEmployeesMessages.nEmployeesMarkedAsFCFW',
    defaultMessage:
      '{count, plural, one {# av {total} markerad} other {# av {total} markerade}} som fullt arbetsför',
  },
});

export const smeOwnerDescriptionMessages = defineMessages({
  jointStockCompany: {
    id: 'smeOwnerDescriptionMessages.jointStockCompany',
    defaultMessage: 'Aktiebolag',
  },
  jointStockCompanyDescription: {
    id: 'smeOwnerDescriptionMessages.jointStockCompanyDescription',
    defaultMessage: `
    <ul>
      <li>
        Företagare som antingen själv eller tillsammans med make/maka,
        registrerad partner, förälder eller barn, äger en tredjedel eller
        mer av aktierna i företaget.
      </li>
      <li>
        Företagarens i företaget verksamma make/maka, registrerad partner.
      </li>
      <li>
        Barn till företagare räknas som företagare om de äger minst en
        aktie.
      </li>
    </ul>
    <p>Syskon kan inte räkna ihop sina aktier.</p>`,
  },
  generalPartnership: {
    id: 'smeOwnerDescriptionMessages.generalPartnership',
    defaultMessage: 'Handelsbolag',
  },
  generalPartnershipDescription: {
    id: 'smeOwnerDescriptionMessages.generalPartnershipDescription',
    defaultMessage: `
    <ul>
      <li>Delägare</li>
      <li>Delägarens make/maka, registrerad partner.</li>
    </ul>`,
  },
  limitedPartnership: {
    id: 'smeOwnerDescriptionMessages.limitedPartnership',
    defaultMessage: 'Kommanditbolag',
  },
  limitedPartnershipDescription: {
    id: 'smeOwnerDescriptionMessages.limitedPartnershipDescription',
    defaultMessage: `
    <ul>
      <li>Komplementär</li>
      <li>Komplementär make/maka, registrerad partner.</li>
    </ul>`,
  },
  proprietorship: {
    id: 'smeOwnerDescriptionMessages.proprietorship',
    defaultMessage: 'Enskild Näringsidkare',
  },
  proprietorshipDescription: {
    id: 'smeOwnerDescriptionMessages.proprietorshipDescription',
    defaultMessage: `
    <ul>
      <li>Samtliga företagare</li>
    </ul>`,
  },
});

export const smeCompanySizeMessages = defineMessages<
  Exclude<ProposalCompanySize, ProposalCompanySize.GTE_1000>
>({
  LT_10: {
    id: 'smeCompanySizeMessages.LT_10',
    defaultMessage: '1-9',
  },
  LT_50: {
    id: 'smeCompanySizeMessages.LTE_50',
    defaultMessage: '10-49',
  },
  GTE_50: {
    id: 'smeCompanySizeMessages.GTE_50',
    defaultMessage: '50-249',
  },
  GTE_250: {
    id: 'smeCompanySizeMessages.GTE_250',
    defaultMessage: '250-999',
  },
});

export const smeFeesMessages = defineMessages({
  fees: {
    id: 'smeFeesMessages.fees',
    defaultMessage: 'Avgifter',
  },
  title: {
    id: 'smeFeesMessages.title',
    defaultMessage: 'Justera avgifter',
  },
  feesDescription: {
    id: 'smeFeesMessages.feesDescription',
    defaultMessage:
      'Du kan ange kapitalavgift i hela steg om fem punkter. Tänk på att avgiften alltid ska vara samma för fond- och depåförsäkring.',
  },
  fund: {
    id: 'smeFeesMessages.fund',
    defaultMessage: 'Fondförsäkring',
  },
  traditional: {
    id: 'smeFeesMessages.traditional',
    defaultMessage: 'Garantiförsäkring',
  },
  portfolio: {
    id: 'smeFeesMessages.portfolio',
    defaultMessage: 'Depåförsäkring',
  },
  premiumWaiver: {
    id: 'smeFeesMessages.premiumWaiver',
    defaultMessage: 'Premiebefrielseförsäkring',
  },
  disability: {
    id: 'smeFeesMessages.disability',
    defaultMessage: 'Sjukförsäkring',
  },
  healthcare: {
    id: 'smeFeesMessages.healthcare',
    defaultMessage: 'Sjukvårdförsäkring',
  },
  capitalFee: {
    id: 'smeFeesMessages.capitalFee',
    defaultMessage: 'Kapitalavgift',
  },
  withAnnualFee: {
    id: 'smeFeesMessages.withAnnualFee',
    defaultMessage: 'Med fast årsavgift',
  },
  withoutAnnualFee: {
    id: 'smeFeesMessages.withoutAnnualFee',
    defaultMessage: 'Utan fast årsavgift',
  },
  fundDiscount: {
    id: 'smeFeesMessages.fundDiscount',
    defaultMessage: 'Fondrabatt',
  },
  fundDiscountPermitted: {
    id: 'smeFeesMessages.fundDiscountPermitted',
    defaultMessage:
      'Jag har fått godkänt av undantagsgruppen att erbjuda kunden en fondrabatt',
  },
  fundDiscountValue: {
    id: 'smeFeesMessages.fundDiscountValue',
    defaultMessage: '{percentage} rabatt',
  },
  provisionWaiver: {
    id: 'smeFeesMessages.provisionWaiver',
    defaultMessage: 'Provisionsavstående',
  },
  provisionWaiverAllowed: {
    id: 'smeFeesMessages.provisionWaiverAllowed',
    defaultMessage:
      'Jag har fått godkänt av undantagsgruppen att erbjuda kunden ett provisionsavstående',
  },
  noProvisionWaiver: {
    id: 'smeFeesMessages.noProvisionWaiver',
    defaultMessage: 'Inget avstående',
  },
  halfProvisionWaiver: {
    id: 'smeFeesMessages.halfProvisionWaiver',
    defaultMessage: 'Halvt avstående',
  },
  fullProvisionWaiver: {
    id: 'smeFeesMessages.fullProvisionWaiver',
    defaultMessage: 'Helt avstående',
  },
  riskBucket: {
    id: 'smeFeesMessages.riskBucket',
    defaultMessage: 'Riskhink',
  },
  riskBucketHealthcare: {
    id: 'smeFeesMessages.riskBucketHealthcare',
    defaultMessage: 'Riskhink sjukvård',
  },
  portfolioAndFundFeeMismatch: {
    id: 'smeFeesMessages.portfolioAndFundFeeMismatch',
    defaultMessage:
      'Kapitalavgift för fond- och depåförsäkring överensstämmer inte',
  },
});

export const smeRiskBucketLevelMessages = defineMessages<RiskBucket>({
  NEW: {
    id: 'smeRiskBucketLevelMessages.NEW',
    defaultMessage: 'Nyteckning',
  },
  SMALL: {
    id: 'smeRiskBucketLevelMessages.SMALL',
    defaultMessage: 'Småbolag',
  },
  LEVEL_1: {
    id: 'smeRiskBucketLevelMessages.LEVEL_1',
    defaultMessage: '1',
  },
  LEVEL_2: {
    id: 'smeRiskBucketLevelMessages.LEVEL_2',
    defaultMessage: '2',
  },
  LEVEL_3: {
    id: 'smeRiskBucketLevelMessages.LEVEL_3',
    defaultMessage: '3',
  },
  LEVEL_4: {
    id: 'smeRiskBucketLevelMessages.LEVEL_4',
    defaultMessage: '4',
  },
  LEVEL_5: {
    id: 'smeRiskBucketLevelMessages.LEVEL_5',
    defaultMessage: '5',
  },
  LEVEL_6: {
    id: 'smeRiskBucketLevelMessages.LEVEL_6',
    defaultMessage: '6',
  },
  LEVEL_7: {
    id: 'smeRiskBucketLevelMessages.LEVEL_7',
    defaultMessage: '7',
  },
});

export const smeSummaryMessages = defineMessages({
  summary: {
    id: 'smeSummaryMessages.summary',
    defaultMessage: 'Sammanfattning',
  },
  adviceSummary: {
    id: 'smeSummaryMessages.adviceSummary',
    defaultMessage: 'Sammanfattning av råd',
  },
  summaryDescription: {
    id: 'smeSummaryMessages.summaryDescription',
    defaultMessage:
      'Här ska en sammanfattning av råden presenteras, där det tydligt framgår vilka råd du har gett och varför du gav företaget just dessa råd. Det skall även framgå vilka produkter och tjänster som du har beskrivit i mötet. Beskriv de justeringar du anser att kunden behöver göra och varför. Om kunden vill frångå dina rekommendationer ange detta och beskriv varför.',
  },
  ownerWithNewlyStartedCompany: {
    id: 'smeSummaryMessages.ownerWithNewlyStartedCompany',
    defaultMessage: 'Ägare - Nystartat bolag utan försäkringslösning idag',
  },
  ownerWithNewlyStartedCompanyPlaceholder: {
    id: 'smeSummaryMessages.ownerWithNewlyStartedCompanyPlaceholder',
    defaultMessage: `
      <p>Jag uppfattade i mötet att du inledningsvis inte vill ta allt för stora kostnader för pensionsavsättningar utan vill bygga upp ett kapital i bolaget. Därför rekommenderar jag att du startar ett sparande på minst motsvarande nivå som du haft som anställd. Jag rekommenderar att vi lägger upp en procentplan på X % av din lön, vilket motsvarar denna summa. Genom att lägga upp en procentplan kommer pensionsavsättningen automatisk vara löneföljsam och successivt höjas vid eventuella framtida lönehöjningar. Jag rekommenderar även att du höjer månadssparande när resultatet och likviditeten känns stabilt. Den maximala avsättningen är 35 % av bruttolönen för att få den som en avdragsgill kostnad.&nbsp;</p>
      <p>För att få ett fullgott försäkringsskydd rekommenderar jag dig även att teckna upp följande riskförsäkringar: <br>Sjukförsäkring för inkomstbortfall- skydd av lön vid långvarig sjukdom via Euro Accident<br>Premiebefrielse - skydd av sparande vid långvarig sjukdom via Euro Accident <br>Tjänstegruppliv - Betalar upp till 6 prisbasbelopp vid dödsfall <br>Olycksfallsförsäkring - Gäller vid olycksfall dygnet runt <br>Sjukvårdsförsäkring- Tillgång till snabbare specialistvård utan remiss<br>Mer detaljerad information om nivåer finns i offerten.&nbsp;</p>
      <p>Ni kommer också utan extra kostnad få tillgång till en Självserviceportal för att enkelt kunna administrera ert avtal samt Förmånsportalen Min Framtidsplan via Benify.&nbsp;</p>
      <p>En tjänstepensionsförsäkring är ett levande engagemang som kan behöva korrigeras eller förändras över tid, antingen på grund av företagsspecifika förändringar eller på grund av att privatlivet förändras. Det är därför klokt att ha en kontinuerlig uppföljning.&nbsp;</p>
      <p>Tveka inte att höra av dig om du har några frågor.&nbsp;</p>
      <p>Med vänlig hälsning,</p>
    `,
  },
  ownerWithExistingInsuranceSolution: {
    id: 'smeSummaryMessages.ownerWithExistingInsuranceSolution',
    defaultMessage: 'Ägare med försäkringslösning i annat bolag',
  },
  ownerWithExistingInsuranceSolutionPlaceholder: {
    id: 'smeSummaryMessages.ownerWithExistingInsuranceSolutionPlaceholder',
    defaultMessage: `
      <p>För att uppnå dina önskemål med marknadsmässigt försäkringsavtal rekommenderar jag företaget att teckna följande:</p>
      <p>Ålderspension - med högre avsättning än nuvarande nivå, detta med anledning av att du som ägare sa att du vid anställning hade haft låg avsättning. Denna mer marknadsmässiga pensionsavsättning tecknas med premiebefrielseförsäkring vilket innebär att det är detta belopp som ligger till grund för fortsatt inbetalning till pensionssparandet vid sjukskrivning längre tid än 3 månader (lägst 25 % sjukskrivning). Detta ger en ökad trygghet för ägaren då denne är ensam anställd i bolaget och den som står för samtliga intäkter.</p>
      <p>Sjukförsäkring på konsolideringsnivå rekommenderas då lönen i fråga inte är marknadsmässig och då för låg i förhållande till vad som hade varit aktuellt som lön om ägaren istället hade haft en anställning. Detta ger en ökad ekonomisk trygghet vid sjukskrivning längre tid än 3 månader (lägst 25 % sjukskrivning). Du får även tillgång till Euro Accidents förebyggande försäkringar samtalsstöd och rehabiliteringsförsäkring.</p>
      <p>Olycksfallsförsäkring vilket gäller vid olycksskada dygnet runt. Idag har du en ersättningsnivå om 20 prisbasbelopp och jag rekommenderar dig framöver en med ersättningsnivå om 30 prisbasbelopp för att ge dig ett bättre skydd.</p>
      <p>Tjänstegrupplivförsäkring som ger ersättning till efterlevande vid dödsfall.</p>
      <p>Sjukvårdsförsäkring Privat Access Silver för snabbare hjälp vid behov av specialistvård. Gäller med självrisk 500kr / 1500 kr. Denna försäkring är förmånskattepliktig.</p>
      <p>Ni kommer också utan extra kostnad få tillgång till en Självserviceportal för att enkelt kunna administrera ert avtal samt Förmånsportalen Min Framtidsplan via Benify.</p>
      <p>En tjänstepensionsförsäkring är ett levande engagemang som kan behöva korrigeras eller förändras över tid, antingen på grund av företagsspecifika förändringar eller på grund av att privatlivet förändras. Det är därför klokt att ha en kontinuerlig uppföljning.</p>
      <p>Tveka inte att höra av dig om du har några frågor.</p>
      <p>Med vänliga hälsingar,</p>
    `,
  },
  companiesWithoutCollectiveAgreement: {
    id: 'smeSummaryMessages.companiesWithoutCollectiveAgreement',
    defaultMessage:
      'Bolag med anställda utan kollektivavtal med befintlig lösning',
  },
  companiesWithoutCollectiveAgreementPlaceholder: {
    id: 'smeSummaryMessages.companiesWithoutCollectiveAgreementPlaceholder',
    defaultMessage: `
    <p>Nedan följer en sammanfattning över mina råd avseende pension och försäkring. Jag har lämnat rådgivning om en tjänstepensionslösning för bolagets anställda.</p>
    <p>För att nå önskemålet om en marknadsmässig försäkringslösning för er rekommenderar jag följande:</p>
    <ul>
      <li>Sjukförsäkring - skydd mot inkomstbortfall vid långvarig sjukskrivning. Ger även anställda tillgång till samtalsstöd och rehabiliteringsförsäkringsförsäkring.</li>
      <li>Premiebefrielse - skydd av sparande vid långvarig sjukdom.</li>
      <li>Tjänstegruppliv - Betalar upp till 6 prisbasbelopp vid dödsfall.</li>
      <li>Olycksfallsförsäkring - Gäller vid olycksfall dygnet runt.</li>
      <li>Sjukvårdsförsäkring- Tillgång till snabbare specialistvård utan remiss. Denna försäkring förmånsbeskattas för individen.</li>
      <li>Löneväxling. Anställda som har lön över 8,07 inkomstbasbelopp har möjlighet att växla delar av lönen till pension. Sparandet kommer att räknas upp för kostnadsneutralitet enligt policy som vi tar fram tillsammans.</li>
      <li>Gruppförsäkringar, som administreras och betalas av den anställde privat.</li>
    </ul>
    <p>Utöver detta rekommenderar jag er att flytta över tjänstepensionerna och starta ett sparande med marknadsmässig avsättningsnivå vilket motsvarar 4,5 % upp till 7,5 ibb och 30 % på lön utöver det. Genom att lägga upp en procentplan kommer pensionsavsättningen automatisk vara löneföljsam och successivt höjas vid ev. framtida lönehöjningar.</p>
    <p>Alla anställda kommer att anslutas till vår förmånsportal - Min Framtidsplan. Via denna portal kan de boka in sig för rådgivning gällande både försäkrings- och bankfrågor. De som kan löneväxla kan göra det via portalen samt teckna upp gruppförsäkringar.</p>
    <p>En tjänstepensionsförsäkring är ett levande engagemang som kan behöva korrigeras eller förändras över tid, antingen på grund av företagsspecifika förändringar eller på grund av att privatlivet förändras. Det är därför klokt att ha en kontinuerlig uppföljning.</p>
    <p>Tveka inte att höra av dig om du har några frågor.</p>
    <p>Med vänlig hälsning,</p>
    `,
  },
  existingSolutionInNLP: {
    id: 'smeSummaryMessages.existingSolutionInNLP',
    defaultMessage:
      'Årsgenomgång Ägare - med anställda befintliga lösningar i NLP',
  },
  existingSolutionInNLPPlaceholder: {
    id: 'smeSummaryMessages.existingSolutionInNLPPlaceholder',
    defaultMessage: `
      <p>Vi gick igenom era försäkringslösningar och pratade om försäkringsskyddet för dina anställda och vi kom överens om att de ligger rätt utifrån företagets önskemål.</p>
      <p>Försäkringsskyddet för dig som ägare ligger även de i önskade nivåer.</p>
      <p>Vi pratade om avdragsmöjligheterna, där du skulle kunna sätta av en större summa till tjänstepensionen avdragsgillt via köpa i kapp regeln. Något som inte är aktuellt i år då bolaget gör en större investering men vi tar upp frågan nästa år.</p>
      <p>Efter analys av dina pensioner och försäkringar tycker jag att ni har det skydd som ni behöver och önskar vid pension, sjukdom, sjukvård, olycksfall och dödsfall. Jag rekommenderar därför att du inte gör några ändringar.</p>
      <p>Om du har övriga frågor är du varmt välkommen att kontakta mig.</p>
      <p>En tjänstepensionsförsäkring är ett levande engagemang som kan behöva korrigeras eller förändras över tid, antingen på grund av företagsspecifika förändringar eller på grund av att privatlivet förändras. Det är därför klokt att ha en kontinuerlig uppföljning.</p>
      <p>Med vänlig hälsning,</p>
    `,
  },
  ownerWithExistingInsuranceSolutionEnglish: {
    id: 'smeSummaryMessages.ownerWithExistingInsuranceSolutionEnglish',
    defaultMessage: 'Ägare med försäkringslösning i annat bolag - engelska',
  },
  ownerWithExistingInsuranceSolutionEnglishPlaceholder: {
    id: 'smeSummaryMessages.ownerWithInsuranceInAnotherCompanyPlaceholder',
    defaultMessage: `
      <p>You have recently initiated a collaboration with Nordea, and in connection with this, you would also like to review the occupational pension solution for you employees. Currently, you have a solution with X but are prepared to change providers if we can offer a comparable solution. You are requesting a Confidential proposal for a pension solution that aligns with what you currently have through X, as you aim to consolidate everything with Nordea for simplicity.</p>
      <p>The goal of saving is to offer employees an attractive solution and salary changes to maintain good knowledge within the company in the form of labour. The goals of the security insurance are to contribute to increased financial security if long-term sick leave occurs, to get help more quickly for most needs of planned care, or receive compensation after accidents that lead to permanent injuries to the body and some basic security for survivors in case of premature death.</p>
      <p>We have had a review of what a collectively agreed pension agreement normally looks like and examined various levels that you can choose for your employees. In order to appear as an attractive employer, it is important to provide the employees with a pension solution and risk protection. Since you have already agreed to a solution for you employees, we will strive to mirror it as closely as possible.</p>
      <p>Based on your needs, my recommendation is to opt for a solution that mirrors the agreement you currently have with X. Additionally, you would have the option to provide employees with salary exchange, accessible only on salaries exceeding the breaking point of about 50.000 SEK per month. Regarding risk insurance, I propose the following, based on the information received, which we can offer at Nordea and recommend for your employees.</p>
      <p>Sickness Insurance - recommended max level, i.e. about 90% of the salary is insured for long-term sick leave for more than 3 months. You have received figures on what it corresponds to for each employee.</p>
      <p>Premium waiver Insurance - insure the savings premium for long-term sick leave (3 months waiting period).</p>
      <p>Accident insurance - applies 24/7, 30 price basic amounts.</p>
      <p>Group Life Insurance - life insurance that pays out up to 6 price basic amounts in the event of death to beneficiary. Pension provision is fixed already for each individual, and we will copy that.</p>
      <p>It is important that you close your agreement with X. We have discussed and ensured that the products included in the offer are products that meet your needs.</p>
      <p>If you have any further questions, feel free to contact me.</p>
      <p>Kind regards,</p>
    `,
  },
  checksTitle: {
    id: 'smeSummaryMessages.checksTitle',
    defaultMessage: 'Jag har gått igenom följande:',
  },
  checkDeductionSpace: {
    id: 'smeSummaryMessages.checkDeductionSpace',
    defaultMessage: 'Avdragsutrymmet för företagsbetalda pensionsavsättningar',
  },
  checkExistingCoverage: {
    id: 'smeSummaryMessages.checkExistingCoverage',
    defaultMessage: 'Företagets befintliga försäkringar och pensionsavtal',
  },
  checkFinancialSituation: {
    id: 'smeSummaryMessages.checkFinancialSituation',
    defaultMessage:
      'Information om företagets finansiella ställning. Utifrån företagets finansiella ställning gör jag bedömningen att företaget kan finansiera de löpande premierna för den tänkta pensionsförsäkringen.',
  },
  checkNeed: {
    id: 'smeSummaryMessages.checkNeed',
    defaultMessage: 'Företagets behov och önskemål',
  },
  checkPlannedDevelopment: {
    id: 'smeSummaryMessages.checkPlannedDevelopment',
    defaultMessage:
      'Eventuell planerad utveckling som kan påverka företagets ekonomiska ställning',
  },
  format: {
    id: 'smeSummaryMessages.format',
    defaultMessage: 'Format',
  },
  digitalFormat: {
    id: 'smeSummaryMessages.digitalFormat',
    defaultMessage:
      'Företaget har valt att ta del av denna information och tillhörande rådgivningsdokumentation i digitalt format.',
  },
  paperFormat: {
    id: 'smeSummaryMessages.paperFormat',
    defaultMessage:
      'Företaget har valt att ta del av denna information och tillhörande rådgivningsdokumentation i pappersformat.',
  },
});

export const smeCompilationMessages = defineMessages({
  accidentInsurance: {
    id: 'smeCompilationMessages.accidentInsurance',
    defaultMessage: 'Olycksfallsförsäkring',
  },
  annualFixedFee: {
    id: 'smeCompilationMessages.annualFixedFee',
    defaultMessage: 'Fast årsavgift',
  },
  benefitPackageDetails: {
    id: 'smeCompilationMessages.benefitPackageDetails',
    defaultMessage: 'Beskrivning per förmånsgrupp',
  },
  benefitPackageTitle: {
    id: 'smeCompilationMessages.benefitPackageTitle',
    defaultMessage: 'Förmånsgrupp: {name}',
  },
  capitalFee: {
    id: 'smeCompilationMessages.capitalFee',
    defaultMessage: 'Kapitalavgift',
  },
  compilation: {
    id: 'smeCompilationMessages.compilation',
    defaultMessage: 'Sammanställning',
  },
  summation: {
    id: 'smeCompilationMessages.summation',
    defaultMessage: 'Summering',
  },
  defaultManagement: {
    id: 'smeCompilationMessages.defaultManagement',
    defaultMessage: 'Nordeas entrélösning',
  },
  disabilityInsurance: {
    id: 'smeCompilationMessages.disabilityInsurance',
    defaultMessage: 'Sjukförsäkring',
  },
  fundManagement: {
    id: 'smeCompilationMessages.fundManagement',
    defaultMessage: 'Fondförvaltning',
  },
  healthcareInsurance: {
    id: 'smeCompilationMessages.healthcareInsurance',
    defaultMessage: 'Sjukvårdsförsäkring',
  },
  lifeInsurance: {
    id: 'smeCompilationMessages.lifeInsurance',
    defaultMessage: 'Livförsäkring',
  },
  offer: {
    id: 'smeCompilationMessages.offer',
    defaultMessage: 'Erbjudande',
  },
  managementType: {
    id: 'smeCompilationMessages.managementType',
    defaultMessage: 'Förvaltningsform',
  },
  supplier: {
    id: 'smeCompilationMessages.supplier',
    defaultMessage: 'Leverantör',
  },
  monthlyPayment: {
    id: 'smeCompilationMessages.monthlyPayment',
    defaultMessage: 'Premie per månad',
  },
  noPremiumDeduction: {
    id: 'smeCompilationMessages.noPremiumDeduction',
    defaultMessage: 'Utanför premieutrymmet.',
  },
  premiumDeduction: {
    id: 'smeCompilationMessages.premiumDeduction',
    defaultMessage: 'Inom premieutrymmet.',
  },
  pensionFees: {
    id: 'smeCompilationMessages.pensionFees',
    defaultMessage: 'Avgifter',
  },
  pensionPremium: {
    id: 'smeCompilationMessages.pensionPremium',
    defaultMessage: 'Sparpremie/mån',
  },
  portfolioManagement: {
    id: 'smeCompilationMessages.portfolioManagement',
    defaultMessage: 'Depåförvaltning',
  },
  premiumWaiver: {
    id: 'smeCompilationMessages.premiumWaiver',
    defaultMessage: 'Premiebefrielse',
  },
  premiumWaiverNoPremiumDeduction: {
    id: 'smeCompilationMessages.premiumWaiverNoPremiumDeduction',
    defaultMessage: 'Utanför premieutrymmet',
  },
  premiumWaiverPremiumDeduction: {
    id: 'smeCompilationMessages.premiumWaiverPremiumDeduction',
    defaultMessage: 'Inom premieutrymmet',
  },
  riskInsurances: {
    id: 'smeCompilationMessages.riskInsurances',
    defaultMessage: 'Riskförsäkringar/mån',
  },
  salaryExchangeWithoutPremiumWaiver: {
    id: 'smeCompilationMessages.salaryExchangeWithoutPremiumWaiver',
    defaultMessage: 'Löneväxling utan premiebefrielse',
  },
  salaryExchangeWithPremiumWaiver: {
    id: 'smeCompilationMessages.salaryExchangeWithPremiumWaiver',
    defaultMessage: 'Löneväxling med premiebefrielse',
  },
  salaryExchangeWithoutPremiumWaiverDesc: {
    id: 'smeCompilationMessages.salaryExchangeWithoutPremiumWaiverDesc',
    defaultMessage: 'Möjlighet att löneväxla',
  },
  salaryExchangeWithPremiumWaiverDesc: {
    id: 'smeCompilationMessages.salaryExchangeWithPremiumWaiverDesc',
    defaultMessage:
      'Möjlighet att löneväxla (premiebefrielse utanför premieutrymmet)',
  },
  specification: {
    id: 'smeCompilationMessages.specification',
    defaultMessage: 'Specifikation',
  },
  tglInsurance: {
    id: 'smeCompilationMessages.tglInsurance',
    defaultMessage: 'Tjänstegrupplivförsäkring',
  },
  tglNoPremiumDeduction: {
    id: 'smeCompilationMessages.tglNoPremiumDeduction',
    defaultMessage: 'Utanför premieutrymmet',
  },
  total: {
    id: 'smeCompilationMessages.total',
    defaultMessage: 'Totalt/mån',
  },
  traditionalManagement: {
    id: 'smeCompilationMessages.traditionalManagement',
    defaultMessage: 'Garantiförvaltning',
  },
  waitingPeriod: {
    id: 'smeCompilationMessages.waitingPeriod',
    defaultMessage: 'med karens',
  },
  noWaitingPeriod: {
    id: 'smeCompilationMessages.noWaitingPeriod',
    defaultMessage: 'utan karens',
  },
  downloadQuote: {
    id: 'smeCompilationMessages.downloadQuote',
    defaultMessage: 'Ladda ned prisförslag',
  },
  noEmployees: {
    id: 'smeCompilationMessages.noEmployees',
    defaultMessage: 'Inga anställda',
  },
  occupationalPension: {
    id: 'smeCompilationMessages.occupationalPension',
    defaultMessage: 'Tjänstepension',
  },
});

export const smeCompilationManagementTypeMessages =
  defineMessages<ProposalDefaultManagementType>({
    FUND: {
      id: 'smeCompilationManagementTypeMessages.FUND',
      defaultMessage: 'Förvalt: Pensionsförsäkring med fondförvaltning',
    },
    PORTFOLIO: {
      id: 'smeCompilationManagementTypeMessages.PORTFOLIO',
      defaultMessage: 'Förvalt: Pensionsförsäkring med depåförvaltning',
    },
    TRAD: {
      id: 'smeCompilationManagementTypeMessages.TRAD',
      defaultMessage: 'Förvalt: Pensionsförsäkring med garantiförvaltning',
    },
  });

export const smeCompilationDisabilityInsuranceMessages =
  defineMessages<ProposalSicknessLevel>({
    CONSOLIDATION: {
      id: 'smeCompilationDisabilityInsuranceMessages.CONSOLIDATION',
      defaultMessage: 'Konsolideringsnivå. {premiumDeduction}',
    },
    HYBRID: {
      id: 'smeCompilationDisabilityInsuranceMessages.HYBRID',
      defaultMessage: 'Hybridnivå. {premiumDeduction}',
    },
    ITP: {
      id: 'smeCompilationDisabilityInsuranceMessages.ITP',
      defaultMessage: 'ITP-nivå. {premiumDeduction}',
    },
    KOMP: {
      id: 'smeCompilationDisabilityInsuranceMessages.KOMP',
      defaultMessage: 'ITP-kompletteringsnivå. {premiumDeduction}',
    },
    MAX: {
      id: 'smeCompilationDisabilityInsuranceMessages.MAX',
      defaultMessage: 'Maxnivå. {premiumDeduction}',
    },
  });

export const smeCompilationHealthcareInsuranceMessages =
  defineMessages<ProposalHealthcareLevel>({
    BRONS_0: {
      id: 'smeCompilationHealthcareInsuranceMessages.BRONS_0',
      defaultMessage:
        'PrivatAccess BRONS, självrisk 0 kr {waitingPeriod}. Utanför premieutrymmet',
    },
    BRONS_500: {
      id: 'smeCompilationHealthcareInsuranceMessages.BRONS_500',
      defaultMessage:
        'PrivatAccess BRONS, självrisk 500 kr {waitingPeriod}. Utanför premieutrymmet',
    },
    GOLD: {
      id: 'smeCompilationHealthcareInsuranceMessages.GOLD',
      defaultMessage:
        'PrivatAccess GULD {waitingPeriod}. Utanför premieutrymmet',
    },
    L0: {
      id: 'smeCompilationHealthcareInsuranceMessages.L0',
      defaultMessage:
        'PrivatAccess SILVER, självrisk 0 kr {waitingPeriod}. Utanför premieutrymmet',
    },
    L500: {
      id: 'smeCompilationHealthcareInsuranceMessages.L500',
      defaultMessage:
        'PrivatAccess SILVER, självrisk 500 kr {waitingPeriod}. Utanför premieutrymmet',
    },
    L1000: {
      id: 'smeCompilationHealthcareInsuranceMessages.L1000',
      defaultMessage:
        'PrivatAccess SILVER, självrisk 1000 kr {waitingPeriod}. Utanför premieutrymmet',
    },
    L1500: {
      id: 'smeCompilationHealthcareInsuranceMessages.L1500',
      defaultMessage:
        'PrivatAccess SILVER, självrisk 1500 kr {waitingPeriod}. Utanför premieutrymmet',
    },
  });

export const smeCompilationAccidentInsuranceMessages =
  defineMessages<ProposalAccidentLevel>({
    PBB20: {
      id: 'smeCompilationAccidentInsuranceMessages.PBB20',
      defaultMessage: '20 prisbasbelopp. Utanför premieutrymmet',
    },
    PBB30: {
      id: 'smeCompilationAccidentInsuranceMessages.PBB30',
      defaultMessage: '30 prisbasbelopp. Utanför premieutrymmet',
    },
    PBB40: {
      id: 'smeCompilationAccidentInsuranceMessages.PBB40',
      defaultMessage: '40 prisbasbelopp. Utanför premieutrymmet',
    },
    PBB50: {
      id: 'smeCompilationAccidentInsuranceMessages.PBB50',
      defaultMessage: '50 prisbasbelopp. Utanför premieutrymmet',
    },
  });

export const smeCompilationLifeInsuranceMessages =
  defineMessages<ProposalLifeLevel>({
    PBB5: {
      id: 'smeCompilationLifeInsuranceMessages.PBB5',
      defaultMessage: '5 prisbasbelopp. Utanför premieutrymmet',
    },
    PBB10: {
      id: 'smeCompilationLifeInsuranceMessages.PBB10',
      defaultMessage: '10 prisbasbelopp. Utanför premieutrymmet',
    },
    PBB15: {
      id: 'smeCompilationLifeInsuranceMessages.PBB15',
      defaultMessage: '15 prisbasbelopp. Utanför premieutrymmet',
    },
    PBB20: {
      id: 'smeCompilationLifeInsuranceMessages.PBB20',
      defaultMessage: '20 prisbasbelopp. Utanför premieutrymmet',
    },
    PBB25: {
      id: 'smeCompilationLifeInsuranceMessages.PBB25',
      defaultMessage: '25 prisbasbelopp. Utanför premieutrymmet',
    },
    PBB30: {
      id: 'smeCompilationLifeInsuranceMessages.PBB30',
      defaultMessage: '30 prisbasbelopp. Utanför premieutrymmet',
    },
    PBB35: {
      id: 'smeCompilationLifeInsuranceMessages.PBB35',
      defaultMessage: '35 prisbasbelopp. Utanför premieutrymmet',
    },
    PBB40: {
      id: 'smeCompilationLifeInsuranceMessages.PBB40',
      defaultMessage: '40 prisbasbelopp. Utanför premieutrymmet',
    },
    PBB45: {
      id: 'smeCompilationLifeInsuranceMessages.PBB45',
      defaultMessage: '45 prisbasbelopp. Utanför premieutrymmet',
    },
    PBB50: {
      id: 'smeCompilationLifeInsuranceMessages.PBB50',
      defaultMessage: '50 prisbasbelopp. Utanför premieutrymmet',
    },
  });

export const smeGenerateProposalMessages = defineMessages({
  generate: {
    id: 'smeGenerateProposalMessages.generate',
    defaultMessage: 'Generera',
  },
  generateProposal: {
    id: 'smeGenerateProposalMessages.generateProposal',
    defaultMessage: 'Generera offert',
  },
  generatingProposal: {
    id: 'smeGenerateProposalMessages.generatingProposal',
    defaultMessage: 'Genererar offert...',
  },
  includeIndividualProposal: {
    id: 'smeGenerateProposalMessages.includeIndividualProposal',
    defaultMessage: 'Inkludera individoffert',
  },
  missingCompanyDetailsTitle: {
    id: 'smeGenerateProposalMessages.missingCompanyDetailsTitle',
    defaultMessage: 'Komplettera företagsuppgifter',
  },
  missingCompanyDetailsDescription: {
    id: 'smeGenerateProposalMessages.missingCompanyDetailsDescription',
    defaultMessage:
      'För att kunna generera offerten behövs följande kompletterande företagsuppgifter. Du kommer även kunna se detta under företagsinställningar.',
  },
  proposalDetailsTitle: {
    id: 'smeGenerateProposalMessages.proposalDetailsTitle',
    defaultMessage: 'Startdatum',
  },
  proposalDetailsDescription: {
    id: 'smeGenerateProposalMessages.proposalDetailsDescription',
    defaultMessage:
      'För att generera offerten behövs ett startdatum för när den ska börja gälla.',
  },
  proposalsAndAttachments: {
    id: 'smeGenerateProposalMessages.proposalsAndAttachments',
    defaultMessage: 'Offert & bilagor',
  },
  startDate: {
    id: 'smeGenerateProposalMessages.startDate',
    defaultMessage: 'Startdatum',
  },
});
