import gql from 'graphql-tag';

export const SME_COMPANY_PENSION_FEES_QUERY = gql`
  query smeCompanyPensionFeesQuery($id: ID!) {
    proposal(id: $id) {
      id
      fees {
        annualFixedFee
        capitalFeeFund
        capitalFeePortfolio
        capitalFeeTraditional
        fundRange
        portfolioRange
      }
    }
  }
`;
