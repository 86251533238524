import { Td } from '@frontend/ui';
import {
  accountingDimensionsQuery,
  accountingDimensionsQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { LinkButton } from 'components/LinkButton';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { CostReportingTable, CostReportingTableRow } from './components/Table';
import { ACCOUNTING_DIMENSIONS_QUERY } from './graphql/queries';
import { costReportingMessages } from './messages';

export const CostReporting: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const { data, loading, error } = useQuery<
    accountingDimensionsQuery,
    accountingDimensionsQueryVariables
  >(ACCOUNTING_DIMENSIONS_QUERY, {
    variables: { companyId },
    errorPolicy: 'all',
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.accountingDimensions) {
    return <GraphQlError error={error} />;
  }

  const dimensions = data.accountingDimensions;

  if (!dimensions.length) {
    return (
      <EmptyState
        title={
          <FormattedMessage
            {...costReportingMessages.emptyStateDimensionsTitle}
          />
        }
        actions={
          <LinkButton to={{ pathname, search: qs.stringify({ create: true }) }}>
            <FormattedMessage {...costReportingMessages.createDimension} />
          </LinkButton>
        }
      >
        <p>
          <FormattedMessage
            {...costReportingMessages.emptyStateDimensionsDescription}
          />
        </p>
      </EmptyState>
    );
  }

  return (
    <>
      {!!error && <GraphQlError error={error} />}
      <CostReportingTable
        columns={[
          <FormattedMessage
            key={costReportingMessages.tableCodeColumn.id}
            {...costReportingMessages.tableCodeColumn}
          />,
          <FormattedMessage
            key={costReportingMessages.tableNameColumn.id}
            {...costReportingMessages.tableNameColumn}
          />,
        ]}
      >
        {dimensions.map(dimension => (
          <CostReportingTableRow
            key={dimension.id}
            primaryLinkAction={() => push(`${pathname}/${dimension.id}`)}
            editLink={{
              pathname,
              search: qs.stringify({ edit: dimension.id }),
            }}
          >
            <Td>{dimension.identifier}</Td>
            <Td>{dimension.name}</Td>
          </CostReportingTableRow>
        ))}
      </CostReportingTable>
    </>
  );
};
