import {
  SideSheetListContainer,
  SideSheetSection,
  SideSheetSubtitle,
} from 'components/SideSheet';
import React from 'react';

interface FilterSectionProps {
  children: React.ReactNode;
  header: React.ReactNode;
}

export const FilterSection: React.FC<FilterSectionProps> = ({
  header,
  children,
}) => (
  <SideSheetSection>
    <SideSheetSubtitle>{header}</SideSheetSubtitle>
    <SideSheetListContainer>{children}</SideSheetListContainer>
  </SideSheetSection>
);
