import { Table, Td, Th, Tr } from '@frontend/ui';
import { bpsToDecimalFraction } from '@frontend/utils';
import { smeCompanyPensionFeesQuery_proposal_Proposal_fees_ProposalFees as Fees } from 'app/apollo/graphql/types';
import {
  FormattedCurrency,
  FormattedMessage,
  FormattedPercent,
  FormattedRange,
} from 'components/formats';
import { NoValue } from 'components/NoValue';
import { smeCompilationMessages } from 'features/sme/messages/sme';
import React from 'react';

interface Props {
  fees: Fees;
}

export const FeesTable: React.FC<Props> = ({ fees }) => (
  <Table size="small">
    <colgroup>
      <col style={{ width: '25%' }} />
      <col style={{ width: '25%' }} />
      <col style={{ width: '25%' }} />
      <col style={{ width: '25%' }} />
    </colgroup>
    <thead>
      <Tr>
        <Th>
          <FormattedMessage {...smeCompilationMessages.specification} />
        </Th>
        <Th align="left" type="number">
          <FormattedMessage {...smeCompilationMessages.traditionalManagement} />
        </Th>
        <Th align="left" type="number">
          <FormattedMessage {...smeCompilationMessages.fundManagement} />
        </Th>
        <Th align="left" type="number">
          <FormattedMessage {...smeCompilationMessages.portfolioManagement} />
        </Th>
      </Tr>
    </thead>
    <tbody>
      <Tr>
        <Td>
          <FormattedMessage {...smeCompilationMessages.annualFixedFee} />
        </Td>
        <Td align="left" type="number">
          <FormattedCurrency currency="SEK" value={fees.annualFixedFee} />
        </Td>
        <Td align="left" type="number">
          <FormattedCurrency currency="SEK" value={fees.annualFixedFee} />
        </Td>
        <Td align="left" type="number">
          <FormattedCurrency currency="SEK" value={fees.annualFixedFee} />
        </Td>
      </Tr>
      <Tr>
        <Td>
          <FormattedMessage {...smeCompilationMessages.capitalFee} />
        </Td>
        <Td align="left" type="number">
          {fees.capitalFeeTraditional != null ? (
            <FormattedPercent
              value={bpsToDecimalFraction(fees.capitalFeeTraditional)}
            />
          ) : (
            <NoValue />
          )}
        </Td>
        <Td align="left" type="number">
          {fees.capitalFeeFund != null ? (
            <FormattedPercent
              value={bpsToDecimalFraction(fees.capitalFeeFund)}
            />
          ) : (
            <NoValue />
          )}
        </Td>
        <Td align="left" type="number">
          {fees.capitalFeePortfolio != null ? (
            <FormattedPercent
              value={bpsToDecimalFraction(fees.capitalFeePortfolio)}
            />
          ) : (
            <NoValue />
          )}
        </Td>
      </Tr>
      <Tr>
        <Td>
          <FormattedMessage {...smeCompilationMessages.defaultManagement} />
        </Td>
        <Td />
        <Td align="left" type="number">
          {fees.fundRange[0] != null && fees.fundRange[1] != null ? (
            <FormattedRange
              lowerBound={
                <FormattedPercent
                  value={bpsToDecimalFraction(fees.fundRange[0])}
                />
              }
              upperBound={
                <FormattedPercent
                  value={bpsToDecimalFraction(fees.fundRange[1])}
                />
              }
            />
          ) : (
            <NoValue />
          )}
        </Td>
        <Td align="left" type="number">
          {fees.portfolioRange[0] != null && fees.portfolioRange[1] != null ? (
            <FormattedRange
              lowerBound={
                <FormattedPercent
                  value={bpsToDecimalFraction(fees.portfolioRange[0])}
                />
              }
              upperBound={
                <FormattedPercent
                  value={bpsToDecimalFraction(fees.portfolioRange[1])}
                />
              }
            />
          ) : (
            <NoValue />
          )}
        </Td>
      </Tr>
    </tbody>
  </Table>
);
