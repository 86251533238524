/* eslint-disable */
import {
  Button,
  ButtonLayout,
  CheckboxField,
  Icon,
  MenuSurface,
  MenuSurfaceAnchor,
  ModalBody,
  ModalFooter,
  TextField,
} from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Option } from '../Option';
import { formMessages } from 'app/messages/form';
import { commonMessages } from 'app/messages/common';

interface Link {
  doCollapse: () => void;
  doExpand: () => void;
  onChange: (...input: any[]) => void;
  onExpandEvent: () => void;
  config?: { [key: string]: any };
  currentState?: { [key: string]: any };
  expanded?: boolean;
  translations?: { [key: string]: any };
}

export const Link: React.FC<Link> = ({
  expanded,
  config,
  doCollapse,
  currentState,
  translations,
  onChange,
  onExpandEvent,
}) => {
  const initState = {
    showModal: false,
    linkTarget: '',
    linkTitle: '',
    linkTargetOption: config?.defaultTargetOption,
  };

  const [state, setState] = useState(initState);
  const [cancelButtonFocused, setCancelButtonFocused] = useState(false);

  useEffect(() => {
    if (!expanded) {
      setState(initState);
    }
  }, [expanded]);

  const removeLink = () => !!onChange && onChange('unlink');

  const addLink = () =>
    !!onChange &&
    onChange('link', state.linkTitle, state.linkTarget, state.linkTargetOption);

  const updateValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const { value } = e.target;
    setState(prev => ({ ...prev, [`${name}`]: value }));
  };

  const updateTargetOption = (checked: boolean) => {
    setState(prev => ({
      ...prev,
      linkTargetOption: checked ? '_blank' : 'self',
    }));
  };

  const signalExpandShowModal = () => {
    if (onExpandEvent) {
      onExpandEvent();
    }
    setState({
      showModal: true,
      linkTarget: currentState?.link?.target || '',
      linkTargetOption:
        currentState?.link?.targetOption || state.linkTargetOption,
      linkTitle: currentState?.link?.title || currentState?.selectionText,
    });
  };

  return (
    <div className="rdw-link-wrapper" aria-label="rdw-link-control">
      {config?.options.indexOf('link') >= 0 && (
        <Option
          value="unordered-list-item"
          className={config?.link.className}
          onClick={signalExpandShowModal}
          aria-haspopup="true"
          aria-expanded={state.showModal}
          title={
            config?.link.title ||
            (!!translations && translations['components.controls.link.link'])
          }
        >
          {typeof config?.link.icon === 'string' ? (
            <img alt="icon" src={config.link.icon} />
          ) : (
            <Icon icon={config?.link.icon} size="small" />
          )}
        </Option>
      )}
      {config?.options.indexOf('unlink') >= 0 && (
        <Option
          disabled={!currentState?.link}
          value="ordered-list-item"
          className={config?.unlink.className}
          onClick={removeLink}
          title={
            config?.unlink.title ||
            (!!translations && translations['components.controls.link.unlink'])
          }
        >
          {typeof config?.unlink.icon === 'string' ? (
            <img alt="icon" src={config.unlink.icon} />
          ) : (
            <Icon icon={config?.unlink.icon} size="small" />
          )}
        </Option>
      )}
      {expanded && state.showModal ? (
        <MenuSurfaceAnchor>
          <MenuSurface
            style={{ transform: 'none' }}
            open={state.showModal}
            onClose={doCollapse}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              e.stopPropagation();
              if (document.activeElement instanceof HTMLElement) {
                document.activeElement?.blur();
              }
            }}
          >
            {/* eslint-disable-next-line */}
            <div
              role="form"
              onKeyDown={e => {
                if (e.key === 'Enter' && !cancelButtonFocused) {
                  e.preventDefault();
                  if (state.linkTarget && state.linkTitle) {
                    addLink();
                  }
                }
              }}
            >
              <ModalBody style={{ minWidth: '15rem' }}>
                <TextField
                  label={
                    !!translations &&
                    translations['components.controls.link.linkTitle']
                  }
                  name="linkTitle"
                  value={state.linkTitle}
                  onChange={updateValue}
                  onClick={e => e.stopPropagation()}
                  dense
                />
                <TextField
                  label={
                    !!translations &&
                    translations['components.controls.link.linkTarget']
                  }
                  name="linkTarget"
                  value={state.linkTarget}
                  onChange={updateValue}
                  onClick={e => e.stopPropagation()}
                  dense
                />
                <CheckboxField
                  checked={state.linkTargetOption === '_blank'}
                  onChange={updateTargetOption}
                  label={
                    !!translations &&
                    translations['components.controls.link.linkTargetOption']
                  }
                />
              </ModalBody>
              <ModalFooter>
                <ButtonLayout align="right">
                  <Button
                    text
                    onClick={doCollapse}
                    onFocus={() => setCancelButtonFocused(true)}
                    onBlur={() => setCancelButtonFocused(false)}
                  >
                    <FormattedMessage {...formMessages.cancel} />
                  </Button>
                  <Button
                    filled
                    disabled={!state.linkTarget || !state.linkTitle}
                    onClick={addLink}
                  >
                    <FormattedMessage {...commonMessages.create} />
                  </Button>
                </ButtonLayout>
              </ModalFooter>
            </div>
          </MenuSurface>
        </MenuSurfaceAnchor>
      ) : undefined}
    </div>
  );
};
