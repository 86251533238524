import { hasBackstagePermission } from 'app/utils/has-backstage-access';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { useCurrentUser } from 'contexts/current-permissions';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { smeCompanyMessages } from 'features/sme/messages/sme';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import { SmeOverviewRoute } from './_index';
import { SmeCompanyRoutes } from './company';
import { SmeCreateCompanyRoute } from './create';

export const SmeRoutes: React.FC<RouteComponentProps> = ({ match }) => {
  const { permissions } = useCurrentUser();

  if (!hasBackstagePermission('backstageApp', 'smeTab', permissions)) {
    return <Redirect to="/" />;
  }

  return (
    <NavigationAnchor path={match.url} title={smeCompanyMessages.proposal}>
      <Switch>
        <Route exact path={match.url} component={SmeOverviewRoute} />
        <Route path={`${match.url}/create`} component={SmeCreateCompanyRoute} />
        <Route path={`${match.url}/:companyId`} component={SmeCompanyRoutes} />
        <Route component={EmptyStatePage} />
      </Switch>
    </NavigationAnchor>
  );
};
