import { EmptyStatePage } from 'components/EmptyStatePage';
import { TopLoading } from 'components/TopLoading';
import { isLargeAdvinansTier } from 'features/companies/company/agreements/utils/is-large-advinans-tier';
import { useAgreements } from 'features/companies/company/agreements/utils/use-agreements';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { MatchParams } from '..';
import { Overview } from './_index';
import { CreateRoute } from './create';
import { EditRoute } from './edit';

export const JobOffers: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const {
    path,
    params: { companyId },
  } = match;
  const { loading, agreements } = useAgreements(companyId);

  if (loading) {
    return <TopLoading />;
  }

  if (!isLargeAdvinansTier(agreements)) {
    return <EmptyStatePage parentLink={`/companies/${companyId}`} />;
  }

  return (
    <Switch>
      <Route path={`${path}/create`} component={CreateRoute} />
      <Route path={`${path}/edit/:jobOfferId`} component={EditRoute} />
      <Route path={path} component={Overview} />
    </Switch>
  );
};
