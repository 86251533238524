import gql from 'graphql-tag';

export const SME_COMPANY_QUERY = gql`
  query smeCompanyQuery($id: ID!) {
    company(id: $id) {
      id
      # accountNumber is not rendered in the UI but is updated through the
      # company details mutation which must be reflected in the cache
      # for the generate proposal wizard
      accountNumber
      clearingCode {
        displayName
      }
      displayName
      registrationNumber
      nlpSalesRep {
        id
        externalId
        firstName
        lastName
      }
      legalEntityType
      accountClosureMonth
      paymentMethod
      salaryReviewMonth
      address {
        line1
      }
    }
    proposalContactPerson(companyId: $id) {
      firstName
      lastName
      personalIdentityNumber
      email
      phoneNumber
    }
    proposalHeadAdmin(companyId: $id) {
      firstName
      lastName
      personalIdentityNumber
      email
      phoneNumber
    }
  }
`;
