import { useMutation } from '@apollo/client';
import {
  DeleteJobOfferInput,
  deleteJobOfferMutation,
  deleteJobOfferMutationVariables,
} from 'app/apollo/graphql/types';
import { JobOffersEditRouteMatchParams } from 'app/pages/companies/company/job-offers/edit';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { FormikErrors } from 'formik';
import { useHistory, useRouteMatch } from 'react-router';

import { FormValues } from '../../form';
import { DELETE_JOB_OFFER_MUTATION } from '../../graphql/mutations';
import { jobOfferMessages } from '../../messages';

interface Args {
  setErrors: (errors: FormikErrors<FormValues>) => void;
}

export const useDelete = () => {
  const intl = useIntl();
  const { send } = useNotification();
  const {
    params: { jobOfferId, companyId },
  } = useRouteMatch<JobOffersEditRouteMatchParams>();
  const { push } = useHistory();

  const [deleteJobOffer, { loading }] = useMutation<
    deleteJobOfferMutation,
    deleteJobOfferMutationVariables
  >(DELETE_JOB_OFFER_MUTATION, {
    update: cache => {
      cache.evict({ id: `JobOffer:${jobOfferId}` });
      cache.gc();
    },
  });

  const _delete = async ({ setErrors }: Args) => {
    try {
      const input: DeleteJobOfferInput = {
        id: jobOfferId,
      };

      const deleteJobOfferResult = await deleteJobOffer({
        variables: { input },
      });

      if (!deleteJobOfferResult.data?.deleteJobOffer?.jobOfferId) {
        throw new Error();
      }

      send({
        message: intl.formatMessage(jobOfferMessages.deleted),
        type: 'success',
      });
      push(`/companies/${companyId}/job-offers`);
    } catch (error) {
      setErrors({
        submissionError: error?.message,
      });
    }
  };
  return { _delete, loading };
};
