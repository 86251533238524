import { cardLinkCss, CardPrimaryAction } from '@frontend/ui';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  ${cardLinkCss}
`;

export const CardLink: React.FC<LinkProps> = ({ children, ...props }) => (
  <StyledLink {...props}>
    <CardPrimaryAction>{children}</CardPrimaryAction>
  </StyledLink>
);
