import { useIntl } from 'components/formats';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { MessageDescriptor } from 'react-intl';

interface StackItem {
  path: string;
  title: string;
}

interface ContextProps {
  pop: (url: string) => void;
  push: (url: string, item: StackItem) => void;
  stack: Record<string, StackItem>;
}

export const NavigationAnchorContext = createContext<ContextProps>({
  pop: () => null,
  push: () => null,
  stack: {},
});

export const useNavigationAnchorContext = (): ContextProps =>
  useContext(NavigationAnchorContext);

interface Props {
  children: React.ReactNode;
}

/**
 * Provides context for navigation anchors, managing a stack of navigation paths and titles.
 * This context is used to maintain breadcrumbs and document titles dynamically.
 */
export const NavigationAnchorContextProvider: React.FC<Props> = ({
  children,
}) => {
  const [stack, setStack] = useState<Record<string, StackItem>>({});

  const value = useMemo(
    () => ({
      pop: (url: string) => {
        setStack(_stack => {
          const { [url]: _, ...rest } = _stack;
          return rest;
        });
      },
      push: (url: string, item: StackItem) => {
        setStack(_stack => ({ ..._stack, [url]: item }));
      },
      stack,
    }),
    [stack],
  );

  return (
    <NavigationAnchorContext.Provider value={value}>
      {children}
    </NavigationAnchorContext.Provider>
  );
};

interface NavigationAnchorProps extends PropsWithChildren {
  path: string;
  title: MessageDescriptor | string;
}

/**
 * Represents a navigation anchor that manages its own title and path within the navigation stack context.
 *
 * @param path - The path of the breadcrumb navigation anchor.
 * @param title - The title of the navigation anchor.
 * @returns  The navigation anchor component, which includes a `Helmet` component to set the document title.
 */
export const NavigationAnchor: React.FC<NavigationAnchorProps> = ({
  children,
  title,
  path,
}) => {
  const { push, pop, stack } = useNavigationAnchorContext();
  const { formatMessage } = useIntl();

  const _title = typeof title !== 'string' ? formatMessage(title) : title;

  useEffect(() => {
    push(path, {
      path,
      title: _title,
    });

    return () => pop(path);
  }, [title]);

  const titles = [
    ...Object.values(stack)
      .sort((a, b) => b.path.split('/').length - a.path.split('/').length)
      .map(({ title: pageTitle }) => pageTitle),
    'Nordea Node',
  ];

  return (
    <>
      <Helmet>
        <title>{titles.join(' - ')}</title>
      </Helmet>
      {children}
    </>
  );
};
