import { IconButton, Table, Td, Th, Tr } from '@frontend/ui';
import { _delete, addCircle } from '@frontend/ui/icons';
import { commonMessages } from 'app/messages/common';
import { employeeFormMessages } from 'app/messages/employees';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage, useIntl } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { FieldArray, useField } from 'formik';
import React, { useState } from 'react';

import { NidTableFieldModal } from '../NidTableFieldModal';

export interface NidValue {
  value: string;
  editable?: boolean;
}

interface Props {
  name: string;
}

export const NidTableField: React.FC<Props> = ({ name }) => {
  const [{ value }] = useField<NidValue[]>({ name });
  const [isModalOpen, setModalOpen] = useState(false);
  const { formatMessage } = useIntl();
  /* XXX Restricting UI to accept one new nid per mutation due to API limitations.
    Feel free to remove this if the api is extended to accept an array of nids. 
  */
  const addNidDisabled = value.filter(v => v.editable).length > 0;

  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <>
          {!addNidDisabled && (
            <ChipsWrapper>
              <AssistChip
                onClick={() => setModalOpen(true)}
                text={<FormattedMessage {...employeeFormMessages.addNid} />}
                leadingIcon={addCircle}
              />
            </ChipsWrapper>
          )}
          <Table size="xsmall">
            <thead>
              <Tr>
                <Th>
                  <FormattedMessage {...commonMessages.nidLong} />
                </Th>
              </Tr>
            </thead>
            <tbody>
              {value.map((nid, i) => (
                <Tr
                  key={i}
                  inlineMenu={
                    nid.editable && (
                      <IconButton
                        style={{ marginLeft: '0.5rem' }}
                        label={formatMessage(commonMessages.remove)}
                        icon={_delete}
                        onClick={() => remove(i)}
                        size="small"
                      />
                    )
                  }
                >
                  <Td>
                    <FormattedNaturalPersonIdentifier nid={nid.value} />
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
          <NidTableFieldModal
            isOpen={isModalOpen}
            onRequestClose={() => setModalOpen(false)}
            onAddValue={_value => push({ editable: true, value: _value })}
          />
        </>
      )}
    </FieldArray>
  );
};
