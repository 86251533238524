import { TextField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import { edit } from '@frontend/ui/icons';
import { stripSearchParams } from '@frontend/utils';
import {
  changeProposalNameQuery,
  changeProposalNameQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { useQuery } from 'app/utils/use-query';
import { Props as AssistChipProps } from 'components/AssistChip';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { TopLoading } from 'components/TopLoading';
import { smeCompanyProposalsMessages } from 'features/sme/messages/sme';
import { Form, Formik } from 'formik';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import * as Yup from 'yup';

import { useProposalReadonly } from '../../utils/use-proposal-readonly';
import { CHANGE_PROPOSAL_NAME_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface FormValues {
  name: string;
}

export const EditProposalNameModal: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const intl = useIntl();

  const { 'edit-proposal-name': proposalId } = qs.parse(location.search);

  const { loading, data, error } = useQuery<
    changeProposalNameQuery,
    changeProposalNameQueryVariables
  >(CHANGE_PROPOSAL_NAME_QUERY, {
    skip: !proposalId,
    errorPolicy: 'all',
    variables: { proposalId },
  });

  const editRepresentative = !!proposalId;

  const onRequestClose = () => {
    stripSearchParams(history, location, ['edit-proposal-name']);
  };

  const { submit, submissionError } = useSubmit({ onRequestClose, proposalId });

  if (loading) {
    return <TopLoading />;
  }

  const initialValues = {
    name: data?.proposal?.name ?? '',
  };

  return (
    <Modal isOpen={editRepresentative} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage {...smeCompanyProposalsMessages.editProposalName} />
      </ModalHeader>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={submit}
        validateOnMount
        validationSchema={validationSchema(intl)}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ModalBody>
              {error && <GraphQlError inModal error={error} />}
              <TextField
                dense
                name="name"
                label={<FormattedMessage {...commonMessages.name} />}
                required
              />
              {submissionError && (
                <GraphQlError inModal error={submissionError} />
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button
                  text
                  type="submit"
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  <FormattedMessage {...formMessages.save} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

interface Args {
  location: RouteComponentProps['location'];
  proposalId: string;
}

export const getEditProposalNameLink = ({
  location,
  proposalId,
}: Args): RouteComponentProps['location'] => ({
  ...location,
  search: qs.stringify({ 'edit-proposal-name': proposalId }),
});

export const getEditProposalNameAction = ({
  location,
  proposalId,
}: Args): AssistChipProps => {
  const isReadonly = useProposalReadonly();

  return {
    to: getEditProposalNameLink({ location, proposalId }),
    text: <FormattedMessage {...smeCompanyProposalsMessages.changeName} />,
    leadingIcon: edit,
    disabled: isReadonly,
  };
};
