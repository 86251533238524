import gql from 'graphql-tag';

const agreementDetails = gql`
  fragment agreementDetails on Agreement {
    __typename
    id
    status
    companyId
    companyName
    attachments {
      name
      type
      url
    }
    ... on AgreementEuroAccident {
      subAgreementType
    }
    ... on AgreementRequestEuroAccident {
      subAgreementType
    }
    ... on AgreementAdvinans {
      tier
    }
    ... on AgreementRequestAdvinans {
      tier
    }
  }
`;

export const AGREEMENTS_LIST_QUERY = gql`
  query agreementsListQuery($id: ID!) {
    company(id: $id) {
      id
      agreements {
        ...agreementDetails
      }
    }
  }
  ${agreementDetails}
`;
