import { smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit as Benefit } from 'app/apollo/graphql/types';

import { DEFAULT_BENEFITS_SORT_ORDER } from '../../../../utils/sort-benefits-by';

interface Config {
  __typename: string;
}

interface AccidentConfig extends Config {
  __typename: 'ProposalAccidentBenefitConfig';
}

export const isAccidentConfig = (config: Config): config is AccidentConfig =>
  config.__typename === 'ProposalAccidentBenefitConfig';

interface DisabilityConfig extends Config {
  __typename: 'ProposalSicknessBenefitConfig';
}

export const isDisabilityConfig = (
  config: Config,
): config is DisabilityConfig =>
  config.__typename === 'ProposalSicknessBenefitConfig';

interface HealthcareConfig extends Config {
  __typename: 'ProposalHealthcareBenefitConfig';
}

export const isHealthcareConfig = (
  config: Config,
): config is HealthcareConfig =>
  config.__typename === 'ProposalHealthcareBenefitConfig';

interface LifeConfig extends Config {
  __typename: 'ProposalLifeBenefitConfig';
}

export const isLifeConfig = (config: Config): config is LifeConfig =>
  config.__typename === 'ProposalLifeBenefitConfig';

interface PensionConfig extends Config {
  __typename:
    | 'ProposalEmployersPensionBenefitConfig'
    | 'ProposalOccupationalPensionBenefitConfig';
}

export const isPensionConfig = (config: Config): config is PensionConfig =>
  [
    'ProposalEmployersPensionBenefitConfig',
    'ProposalOccupationalPensionBenefitConfig',
  ].includes(config.__typename);

interface OccupationalPensionConfig extends Config {
  __typename: 'ProposalOccupationalPensionBenefitConfig';
}

export const isOccupationalPensionConfig = (
  config: Config,
): config is OccupationalPensionConfig =>
  config.__typename === 'ProposalOccupationalPensionBenefitConfig';

export const isPensionBenefit = <B extends { configuration: Config }>(
  benefit: B,
): benefit is B & {
  configuration: PensionConfig;
} => isPensionConfig(benefit.configuration);

interface SalaryExchangeConfig extends Config {
  __typename: 'ProposalSalaryExchangeBenefitConfig';
}

export const isSalaryExchangeConfig = (
  config: Config,
): config is SalaryExchangeConfig =>
  config.__typename === 'ProposalSalaryExchangeBenefitConfig';

interface TglConfig extends Config {
  __typename: 'ProposalTglBenefitConfig';
}

export const isTglConfig = (config: Config): config is TglConfig =>
  config.__typename === 'ProposalTglBenefitConfig';

export const by =
  <T extends Benefit>() =>
  (a: T, b: T) =>
    DEFAULT_BENEFITS_SORT_ORDER[a.type] - DEFAULT_BENEFITS_SORT_ORDER[b.type];
