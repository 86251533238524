import { Dot, Section, SectionHeader } from '@frontend/ui';
import {
  ProposalSicknessLevel,
  riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentSickness as Component,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { insuranceMessages } from 'app/messages/insurance';
import { insuranceComponentStatusMessages } from 'app/messages/status';
import { MEMBERSHIP_INSURANCE_COMPONENT_STATUS_COLOR } from 'app/utils/constants';
import {
  DescriptionTable,
  DescriptionTableEntry,
} from 'components/DescriptionTable';
import {
  FormattedCurrency,
  FormattedDate,
  FormattedMessage,
} from 'components/formats';
import { NoValue } from 'components/NoValue';
import { smeBenefitDisabilityLevelMessages } from 'features/sme/messages/sme';
import React from 'react';

import { getInsurancePremium } from '../../get-insurance-premium';

// `sicknessLevel` is not typed, so we perform a best-effort check
// to determine if it exists in the `ProposalSicknessLevel` enum.
// If it does, we display its corresponding message in `smeBenefitDisabilityLevelMessages`.
const isProposalSicknessLevel = (
  level: string | null,
): level is ProposalSicknessLevel => !!level && level in ProposalSicknessLevel;

interface Props {
  data: Component;
}

export const SicknessInsurance: React.FC<Props> = ({ data }) => {
  if (!data) {
    return null;
  }

  const isPremiumWaiver = data.typeCode === 'PB1';

  const sicknessData: DescriptionTableEntry[] = [
    {
      id: 'sicknessInsurance.status',
      label: <FormattedMessage {...commonMessages.status} />,
      value: data.status ? (
        <Dot
          colorValue={MEMBERSHIP_INSURANCE_COMPONENT_STATUS_COLOR[data.status]}
          size={0.75}
          label={
            <FormattedMessage
              select={data.status}
              messages={insuranceComponentStatusMessages}
            />
          }
        />
      ) : (
        <NoValue />
      ),
    },
    ...(isPremiumWaiver
      ? [
          {
            id: 'sicknessInsurance.compensation',
            label: <FormattedMessage {...commonMessages.compensation} />,
            value: data.payout?.[0]?.amount ? (
              <FormattedCurrency
                currency={data.payout[0].amount.currency}
                value={data.payout[0].amount.value}
                noParse
              />
            ) : (
              <NoValue />
            ),
          },
        ]
      : [
          {
            id: 'sicknessInsurance.level',
            label: <FormattedMessage {...commonMessages.level} />,
            value: isProposalSicknessLevel(data.sicknessLevel) ? (
              <FormattedMessage
                messages={smeBenefitDisabilityLevelMessages}
                select={data.sicknessLevel}
              />
            ) : (
              data.sicknessLevel ?? <NoValue />
            ),
          },
        ]),
    {
      id: 'sicknessInsurance.taxable',
      label: <FormattedMessage {...commonMessages.taxable} />,
      value: (
        <FormattedMessage
          {...(isPremiumWaiver ? commonMessages.no : commonMessages.yes)}
        />
      ),
    },
    {
      id: 'sicknessInsurance.premium',
      label: <FormattedMessage {...commonMessages.premium} />,
      value: getInsurancePremium(data.insurance),
    },
    {
      id: 'sicknessInsurance.insuranceProvider',
      label: <FormattedMessage {...commonMessages.insuranceProvider} />,
      value: data.insurance.institute.name ?? <NoValue />,
    },
    {
      id: 'sicknessInsurance.insuranceNumber',
      label: <FormattedMessage {...commonMessages.insuranceNumber} />,
      value: data.insuranceNumber ?? <NoValue />,
    },
    {
      id: 'sicknessInsurance.effectiveDate',
      label: <FormattedMessage {...commonMessages.effectiveFrom} />,
      value: data.insurance.effectiveDate ? (
        <FormattedDate value={data.insurance.effectiveDate} />
      ) : (
        <NoValue />
      ),
    },
  ];

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage
          {...(isPremiumWaiver
            ? insuranceMessages.premiumWaiver
            : commonBenefitMessages.sickness)}
        />
      </SectionHeader>
      <DescriptionTable alignLeft entries={sicknessData} />
    </Section>
  );
};
