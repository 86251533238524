import {
  AgreementAdvinansTier,
  agreementsListQuery_company_Company_agreements,
  agreementsListQuery_company_Company_agreements_AgreementAdvinans,
} from 'app/apollo/graphql/types';

type Agreement = agreementsListQuery_company_Company_agreements;

type AdvinansAgreement =
  agreementsListQuery_company_Company_agreements_AgreementAdvinans;

const isAdvinansAgreement = (
  agreement: Agreement,
): agreement is AdvinansAgreement =>
  agreement.__typename === 'AgreementAdvinans';

export const isLargeAdvinansTier = (agreements: Agreement[]) => {
  const advinansAgreement = agreements.find(isAdvinansAgreement);
  return advinansAgreement?.tier === AgreementAdvinansTier.LARGE;
};
