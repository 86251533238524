/* -- Assert Benefit type to enable stricter benefit type typing than string --  */
var benefitTypes = ['advinans_occupational_pension', 'nordea_node_personal_advice', 'advinans_salary_exchange', 'epassi', 'epassi_bike', 'euro_accident_accident', 'euro_accident_life', 'euro_accident_plansjuk', 'euro_accident_privataccess', 'euro_accident_tgl', 'euro_accident_sickness_and_accident', 'nordea_node_digital_advice', 'synsam', 'wellness'];
var isBenefitType = function isBenefitType(value) {
  return benefitTypes.includes(value);
};
/* Typescript thinks the predicate type (e.g. AdvinansOccupationalPension<B>)
  isn't assignable to the function parameter benefit's type (B extends WithConfig)
  because we omit fields in the predicate type. We only do so to assign them again
  with better typing though. Thus the ts-ignore.
  */
var isSalaryExchangeBenefit = function isSalaryExchangeBenefit(benefit) {
  return benefit.type === 'advinans_salary_exchange';
};
var isOccupationalPensionBenefit = function isOccupationalPensionBenefit(benefit) {
  return benefit.type === 'advinans_occupational_pension';
};
var isAccidentInsuranceBenefit = function isAccidentInsuranceBenefit(benefit) {
  return benefit.type === 'euro_accident_accident';
};
var isSicknessAccidentInsuranceBenefit = function isSicknessAccidentInsuranceBenefit(benefit) {
  return benefit.type === 'euro_accident_sickness_and_accident';
};
var isDisabilityInsuranceBenefit = function isDisabilityInsuranceBenefit(benefit) {
  return benefit.type === 'euro_accident_plansjuk';
};
var isLifeInsuranceBenefit = function isLifeInsuranceBenefit(benefit) {
  return benefit.type === 'euro_accident_life';
};
var isPersonalAdviceBenefit = function isPersonalAdviceBenefit(benefit) {
  return benefit.type === 'nordea_node_personal_advice';
};
var isTglInsuranceBenefit = function isTglInsuranceBenefit(benefit) {
  return benefit.type === 'euro_accident_tgl';
};
var isHealthInsuranceBenefit = function isHealthInsuranceBenefit(benefit) {
  return benefit.type === 'euro_accident_privataccess';
};
var isEpassiBenefit = function isEpassiBenefit(benefit) {
  return benefit.type === 'epassi';
};
var isEpassiBikeBenefit = function isEpassiBikeBenefit(benefit) {
  return benefit.type === 'epassi_bike';
};
var isWellnessBenefit = function isWellnessBenefit(benefit) {
  return benefit.type === 'wellness';
};
var isSynsamBenefit = function isSynsamBenefit(benefit) {
  return benefit.type === 'synsam';
};
var isDigitalAdviceBenefit = function isDigitalAdviceBenefit(benefit) {
  return benefit.type === 'nordea_node_digital_advice';
};
var isNotLegacyPensionProvider = function isNotLegacyPensionProvider(provider) {
  return provider !== 'advinans' && provider !== 'lf';
};
var isNotLegacySalaryExchangeProvider = function isNotLegacySalaryExchangeProvider(provider) {
  return provider !== 'advinans' && provider !== 'lf';
};

export { isAccidentInsuranceBenefit, isBenefitType, isDigitalAdviceBenefit, isDisabilityInsuranceBenefit, isEpassiBenefit, isEpassiBikeBenefit, isHealthInsuranceBenefit, isLifeInsuranceBenefit, isNotLegacyPensionProvider, isNotLegacySalaryExchangeProvider, isOccupationalPensionBenefit, isPersonalAdviceBenefit, isSalaryExchangeBenefit, isSicknessAccidentInsuranceBenefit, isSynsamBenefit, isTglInsuranceBenefit, isWellnessBenefit };
