import { useMutation } from '@apollo/client';
import { toNumber } from '@frontend/utils';
import {
  CreatePreSignedAgreementsInput,
  createPreSignedAgreementsMutation,
  createPreSignedAgreementsMutationVariables,
  NLPAdministrativeFeeDiscountType,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { CREATE_PRE_SIGNED_AGREEMENTS_MUTATION } from 'features/companies/create/graphql/mutations';
import { useNotification } from 'features/notifications';
import { FormikHelpers } from 'formik';
import { useHistory } from 'react-router';

import { AGREEMENTS_LIST_QUERY } from '../../graphql/queries';
import { agreementMessages } from '../../messages';
import { COMPANY_AGREEMENTS_QUERY } from '../graphql';
import { EuroAccidentAgreementType, FormValues } from './';

interface SubmitArgs {
  helpers: FormikHelpers<FormValues>;
  values: FormValues;
  hasEuroAccidentGruppAgreement?: boolean;
  hasEuroAccidentTjpAgreement?: boolean;
  hasNordeaAgreement?: boolean;
}

interface Submit {
  submit: (args: SubmitArgs) => void;
}

interface Args {
  companyId: string;
}

export const useSubmit = (args: Args): Submit => {
  const { push } = useHistory();
  const { send } = useNotification();
  const { formatMessage } = useIntl();
  const { companyId } = args;

  const [createAgreements] = useMutation<
    createPreSignedAgreementsMutation,
    createPreSignedAgreementsMutationVariables
  >(CREATE_PRE_SIGNED_AGREEMENTS_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: COMPANY_AGREEMENTS_QUERY, variables: { id: companyId } },
      { query: AGREEMENTS_LIST_QUERY, variables: { id: companyId } },
    ],
  });

  const submit = async (submitArgs: SubmitArgs) => {
    const {
      helpers,
      values: {
        createNordea: _createNordea,
        eaAgreementTypes,
        eaExtendedFcfw,
        eaStartDate,
        nordeaSalesRep,
        nordeaStartDate,
        eaCompanySize,
        eaGruppAgreementType,
        nordeaAnnualFeeFund,
        nordeaAnnualFeeGuarantee,
        nordeaCapitalFeeFund,
        nordeaCapitalFeeGuarantee,
        nordeaFundFeeDiscountType,
      },
      hasEuroAccidentGruppAgreement,
      hasEuroAccidentTjpAgreement,
      hasNordeaAgreement,
    } = submitArgs;

    const createNordea = _createNordea && !hasNordeaAgreement;
    const createEaGrupp =
      eaAgreementTypes.includes(EuroAccidentAgreementType.eaGrupp) &&
      !hasEuroAccidentGruppAgreement;
    const createEaTjp =
      eaAgreementTypes.includes(EuroAccidentAgreementType.eaTjp) &&
      !hasEuroAccidentTjpAgreement;

    try {
      const agreementInput: CreatePreSignedAgreementsInput = {
        companyId,
        createEaGrupp,
        createEaTjp,
        createNordea,
        eaExtendedFcfw,
        nlpFundFeeDiscountType:
          nordeaFundFeeDiscountType !== ''
            ? nordeaFundFeeDiscountType
            : undefined,
        eaGruppAgreementType:
          eaGruppAgreementType !== '' ? eaGruppAgreementType : undefined,
        eaStartDate: eaStartDate || null,
        nlpAdministrativeFeeDiscount:
          nordeaCapitalFeeFund == null ||
          nordeaAnnualFeeFund == null ||
          nordeaCapitalFeeGuarantee == null ||
          nordeaAnnualFeeGuarantee == null
            ? []
            : [
                {
                  DiscountType:
                    NLPAdministrativeFeeDiscountType.CAPITAL_FEE_FUND,
                  DiscountValue: toNumber(nordeaCapitalFeeFund),
                },
                {
                  DiscountType:
                    NLPAdministrativeFeeDiscountType.ANNUAL_FEE_FUND,
                  DiscountValue: toNumber(nordeaAnnualFeeFund),
                },
                {
                  DiscountType:
                    NLPAdministrativeFeeDiscountType.CAPITAL_FEE_GUARANTEE,
                  DiscountValue: toNumber(nordeaCapitalFeeGuarantee),
                },
                {
                  DiscountType:
                    NLPAdministrativeFeeDiscountType.ANNUAL_FEE_GUARANTEE,
                  DiscountValue: toNumber(nordeaAnnualFeeGuarantee),
                },
              ],
        nlpAdvisor: nordeaSalesRep,
        eaCompanySize: eaCompanySize !== '' ? eaCompanySize : undefined,
        nlpStartDate: nordeaStartDate || null,
      };

      await createAgreements({
        variables: { input: agreementInput },
      });

      send({
        message: formatMessage(agreementMessages.agreementsCreated),
        type: 'success',
      });

      push(`/companies/${companyId}/settings/agreements`);
    } catch (error) {
      helpers.setErrors({ submissionError: error?.message });
    }
  };

  return {
    submit,
  };
};
