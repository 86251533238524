import { ContentContainer, Section } from '@frontend/ui';
import {
  benefitPackageLayoutQuery,
  benefitPackageLayoutQueryVariables,
} from 'app/apollo/graphql/types';
import { BENEFIT_PACKAGE_LAYOUT_QUERY } from 'app/queries';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { Benefits } from 'features/companies/company/benefits';
import { Page } from 'features/page';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { MatchParams as CompanyMatchParams } from '../..';

export interface MatchParams extends CompanyMatchParams {
  benefitPackageId: string;
}

export const BenefitPackage: React.FC<
  RouteComponentProps<MatchParams>
> = props => {
  const {
    match: { params, url },
  } = props;

  const { data, loading, error } = useQuery<
    benefitPackageLayoutQuery,
    benefitPackageLayoutQueryVariables
  >(BENEFIT_PACKAGE_LAYOUT_QUERY, {
    errorPolicy: 'all',
    variables: { id: params.benefitPackageId },
  });

  if (loading) {
    return <TopLoading />;
  }

  const parentLink = `/companies/${params.companyId}/benefit-packages`;
  const benefitPackage = data?.benefitPackage;

  if (!benefitPackage) {
    return <EmptyStatePage error={error} parentLink={parentLink} />;
  }
  return (
    <NavigationAnchor title={benefitPackage.name} path={url}>
      <Page
        parentLink={`/companies/${params.companyId}/benefit-packages`}
        title={benefitPackage.name}
      >
        <ContentContainer>
          <Section>
            <Benefits {...props} />
          </Section>
        </ContentContainer>
      </Page>
    </NavigationAnchor>
  );
};
