import { Section, SectionHeader } from '@frontend/ui';
import {
  smeCompanyProposalsQuery,
  smeCompanyProposalsQueryVariables,
  SortingFields,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/sme/company';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { useTableSort } from 'app/utils/use-table-sort';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import { smeCompanyProposalsMessages } from 'features/sme/messages/sme';
import React, { useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { useDelete } from './delete';
import { useDuplicate } from './duplicate';
import { EditProposalNameModal } from './edit/name';
import { ExecuteProposalModal } from './execute';
import { SME_COMPANY_PROPOSALS_QUERY } from './graphql/queries';
import { ProposalsTable } from './Table';
import { getSortingQueryArgs } from './utils/get-sorting-query-args';
import { updateProposalsQuery } from './utils/update-proposals-query';

export type SortableColumns = SortingFields.STATUS | SortingFields.UPDATED_AT;

const isEmpty = (data?: smeCompanyProposalsQuery) =>
  !data?.proposals?.edges?.length;

export const ProposalsSection: React.FC = () => {
  const { params } = useRouteMatch<CompanyMatchParams>();
  const { _delete, deleteLoading, deleteError } = useDelete();
  const { duplicate, duplicateLoading, duplicateError } = useDuplicate();
  const [perPage, setPerPage] = useState(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );

  const { handleSort, order, orderBy } = useTableSort<SortableColumns>();
  const queryArgs = getSortingQueryArgs({ order, orderBy });

  const suspendRef = useRef(true);
  const { data, previousData, loading, fetchMore } = useQuery<
    smeCompanyProposalsQuery,
    smeCompanyProposalsQueryVariables
  >(SME_COMPANY_PROPOSALS_QUERY, {
    errorPolicy: 'all',
    isEmpty,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      suspendRef.current = false;
    },
    suspend: suspendRef.current,
    variables: {
      companyId: params.companyId,
      first: Number(perPage),
      orderBy: queryArgs,
    },
  });

  const _data = data ?? previousData;

  if (!_data?.proposals || isEmpty(_data)) {
    return null;
  }

  const { edges, pageInfo } = _data.proposals;
  const proposals = edges.map(({ node }) => node);

  const navigation = (
    <TableNavigation
      perPage={perPage}
      setPerPage={setPerPage}
      onNextPage={() => {
        fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
          updateQuery: updateProposalsQuery,
        });
      }}
      onPreviousPage={() => {
        fetchMore({
          variables: {
            before: pageInfo.startCursor,
            first: undefined,
            last: Number(perPage),
          },
          updateQuery: updateProposalsQuery,
        });
      }}
      pageInfo={pageInfo}
    />
  );

  const _loading = loading || deleteLoading || duplicateLoading;

  return (
    <>
      {!suspendRef.current && _loading && <TopLoading />}
      <Section>
        <SectionHeader>
          <FormattedMessage {...smeCompanyProposalsMessages.proposals} />
        </SectionHeader>
        {(deleteError || duplicateError) && (
          <GraphQlError error={deleteError ?? duplicateError} />
        )}
        <ProposalsTable
          navigation={navigation}
          proposals={proposals}
          sort={{ order, orderBy, handleSort }}
          handleDeleteProposal={_delete}
          handleDuplicateProposal={duplicate}
        />
      </Section>
      <EditProposalNameModal />
      <ExecuteProposalModal />
    </>
  );
};
