import {
  Card,
  Figure,
  Icon,
  IconContent,
  SnapshotCardLayout,
} from '@frontend/ui';
import { announcement, money, people, timer } from '@frontend/ui/icons';
import { suffixMessages } from 'app/messages/common';
import { employeesImportMessages } from 'app/messages/employees';
import { FormattedMessage } from 'components/formats';
import { GridCell50 } from 'components/GridCell';
import { NoValue } from 'components/NoValue';
import React from 'react';
import { MessageDescriptor } from 'react-intl';

export enum CardType {
  EMPLOYEE_STATUS = 'EMPLOYEE_STATUS',
  EMPLOYMENT_ACTIVITY = 'EMPLOYMENT_ACTIVITY',
  SALARY_UPDATES = 'SALARY_UPDATES',
  UNCHANGED = 'UNCHANGED',
}

interface CardData {
  description: [MessageDescriptor] | [MessageDescriptor, MessageDescriptor];
  icon: IconContent;
  title: MessageDescriptor;
}

const CONTENT: Record<CardType, CardData> = {
  EMPLOYEE_STATUS: {
    title: employeesImportMessages.previewTitleEmployees,
    description: [
      employeesImportMessages.previewTitleNewEmployees,
      employeesImportMessages.previewTitleTerminated,
    ],
    icon: people,
  },
  SALARY_UPDATES: {
    title: employeesImportMessages.previewTitleSalaryUpdates,
    description: [employeesImportMessages.quantity],
    icon: money,
  },
  EMPLOYMENT_ACTIVITY: {
    title: employeesImportMessages.previewTitleEmploymentActivity,
    description: [employeesImportMessages.quantity],
    icon: timer,
  },
  UNCHANGED: {
    title: employeesImportMessages.previewTitleUnchanged,
    description: [employeesImportMessages.quantity],
    icon: announcement,
  },
};

interface Props {
  data: [number] | [number, number];
  type: CardType;
}

export const ImportSummaryCard: React.FC<Props> = ({
  type,
  data: [data1, data2],
}) => (
  <GridCell50>
    <Card>
      <SnapshotCardLayout
        title={<FormattedMessage {...CONTENT[type].title} />}
        icon={<Icon size="medium" icon={CONTENT[type].icon} color="primary" />}
        figure1={
          <Figure
            title={
              <>
                {data1.toString()}{' '}
                <FormattedMessage {...suffixMessages.pieces} />
              </>
            }
            description={<FormattedMessage {...CONTENT[type].description[0]} />}
          />
        }
        figure2={
          data2 !== undefined && (
            <Figure
              title={
                <>
                  {data2.toString()}{' '}
                  <FormattedMessage {...suffixMessages.pieces} />
                </>
              }
              description={
                CONTENT[type].description[1] ? (
                  <FormattedMessage {...CONTENT[type].description[1]} />
                ) : (
                  <NoValue />
                )
              }
            />
          )
        }
      />
    </Card>
  </GridCell50>
);
