import { ContentContainer } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { TextGrid } from 'components/GridCell';
import { Page } from 'features/page';
import { CreateEmployee } from 'features/sme/companies/company/proposals/edit/employees/create';
import { smeEmployeesMessages } from 'features/sme/messages/sme';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { MatchParams } from '../..';

export const SmeCompanyProposalEmployeesCreateRoute: React.FC<
  RouteComponentProps<MatchParams>
> = ({ match: { params } }) => (
  <Page
    title={<FormattedMessage {...smeEmployeesMessages.createEmployee} />}
    parentLink={`/sme/${params.companyId}/proposals/${params.proposalId}/employees`}
  >
    <ContentContainer>
      <TextGrid>
        <CreateEmployee />
      </TextGrid>
    </ContentContainer>
  </Page>
);
