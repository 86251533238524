import { ApolloError, useMutation } from '@apollo/client';
import { toMoney } from '@frontend/utils';
import {
  editProposalEmployeeMutation,
  editProposalEmployeeMutationVariables,
  UpdateProposalMembershipInput,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal/employee';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeEmployeesMessages } from 'features/sme/messages/sme';
import { useHistory } from 'react-router';

import { clearProposalCache } from '../../../../../utils/clear-proposal-cache';
import { FormValues } from '../../../form';
import { EDIT_PROPOSAL_EMPLOYEE_MUTATION } from '../graphql/mutations';

interface Submit {
  submit: (values: FormValues) => void;
  submissionError?: ApolloError;
}

interface Params {
  params: MatchParams;
}

export const useSubmit = ({ params }: Params): Submit => {
  const { push } = useHistory();
  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [editProposalEmployee, { error: submissionError }] = useMutation<
    editProposalEmployeeMutation,
    editProposalEmployeeMutationVariables
  >(EDIT_PROPOSAL_EMPLOYEE_MUTATION, {
    update: clearProposalCache({
      proposalId: params.proposalId,
      fieldNames: ['cost', 'proposals'],
    }),
  });

  const submit = async (formValues: FormValues) => {
    try {
      const input: UpdateProposalMembershipInput = {
        benefitPackageId: formValues.benefitPackageId,
        email: formValues.email,
        firstName: formValues.firstName,
        id: params.employeeId,
        isOwner: formValues.isOwner,
        lastName: formValues.lastName,
        monthlyFixedPensionPremium: formValues.monthlyFixedPensionPremium
          ? toMoney(formValues.monthlyFixedPensionPremium)
          : undefined,
        monthlySalary: formValues.monthlySalary
          ? toMoney(formValues.monthlySalary)
          : undefined,
        personalIdentityNumber: formValues.personalIdentityNumber,
        phoneNumber: formValues.phoneNumber,
        proposalId: params.proposalId,
      };

      const res = await editProposalEmployee({
        variables: { input },
      });

      if (!res.data?.updateProposalMembership) {
        return;
      }

      send({
        message: formatMessage(smeEmployeesMessages.employeeSaved),
        type: 'success',
      });
      push(`/sme/${params.companyId}/proposals/${params.proposalId}/employees`);
    } catch (error) {
      // do nothing
    }
  };

  return { submit, submissionError };
};
