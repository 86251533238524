import gql from 'graphql-tag';

import {
  employersPensionBenefitConfigWithFixedPremium,
  occupationalPensionBenefitConfigWithFixedPremium,
} from '../../../graphql/queries';

export const SME_COMPANY_EDIT_EMPLOYEE_QUERY = gql`
  query smeCompanyEditEmployeeQuery($employeeId: ID!, $proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      benefitPackages {
        id
        name
        benefits {
          id
          type
          configuration {
            __typename
            ...employersPensionBenefitConfigWithFixedPremium
            ...occupationalPensionBenefitConfigWithFixedPremium
          }
        }
      }
    }
    proposalMembership(id: $employeeId) {
      id
      benefitPackage {
        id
      }
      email
      firstName
      isOwner
      lastName
      monthlyFixedPensionPremium
      monthlySalary
      personalIdentityNumber
      phoneNumber
    }
  }
  ${employersPensionBenefitConfigWithFixedPremium}
  ${occupationalPensionBenefitConfigWithFixedPremium}
`;
