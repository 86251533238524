import { Subsection, SubsectionHeader } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { PremiumMatrixTable } from 'features/sme/companies/company/PremiumMatrixTable';
import { smeBenefitFormMessages } from 'features/sme/messages/sme';
import { useField } from 'formik';
import React from 'react';

import { SalaryMultiplerSection } from '../SalaryMultiplierSection';
import { ProposalPremiumMatrixFormValues } from '../utils/types';

interface Props {
  name: string;
}

export const ItpPremiumMatrixSection: React.FC<Props> = ({ name }) => {
  const [{ value }] = useField<ProposalPremiumMatrixFormValues>(name);

  const { salaryMultiplier, ...matrix } = value;

  return (
    <>
      <Subsection>
        <SubsectionHeader>
          <FormattedMessage {...smeBenefitFormMessages.premiumMatrix} />
        </SubsectionHeader>
        <PremiumMatrixTable matrix={matrix} />
      </Subsection>
      <SalaryMultiplerSection name={name} />
    </>
  );
};
