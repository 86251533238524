import {
  APP_BAR_HEIGHT,
  Breadcrumbs,
  ChipSet as _ChipSet,
  IconContent,
  PageBody,
  PageHeader,
  PageTitle,
  PageTitleBar,
  StatusLabel,
  useComponentSize,
} from '@frontend/ui';
import { a11yMessages } from 'app/messages/a11y';
import { useHideOnScroll } from 'app/utils/use-hide-on-scroll';
import { AssistChip, Props as AssistChipProps } from 'components/AssistChip';
import { useIntl } from 'components/formats';
import { useNavigationContext } from 'components/NavigationDrawer/containers/NavigationContext';
import { useAppBarNavigationStack } from 'components/NavigationDrawer/lib/use-app-bar-navigation-stack';
import { RoutedTab, RoutedTabBar } from 'components/RoutedTabBar';
import { useNavigationAnchorContext } from 'contexts/navigation-anchor';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface ActionHolderProps {
  hide?: boolean;
}

const ChipSet = styled(_ChipSet)<ActionHolderProps>`
  padding-left: 2rem;
  flex-wrap: nowrap;
  ${p => p.hide && `display: none;`}
`;

interface Status {
  icon: IconContent;
  title: React.ReactNode;
}

interface Props {
  children: React.ReactNode;
  actions?: AssistChipProps[];
  fixed?: boolean;
  parentLink?: string;
  status?: Status;
  tabs?: RoutedTab[];
  title?: React.ReactNode;
  trackPageOnUnmount?: boolean;
}

export const Page: React.FC<Props> = ({
  children,
  title,
  tabs,
  parentLink,
  actions,
  status,
  trackPageOnUnmount = true,
  fixed,
}) => {
  const { formatMessage } = useIntl();
  const { setPage } = useNavigationContext();

  useEffect(() => {
    setPage({ parentLink });
  }, [parentLink]);

  useAppBarNavigationStack({
    trackPageOnUnmount,
  });
  const { stack } = useNavigationAnchorContext();

  const hideHeader = useHideOnScroll({ offsetValue: APP_BAR_HEIGHT }) && !fixed;

  const [showChipSet, setshowChipSet] = useState(false);
  const titleBarRef = useRef(null);
  const { width: titleBarWidth } = useComponentSize(titleBarRef);
  const titleRef = useRef(null);
  const { width: titleWidth } = useComponentSize(titleRef);
  const chipSetRef = useRef(null);
  const { width: chipSetWidth } = useComponentSize(chipSetRef);
  useEffect(() => {
    setshowChipSet(chipSetWidth + titleWidth < titleBarWidth);
  }, [titleBarWidth, titleWidth, chipSetWidth]);

  const showPageHeader = !!(title || tabs || actions?.length);

  const stackItems = Object.values(stack);
  const breadcrumbs = stackItems
    .sort((a, b) => a.path.split('/').length - b.path.split('/').length)
    .map(({ title: breadcrumbTitle, path }, index) => {
      if (index === stackItems.length - 1) {
        return breadcrumbTitle;
      }
      return (
        <NavLink to={path} key={path}>
          {breadcrumbTitle}
        </NavLink>
      );
    });

  return (
    <>
      {showPageHeader && (
        <PageHeader hide={hideHeader}>
          {breadcrumbs.length > 1 && (
            <Breadcrumbs
              ariaLabel={formatMessage(a11yMessages.breadcrumb)}
              buttonLabel={formatMessage(a11yMessages.showBreadcrumbs)}
              breadcrumbs={breadcrumbs}
            />
          )}
          <PageTitleBar ref={titleBarRef}>
            <PageTitle
              ref={titleRef}
              status={
                status && (
                  <StatusLabel title={status.title} icon={status.icon} />
                )
              }
            >
              {title}
            </PageTitle>
            {!!actions?.length && (
              <ChipSet ref={chipSetRef} hide={!showChipSet}>
                {actions.map((action, i) => (
                  <AssistChip key={i} {...action} />
                ))}
              </ChipSet>
            )}
          </PageTitleBar>
          {!!tabs && <RoutedTabBar tabs={tabs} />}
        </PageHeader>
      )}
      <PageBody>{children}</PageBody>
    </>
  );
};
