import gql from 'graphql-tag';

export const SME_COMPANY_BENEFITS_QUERY = gql`
  query smeCompanyBenefitsQuery($id: ID!) {
    proposal(id: $id) {
      id
      benefits {
        id
        type
        benefitPackages {
          id
          name
        }
        configuration {
          __typename
          ... on ProposalAccidentBenefitConfig {
            accidentLevel
          }
          ... on ProposalHealthcareBenefitConfig {
            healthcareLevel
          }
          ... on ProposalLifeBenefitConfig {
            lifeLevel
          }
          ... on ProposalSicknessBenefitConfig {
            sicknessLevel
          }
          ... on ProposalOccupationalPensionBenefitConfig {
            premiumCalculationMethod
            premiumDeductions
            extraFixedPremium
          }
          ... on ProposalSalaryExchangeBenefitConfig {
            ongoingDeductionShareMax
          }
        }
      }
    }
  }
`;
