import {
  Button,
  CheckboxField,
  Table,
  TableSortButton,
  Th,
  Tr,
} from '@frontend/ui';
import {
  MembershipKey,
  pageInfoDetails,
  SortTransferableCapitalBy,
} from 'app/apollo/graphql/types';
import { advisorMessages } from 'app/messages/advisor';
import { commonMessages } from 'app/messages/common';
import { OpenAddHeldMeetingModal } from 'app/pages/advisor';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { EmptyTable } from 'components/EmptyTable';
import { FormattedMessage } from 'components/formats';
import { SearchField } from 'components/SearchField';
import { TableNavigation } from 'components/TableNavigation';
import { AdviceFilterChips } from 'features/advisor/components/Filter';
import { useCheckboxRows } from 'features/advisor/utils/use-checkbox-rows';
import React from 'react';

import { PensionTransferEmployee } from '..';
import { PensionTransferEmployeeTableRow } from '../components/PensionTransferEmployeeTableRow';
import { TableSort } from './use-table-sort';

const EmployeeTableColgroup = () => (
  <colgroup>
    <col style={{ width: '5%' }} />
    <col style={{ width: '17%' }} />
    <col style={{ width: 'auto' }} />
    <col style={{ width: '20%' }} />
    <col style={{ width: '18%' }} />
    <col style={{ width: '15%' }} />
  </colgroup>
);

interface EmployeeTableTheadProps {
  allSelected: boolean;
  indeterminate: boolean;
  sort: TableSort<SortTransferableCapitalBy>;
  toggleSelectAll: (checked: boolean) => void;
}

const EmployeeTableThead = ({
  allSelected,
  indeterminate,
  toggleSelectAll,
  sort,
}: EmployeeTableTheadProps) => (
  <thead>
    <Tr withInlineMenuTh>
      <Th type="input">
        <CheckboxField
          checked={allSelected}
          onChange={toggleSelectAll}
          indeterminate={indeterminate}
        />
      </Th>
      <Th align="left">
        <FormattedMessage {...commonMessages.id} />
      </Th>
      <Th align="left">
        <FormattedMessage {...commonMessages.name} />
      </Th>
      <Th align="left">
        <FormattedMessage {...commonMessages.company} />
      </Th>
      <Th align="right">
        <TableSortButton
          onClick={sort.handleSort}
          order={
            sort.orderBy === SortTransferableCapitalBy.CAPITAL
              ? sort.order
              : undefined
          }
          value={SortTransferableCapitalBy.CAPITAL}
          align="left"
        >
          <FormattedMessage {...advisorMessages.transferableCapital} />
        </TableSortButton>
      </Th>
      <Th align="right">
        <TableSortButton
          onClick={sort.handleSort}
          order={
            sort.orderBy === SortTransferableCapitalBy.LATEST_EMAIL_SENT_AT
              ? sort.order
              : undefined
          }
          value={SortTransferableCapitalBy.LATEST_EMAIL_SENT_AT}
          align="left"
        >
          <FormattedMessage {...advisorMessages.latestEmailSentAt} />
        </TableSortButton>
      </Th>
    </Tr>
  </thead>
);

interface Props {
  clearFilter: () => void;
  employees: Array<PensionTransferEmployee>;
  onNextPage: () => void;
  onPreviousPage: () => void;
  openAddHeldMeetingModal: OpenAddHeldMeetingModal;
  openSendInvitationsModal: (membershipKeys: Array<MembershipKey>) => void;
  pageInfo: pageInfoDetails;
  sort: TableSort<SortTransferableCapitalBy>;
  toggleFilterSideSheet: () => void;
}

export const PensionTransferEmployeesTable: React.FC<Props> = ({
  pageInfo,
  onNextPage,
  onPreviousPage,
  employees,
  openAddHeldMeetingModal,
  clearFilter,
  toggleFilterSideSheet,
  openSendInvitationsModal,
  sort,
}) => {
  const membershipKeys = employees.map(employee => ({
    userAccountId: employee.userAccountId,
    companyId: employee.companyId,
    rowId: employee.membership.id,
  }));

  const {
    checkedRowKeys,
    allSelected,
    indeterminate,
    toggleSelectAll,
    toggleSelectRow,
  } = useCheckboxRows(membershipKeys);

  return (
    <>
      <SearchField
        label={<FormattedMessage {...advisorMessages.searchEntitledEmployee} />}
      />
      <ChipsWrapper belowSearch>
        <AdviceFilterChips
          clearFilter={clearFilter}
          toggleFilterSideSheet={toggleFilterSideSheet}
        />
      </ChipsWrapper>
      <Table
        navigation={
          <TableNavigation
            pageInfo={pageInfo}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
          />
        }
        fixed
        feedback={{
          actions: (
            <Button
              text
              onClick={() => openSendInvitationsModal(checkedRowKeys)}
            >
              <FormattedMessage {...advisorMessages.sendInvitations} />
            </Button>
          ),
          active: checkedRowKeys.length > 0,
          children: (
            <FormattedMessage
              {...commonMessages.nRowsSelected}
              values={{ count: checkedRowKeys.length }}
            />
          ),
        }}
      >
        {employees.length === 0 ? (
          <EmptyTable noMatchMessage={advisorMessages.noEmployeeMatch} />
        ) : (
          <>
            <EmployeeTableColgroup />
            <EmployeeTableThead
              sort={sort}
              allSelected={allSelected}
              indeterminate={indeterminate}
              toggleSelectAll={toggleSelectAll}
            />
            <tbody>
              {employees.map(employee => (
                <PensionTransferEmployeeTableRow
                  key={employee.membership.id}
                  id={employee.membership.id}
                  employee={employee}
                  sendInvitation={() =>
                    openSendInvitationsModal([
                      {
                        userAccountId: employee.userAccountId,
                        companyId: employee.companyId,
                      },
                    ])
                  }
                  openAddHeldMeetingModal={openAddHeldMeetingModal}
                  checked={checkedRowKeys.some(
                    key => key.rowId === employee.membership.id,
                  )}
                  onCheckboxChange={checked => {
                    toggleSelectRow(checked, {
                      userAccountId: employee.userAccountId,
                      companyId: employee.companyId,
                      rowId: employee.membership.id,
                    });
                  }}
                />
              ))}
            </tbody>
          </>
        )}
      </Table>
    </>
  );
};
