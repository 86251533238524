import {
  CheckboxField,
  RadioGroup,
  SelectField,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, useIntl } from 'components/formats';
import { TextGrid } from 'components/GridCell';
import { TopLoading } from 'components/TopLoading';
import {
  smeFeesMessages,
  smeRiskBucketLevelMessages,
} from 'features/sme/messages/sme';
import React from 'react';

import { AutosaveStatus, useAutosave } from '../../edit/contexts/autosave';
import { useProposalReadonly } from '../../utils/use-proposal-readonly';
import {
  getDiscountOptions,
  getFeeOptions,
  getFormValues,
  getOnChangeHandlers,
  getProvisionWaiverOptions,
} from './utils';
import { RISK_BUCKET_LEVELS } from './utils/constants';

export const Fees: React.FC = () => {
  const { loading, data, onChange, status } = useAutosave();
  const intl = useIntl();
  const { formatMessage } = intl;
  const isReadonly = useProposalReadonly();

  if (loading) {
    return <TopLoading />;
  }

  const formValues = getFormValues(data);
  const _onChange = getOnChangeHandlers(onChange);

  const disabled = isReadonly || status === AutosaveStatus.RECONNECTING;

  const feeWarning =
    formValues.capitalFeePortfolio !== formValues.capitalFeeFund;

  return (
    <>
      <Subsection>
        <TextGrid>
          <SubsectionHeader>
            <FormattedMessage {...smeFeesMessages.fees} />
          </SubsectionHeader>
          <DescriptionWrapper>
            <FormattedMessage {...smeFeesMessages.feesDescription} />
          </DescriptionWrapper>
          <SubsectionHeader>
            <FormattedMessage {...smeFeesMessages.fund} />
          </SubsectionHeader>
          <SelectField
            dense
            disabled={disabled}
            label={<FormattedMessage {...smeFeesMessages.capitalFee} />}
            onChange={e => _onChange.capitalFeeFund(e.detail.value)}
            options={getFeeOptions(intl)}
            value={formValues.capitalFeeFund}
            helperText={
              feeWarning && (
                <FormattedMessage
                  {...smeFeesMessages.portfolioAndFundFeeMismatch}
                />
              )
            }
            warning={feeWarning}
            required
          />
          <RadioGroup
            disabled={disabled}
            checked={formValues.annualFeeFund}
            onChange={_onChange.annualFeeFund}
            options={[
              {
                label: formatMessage(smeFeesMessages.withAnnualFee),
                value: 'true',
              },
              {
                label: formatMessage(smeFeesMessages.withoutAnnualFee),
                value: 'false',
              },
            ]}
          />
          <SubsectionHeader>
            <FormattedMessage {...smeFeesMessages.traditional} />
          </SubsectionHeader>
          <SelectField
            dense
            disabled={disabled}
            label={<FormattedMessage {...smeFeesMessages.capitalFee} />}
            onChange={e => _onChange.capitalFeeTraditional(e.detail.value)}
            options={getFeeOptions(intl)}
            value={formValues.capitalFeeTraditional}
            required
          />
          <RadioGroup
            disabled={disabled}
            checked={formValues.annualFeeTraditional}
            onChange={_onChange.annualFeeTraditional}
            options={[
              {
                label: formatMessage(smeFeesMessages.withAnnualFee),
                value: 'true',
              },
              {
                label: formatMessage(smeFeesMessages.withoutAnnualFee),
                value: 'false',
              },
            ]}
          />
          <SubsectionHeader>
            <FormattedMessage {...smeFeesMessages.portfolio} />
          </SubsectionHeader>
          <SelectField
            dense
            disabled={disabled}
            label={<FormattedMessage {...smeFeesMessages.capitalFee} />}
            onChange={e => _onChange.capitalFeePortfolio(e.detail.value)}
            options={getFeeOptions(intl)}
            value={formValues.capitalFeePortfolio}
            helperText={
              feeWarning && (
                <FormattedMessage
                  {...smeFeesMessages.portfolioAndFundFeeMismatch}
                />
              )
            }
            warning={feeWarning}
            required
          />
          <RadioGroup
            checked={formValues.annualFeePortfolio}
            onChange={_onChange.annualFeePortfolio}
            disabled={disabled}
            options={[
              {
                label: formatMessage(smeFeesMessages.withAnnualFee),
                value: 'true',
              },
              {
                label: formatMessage(smeFeesMessages.withoutAnnualFee),
                value: 'false',
              },
            ]}
            withoutMargin
          />
        </TextGrid>
      </Subsection>
      <Subsection>
        <TextGrid>
          <SubsectionHeader>
            <FormattedMessage {...smeFeesMessages.fundDiscount} />
          </SubsectionHeader>
          <CheckboxField
            checked={formValues.fundDiscountPermitted}
            onChange={_onChange.fundDiscountPermitted}
            disabled={disabled}
            label={
              <FormattedMessage {...smeFeesMessages.fundDiscountPermitted} />
            }
            withMargin
          />
          {formValues.fundDiscountPermitted && (
            <RadioGroup
              checked={formValues.fundDiscountValue}
              onChange={_onChange.fundDiscountValue}
              disabled={disabled}
              options={getDiscountOptions(intl)}
              withoutMargin
            />
          )}
        </TextGrid>
      </Subsection>
      <Subsection>
        <TextGrid>
          <SubsectionHeader>
            <FormattedMessage {...smeFeesMessages.riskBucketHealthcare} />
          </SubsectionHeader>
          <SelectField
            dense
            disabled={disabled}
            label={<FormattedMessage {...smeFeesMessages.riskBucket} />}
            onChange={e => {
              _onChange.riskBucket(e.detail.value);
            }}
            options={RISK_BUCKET_LEVELS.map(level => ({
              label: formatMessage({
                messages: smeRiskBucketLevelMessages,
                select: level,
              }),
              value: level,
            }))}
            value={formValues.riskBucket}
          />
        </TextGrid>
      </Subsection>
      <Subsection>
        <TextGrid>
          <SubsectionHeader>
            <FormattedMessage {...smeFeesMessages.provisionWaiver} />
          </SubsectionHeader>
          <CheckboxField
            checked={formValues.provisionWaiverAllowed}
            onChange={_onChange.provisionWaiverAllowed}
            disabled={disabled}
            label={
              <FormattedMessage {...smeFeesMessages.provisionWaiverAllowed} />
            }
            withMargin
          />
          {formValues.provisionWaiverAllowed && (
            <>
              <SelectField
                dense
                disabled={disabled}
                label={<FormattedMessage {...smeFeesMessages.premiumWaiver} />}
                onChange={e =>
                  _onChange.provisionWaiverPremiumWaiver(e.detail.value)
                }
                options={getProvisionWaiverOptions(intl)}
                value={formValues.provisionWaiverPremiumWaiver}
                required
              />
              <SelectField
                dense
                disabled={disabled}
                label={<FormattedMessage {...smeFeesMessages.disability} />}
                onChange={e =>
                  _onChange.provisionWaiverSickness(e.detail.value)
                }
                options={getProvisionWaiverOptions(intl)}
                value={formValues.provisionWaiverSickness}
                required
              />
              <SelectField
                dense
                disabled={disabled}
                label={<FormattedMessage {...smeFeesMessages.healthcare} />}
                onChange={e =>
                  _onChange.provisionWaiverHealthcare(e.detail.value)
                }
                options={getProvisionWaiverOptions(intl)}
                value={formValues.provisionWaiverHealthcare}
                withoutMargin
                required
              />
            </>
          )}
        </TextGrid>
      </Subsection>
    </>
  );
};
