import { useMutation } from '@apollo/client';
import { Button } from '@frontend/ui';
import { getSubmitErrors } from '@frontend/utils';
import {
  deleteEmploymentActivityMutation,
  deleteEmploymentActivityMutationVariables,
  employmentActivityDetails,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { employmentMessages } from 'app/messages/employees';
import { formSubmitMessages } from 'app/messages/form';
import { FormattedMessage, useIntl } from 'components/formats';
import { useConfirm } from 'contexts/confirmation';
import { useNotification } from 'features/notifications';
import React from 'react';

import { EMPLOYMENT_ACTIVITIES_QUERY } from '../graphql/queries';
import { DELETE_EMPLOYMENT_ACTIVITY_MUTATION } from './graphql/mutations';

export const DeleteEmploymentActivityButton: React.FC<{
  activity: employmentActivityDetails;
  employmentId: string;
  onDeleteComplete: () => void;
  onError: (error?: string) => void;
  onSubmit: () => void;
  disabled?: boolean;
}> = ({
  activity: { id, type },
  employmentId,
  disabled,
  onSubmit,
  onDeleteComplete,
  onError,
}) => {
  const { send } = useNotification();
  const intl = useIntl();
  const { confirm } = useConfirm();

  const [deleteEmployment, { loading }] = useMutation<
    deleteEmploymentActivityMutation,
    deleteEmploymentActivityMutationVariables
  >(DELETE_EMPLOYMENT_ACTIVITY_MUTATION, {
    awaitRefetchQueries: true,
    variables: { input: { id } },
    refetchQueries: [
      { query: EMPLOYMENT_ACTIVITIES_QUERY, variables: { id: employmentId } },
    ],
  });

  return (
    <Button
      danger
      disabled={disabled}
      loading={loading}
      onClick={async () => {
        // eslint-disable-next-line
        const confirmed = await confirm({
          title: intl.formatMessage(
            employmentMessages.confirmDeleteEmploymentActivityTitle,
          ),
          description: intl.formatMessage(
            employmentMessages.confirmDeleteEmploymentActivity,
            { type },
          ),
        });

        if (!confirmed) {
          return;
        }

        onSubmit();
        try {
          await deleteEmployment();
          send({
            type: 'success',
            message: intl.formatMessage(
              formSubmitMessages.deleteEmploymentActivitySuccess,
              { type },
            ),
          });
          onDeleteComplete();
        } catch (error) {
          onError(getSubmitErrors({ intl, error }));
        }
      }}
    >
      <FormattedMessage {...commonMessages.remove} />
    </Button>
  );
};
