import {
  smeCompanyEmployeesQuery_proposal_Proposal_memberships_ProposalMembership as Employee,
  SortingDirection,
} from 'app/apollo/graphql/types';

export type SortableColumns = 'nid' | 'firstName' | 'benefitPackage';

interface Params {
  order: SortingDirection;
  orderBy: SortableColumns;
}

export const by =
  ({ order, orderBy }: Params) =>
  (a: Employee, b: Employee) => {
    if (orderBy === 'nid') {
      return order === SortingDirection.ASC
        ? a.personalIdentityNumber.localeCompare(b.personalIdentityNumber)
        : b.personalIdentityNumber.localeCompare(a.personalIdentityNumber);
    }
    if (orderBy === 'firstName') {
      return order === SortingDirection.ASC
        ? a.firstName.localeCompare(b.firstName)
        : b.firstName.localeCompare(a.firstName);
    }
    return order === SortingDirection.ASC
      ? a.benefitPackage.name.localeCompare(b.benefitPackage.name)
      : b.benefitPackage.name.localeCompare(a.benefitPackage.name);
  };
