import { SelectField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import { add } from '@frontend/ui/icons';
import { stripSearchParams } from '@frontend/utils';
import { ProposalBenefitType } from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { benefitUrls } from 'app/pages/sme/company/proposal/benefits/create';
import { AssistChip } from 'components/AssistChip';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { Modal } from 'components/Modal';
import {
  smeBenefitsMessages,
  smeBenefitTypesMessages,
} from 'features/sme/messages/sme';
import { Form, Formik } from 'formik';
import qs from 'query-string';
import React from 'react';
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';
import * as Yup from 'yup';

import { useProposalReadonly } from '../../../utils/use-proposal-readonly';
import { DEFAULT_BENEFITS_SORT_ORDER } from '../../utils/sort-benefits-by';

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    benefitType: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface FormValues {
  benefitType: ProposalBenefitType | '';
}

export const CreateBenefitModal = () => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const history = useHistory();
  const { push } = history;
  const location = useLocation();
  const { params } = useRouteMatch<MatchParams>();

  const { 'create-benefit': createBenefit } = qs.parse(location.search);

  const onRequestClose = () => {
    stripSearchParams(history, location, ['create-benefit']);
  };

  const initialValues: FormValues = {
    benefitType: '',
  };

  return (
    <Modal isOpen={createBenefit} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage {...smeBenefitsMessages.createBenefit} />
      </ModalHeader>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={value =>
          push(
            `/sme/${params.companyId}/proposals/${params.proposalId}/benefits/create/${benefitUrls[value.benefitType]}`,
          )
        }
        validateOnMount
        validationSchema={validationSchema(intl)}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ModalBody>
              <p>
                <FormattedMessage
                  {...smeBenefitsMessages.createBenefitDescription}
                />
              </p>
              <SelectField
                dense
                name="benefitType"
                fixed
                label={
                  <FormattedMessage
                    {...smeBenefitsMessages.selectBenefitType}
                  />
                }
                options={Object.values(ProposalBenefitType)
                  .sort(
                    (a, b) =>
                      DEFAULT_BENEFITS_SORT_ORDER[a] -
                      DEFAULT_BENEFITS_SORT_ORDER[b],
                  )
                  .map(benefitType => ({
                    label: formatMessage({
                      select: benefitType,
                      messages: smeBenefitTypesMessages,
                    }),
                    value: benefitType,
                  }))}
                required
              />
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button
                  disabled={!isValid}
                  loading={isSubmitting}
                  text
                  type="submit"
                >
                  <FormattedMessage {...commonMessages.continue} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const getCreateBenefitLink = (
  location: RouteComponentProps['location'],
): RouteComponentProps['location'] => ({
  ...location,
  search: qs.stringify({ 'create-benefit': true }),
});

export const CreateBenefitAssistChip: React.FC = () => {
  const location = useLocation();
  const isReadonly = useProposalReadonly();

  return (
    <AssistChip
      text={<FormattedMessage {...smeBenefitsMessages.createBenefit} />}
      leadingIcon={add}
      to={getCreateBenefitLink(location)}
      disabled={isReadonly}
    />
  );
};
