import qs from 'query-string';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';

export const DEBOUNCE_TIMER = 300;

type Filters<T> = {
  [K in keyof T]?: T[K];
};

interface Params<T> {
  initialValues?: Filters<T>;
}

export interface UpdateConfig {
  skipDebounce?: boolean;
}

interface DebouncedFilters<T> {
  /**
   * A function which resets the filters to their initial state or empty object if no initial state was provided.
   * This function can be configured to skip debouncing. Debouncing is enabled by default for optimization
   * purposes. Do not skip debounce when the debounce delay is hidden to the user,
   * e.g. when applying the filter in the filter side sheet.
   */
  clearFilters: (config?: UpdateConfig) => void;
  /**
   * The current filters object.
   */
  filters: Filters<T>;
  /**
   * Function to handle changes to individual filter values.
   * This function can be configured to skip debouncing. Debouncing is enabled by default for optimization
   * purposes. Do not skip debounce when the debounce delay is hidden to the user,
   * e.g. when applying the filter in the filter side sheet.
   */
  setFilterValue: (
    value: string | string[],
    filterName: keyof T,
    config?: UpdateConfig,
  ) => void;
}

/**
 * Hook to manage debounced filters in a filter sheet
 * and synchronize the filter values with the URL query string.
 */
export const useDebouncedFilters = <T>(
  params?: Params<T>,
): DebouncedFilters<T> => {
  const history = useHistory();
  const location = useLocation();

  const initialValues = params?.initialValues ?? {};
  const hasInitialValues = Object.keys(initialValues).length > 0;

  const [filters, setFilters] = useState<Filters<T>>(initialValues);
  const skipDebounceRef = useRef(hasInitialValues);

  const { per_page } = qs.parse(location.search);

  const updateSearch = () => {
    history.push({
      search: qs.stringify({
        per_page,
        ...filters,
      }),
    });
  };

  const [updateSearchDebounced] = useDebouncedCallback(
    updateSearch,
    DEBOUNCE_TIMER,
  );

  useEffect(() => {
    if (skipDebounceRef.current) {
      skipDebounceRef.current = false;
      updateSearch();
      return;
    }
    updateSearchDebounced();
  }, [filters]);

  const setFilterValue = (
    value: string | string[],
    filterName: keyof T,
    config?: UpdateConfig,
  ) => {
    if (config?.skipDebounce) {
      skipDebounceRef.current = true;
    }
    if (
      (Array.isArray(value) && !value.length) ||
      (!Array.isArray(value) && !value)
    ) {
      setFilters(prev => {
        const { [filterName]: _, ...rest } = prev;
        return rest as Filters<T>;
      });
      return;
    }

    setFilters(prev => ({
      ...prev,
      [filterName]: value,
    }));
  };

  const clearFilters = (config?: UpdateConfig) => {
    if (config?.skipDebounce) {
      skipDebounceRef.current = true;
    }
    setFilters(params?.initialValues ?? {});
  };

  return {
    clearFilters,
    filters,
    setFilterValue,
  };
};
