import { Icon } from '@frontend/ui';
import { _delete, edit } from '@frontend/ui/icons';
import { FormattedMessage } from 'components/formats';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import qs from 'query-string';
import React from 'react';
import { useLocation } from 'react-router';

import { orgUnitMessages } from '../../messages';

interface Props {
  id: string;
}

export const OrgUnitTableEditMenu: React.FC<Props> = ({ id }) => {
  const { pathname } = useLocation();

  return (
    <KebabMenu label={orgUnitMessages.orgUnitEditMenu} fixed>
      <MenuItem
        title={<FormattedMessage {...orgUnitMessages.editOrgUnit} />}
        link={{ pathname, search: qs.stringify({ edit: id }) }}
        icon={<Icon icon={edit} />}
      />
      <MenuItem
        title={<FormattedMessage {...orgUnitMessages.deleteOrgUnit} />}
        link={{ pathname, search: qs.stringify({ delete: id }) }}
        icon={<Icon icon={_delete} />}
      />
    </KebabMenu>
  );
};
