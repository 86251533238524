import { commonMessages } from 'app/messages/common';
import { menuMessages } from 'app/messages/menu';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { Page } from 'features/page';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { Overview } from './_index';
import { Calculate } from './calculate';
import { Events } from './events';
import { Reports } from './reports';
import { Requests } from './requests';
import { Approve } from './requests/approve';
import { Reject } from './requests/reject';
import { Terminate } from './terminate';

export interface SalaryExchangeRouteMatchParams {
  companyId: string;
}

const TabbedRoutes: React.FC<RouteComponentProps> = ({
  match: { path, url },
}) => (
  <Page
    title={<FormattedMessage {...menuMessages.salaryExchange} />}
    tabs={[
      {
        title: menuMessages.overview,
        url,
      },
      {
        title: commonMessages.requests,
        url: `${url}/requests`,
      },
      {
        title: commonMessages.calculate,
        url: `${url}/calculate`,
      },
      {
        title: menuMessages.events,
        url: `${url}/events`,
      },
      {
        title: menuMessages.reports,
        url: `${url}/reports`,
      },
    ]}
  >
    <Switch>
      <Route path={`${path}/requests`} component={Requests} />
      <Route path={`${path}/calculate`} component={Calculate} />
      <Route path={`${path}/events`} component={Events} />
      <Route exact path={`${path}/reports`} component={Reports} />
      <Route exact path={path} component={Overview} />
      <Route component={EmptyState} />
    </Switch>
  </Page>
);

export const SalaryExchange: React.FC<RouteComponentProps> = ({
  match: { path },
}) => (
  <Switch>
    <Route path={`${path}/requests/reject`} component={Reject} />
    <Route path={`${path}/requests/approve`} component={Approve} />
    <Route path={`${path}/terminate`} component={Terminate} />
    <Route path={path} component={TabbedRoutes} />
  </Switch>
);
