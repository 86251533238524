import { ContentContainer } from '@frontend/ui';
import { companyMessages } from 'app/messages/company';
import { FormattedMessage } from 'components/formats';
import { SalaryReports } from 'features/companies/company/salary-reports';
import { Page } from 'features/page';
import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { MatchParams as CompanyMatchParams } from '..';

export const Reports: React.FC<RouteComponentProps<CompanyMatchParams>> = ({
  match,
}) => (
  <Page
    title={<FormattedMessage {...companyMessages.reports} />}
    parentLink={`/companies/${match.params.companyId}`}
  >
    <ContentContainer>
      <Route exact path={match.path} component={SalaryReports} />
    </ContentContainer>
  </Page>
);
