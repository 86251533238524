import { pageInfoDetails } from 'app/fragments';
import { gql } from 'graphql-tag';

export const MEMBERSHIPS_QUERY = gql`
  query membershipsQuery(
    $companyId: ID
    $userAccountId: ID
    $first: Int
    $last: Int
    $before: String
    $after: String
    $search: String
    $includeTerminated: Boolean
  ) {
    memberships(
      userAccountId: $userAccountId
      companyId: $companyId
      first: $first
      last: $last
      before: $before
      after: $after
      search: $search
      includeTerminated: $includeTerminated
    ) {
      __typename
      edges {
        node {
          id
          userAccountId
          company {
            id
            name
            displayName
          }
          givenName
          lastName
          naturalPersonIdentifiers
        }
      }
      pageInfo {
        ...pageInfoDetails
      }
    }
  }
  ${pageInfoDetails}
`;

export const COMPANY_OPTIONS_QUERY = gql`
  query companyOptionsQuery {
    companies {
      edges {
        node {
          id
          displayName
          name
        }
      }
    }
  }
`;
