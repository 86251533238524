import { Section, Table, Td, Th, Tr } from '@frontend/ui';
import { allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage as BenefitPackage } from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { totalCompensationMessages } from 'app/messages/total-compensation';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage, useIntl } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import { getBenefitDisplayName } from '../../benefits/utils/get-benefit-display-name';

interface PackageTableRowProps {
  benefitPackage: BenefitPackage;
  onClick: () => void;
}

const PackageTableRow: React.FC<PackageTableRowProps> = ({
  benefitPackage,
  onClick,
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const {
    name,
    benefits,
    memberships,
    showTotalCompensation,
    hasBenefitPortal,
  } = benefitPackage;
  const numberOfEmployees = memberships?.edges.length;

  const benefitNames = [
    ...benefits.edges.map(({ node: benefit }) =>
      getBenefitDisplayName(benefit, intl),
    ),
    ...(showTotalCompensation
      ? [formatMessage(totalCompensationMessages.totalCompensation)]
      : []),
    ...(hasBenefitPortal
      ? [formatMessage(commonBenefitMessages.benefitPortal)]
      : []),
  ];

  const benefitList =
    benefitNames.length > 0 ? benefitNames.join(', ') : <NoValue />;

  return (
    <Tr onClick={onClick} linked>
      <Th multiline>{name}</Th>
      <Td multiline>{benefitList}</Td>
      <Td>
        <FormattedNumber value={numberOfEmployees ?? 0} />
      </Td>
    </Tr>
  );
};

interface Props extends RouteComponentProps {
  benefitPackages: BenefitPackage[];
  companyId: string;
}

export const BenefitPackagesTable: React.FC<Props> = ({
  benefitPackages,
  history: { push },
  match: { url },
}) => {
  const handleClick = (id: string) => {
    push(`${url}/${id}`);
  };

  return (
    <Section>
      {benefitPackages.length === 0 ? (
        <EmptyState
          title={
            <FormattedMessage
              {...commonBenefitMessages.noBenefitPackagesTitle}
            />
          }
        >
          <FormattedMessage {...commonBenefitMessages.noBenefitPackagesText} />
        </EmptyState>
      ) : (
        <Table size="xsmall">
          <colgroup>
            <col style={{ width: '30%' }} />
            <col style={{ width: '45%' }} />
            <col style={{ width: '20%' }} />
          </colgroup>
          <thead>
            <Tr>
              <Th>
                <FormattedMessage {...commonMessages.name} />
              </Th>
              <Th>
                <FormattedMessage {...commonMessages.benefits} />
              </Th>
              <Th>
                <FormattedMessage
                  {...commonBenefitMessages.numberOfEmployees}
                />
              </Th>
            </Tr>
          </thead>
          <tbody>
            {benefitPackages.map(p => (
              <PackageTableRow
                key={p.id}
                benefitPackage={p}
                onClick={() => handleClick(p.id)}
              />
            ))}
          </tbody>
        </Table>
      )}
    </Section>
  );
};
