import { CheckboxGroupField, TextField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import { person } from '@frontend/ui/icons';
import { MembershipRole } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { companyMessages } from 'app/messages/company';
import { EMPLOYEE_ROLE } from 'app/utils/constants';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { NotificationCard } from 'components/NotificationCard';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';

import { useSubmit } from './utils/use-submit';

export interface FormValues {
  roles: Array<MembershipRole>;
  userAccountId: string;
  submissionError?: string;
}

interface EditFormProps {
  onRequestClose: () => void;
}

const EditForm: React.FC<FormikProps<FormValues> & EditFormProps> = ({
  onRequestClose,
  isSubmitting,
  errors,
  initialValues,
  values,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Form>
      <ModalHeader>
        <FormattedMessage
          {...(values.userAccountId
            ? companyMessages.editAdministrator
            : companyMessages.addAdministrator)}
        />
      </ModalHeader>
      <ModalBody>
        <TextField
          dense
          name="userAccountId"
          label={<FormattedMessage {...commonMessages.userAccountId} />}
          required
          value={values.userAccountId}
          leadingIcon={<Icon icon={person} />}
          spellCheck={false}
          disabled={!!initialValues.userAccountId}
        />

        <CheckboxGroupField
          name="roles"
          options={Object.values(MembershipRole).map(role => ({
            label: formatMessage({
              select: role,
              messages: EMPLOYEE_ROLE,
            }),
            value: role,
          }))}
          value={values.roles}
          label={<FormattedMessage {...companyMessages.membershipRole} />}
        />

        {errors.submissionError && (
          <NotificationCard type="error" inModal>
            {errors.submissionError}
          </NotificationCard>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonLayout align="right">
          <Button text onClick={onRequestClose}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
          <Button
            text
            type="submit"
            loading={isSubmitting}
            disabled={
              JSON.stringify(initialValues.roles.sort()) ===
                JSON.stringify(values.roles.sort()) || values.roles.length === 0
            }
          >
            <FormattedMessage {...commonMessages.send} />
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </Form>
  );
};

interface Props {
  companyId: string;
  isOpen: boolean;
  onRequestClose: () => void;
  roles?: Array<MembershipRole>;
  userAccountId?: string;
}

export const EditAdministratorModal: React.FC<Props> = ({
  companyId,
  isOpen,
  roles,
  userAccountId,
  onRequestClose,
}) => {
  const { submit, error } = useSubmit({ companyId, onRequestClose });

  return (
    <Formik<FormValues>
      initialValues={{
        userAccountId: userAccountId ?? '',
        roles: roles ?? [],
      }}
      onSubmit={submit}
      enableReinitialize
    >
      {formikProps => (
        <Modal size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
          {error && <GraphQlError error={error} />}
          <EditForm
            {...formikProps}
            {...{
              onRequestClose,
            }}
          />
        </Modal>
      )}
    </Formik>
  );
};
