import { TotalCompensationId2 as TotalCompensationId } from 'app/apollo/graphql/types';
import { GraphItemId } from 'features/companies/company/employees/employee/total-compensation/utils/aggregate-total-comp-chart-data';
import { defineMessages } from 'react-intl';

import { commonBenefitMessages } from './benefits';
import { commonMessages } from './common';

export const totalCompensationMessages = defineMessages({
  totalCompensation: {
    id: 'totalCompensationMessages.totalCompensation',
    description: 'Total ersättning',
    defaultMessage: 'Total ersättning',
  },
  totalCompensationDisclaimer: {
    id: 'totalCompensationMessages.totalCompensationDisclaimer',
    defaultMessage:
      'Uppgifter om total ersättning baseras på uppgifter från lönefiler och unyttjade förmåner som vi känner till. Vissa värden är uppskattade.',
  },
  totalCompensationPerMonth: {
    id: 'totalCompensationMessages.totalCompensationPerMonth',
    defaultMessage: 'Total ersättning per månad',
  },
  currentPremium: {
    id: 'totalCompensationMessages.currentPremium',
    defaultMessage: 'Nuvarande inbetalning',
  },
  fromNextMonth: {
    id: 'totalCompensationMessages.fromNextMonth',
    defaultMessage: 'Från nästa månad',
  },
  fitnessContribution: {
    id: 'totalCompensationMessages.fitnessContribution',
    defaultMessage: 'Friskvårdsbidrag',
  },
  vacation: {
    id: 'totalCompensationMessages.vacation',
    defaultMessage: 'Semester',
  },
  xDays: {
    id: 'totalCompensationMessages.xDays',
    defaultMessage: '{count, plural, one {# dag} other {# dagar}}',
  },
  remaining: {
    id: 'totalCompensationMessages.remaining',
    defaultMessage: 'Kvarvarande',
  },
  daysSaved: {
    id: 'totalCompensationMessages.daysSaved',
    defaultMessage: 'Varav sparade',
  },
  daysPaid: {
    id: 'totalCompensationMessages.daysPaid',
    defaultMessage: 'Varav betalda',
  },
  daysUnpaid: {
    id: 'totalCompensationMessages.daysUnpaid',
    defaultMessage: 'Varav obetalda',
  },
  daysAdvance: {
    id: 'totalCompensationMessages.daysAdvance',
    defaultMessage: 'Varav förskott',
  },
  variableCompensation: {
    id: 'totalCompensationMessages.variableCompensation',
    defaultMessage: 'Rörlig ersättning',
  },
  annualBonus: {
    id: 'totalCompensationMessages.annualBonus',
    defaultMessage: 'Årsbonus',
  },
  salary: {
    id: 'totalCompensationMessages.salary',
    defaultMessage: 'Lön',
  },
  pension: {
    id: 'totalCompensationMessages.pension',
    defaultMessage: 'Pension',
  },
  other: {
    id: 'totalCompensationMessages.other',
    defaultMessage: 'Övrigt',
  },
  employersContributionsAndspecialPayrollTax: {
    id: 'totalCompensationMessages.employersContributionsAndspecialPayrollTax',
    defaultMessage: 'Sociala avgifter och särskild löneskatt',
  },
  incomeTax: {
    id: 'totalCompensationMessages.incomeTax',
    defaultMessage: 'Inkomstskatt',
  },
  netSalary: {
    id: 'totalCompensationMessages.netSalary',
    defaultMessage: 'Lön efter skatt',
  },
  salaryAfterSalaryExchange: {
    id: 'totalCompensationMessages.salaryAfterSalaryExchange',
    defaultMessage: 'Lön efter löneväxling',
  },
  specialPayrollTax: {
    id: 'totalCompensationMessages.specialPayrollTax',
    defaultMessage: 'Särskild löneskatt',
  },
  employersContributions: {
    id: 'totalCompensationMessages.employersContributions',
    defaultMessage: 'Arbetsgivaravgifter',
  },
  companyCar: {
    id: 'totalCompensationMessages.companyCar',
    defaultMessage: 'Bilförmån',
  },
  ITPK: {
    id: 'totalCompensationMessages.ITPK',
    defaultMessage: 'ITPK',
  },
  BTP1: {
    id: 'totalCompensationMessages.BTP1',
    defaultMessage: 'BTP 1',
  },
  ITP: {
    id: 'totalCompensationMessages.ITP',
    defaultMessage: 'ITP 2',
  },
  ITP1: {
    id: 'totalCompensationMessages.ITP1',
    defaultMessage: 'ITP 1',
  },
  KAPKL: {
    id: 'totalCompensationMessages.KAPKL',
    defaultMessage: 'KAP-KL',
  },
  AKAPKL: {
    id: 'totalCompensationMessages.AKAPKL',
    defaultMessage: 'AKAP-KL',
  },
  AKAPKR: {
    id: 'totalCompensationMessages.AKAPKR',
    defaultMessage: 'AKAP-KR',
  },
  SAFLO: {
    id: 'totalCompensationMessages.SAFLO',
    defaultMessage: 'SAF-LO',
  },
  PAKFS09: {
    id: 'totalCompensationMessages.PAKFS09',
    defaultMessage: 'PA-KFS 09',
  },
  BTP1Description: {
    id: 'totalCompensationMessages.BTP1Description',
    defaultMessage:
      'Du omfattas av den kollektivavtalade tjänstepension BTP 1. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
  },
  ITPKDescription: {
    id: 'totalCompensationMessages.ITPKDescription',
    description: 'ITPKDescription',
    defaultMessage:
      'ITPK är en del av den pension som din arbetsgivare betalar för dig då du tillhör ITP 2.',
  },
  ITPDescription: {
    id: 'totalCompensationMessages.ITPDescription',
    description: 'ITP description',
    defaultMessage:
      'ITP 2 är en förmånsbestämd pension som din arbetsgivare betalar för dig. Vad den kommer att ge dig beror på din lön och hur länge du arbetat och haft ITP 2.',
  },
  ITP1Description: {
    id: 'totalCompensationMessages.ITP1Description',
    description: 'ITP1 description',
    defaultMessage:
      'Du omfattas av ITP 1 genom din arbetsgivare. Information om försäkringsgivare hittar du på dina sidor hos Collectum.',
  },
  KAPKLDescription: {
    id: 'totalCompensationMessages.KAPKLDescription',
    description: 'KAP-KL description',
    defaultMessage:
      'Du omfattas av den kollektivavtalade tjänstepension KAP-KL. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
  },
  AKAPKLDescription: {
    id: 'totalCompensationMessages.AKAPKLDescription',
    description: 'AKAP-KL description',
    defaultMessage:
      'Du omfattas av den kollektivavtalade tjänstepension AKAP-KL. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
  },
  AKAPKRDescription: {
    id: 'totalCompensationMessages.AKAPKRDescription',
    description: 'AKAP-KR description',
    defaultMessage:
      'Du omfattas av den kollektivavtalade tjänstepension AKAP-KR. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
  },
  SAFLODescription: {
    id: 'totalCompensationMessages.SAFLODescription',
    description: 'SAF-LO description',
    defaultMessage:
      'Du omfattas av den kollektivavtalade tjänstepension SAF-LO. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
  },
  PAKFS09Description: {
    id: 'totalCompensationMessages.PAKFS09Description',
    description: 'PA-KFS 09 description',
    defaultMessage:
      'Du omfattas av den kollektivavtalade tjänstepension PA-KFS 09. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
  },
  lunchAllowance: {
    id: 'totalCompensationMessages.lunchAllowance',
    defaultMessage: 'Lunchförmån',
  },
  salaryBeforeTax: {
    id: 'totalCompensationMessages.salaryBeforeTax',
    defaultMessage: `Månadslön`,
  },
  bonus: {
    id: 'totalCompensationMessages.bonus',
    defaultMessage: 'Bonus',
  },
  wellnessAllowance: {
    id: 'totalCompensationMessages.wellnessAllowance',
    defaultMessage: 'Friskvårdsförmån',
  },
  netSalaryDescription: {
    id: 'totalCompensationMessages.netSalaryDescription',
    defaultMessage: 'Lön som sätts in på ditt bankkonto.',
  },
  monthlySalaryDescription: {
    id: 'totalCompensationMessages.monthlySalaryDescription',
    defaultMessage: `Lön före skatt.`,
  },
  incomeTaxDescription: {
    id: 'totalCompensationMessages.incomeTaxDescription',
    defaultMessage:
      'Din arbetsgivare drar av skatt innan du får ut din lön. Skatten beräknas på din kontanta lön och andra skattepliktiga förmåner.',
  },
  pensionDescription: {
    id: 'totalCompensationMessages.pensionDescription',
    defaultMessage: 'Pension som din arbetsgivare betalar för dig.',
  },
  salaryExchangeDescription: {
    id: 'totalCompensationMessages.salaryExchangeDescription',
    defaultMessage:
      'Bruttolöneavdrag som växlas för extra pensionsavsättningar.',
  },
  companyCarDescription: {
    id: 'totalCompensationMessages.companyCarDescription',
    defaultMessage:
      'Det skattepliktiga värdet för privat användande av bilförmån.',
  },
  lunchAllowanceDescription: {
    id: 'totalCompensationMessages.lunchAllowanceDescription',
    defaultMessage: 'Värdet av din lunchförmån varje månad',
  },
  specialPayrollTaxDescription: {
    id: 'totalCompensationMessages.specialPayrollTaxDescription',
    defaultMessage:
      'Din arbetsgivare betalar 24,26 % på tjänstepension och sjukförsäkringskostnader i särskild löneskatt.',
  },
  employersContributionsDescription: {
    id: 'totalCompensationMessages.employersContributionsDescription',
    defaultMessage:
      'Din arbetsgivare betalar {employerContributionShare} på det totala värdet av lön och förmåner i arbetsgivaravgifter.',
  },
  bonusDescription: {
    id: 'totalCompensationMessages.bonusDescription',
    defaultMessage: 'Ersättning utöver din lön.',
  },
  wellnessAllowanceDesc: {
    id: 'totalCompensationMessages.wellnessAllowanceDesc',
    defaultMessage:
      'Ditt årliga friskvårdsbidrag via Epassi delat på 12 månader.',
  },
  title: {
    id: 'totalCompensationMessages.title',
    defaultMessage: 'Titel',
  },
  description: {
    id: 'totalCompensationMessages.description',
    defaultMessage: 'Beskrivning',
  },
  value: {
    id: 'totalCompensationMessages.value',
    defaultMessage: 'Värde (kr/mån)',
  },
  sum: {
    id: 'totalCompensationMessages.sum',
    defaultMessage: 'Summa',
  },
});

export const totalCompensationTitleMessages =
  defineMessages<TotalCompensationId>({
    INCOME_TAX: totalCompensationMessages.incomeTax,
    NET_SALARY: totalCompensationMessages.netSalary,
    CASH_SALARY: totalCompensationMessages.salaryAfterSalaryExchange,
    SALARY_EXCHANGE: commonBenefitMessages.salaryExchange,
    PENSION: commonBenefitMessages.occupationalPension,
    SPECIAL_PAYROLL_TAX: totalCompensationMessages.specialPayrollTax,
    EMPLOYERS_CONTRIBUTIONS: totalCompensationMessages.employersContributions,
    COMPANY_CAR: totalCompensationMessages.companyCar,
    BTP1: totalCompensationMessages.BTP1,
    ITP: totalCompensationMessages.ITP,
    ITP1: totalCompensationMessages.ITP1,
    ITPK: totalCompensationMessages.ITPK,
    KAPKL: totalCompensationMessages.KAPKL,
    AKAPKL: totalCompensationMessages.AKAPKL,
    AKAPKR: totalCompensationMessages.AKAPKR,
    SAFLO: totalCompensationMessages.SAFLO,
    PAKFS09: totalCompensationMessages.PAKFS09,
    LUNCH_ALLOWANCE: totalCompensationMessages.lunchAllowance,
    GROSS_SALARY: totalCompensationMessages.salaryBeforeTax,
    SALARY_EXCHANGE_DEDUCTION: commonBenefitMessages.salaryExchange,
    VARIABLE_SALARY: totalCompensationMessages.bonus,
    WELLNESS: totalCompensationMessages.wellnessAllowance,
  });

export const totalCompensationDescriptionMessages =
  defineMessages<TotalCompensationId>({
    INCOME_TAX: {
      id: 'totalCompensationDescriptionMessages.incomeTaxDescription',
      defaultMessage:
        'Din arbetsgivare drar av skatt innan du får ut din lön. Skatten beräknas på din kontanta lön och andra skattepliktiga förmåner.',
    },
    NET_SALARY: {
      id: 'totalCompensationDescriptionMessages.netSalaryDescription',
      defaultMessage: 'Lön som sätts in på ditt bankkonto.',
    },
    CASH_SALARY: totalCompensationMessages.monthlySalaryDescription,
    SALARY_EXCHANGE: totalCompensationMessages.salaryExchangeDescription,
    PENSION: {
      id: 'totalCompensationDescriptionMessages.pensionDescription',
      defaultMessage: 'Pension som din arbetsgivare betalar för dig.',
    },
    SPECIAL_PAYROLL_TAX: {
      id: 'totalCompensationDescriptionMessages.specialPayrollTaxDescription',
      defaultMessage:
        'Din arbetsgivare betalar 24,26 % på tjänstepension och sjukförsäkringskostnader i särskild löneskatt.',
    },
    EMPLOYERS_CONTRIBUTIONS: {
      id: 'totalCompensationDescriptionMessages.employersContributionsDescription',
      defaultMessage:
        'Din arbetsgivare betalar {employerContributionShare} på det totala värdet av lön och förmåner i arbetsgivaravgifter.',
    },
    COMPANY_CAR: {
      id: 'totalCompensationDescriptionMessages.companyCarDescription',
      defaultMessage:
        'Det skattepliktiga värdet för privat användande av bilförmån.',
    },
    LUNCH_ALLOWANCE: {
      id: 'totalCompensationDescriptionMessages.lunchAllowanceDescription',
      defaultMessage: 'Värdet av din lunchförmån varje månad',
    },
    BTP1: {
      id: 'totalCompensationDescriptionMessages.BTP1Description',
      defaultMessage:
        'Du omfattas av den kollektivavtalade tjänstepension BTP 1. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
    },
    ITP: {
      id: 'totalCompensationDescriptionMessages.ITPDescription',
      defaultMessage:
        'ITP 2 är en förmånsbestämd pension som din arbetsgivare betalar för dig. Vad den kommer att ge dig beror på din lön och hur länge du arbetat och haft ITP 2.',
    },
    ITP1: {
      id: 'totalCompensationDescriptionMessages.ITP1Description',
      defaultMessage:
        'Du omfattas av ITP 1 genom din arbetsgivare. Information om försäkringsgivare hittar du på dina sidor hos Collectum.',
    },
    ITPK: {
      id: 'totalCompensationDescriptionMessages.ITPKDescription',
      defaultMessage:
        'ITPK är en del av den pension som din arbetsgivare betalar för dig då du tillhör ITP 2.',
    },
    KAPKL: {
      id: 'totalCompensationDescriptionMessages.KAPKLDescription',
      defaultMessage:
        'Du omfattas av den kollektivavtalade tjänstepension KAP-KL. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
    },
    AKAPKL: {
      id: 'totalCompensationDescriptionMessages.AKAPKLDescription',
      defaultMessage:
        'Du omfattas av den kollektivavtalade tjänstepension AKAP-KL. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
    },
    AKAPKR: {
      id: 'totalCompensationDescriptionMessages.AKAPKRDescription',
      defaultMessage:
        'Du omfattas av den kollektivavtalade tjänstepension AKAP-KR. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
    },
    SAFLO: {
      id: 'totalCompensationDescriptionMessages.SAFLODescription',
      defaultMessage:
        'Du omfattas av den kollektivavtalade tjänstepension SAF-LO. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
    },
    PAKFS09: {
      id: 'totalCompensationDescriptionMessages.PAKFS09Description',
      defaultMessage:
        'Du omfattas av den kollektivavtalade tjänstepension PA-KFS 09. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
    },
    GROSS_SALARY: totalCompensationMessages.monthlySalaryDescription,
    SALARY_EXCHANGE_DEDUCTION:
      totalCompensationMessages.salaryExchangeDescription,
    VARIABLE_SALARY: {
      id: 'totalCompensationDescriptionMessages.bonusDescription',
      defaultMessage: 'Ersättning utöver din lön.',
    },
    WELLNESS: {
      id: 'totalCompensationDescriptionMessages.wellnessAllowanceDesc',
      defaultMessage:
        'Ditt årliga friskvårdsbidrag via Epassi delat på 12 månader.',
    },
  });

export const totalCompensationLegendMessages = defineMessages<GraphItemId>({
  NET_SALARY: totalCompensationMessages.salary,
  CASH_SALARY: {
    id: 'totalCompensationLegendMessages.CASH_SALARY',
    defaultMessage: '{cashSalaryMessage}',
  },
  INCOME_TAX: totalCompensationMessages.incomeTax,
  SALARY_EXCHANGE: commonBenefitMessages.salaryExchange,
  PENSION: commonBenefitMessages.occupationalPension,
  COMPANY_CAR: totalCompensationMessages.companyCar,
  EMPLOYERS_CONTRIBUTIONS: totalCompensationMessages.employersContributions,
  BTP1: totalCompensationMessages.BTP1,
  ITPK: totalCompensationMessages.ITPK,
  ITP: totalCompensationMessages.ITP,
  ITP1: totalCompensationMessages.ITP1,
  KAPKL: totalCompensationMessages.KAPKL,
  AKAPKL: totalCompensationMessages.AKAPKL,
  AKAPKR: totalCompensationMessages.AKAPKR,
  SAFLO: totalCompensationMessages.SAFLO,
  PAKFS09: totalCompensationMessages.PAKFS09,
  LUNCH_ALLOWANCE: totalCompensationMessages.lunchAllowance,
  SPECIAL_PAYROLL_TAX: totalCompensationMessages.specialPayrollTax,
  SALARY_EXCHANGE_DEDUCTION: commonBenefitMessages.salaryExchange,
  VARIABLE_SALARY: totalCompensationMessages.variableCompensation,
  GROSS_SALARY: totalCompensationMessages.salaryBeforeTax,
  BENEFITS: commonMessages.benefits,
  WELLNESS: totalCompensationMessages.wellnessAllowance,
});
