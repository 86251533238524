import { Icon, Table, TableSortButton, Td, Th, Tr } from '@frontend/ui';
import { _delete as deleteIcon, edit } from '@frontend/ui/icons';
import { ProposalBenefitType } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { useTableSort } from 'app/utils/use-table-sort';
import { FormattedMessage, useIntl } from 'components/formats';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import { useConfirm } from 'contexts/confirmation';
import {
  smeBenefitPackagesMessages,
  smeBenefitsMessages,
} from 'features/sme/messages/sme';
import React from 'react';

import { useProposalReadonly } from '../../../utils/use-proposal-readonly';
import { by, SortableColumns } from '../../utils/sort-benefits-by';

export interface Benefit {
  details: React.ReactNode;
  id: string;
  name: string;
  type: ProposalBenefitType;
}

interface Props {
  benefits: readonly Benefit[];
  onEdit: (id: string) => void;
  onRemove: (id: string) => void;
}

export const BenefitsTable: React.FC<Props> = ({
  benefits,
  onEdit,
  onRemove,
}) => {
  const { handleSort, order, orderBy } = useTableSort<SortableColumns>();
  const { formatMessage } = useIntl();
  const disabled = useProposalReadonly();
  const { confirm } = useConfirm();

  const tableHead = (
    <>
      <colgroup>
        <col style={{ width: '40%' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
      </colgroup>
      <thead>
        <Tr withInlineMenuTh>
          <Th>
            <TableSortButton
              onClick={handleSort}
              order={orderBy === 'name' ? order : undefined}
              value="name"
            >
              <FormattedMessage {...commonMessages.benefit} />
            </TableSortButton>
          </Th>
          <Th align="left">
            <FormattedMessage {...commonMessages.detail} />
          </Th>
        </Tr>
      </thead>
    </>
  );

  if (!benefits.length) {
    return (
      <Table size="small">
        {tableHead}
        <tbody>
          <Tr>
            <Td colSpan={4}>
              <FormattedMessage
                {...smeBenefitsMessages.noBenefitsInBenefitPackage}
              />
            </Td>
          </Tr>
        </tbody>
      </Table>
    );
  }

  const params = order && orderBy ? { order, orderBy } : undefined;
  const sortedBenefits = [...benefits].sort(by(params));

  return (
    <Table size="small">
      <>
        {tableHead}
        <tbody>
          {sortedBenefits.map(benefit => (
            <Tr
              key={benefit.id}
              inlineMenu={
                <KebabMenu inClickableArea fixed disabled={disabled}>
                  <MenuItem
                    title={
                      <FormattedMessage {...smeBenefitsMessages.editLink} />
                    }
                    icon={<Icon icon={edit} />}
                    onClick={() => onEdit(benefit.id)}
                  />

                  <MenuItem
                    title={
                      <FormattedMessage
                        {...smeBenefitPackagesMessages.removeBenefit}
                      />
                    }
                    icon={<Icon icon={deleteIcon} />}
                    onClick={async () => {
                      const confirmed = await confirm({
                        description: formatMessage(
                          smeBenefitPackagesMessages.confirmRemoveBenefit,
                        ),
                      });

                      if (confirmed) {
                        onRemove(benefit.id);
                      }
                    }}
                  />
                </KebabMenu>
              }
              linked
              onClick={() => onEdit(benefit.id)}
            >
              <Td>{benefit.name}</Td>
              <Td align="left">{benefit.details}</Td>
            </Tr>
          ))}
        </tbody>
      </>
    </Table>
  );
};
