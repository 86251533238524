import { ApolloCache } from '@apollo/client';

export type AllowedFields = 'cost' | 'proposals' | 'validity';

interface Params {
  fieldNames: AllowedFields[];
  proposalId: string;
}

/**
 *  Clears the cache for specific fields on a proposal.
 *
 *  @param fieldNames - Array of allowed cached field names which should be cleared.
 *  @param proposalId - ID of the current proposal.
 */

export const clearProposalCache =
  ({ fieldNames, proposalId }: Params) =>
  (cache: ApolloCache<unknown>) => {
    fieldNames.forEach(fieldName => {
      // Creation or deletion of benefits, benefit packages, memberships or representatives may
      // affect the validity data and should clear the `validity` cache
      if (fieldName === 'validity') {
        cache.evict({ id: `Proposal:${proposalId}`, fieldName });
      }
      // When making changes to a proposal, we need to clear the proposals cache
      // in order to updated the 'updated at' and 'updated by' in the table.
      // Also used when generating a proposal so that it is displayed in the
      // "Generated Proposals" table correctly.
      if (fieldName === 'proposals') {
        cache.evict({
          fieldName,
        });
      }
      // Any change to benefits, benefit packages, memberships or fees
      // may affect the cost data and should clear the `cost` cache.
      if (fieldName === 'cost') {
        cache.evict({ id: `Proposal:${proposalId}`, fieldName });
        cache.modify({
          id: `Proposal:${proposalId}`,
          fields: {
            memberships: (existingMemberships = []) =>
              existingMemberships.map(membership => {
                const { cost, ...rest } = membership;
                return rest;
              }),
          },
        });
        cache.modify({
          id: `Proposal:${proposalId}`,
          fields: {
            benefitPackages: (existingBenefitPackages = []) =>
              existingBenefitPackages.map(benefitPackage => {
                const { cost, ...rest } = benefitPackage;
                return rest;
              }),
          },
        });
      }
    });
    cache.gc();
  };
