import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

import { companyBillingConfigurationDetails } from './fragments';

export const COMPANY_BILLING_CONFIGURATION_QUERY = gql`
  query companyBillingConfigurationQuery(
    $companyId: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    company(id: $companyId) {
      id
      billingConfigurations(
        first: $first
        after: $after
        last: $last
        before: $before
      ) {
        __typename
        pageInfo {
          ...pageInfoDetails
        }
        edges {
          node {
            ...companyBillingConfigurationDetails
          }
        }
      }
    }
  }
  ${companyBillingConfigurationDetails}
  ${pageInfoDetails}
`;
