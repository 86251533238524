import '@frontend/ui/css/circular-xx.css';
import '@frontend/ui/css/material.css';
import '@frontend/ui/css/normalize.css';
import '@frontend/ui/css/base.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { ApolloProvider } from '@apollo/client';
import isPropValid from '@emotion/is-prop-valid';
import { defaultTheme as theme } from '@frontend/ui';
import { NavigationContextProvider } from 'components/NavigationDrawer/containers/NavigationContext';
import { IntlProviderWrapper } from 'contexts/IntlProviderWrapper';
import { NavigationAnchorContextProvider } from 'contexts/navigation-anchor';
import { NotificationProvider } from 'features/notifications';
import { TransitionOverlayContextProvider } from 'features/transition-overlay';
import { basename } from 'index';
import React from 'react';
import {
  BrowserRouter,
  Redirect,
  // eslint-disable-next-line
  Route,
  RouteComponentProps,
} from 'react-router-dom';
import { StyleSheetManager, ThemeProvider, WebTarget } from 'styled-components';

import { client } from './apollo/client';

const RemoveTrailingSlash: React.FC<RouteComponentProps> = ({ location }) => {
  const { pathname } = location;

  if (pathname !== '/' && pathname.slice(-1) === '/') {
    return <Redirect to={pathname.slice(0, -1)} />;
  }
  return null;
};

// https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
const shouldForwardProp = (propName: string, target: WebTarget | string) => {
  if (typeof target === 'string') {
    return isPropValid(propName);
  }
  return true;
};

interface Props {
  children: React.ReactNode;
}

export const Provider: React.FC<Props> = ({ children }) => (
  <ApolloProvider client={client}>
    <IntlProviderWrapper>
      <NotificationProvider>
        <TransitionOverlayContextProvider>
          <ThemeProvider theme={theme}>
            <StyleSheetManager
              enableVendorPrefixes
              shouldForwardProp={shouldForwardProp}
            >
              <BrowserRouter basename={basename}>
                <NavigationContextProvider>
                  <NavigationAnchorContextProvider>
                    <Route path="/" render={RemoveTrailingSlash} />
                    {children}
                  </NavigationAnchorContextProvider>
                </NavigationContextProvider>
              </BrowserRouter>
            </StyleSheetManager>
          </ThemeProvider>
        </TransitionOverlayContextProvider>
      </NotificationProvider>
    </IntlProviderWrapper>
  </ApolloProvider>
);
