import { Button, ButtonLayout } from '@frontend/ui';
import { formMessages } from 'app/messages/form';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Form, Formik } from 'formik';
import React from 'react';

import {
  BenefitPackageFields,
  BenefitPackageFormValues,
  benefitPackageInitialValues,
  benefitPackageValidationSchema,
} from './components/BenefitPackageFields';
import {
  CompensationDataFormValues,
  CompensationFields,
  compensationInitialValues,
  compensationValidationSchema,
} from './components/CompensationFields';
import {
  ConfirmationFields,
  ConfirmationFormValues,
  confirmationInitialValues,
  confirmationValidationSchema,
} from './components/ConfirmationFields';
import {
  EmploymentDataFields,
  EmploymentDataFormValues,
  employmentDataInitialValues,
  employmentDataValidationSchema,
} from './components/EmploymentDataFields';
import {
  OrganizationFields,
  OrganizationFormValues,
} from './components/OrganizationFields';
import {
  PersonalDataFields,
  PersonalDataFormValues,
  personalDataInitialValues,
  personalDataValidationSchema,
} from './components/PersonalDataFields';
import {
  VacationFields,
  VacationFormValues,
} from './components/VacationFields';
import { useSubmit } from './submit';

export type FormValues = PersonalDataFormValues &
  EmploymentDataFormValues &
  CompensationDataFormValues &
  OrganizationFormValues &
  BenefitPackageFormValues &
  VacationFormValues &
  ConfirmationFormValues;

export const AddEmployeeForm: React.FC = () => {
  const { submit, submissionError } = useSubmit();
  const intl = useIntl();

  return (
    <Formik<FormValues>
      initialValues={{
        ...personalDataInitialValues,
        ...employmentDataInitialValues,
        ...compensationInitialValues,
        ...benefitPackageInitialValues,
        ...confirmationInitialValues,
      }}
      onSubmit={submit}
      validationSchema={personalDataValidationSchema(intl)
        .concat(employmentDataValidationSchema(intl))
        .concat(compensationValidationSchema(intl))
        .concat(benefitPackageValidationSchema(intl))
        .concat(confirmationValidationSchema(intl))}
      validateOnMount
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <PersonalDataFields />
          <EmploymentDataFields />
          <CompensationFields />
          <OrganizationFields />
          <BenefitPackageFields />
          <VacationFields />
          <ConfirmationFields />
          {submissionError && <GraphQlError error={submissionError} />}
          <ButtonLayout>
            <Button
              filled
              type="submit"
              disabled={!isValid}
              loading={isSubmitting}
            >
              <FormattedMessage {...formMessages.approve} />
            </Button>
          </ButtonLayout>
        </Form>
      )}
    </Formik>
  );
};
