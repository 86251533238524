import {
  smeCompanyProposalEmployeeLayoutQuery,
  smeCompanyProposalEmployeeLayoutQueryVariables,
} from 'app/apollo/graphql/types';
import { SME_COMPANY_PROPOSAL_EMPLOYEE_LAYOUT_QUERY } from 'app/queries';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { smeEmployeesMessages } from 'features/sme/messages/sme';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { MatchParams as SmeProposalMatchParams } from '..';
import { SmeCompanyProposalEmployeeRoute } from './_index';
import { SmeCompanyProposalEmployeeEditRoute } from './edit';

export interface MatchParams extends SmeProposalMatchParams {
  employeeId: string;
}

export const SmeCompanyProposalEmployeeRoutes: React.FC<
  RouteComponentProps<MatchParams>
> = ({ match }) => {
  const { data, loading, error } = useQuery<
    smeCompanyProposalEmployeeLayoutQuery,
    smeCompanyProposalEmployeeLayoutQueryVariables
  >(SME_COMPANY_PROPOSAL_EMPLOYEE_LAYOUT_QUERY, {
    variables: { id: match.params.employeeId },
  });

  const parentLink = `/sme/${match.params.companyId}/proposals/${match.params.proposalId}/employees`;

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.proposalMembership) {
    return <EmptyStatePage error={error} parentLink={parentLink} />;
  }

  const title = `${data.proposalMembership.firstName} ${data.proposalMembership.lastName}`;

  return (
    <NavigationAnchor path={match.url} title={title}>
      <Switch>
        <Route exact path={match.path}>
          <SmeCompanyProposalEmployeeRoute title={title} />
        </Route>
        <NavigationAnchor
          path={`${match.url}/edit`}
          title={smeEmployeesMessages.editEmployee}
        >
          <Route
            exact
            path={`${match.path}/edit`}
            component={SmeCompanyProposalEmployeeEditRoute}
          />
        </NavigationAnchor>
        <Route component={EmptyStatePage} />
      </Switch>
    </NavigationAnchor>
  );
};
