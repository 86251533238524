import { gql } from 'graphql-tag';

export const EMPLOYEES_WITH_PERSONAL_ADVICE_QUERY = gql`
  query employeesWithPersonalAdviceQuery(
    $search: String
    $companyId: ID
    $benefitPackageIds: [ID!]
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    employeesWithPersonalAdvice(
      search: $search
      companyId: $companyId
      benefitPackageIds: $benefitPackageIds
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      edges {
        node {
          userAccountId
          companyId
          latestMeetingDate
          allowedToHaveMeeting
          latestEmailSentAt
          membership {
            id
            naturalPersonIdentifiers
            givenName
            lastName
            company {
              displayName
              registrationNumber
            }
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      __typename
    }
  }
`;

export const MEMBERSHIP_BY_PIN_AND_REG_NO_QUERY = gql`
  query membershipByPinAndRegNoQuery(
    $personalIdentityNumber: IdentityNumber!
    $registrationNumber: String!
  ) {
    memberships(
      personalIdentityNumber: $personalIdentityNumber
      registrationNumber: $registrationNumber
    ) {
      edges {
        node {
          userAccountId
          company {
            id
          }
        }
      }
    }
  }
`;

export const EMPLOYEE_MEETINGS_QUERY = gql`
  query employeeMeetingsQuery(
    $search: String
    $companyId: ID
    $from: Date
    $to: Date
    $advisorId: ID
    $eventTypeCategories: [EventTypeCategory!]
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    employeeMeetings(
      search: $search
      companyId: $companyId
      from: $from
      to: $to
      advisorId: $advisorId
      eventTypeCategories: $eventTypeCategories
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      edges {
        node {
          id
          userAccountId
          companyId
          date
          confirmed
          eventTypeCategory
          latestEmailSentAt
          membership {
            id
            naturalPersonIdentifiers
            givenName
            lastName
            company {
              displayName
            }
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      __typename
    }
  }
`;

export const COMPANIES_WITH_PERSONAL_ADVICE_BENEFIT_QUERY = gql`
  query companiesWithPersonalAdviceBenefitQuery {
    companies {
      edges {
        node {
          id
          displayName
          name
          flexBenefits(benefitTypeNames: ["nordea_node_personal_advice"]) {
            edges {
              node {
                id
              }
            }
          }
          benefitPackages {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const ADVISORS_QUERY = gql`
  query advisorsQuery {
    advisors {
      id
      firstName
      lastName
    }
  }
`;

export const MEETING_STATISTICS_QUERY = gql`
  query meetingStatisticsQuery(
    $companyIds: [ID!]
    $from: Date
    $to: Date
    $eventTypeCategories: [EventTypeCategory!]
    $advisorId: ID
  ) {
    meetingStatistics(
      companyIds: $companyIds
      from: $from
      to: $to
      eventTypeCategories: $eventTypeCategories
      advisorId: $advisorId
    ) {
      numberOfInvitationsSent
      numberOfRemindersSent {
        first
        second
      }
      numberOfBookedMeetings
      numberOfCreatedBookings
      numberOfCancelledMeetings
      numberOfConfirmedMeetings
    }
  }
`;

export const PT_STATISTICS_QUERY = gql`
  query ptStatisticsQuery(
    $from: Date
    $to: Date
    $advisorId: ID
    $companyId: ID
  ) {
    pensionTransferStatistics(
      from: $from
      to: $to
      advisorId: $advisorId
      companyId: $companyId
    ) {
      totalTransferredCapital
      transferredCapitalToInstitute {
        instituteId
        capital
      }
    }
  }
`;

export const SIGNED_PENSION_TRANSFERS_QUERY = gql`
  query signedPensionTransfersQuery($userAccountId: ID!, $date: Date!) {
    signedPensionTransfers(
      userAccountId: $userAccountId
      date: $date
      type: PT
    ) {
      id: pensionTransferTaskId
      sources {
        insuranceNumber
        capital
      }
    }
  }
`;

export const EMPLOYEES_WITH_TRANSFERABLE_CAPITAL = gql`
  query employeesWithTransferableCapitalQuery(
    $search: String
    $minCapital: Int
    $maxCapital: Int
    $brokeredByNode: Boolean
    $companyId: ID
    $benefitPackageIds: [ID!]
    $sortBy: SortTransferableCapitalBy
    $sortOrder: SortOrder
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    employeesWithTransferableCapital(
      search: $search
      minCapital: $minCapital
      maxCapital: $maxCapital
      brokeredByNode: $brokeredByNode
      companyId: $companyId
      benefitPackageIds: $benefitPackageIds
      sortBy: $sortBy
      sortOrder: $sortOrder

      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      edges {
        node {
          userAccountId
          capital
          latestEmailSentAt
          memberships {
            edges {
              node {
                id
                naturalPersonIdentifiers
                givenName
                lastName
                company {
                  displayName
                  id
                }
                employments {
                  edges {
                    node {
                      period
                    }
                  }
                }
              }
            }
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      __typename
    }
  }
`;
