import {
  CollapsibleList,
  CollapsibleListItem,
  Section,
  Table,
  Th,
  Tr,
} from '@frontend/ui';
import { addCircle } from '@frontend/ui/icons';
import { formOrgUnitsTree, TreeNode } from '@frontend/utils';
import {
  organizationalUnitsQuery,
  organizationalUnitsQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { LinkButton } from 'components/LinkButton';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import styled from 'styled-components';

import { OrgUnitTableEditMenu } from './components/OrgUnitTableEditMenu';
import { ORGANIZATIONAL_UNITS_QUERY } from './graphql/queries';
import { orgUnitMessages } from './messages';

const StyledTr = styled(Tr)`
  border-bottom: 0px !important;
`;

const renderTableRowCollapsible = (
  node: Record<string, TreeNode>,
  index: number,
) => {
  const entries = Object.entries(node);
  if (entries.length) {
    return entries.map(([key, value]) =>
      value.children ? (
        <CollapsibleList
          index={index}
          key={key}
          actions={<OrgUnitTableEditMenu id={value.id} />}
          handle={<CollapsibleListItem label={value.name} asHandle />}
        >
          {renderTableRowCollapsible(value.children, index + 1)}
        </CollapsibleList>
      ) : (
        <CollapsibleListItem
          label={value.name}
          key={key}
          index={index}
          actions={<OrgUnitTableEditMenu id={value.id} />}
        />
      ),
    );
  }

  return undefined;
};

export const OrganizationalUnits: React.FC = () => {
  const { pathname } = useLocation();
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const { loading, data, error } = useQuery<
    organizationalUnitsQuery,
    organizationalUnitsQueryVariables
  >(ORGANIZATIONAL_UNITS_QUERY, {
    errorPolicy: 'all',
    skip: !companyId,
    variables: { companyId: companyId ?? '' },
  });

  if (loading) {
    return <TopLoading />;
  }

  const orgUnits =
    data?.company?.organizationalUnits?.edges?.map(e => e.node) ?? [];

  const orgUnitTree = formOrgUnitsTree(orgUnits);

  if (!orgUnits.length) {
    return (
      <EmptyState
        error={error}
        title={
          <p>
            <FormattedMessage {...orgUnitMessages.noOrgUnitsTitle} />
          </p>
        }
        actions={
          <LinkButton to={{ pathname, search: qs.stringify({ create: true }) }}>
            <FormattedMessage {...orgUnitMessages.createOrgUnit} />
          </LinkButton>
        }
      >
        <p>
          <FormattedMessage {...orgUnitMessages.noOrgUnitsText} />
        </p>
      </EmptyState>
    );
  }

  return (
    <Section>
      {error && <GraphQlError error={error} />}
      <ChipsWrapper>
        <AssistChip
          to={{ pathname, search: qs.stringify({ create: true }) }}
          text={<FormattedMessage {...orgUnitMessages.createOrgUnit} />}
          leadingIcon={addCircle}
        />
      </ChipsWrapper>
      <Table size="xsmall">
        <thead>
          <StyledTr>
            <Th>
              <FormattedMessage {...orgUnitMessages.name} />
            </Th>
          </StyledTr>
        </thead>
        <tbody>
          <tr>
            <td style={{ padding: '0 0 0.05rem' }}>
              {orgUnitTree && renderTableRowCollapsible(orgUnitTree, 0)}
            </td>
          </tr>
        </tbody>
      </Table>
    </Section>
  );
};
