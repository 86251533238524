import { ContentContainer } from '@frontend/ui';
import { ProposalBenefitType } from 'app/apollo/graphql/types';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage, useIntl } from 'components/formats';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { Page } from 'features/page';
import { CreateAccident } from 'features/sme/companies/company/proposals/edit/benefits/create/accident';
import { CreateDisability } from 'features/sme/companies/company/proposals/edit/benefits/create/disability';
import { CreateEmployersPension } from 'features/sme/companies/company/proposals/edit/benefits/create/employers-pension';
import { CreateHealthcare } from 'features/sme/companies/company/proposals/edit/benefits/create/healthcare';
import { CreateLife } from 'features/sme/companies/company/proposals/edit/benefits/create/life';
import { CreateOccupationalPension } from 'features/sme/companies/company/proposals/edit/benefits/create/occupational-pension';
import { CreateSalaryExchange } from 'features/sme/companies/company/proposals/edit/benefits/create/salary-exchange';
import { CreateTgl } from 'features/sme/companies/company/proposals/edit/benefits/create/tgl';
import {
  smeBenefitTypesMessages,
  smeCreateBenefitTypesMessages,
} from 'features/sme/messages/sme';
import React from 'react';
import { matchPath, Route, RouteComponentProps, Switch } from 'react-router';

import { MatchParams as SmeProposalMatchParams } from '../..';

type UrlSegment = string;

export const benefitUrls: Record<ProposalBenefitType, UrlSegment> = {
  OCCUPATIONAL_PENSION: 'occupational-pension',
  EMPLOYERS_PENSION: 'employers-pension',
  SALARY_EXCHANGE: 'salary-exchange',
  RISK_SICKNESS: 'disability-insurance',
  RISK_ACCIDENT: 'accident-insurance',
  RISK_TGL: 'tgl-insurance',
  RISK_LIFE: 'life-insurance',
  RISK_HEALTHCARE: 'healthcare-insurance',
};

export interface MatchParams extends SmeProposalMatchParams {
  benefitType: UrlSegment;
}

export const SmeCompanyProposalBenefitsCreateRoutes: React.FC<
  RouteComponentProps<SmeProposalMatchParams>
> = ({ match: { params, path, url }, location: { pathname } }) => {
  const parentLink = `/sme/${params.companyId}/proposals/${params.proposalId}/benefits`;
  const { formatMessage } = useIntl();

  const benefitTypeMatch = matchPath<MatchParams>(pathname, {
    path: `${path}/:benefitType`,
    exact: true,
    strict: false,
  });

  const benefitTypeUrlSegment = benefitTypeMatch?.params.benefitType;

  const benefitType = Object.keys(benefitUrls).find(
    _benefitType => benefitUrls[_benefitType] === benefitTypeUrlSegment,
  );

  if (!benefitType) {
    return <EmptyStatePage parentLink={parentLink} />;
  }

  return (
    <NavigationAnchor
      path={url}
      title={formatMessage({
        select: benefitType,
        messages: smeCreateBenefitTypesMessages,
      })}
    >
      <Page
        title={
          <FormattedMessage
            select={benefitType}
            messages={smeBenefitTypesMessages}
          />
        }
        parentLink={parentLink}
      >
        <ContentContainer>
          <Switch>
            <Route
              exact
              path={`${path}/accident-insurance`}
              component={CreateAccident}
            />
            <Route
              exact
              path={`${path}/disability-insurance`}
              component={CreateDisability}
            />
            <Route
              exact
              path={`${path}/employers-pension`}
              component={CreateEmployersPension}
            />
            <Route
              exact
              path={`${path}/healthcare-insurance`}
              component={CreateHealthcare}
            />
            <Route
              exact
              path={`${path}/life-insurance`}
              component={CreateLife}
            />
            <Route
              exact
              path={`${path}/occupational-pension`}
              component={CreateOccupationalPension}
            />
            <Route
              exact
              path={`${path}/salary-exchange`}
              component={CreateSalaryExchange}
            />
            <Route exact path={`${path}/tgl-insurance`} component={CreateTgl} />
          </Switch>
        </ContentContainer>
      </Page>
    </NavigationAnchor>
  );
};
