import {
  employersPensionBenefitConfigWithFixedPremium,
  occupationalPensionBenefitConfigWithFixedPremium,
  ProposalBenefitType,
  ProposalPremiumCalculationMethod,
} from 'app/apollo/graphql/types';

import { isPensionBenefit } from '../../../compilation/benefit-packages-details/BenefitsTable/utils';

interface Configuration {
  __typename: string;
}

interface ProposalBenefit {
  configuration: Configuration;
  id: string;
  type: ProposalBenefitType;
}

export interface BenefitPackage {
  benefits: readonly ProposalBenefit[];
  id: string;
  name: string;
}
type PensionConfigWithFixedPremium =
  | occupationalPensionBenefitConfigWithFixedPremium
  | employersPensionBenefitConfigWithFixedPremium;

const isPensionConfigWithFixedPremium = (
  config: Configuration,
): config is PensionConfigWithFixedPremium =>
  [
    'ProposalEmployersPensionBenefitConfig',
    'ProposalOccupationalPensionBenefitConfig',
  ].includes(config.__typename);

/**
 *  Checks whether the provided benefit package contains a occupational/employers
 *  pension benefit and whether the benefit is configured with a fixed premium.
 *
 *  @param benefitPackage - A benefit package containing one or more benefits.
 */
export const hasFixedPremiumPensionConfig = (
  benefitPackage: BenefitPackage,
) => {
  const pensionBenefit = benefitPackage?.benefits.find(benefit =>
    isPensionBenefit(benefit),
  );

  if (
    !pensionBenefit ||
    !isPensionConfigWithFixedPremium(pensionBenefit.configuration)
  ) {
    return false;
  }

  const {
    configuration: { extraFixedPremium, premiumCalculationMethod },
  } = pensionBenefit;

  return (
    extraFixedPremium ||
    premiumCalculationMethod ===
      ProposalPremiumCalculationMethod.FIXED_PREMIUM_ONLY
  );
};
