import { ContentContainer } from '@frontend/ui';
import { add } from '@frontend/ui/icons';
import {
  companiesQuery,
  companiesQueryVariables,
} from 'app/apollo/graphql/types';
import { companyMessages } from 'app/messages/company';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { Page } from 'features/page';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router';

import { smeCompanyMessages } from '../sme/messages/sme';
import { CompaniesTable } from './components/CompaniesTable';
import { COMPANIES_QUERY } from './graphql/queries';
import { updateCompaniesQuery } from './utils/update-companies-query';

export const Companies: React.FC<RouteComponentProps> = ({ match }) => {
  const location = useLocation();

  const { search, per_page } = qs.parse(location.search);

  const {
    previousData,
    data: _data,
    loading: companiesLoading,
    error: companiesError,
    fetchMore,
  } = useQuery<companiesQuery, companiesQueryVariables>(COMPANIES_QUERY, {
    variables: {
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
      search,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const data = _data ?? previousData;
  const pageInfo = data?.companies?.pageInfo;
  const companies = data?.companies;

  return (
    <Page
      title={<FormattedMessage {...companyMessages.companies} />}
      actions={[
        {
          text: <FormattedMessage {...smeCompanyMessages.createNewCompany} />,
          leadingIcon: add,
          to: `${match.url}/create`,
        },
      ]}
    >
      <ContentContainer>
        {companiesLoading && <TopLoading />}
        {companiesError && <GraphQlError error={companiesError} />}
        {companies && pageInfo && (
          <CompaniesTable
            pageInfo={pageInfo}
            onNextPage={() => {
              fetchMore({
                variables: {
                  after: pageInfo.endCursor,
                },
                updateQuery: updateCompaniesQuery,
              });
            }}
            onPreviousPage={() => {
              fetchMore({
                variables: {
                  before: pageInfo.startCursor,
                  first: undefined,
                  last: per_page
                    ? parseInt(per_page, 10)
                    : DEFAULT_RESULT_PER_PAGE,
                },
                updateQuery: updateCompaniesQuery,
              });
            }}
            companies={companies.edges.map(e => e.node)}
          />
        )}
      </ContentContainer>
    </Page>
  );
};
