import { ContentContainer } from '@frontend/ui';
import { employeeFormMessages, employeeMessages } from 'app/messages/employees';
import { menuMessages } from 'app/messages/menu';
import { FormattedMessage } from 'components/formats';
import { RoutedTab } from 'components/RoutedTabBar';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { EmployeesOverview } from 'features/companies/company/employees';
import { workCapabilityMessages } from 'features/companies/company/employees/assert-work-capability/messages';
import { Page } from 'features/page';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { AssertWorkCapability } from './assert-work-capability';
import { EmployeeRoutes } from './employee';
import { Add } from './employee/add';
import { ImportRoutes } from './import';
import { ImportsRoute } from './imports';

interface Tab extends RoutedTab {
  path: string;
}

export const Employees: React.FC<RouteComponentProps> = ({ match }) => {
  const tabs: Tab[] = [
    {
      title: menuMessages.overview,
      path: match.path,
      url: match.url,
    },
    {
      title: menuMessages.import,
      path: `${match.path}/imports`,
      url: `${match.url}/imports`,
    },
  ];
  return (
    <NavigationAnchor title={employeeMessages.employees} path={match.url}>
      <Switch>
        <Route exact path={tabs.map(({ path }) => path)}>
          <Page
            title={<FormattedMessage {...employeeMessages.employees} />}
            tabs={tabs}
          >
            <ContentContainer>
              <Switch>
                <Route exact path={match.path} component={EmployeesOverview} />
                <Route
                  path={`${match.path}/imports`}
                  component={ImportsRoute}
                />
              </Switch>
            </ContentContainer>
          </Page>
        </Route>
        <Route path={`${match.path}/import`} component={ImportRoutes} />
        <NavigationAnchor
          title={workCapabilityMessages.confirmFullWorkCapability}
          path={`${match.url}/assert-work-capability`}
        >
          <Route
            path={`${match.path}/assert-work-capability`}
            component={AssertWorkCapability}
          />
        </NavigationAnchor>
        <NavigationAnchor
          title={employeeFormMessages.addEmployee}
          path={`${match.url}/add`}
        >
          <Route path={`${match.path}/add`} component={Add} />
        </NavigationAnchor>
        <Route
          path={`${match.path}/:userAccountId`}
          component={EmployeeRoutes}
        />
      </Switch>
    </NavigationAnchor>
  );
};
