import { commonMessages } from 'app/messages/common';
import { hasBackstagePermission } from 'app/utils/has-backstage-access';
import { useCurrentUser } from 'contexts/current-permissions';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { Companies } from 'features/companies';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import { CompanyRoutes } from './company';
import { CreateCompanyRoute } from './create';

export const CompaniesRoute: React.FC<RouteComponentProps> = ({ match }) => {
  const { permissions } = useCurrentUser();

  if (!hasBackstagePermission('backstageApp', 'companiesTab', permissions)) {
    return <Redirect to="/" />;
  }

  return (
    <NavigationAnchor title={commonMessages.company} path="/companies">
      <Switch>
        <Route path={`${match.url}/create`} component={CreateCompanyRoute} />
        <Route path={`${match.url}/:companyId`} component={CompanyRoutes} />
        <Route exact path={match.url} component={Companies} />
      </Switch>
    </NavigationAnchor>
  );
};
