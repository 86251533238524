import { CheckboxField, Td, Tr } from '@frontend/ui';
import { OpenAddHeldMeetingModal } from 'app/pages/advisor';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { NoValue } from 'components/NoValue';
import { RowOptions } from 'features/advisor/components/RowOptions';
import React from 'react';

import { PensionTransferEmployee } from '../..';

interface Props {
  checked: boolean;
  employee: PensionTransferEmployee;
  id: string;
  onCheckboxChange: (checked: boolean) => void;
  openAddHeldMeetingModal: OpenAddHeldMeetingModal;
  sendInvitation: () => void;
}

export const PensionTransferEmployeeTableRow: React.FC<Props> = ({
  id,
  employee,
  sendInvitation,
  openAddHeldMeetingModal,
  checked,
  onCheckboxChange,
}) => (
  <Tr
    inlineMenu={
      <RowOptions
        employee={employee}
        sendInvitation={sendInvitation}
        openAddHeldMeetingModal={openAddHeldMeetingModal}
      />
    }
  >
    <Td type="input">
      <CheckboxField id={id} checked={checked} onChange={onCheckboxChange} />
    </Td>
    <Td align="left" type="number">
      <FormattedNaturalPersonIdentifier
        nids={employee.membership.naturalPersonIdentifiers}
      />
    </Td>
    <Td align="left">
      {`${employee.membership.givenName} ${employee.membership.lastName}`}
    </Td>
    <Td>{employee.membership.company.displayName}</Td>
    <Td align="right" type="number">
      {employee.capital ?? <NoValue />}
    </Td>
    <Td align="right" type="number">
      {employee.latestEmailSentAt ?? <NoValue />}
    </Td>
  </Tr>
);
