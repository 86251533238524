import { Icon, Table, TableSortButton, Td, Th, Tr } from '@frontend/ui';
import { _delete as deleteIcon, edit } from '@frontend/ui/icons';
import { smeCompanyEmployeesQuery_proposal_Proposal_memberships_ProposalMembership as Employee } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { useTableSort } from 'app/utils/use-table-sort';
import {
  FormattedCurrency,
  FormattedMessage,
  useIntl,
} from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import { NoValue } from 'components/NoValue';
import { useConfirm } from 'contexts/confirmation';
import { smeEmployeesMessages } from 'features/sme/messages/sme';
import React from 'react';

import { useProposalReadonly } from '../../../utils/use-proposal-readonly';
import { by, SortableColumns } from '../utils';

interface Props {
  employees: readonly Employee[];
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  onEmployeeClick: (id: string) => void;
}

export const EmployeesTable: React.FC<Props> = ({
  employees,
  onDelete,
  onEdit,
  onEmployeeClick,
}) => {
  const { formatMessage } = useIntl();
  const { handleSort, order, orderBy } = useTableSort<SortableColumns>();
  const disabled = useProposalReadonly();
  const { confirm } = useConfirm();

  const sortedEmployees =
    order && orderBy ? [...employees].sort(by({ order, orderBy })) : employees;

  return (
    <Table size="small">
      <thead>
        <Tr withInlineMenuTh>
          <Th>
            <TableSortButton
              onClick={handleSort}
              order={orderBy === 'nid' ? order : undefined}
              value="nid"
            >
              <FormattedMessage {...commonMessages.nid} />
            </TableSortButton>
          </Th>
          <Th>
            <TableSortButton
              onClick={handleSort}
              order={orderBy === 'firstName' ? order : undefined}
              value="firstName"
            >
              <FormattedMessage {...commonMessages.name} />
            </TableSortButton>
          </Th>
          <Th>
            <TableSortButton
              onClick={handleSort}
              order={orderBy === 'benefitPackage' ? order : undefined}
              value="benefitPackage"
            >
              <FormattedMessage {...commonMessages.benefitPackage} />
            </TableSortButton>
          </Th>
          <Th type="number">
            <FormattedMessage {...commonMessages.monthlySalary} />
          </Th>
          <Th>
            <FormattedMessage {...smeEmployeesMessages.isOwner} />
          </Th>
          <Th align="left">
            <FormattedMessage {...smeEmployeesMessages.isFcfw} />
          </Th>
        </Tr>
      </thead>
      <tbody>
        {sortedEmployees.map(employee => (
          <Tr
            inlineMenu={
              <KebabMenu inClickableArea fixed disabled={disabled}>
                <MenuItem
                  title={
                    <FormattedMessage {...smeEmployeesMessages.editLink} />
                  }
                  icon={<Icon icon={edit} />}
                  onClick={() => onEdit(employee.id)}
                />
                <MenuItem
                  title={
                    <FormattedMessage
                      {...smeEmployeesMessages.deleteEmployee}
                    />
                  }
                  icon={<Icon icon={deleteIcon} />}
                  onClick={async () => {
                    const confirmed = await confirm({
                      description: formatMessage(
                        smeEmployeesMessages.confirmDeleteEmployee,
                      ),
                    });

                    if (confirmed) {
                      onDelete(employee.id);
                    }
                  }}
                />
              </KebabMenu>
            }
            linked
            onClick={() => onEmployeeClick(employee.id)}
          >
            <Td>
              <FormattedNaturalPersonIdentifier
                nid={employee.personalIdentityNumber}
              />
            </Td>
            <Td>{`${employee.firstName} ${employee.lastName}`}</Td>
            <Td>{employee.benefitPackage.name}</Td>
            <Td type="number">
              {employee.monthlySalary ? (
                <FormattedCurrency
                  value={Number(employee.monthlySalary)}
                  currency="SEK"
                />
              ) : (
                <NoValue />
              )}
            </Td>
            <Td>
              <FormattedMessage
                {...(employee.isOwner ? commonMessages.yes : commonMessages.no)}
              />
            </Td>
            <Td>
              {employee.isFcfw != null ? (
                <FormattedMessage
                  {...(employee.isFcfw
                    ? commonMessages.yes
                    : commonMessages.no)}
                />
              ) : (
                <NoValue />
              )}
            </Td>
          </Tr>
        ))}
      </tbody>
    </Table>
  );
};
