import { PremiumMatrix } from '@frontend/benefit-types';

type ItpPremiumMatrix = Required<NonNullable<PremiumMatrix>>;

export const ITP_PREMIUM_MATRIX: ItpPremiumMatrix = {
  age: [0, 25, 66],
  baseAmount: ['0', '7.5', '30.00'],
  baseAmountType: 'IBB',
  intervals: [
    ['0', '0', '0'],
    ['4.50', '30.00', '0'],
    ['0', '0', '0'],
  ],
};

export enum PensionBenefitType {
  INDIVIDUAL = 'INDIVIDUAL',
  ITP = 'ITP',
  PREMIUM_MATRIX = 'PREMIUM_MATRIX',
}
