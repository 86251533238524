import { MembershipRemunerationItemInput } from 'app/apollo/graphql/types';
import { afterFadeout } from 'components/Modal';
import { RemunerationsTable } from 'features/companies/company/employees/employee/remunerations/table';
import { fields } from 'features/companies/company/employees/employee/remunerations/update';
import { useFormikContext } from 'formik';
import React from 'react';

import { CompensationDataFormValues } from '../CompensationFields';

export const Remunerations: React.FC = () => {
  const {
    values: { remunerations, remunerationModalData },
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<CompensationDataFormValues>();

  const notModalItem = (remuneration: MembershipRemunerationItemInput) =>
    remuneration.itemTypeId !== remunerationModalData?.payrollElementId ||
    remuneration.from !== remunerationModalData.effectiveDate;

  const submitModal = (onRequestClose: () => void) => () => {
    /* Display field errors as if this was an actual form submission */
    fields.forEach(field => setFieldTouched(`remunerationModalData.${field}`));
    try {
      if (
        !remunerationModalData?.payrollElementId ||
        !remunerationModalData?.effectiveDate ||
        !remunerationModalData?.value
      ) {
        throw new Error();
      }
      setFieldValue('remunerations', [
        ...remunerations.filter(notModalItem),
        {
          itemTypeId: remunerationModalData.payrollElementId,
          value: remunerationModalData.value,
          from: remunerationModalData.effectiveDate,
        },
      ]);
      onRequestClose();
      afterFadeout(() => {
        setFieldValue('remunerationModalData', null);
        fields.forEach(field =>
          setFieldTouched(`remunerationModalData.${field}`, false),
        );
      });
    } catch {
      // Do nothing
    }
  };

  const onClose = () =>
    afterFadeout(() => {
      setFieldValue('remunerationModalData', null);
    });

  const deleteItem = (id: string, from: string) =>
    setFieldValue(
      'remunerations',
      remunerations.filter(
        item => item.itemTypeId !== id || item.from !== from,
      ),
    );

  return (
    <RemunerationsTable
      isAddEmployeeSubform
      parentField="remunerationModalData"
      remunerations={remunerations}
      onSubmit={submitModal}
      deleteItem={deleteItem}
      onClose={onClose}
    />
  );
};
