import { ApolloError, useMutation } from '@apollo/client';
import {
  UpdateMembershipBenefitPackageInput,
  updateMembershipBenefitPackageMutation,
  updateMembershipBenefitPackageMutationVariables,
} from 'app/apollo/graphql/types';
import { employeeFormMessages } from 'app/messages/employees';
import { formSubmitMessages } from 'app/messages/form';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useIntl } from 'components/formats';
import { useConfirm } from 'contexts/confirmation';
import { useNotification } from 'features/notifications';
import { FormikHelpers } from 'formik';
import { useRouteMatch } from 'react-router';

import { MEMBERSHIP_BENEFIT_PACKAGE_QUERY } from '../../graphql/queries';
import { FormValues } from '..';
import { UPDATE_MEMBERSHIP_BENEFIT_PACKAGE } from './graphql/mutations';

interface Option {
  label: string;
  value: string;
}

interface Props {
  benefitPackageOptions: Option[];
  onCompleted: () => void;
}

interface Submit {
  submit: (values: FormValues, helpers: FormikHelpers<FormValues>) => void;
  submissionError?: ApolloError;
}

export const useSubmit = ({
  benefitPackageOptions,
  onCompleted,
}: Props): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();
  const {
    params: { companyId, userAccountId },
  } = useRouteMatch<EmployeeRouteMatchParams>();
  const { confirm } = useConfirm();

  const [updateMembershipBenefitPackage, { error: submissionError }] =
    useMutation<
      updateMembershipBenefitPackageMutation,
      updateMembershipBenefitPackageMutationVariables
    >(UPDATE_MEMBERSHIP_BENEFIT_PACKAGE, {
      refetchQueries: [
        {
          query: MEMBERSHIP_BENEFIT_PACKAGE_QUERY,
          variables: {
            userAccountId,
            companyId,
          },
        },
      ],
    });

  const submit = async (values: FormValues) => {
    const { benefitPackageId, effectiveDate } = values;

    const backageName = benefitPackageOptions.find(
      ({ value }) => values.benefitPackageId === value,
    )?.label;

    try {
      if (!backageName) {
        throw new Error();
      }

      const confirmed = await confirm({
        title: formatMessage(employeeFormMessages.confirmSwitchBackageTitle),
        description: formatMessage(employeeFormMessages.confirmSwitchBackage, {
          backageName,
          effectiveDate,
        }),
      });

      if (!confirmed) {
        return;
      }

      const input: UpdateMembershipBenefitPackageInput = {
        userAccountId,
        companyId,
        benefitPackageId,
        effectiveDate,
      };

      const res = await updateMembershipBenefitPackage({
        variables: { input },
      });

      if (!res.data) {
        throw new Error();
      }

      send({
        message: formatMessage(
          formSubmitMessages.editEmployeeBenefitPackagesSubmitSuccess,
        ),
        type: 'success',
      });

      onCompleted();
    } catch {
      // Do nothing
    }
  };

  return { submit, submissionError };
};
