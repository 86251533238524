import gql from 'graphql-tag';

export const UPDATE_PROPOSAL_QUERY = gql`
  query updateProposalQuery($id: ID!, $skipAdviceData: Boolean!) {
    proposal(id: $id) {
      id
      companySize
      fees {
        annualFeeFund
        annualFeePortfolio
        annualFeeTraditional
        capitalFeeFund
        capitalFeePortfolio
        capitalFeeTraditional
        fundDiscountPermitted
        fundDiscountValue
        provisionWaiverAllowed
        provisionWaiverHealthcare
        provisionWaiverPremiumWaiver
        provisionWaiverSickness
        riskBucket
      }
      need @skip(if: $skipAdviceData) {
        collectiveAgreementBlueCollar
        collectiveAgreementWhiteCollar
        documentationProvided
        text
      }
      productKnowledge @skip(if: $skipAdviceData) {
        adviceSavingsInsurance
        recentExperiencePension
        professionalExperiencePension
        pensionInstrumentKnowledge
        notes
      }
      summary @skip(if: $skipAdviceData) {
        checkNeed
        checkExistingCoverage
        checkPlannedDevelopment
        checkDeductionSpace
        checkFinancialSituation
        format
        text
      }
      validity {
        validProposal
        validNeed
        validProductKnowledge
        validBenefits
        validEmployees
        validFees
        validCompilation
        validSummary
      }
    }
  }
`;
