import gql from 'graphql-tag';

export const PERMISSIONS_QUERY = gql`
  query permissionsQuery {
    permissions
    adminViewer {
      firstName
    }
  }
`;

export const EVENT_TYPES_QUERY = gql`
  query eventTypesQuery {
    eventTypes {
      uri
      name
    }
  }
`;

export const COMPANY_LAYOUT_QUERY = gql`
  query companyLayoutQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      name
      displayName
    }
  }
`;

export const BENEFIT_PACKAGE_LAYOUT_QUERY = gql`
  query benefitPackageLayoutQuery($id: ID!) {
    benefitPackage(id: $id) {
      id
      name
    }
  }
`;

export const COMPANY_EMPLOYEES_IMPORT_LAYOUT_QUERY = gql`
  query companyEmployeesImportLayoutQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      name
      registrationNumber
    }
  }
`;

export const SME_COMPANIES_LAYOUT_QUERY = gql`
  query smeCompaniesLayoutQuery {
    adminViewer {
      id
    }
    nlpSalesReps {
      id
      externalId
      firstName
      lastName
    }
  }
`;

export const SME_COMPANY_LAYOUT_QUERY = gql`
  query smeCompanyLayoutQuery($id: ID!) {
    company(id: $id) {
      id
      name
    }
  }
`;

export const SME_COMPANY_PROPOSAL_LAYOUT_QUERY = gql`
  query smeCompanyProposalLayoutQuery($id: ID!) {
    proposal(id: $id) {
      id
      adviceWaived
      name
    }
  }
`;

export const SME_COMPANY_PROPOSAL_EMPLOYEE_LAYOUT_QUERY = gql`
  query smeCompanyProposalEmployeeLayoutQuery($id: ID!) {
    proposalMembership(id: $id) {
      id
      firstName
      lastName
    }
  }
`;

export const SME_COMPANY_PROPOSAL_BENEFIT_LAYOUT_QUERY = gql`
  query smeCompanyProposalBenefitLayoutQuery(
    $proposalId: ID!
    $benefitId: ID!
  ) {
    proposal(id: $proposalId) {
      benefit(id: $benefitId) {
        id
        configuration {
          __typename
        }
        type
      }
    }
  }
`;
