import { BaseAmountType } from '@frontend/benefit-types';
import BigNumber from 'bignumber.js';

import { ITP_PREMIUM_MATRIX, PensionBenefitType } from './constants';

interface PremiumMatrix {
  age: readonly number[];
  baseAmount: readonly string[];
  baseAmountType: 'IBB' | 'PBB';
  intervals: readonly (readonly string[])[];
}

const formatPremiumMatrixInput = (params: {
  age: number[];
  baseAmount: string[];
  baseAmountType: BaseAmountType;
  intervals: string[][];
}): NonNullable<PremiumMatrix> => {
  const { age, baseAmount, baseAmountType, intervals } = params;
  return {
    age: age.map(a => Number(a)),
    baseAmount: baseAmount.map(b =>
      new BigNumber(b.replace(',', '.')).toFixed(4),
    ),
    baseAmountType,
    intervals: intervals.map(interval =>
      interval.map(i => {
        const value = i || '0,00';
        return new BigNumber(value.replace(',', '.')).dividedBy(100).toFixed(4);
      }),
    ),
  };
};

export const isMatchingStringNumbers = (num1, num2) =>
  Number(num1) === Number(num2);

export const isMatchingNumberLists = (list1, list2) => {
  if (list1.length !== list2.length) {
    return false;
  }

  let isMatching = true;
  list1.forEach((elem, index) => {
    if (!isMatchingStringNumbers(elem, list2[index])) {
      isMatching = false;
    }
  });
  return isMatching;
};

export const isMatchingIntervalsLists = (list1, list2) => {
  if (list1.length !== list2.length) {
    return false;
  }

  let isMatching = true;
  list1.forEach((elem, index) => {
    if (!isMatchingNumberLists(elem, list2[index])) {
      isMatching = false;
    }
  });

  return isMatching;
};

export const getPensionBenefitType = (premiumMatrix: PremiumMatrix | null) => {
  if (!premiumMatrix) {
    return PensionBenefitType.INDIVIDUAL;
  }

  const { age, baseAmount, baseAmountType, intervals } = ITP_PREMIUM_MATRIX;
  const itpPremimMatrix = formatPremiumMatrixInput({
    age,
    baseAmount,
    baseAmountType,
    intervals,
  });

  if (
    isMatchingNumberLists(itpPremimMatrix.age, premiumMatrix.age) &&
    isMatchingNumberLists(
      itpPremimMatrix.baseAmount,
      premiumMatrix.baseAmount,
    ) &&
    isMatchingIntervalsLists(
      itpPremimMatrix.intervals,
      premiumMatrix.intervals,
    ) &&
    itpPremimMatrix.baseAmountType === premiumMatrix.baseAmountType
  ) {
    return PensionBenefitType.ITP;
  }
  return PensionBenefitType.PREMIUM_MATRIX;
};
