import { ApolloError } from '@apollo/client';
import {
  CheckboxField,
  DatePickerField,
  NumberField,
  SelectField,
  TextField,
} from '@frontend/formik';
import {
  Button,
  Grid,
  WizardContentCard,
  WizardSection,
  WizardSectionHeader,
} from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell50 } from 'components/GridCell';
import { SimpleWizard } from 'components/SimpleWizard';
import { Form, useFormikContext } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';

import {
  CompanySize,
  EAGruppAgreementType,
  Language,
  NLPFundFeeDiscountType,
} from '../../../../app/apollo/graphql/types';
import { companyMessages } from '../../messages';
import { FormValues } from '..';

interface Props {
  values: FormValues;
  submissionError?: ApolloError;
}

export const CreateCompanyForm: React.FC<Props> = ({
  submissionError,
  values,
}) => {
  const { formatMessage } = useIntl();
  const { isSubmitting, isValid } = useFormikContext<FormValues>();
  const history = useHistory();

  const handleClose = () => history.push('/companies');

  const withForm = (content: React.ReactNode) => <Form>{content}</Form>;

  return (
    <SimpleWizard
      actions={
        <>
          <Button text onClick={handleClose}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
          <Button
            filled
            type="submit"
            disabled={!isValid}
            loading={isSubmitting}
          >
            <FormattedMessage {...companyMessages.addCompany} />
          </Button>
        </>
      }
      offWhite
      parentLink="/companies"
      title={<FormattedMessage {...companyMessages.addCompany} />}
      withForm={withForm}
    >
      <WizardContentCard
        header={<FormattedMessage {...companyMessages.customerInformation} />}
      >
        <TextField
          label={formatMessage(companyMessages.registrationNumber)}
          name="registrationNumber"
          dense
          required
        />
        <DatePickerField
          label={formatMessage(companyMessages.startDate)}
          name="startDate"
          dense
          required
          value={values.startDate}
        />
        <SelectField
          label={formatMessage(companyMessages.companyLanguage)}
          name="language"
          dense
          required
          options={Object.keys(Language).map(k => ({
            value: k,
            label: k,
          }))}
        />
        <WizardSection>
          <WizardSectionHeader>
            <FormattedMessage {...companyMessages.administrator} />
          </WizardSectionHeader>
          <CheckboxField
            label={formatMessage(companyMessages.isOwner)}
            name="isOwner"
          />
          <Grid>
            <GridCell50>
              <TextField
                label={formatMessage(companyMessages.firstName)}
                name="firstName"
                dense
                required
              />
            </GridCell50>
            <GridCell50>
              <TextField
                label={formatMessage(companyMessages.lastName)}
                name="lastName"
                dense
                required
              />
            </GridCell50>
          </Grid>
          <TextField
            label={formatMessage(companyMessages.pid)}
            name="pid"
            dense
            required
          />
          <TextField
            label={formatMessage(companyMessages.email)}
            name="email"
            dense
          />
          <TextField
            label={formatMessage(companyMessages.phone)}
            name="phone"
            dense
          />
        </WizardSection>
        <WizardSection>
          <WizardSectionHeader>
            <FormattedMessage {...companyMessages.nodeAdmin} />
          </WizardSectionHeader>
          <TextField
            label={formatMessage(companyMessages.pid)}
            name="nodeAdminPid"
            dense
          />
        </WizardSection>
        <WizardSection>
          <WizardSectionHeader>
            <FormattedMessage {...companyMessages.nordeaAgreement} />
          </WizardSectionHeader>
          <CheckboxField
            label={formatMessage(companyMessages.createNordea)}
            name="createNordea"
            checked={values.createNordea}
          />
          <TextField
            label={formatMessage(companyMessages.nordeaSalesRep)}
            name="nordeaSalesRep"
            dense
            required
            disabled={!values.createNordea}
          />
          <SelectField
            label={formatMessage(companyMessages.nordeaFundFeeDiscountType)}
            name="nordeaFundFeeDiscountType"
            dense
            options={Object.keys(NLPFundFeeDiscountType).map(k => ({
              value: k,
              label: k,
            }))}
            disabled={!values.createNordea}
          />
          <DatePickerField
            label={formatMessage(companyMessages.nordeaStartDate)}
            name="nordeaStartDate"
            dense
            required
            disabled={!values.createNordea}
          />
          <NumberField
            dense
            required
            disabled={!values.createNordea}
            decimalScale={4}
            name="nordeaCapitalFeeFund"
            label={formatMessage(companyMessages.nordeaCapitalFeeFund)}
          />
          <NumberField
            dense
            required
            disabled={!values.createNordea}
            decimalScale={4}
            name="nordeaAnnualFeeFund"
            label={formatMessage(companyMessages.nordeaAnnualFeeFund)}
          />
          <NumberField
            dense
            required
            disabled={!values.createNordea}
            decimalScale={4}
            name="nordeaCapitalFeeGuarantee"
            label={formatMessage(companyMessages.nordeaCapitalFeeGuarantee)}
          />
          <NumberField
            dense
            required
            disabled={!values.createNordea}
            decimalScale={4}
            name="nordeaAnnualFeeGuarantee"
            label={formatMessage(companyMessages.nordeaAnnualFeeGuarantee)}
          />
        </WizardSection>
        <WizardSection>
          <WizardSectionHeader>
            <FormattedMessage {...companyMessages.eaAgreement} />
          </WizardSectionHeader>
          <CheckboxField
            label={formatMessage(companyMessages.createEaGrupp)}
            name="createEaGrupp"
            checked={values.createEaGrupp}
          />
          <CheckboxField
            label={formatMessage(companyMessages.createEaTjp)}
            name="createEaTjp"
            checked={values.createEaTjp}
          />
          <SelectField
            label={formatMessage(companyMessages.companySize)}
            name="eaCompanySize"
            options={Object.keys(CompanySize).map(k => ({
              value: k,
              label: k,
            }))}
            required
            dense
            disabled={!values.createEaGrupp && !values.createEaTjp}
          />
          <SelectField
            label={formatMessage(companyMessages.eaGruppAgreementType)}
            name="eaGruppAgreementType"
            options={Object.keys(EAGruppAgreementType).map(k => ({
              value: k,
              label: k,
            }))}
            required
            dense
            disabled={!values.createEaGrupp}
          />
          <CheckboxField
            label={formatMessage(companyMessages.eaExtendedFcfw)}
            name="eaExtendedFcfw"
            disabled={!values.createEaGrupp && !values.createEaTjp}
          />
          <DatePickerField
            label={formatMessage(companyMessages.eaStartDate)}
            name="eaStartDate"
            dense
            required
            disabled={!values.createEaGrupp && !values.createEaTjp}
          />
        </WizardSection>
        {submissionError && <GraphQlError error={submissionError} />}
      </WizardContentCard>
    </SimpleWizard>
  );
};
