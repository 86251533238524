import { SelectField, TextField } from '@frontend/formik';
import { CheckboxField, Grid as _Grid } from '@frontend/ui';
import {
  CompanyRepresentativeIdentificationType,
  CompanyRepresentativeRole,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GridCell50 } from 'components/GridCell';
import {
  smeRepresentativeIdentificationTypesMessages,
  smeRepresentativeRolesMessages,
  smeRepresentativesMessages,
} from 'features/sme/messages/sme';
import { useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { validateNaturalPersonIdentifier } from 'validations';
import * as Yup from 'yup';

const Grid = styled(_Grid)`
  width: 100%;
`;

const isIdentificationReferenceRequired = (
  identificationType: CompanyRepresentativeIdentificationType | '',
) => identificationType !== CompanyRepresentativeIdentificationType.BANKID;

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    firstName: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    identification: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    identificationReference: Yup.string().when('identification', {
      is: isIdentificationReferenceRequired,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    lastName: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    personalIdentityNumber: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'is valid pin',
        intl.formatMessage(validationMessages.invalidPin),
        value => !value || validateNaturalPersonIdentifier(value),
      ),
    proposalId: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    role: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface FormValues {
  firstName: string;
  identification: CompanyRepresentativeIdentificationType | '';
  identificationReference: string;
  lastName: string;
  personalIdentityNumber: string;
  role: CompanyRepresentativeRole | '';
}

interface ContactPerson {
  firstName: string;
  lastName: string;
  personalIdentityNumber: string;
}

interface Props {
  contactPerson?: ContactPerson;
}

export const RepresentativeFormFields: React.FC<Props> = ({
  contactPerson,
}) => {
  const { values, setValues } = useFormikContext<FormValues>();
  const [reuseContact, setReuseContact] = useState(false);
  const contactReusedRef = useRef(false);

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (reuseContact && contactPerson) {
      contactReusedRef.current = true;
      setValues({
        ...values,
        firstName: contactPerson.firstName,
        lastName: contactPerson.lastName,
        personalIdentityNumber: contactPerson.personalIdentityNumber,
        role: '',
        identification: '',
        identificationReference: '',
      });
    }
  }, [reuseContact]);

  useEffect(() => {
    if (contactReusedRef.current) {
      contactReusedRef.current = false;
      return;
    }
    setReuseContact(false);
  }, [values]);

  return (
    <>
      <CheckboxField
        checked={reuseContact}
        disabled={!contactPerson}
        label={
          <FormattedMessage
            {...smeRepresentativesMessages.sameContactAndRepresentative}
          />
        }
        onChange={checked => {
          setReuseContact(checked);
        }}
      />
      <Grid>
        <GridCell50>
          <TextField
            dense
            name="firstName"
            label={<FormattedMessage {...commonMessages.name} />}
            required
            withoutMargin
          />
        </GridCell50>
        <GridCell50>
          <TextField
            dense
            name="lastName"
            label={<FormattedMessage {...commonMessages.lastName} />}
            required
            withoutMargin
          />
        </GridCell50>
      </Grid>
      <TextField
        dense
        name="personalIdentityNumber"
        label={<FormattedMessage {...commonMessages.personalIdentityNumber} />}
        required
      />
      <SelectField
        dense
        name="role"
        fixed
        label={
          <FormattedMessage {...smeRepresentativesMessages.roleDescription} />
        }
        options={Object.values(CompanyRepresentativeRole).map(role => ({
          label: formatMessage({
            select: role,
            messages: smeRepresentativeRolesMessages,
          }),
          value: role,
        }))}
        required
      />
      <SelectField
        dense
        name="identification"
        fixed
        label={<FormattedMessage {...smeRepresentativesMessages.legitimized} />}
        options={Object.values(CompanyRepresentativeIdentificationType).map(
          type => ({
            label: formatMessage({
              select: type,
              messages: smeRepresentativeIdentificationTypesMessages,
            }),
            value: type,
          }),
        )}
        required
      />
      <TextField
        dense
        name="identificationReference"
        label={<FormattedMessage {...smeRepresentativesMessages.identifier} />}
        required={isIdentificationReferenceRequired(values.identification)}
      />
    </>
  );
};
