import {
  bodySmallCSS,
  Button,
  ButtonLayout,
  Icon,
  Loading,
  Text,
} from '@frontend/ui';
import { addPhotoAlternative } from '@frontend/ui/icons';
import { commonMessages } from 'app/messages/common';
import { imageUploadMessages } from 'app/messages/image-upload';
import { useUploadFiles } from 'app/utils/use-upload-files';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { Dropzone, Size } from 'components/Dropzone';
import { FormattedMessage, useIntl } from 'components/formats';
import React, { useId, useState } from 'react';
import styled from 'styled-components';

export type ImageType = 'avatar' | 'hero' | 'logotype';

interface HelperTextProps {
  error?: boolean;
}

export const HelperText = styled.div<HelperTextProps>`
  ${bodySmallCSS};
  color: ${p => (p.error ? p.theme.error : p.theme.onSurfaceVariant)};
`;

interface PreviewProps {
  disabled?: boolean;
  error?: boolean;
  imageType?: ImageType;
  imageUrl?: string;
}

const DIMENSIONS: Record<ImageType, Size> = {
  logotype: { height: '12.25rem', width: '24.5rem' },
  avatar: { height: '13.5rem', width: '13.5rem' },
  hero: { height: '19.25rem', width: '38.5rem' },
};

export const Preview = styled.div<PreviewProps>`
  ${p => !p.disabled && 'cursor: pointer;'}
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border: 0.0625rem dashed ${p => (p.error ? p.theme.error : p.theme.outline)};
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  ${p => p.disabled && 'opacity: 0.5;'}
  position: relative;
  background-repeat: no-repeat;
  ${p =>
    p.imageUrl &&
    ` 
      background-image: url(${p.imageUrl});
      background-size: cover;
      background-position: center;
  `};
  ${p =>
    p.imageType === 'logotype' &&
    `
      background-size: contain;
  `}
`;

export const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ComplementaryContainer = styled.div<{ maxWidth: string }>`
  margin-top: 0.5rem;
  padding-bottom: 1.5rem;
  max-width: ${p => p.maxWidth};
`;

export interface Value {
  fallbackUrl?: string;
  previewUrl?: string;
  uploadId?: string | null;
}

interface Props {
  companyId: string;
  imageType: ImageType;
  label: (args: { isEdit: boolean }) => React.ReactNode;
  onBlur: () => void;
  onChange: (value: Value) => void;
  disabled?: boolean;
  errorText?: string;
  value?: Value;
}

export const ImageUpload: React.FC<Props> = ({
  imageType,
  label,
  onChange,
  onBlur,
  value,
  errorText: externalError,
  disabled,
  companyId,
}) => {
  const { formatMessage } = useIntl();
  const [dropzoneError, setDropzoneError] = useState<string>();
  const helperTextId = useId();

  const {
    upload,
    loading: uploadLoading,
    error: uploadError,
  } = useUploadFiles({
    companyId,
    onCompleted: files =>
      files?.[0].id &&
      onChange({
        ...value,
        uploadId: files[0].id,
        previewUrl: files[0].url,
      }),
  });

  const displayPlaceholder =
    !value?.previewUrl && !value?.fallbackUrl && !uploadLoading;
  const error = dropzoneError ?? uploadError ?? externalError;

  const onDropAccepted = (files: File[]) => {
    setDropzoneError(undefined);
    upload(files);
  };

  const onDropRejected = () =>
    setDropzoneError(formatMessage(imageUploadMessages.imageUploadError));

  const actions = (open: () => void) => (
    <ButtonLayout>
      <Button onClick={open} disabled={disabled || uploadLoading}>
        {label({ isEdit: !!value })}
      </Button>
      {!!value?.previewUrl && (
        <Button
          text
          danger
          onClick={() =>
            onChange({ ...value, uploadId: null, previewUrl: undefined })
          }
        >
          <FormattedMessage {...commonMessages.remove} />
        </Button>
      )}
    </ButtonLayout>
  );

  return (
    <>
      <DescriptionWrapper>
        <FormattedMessage {...imageUploadMessages.imageUploadDescription} />
      </DescriptionWrapper>
      <Dropzone
        disabled={disabled || uploadLoading}
        multiple={false}
        accept={{
          'image/jpeg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
          'image/svg+xml': ['.svg'],
        }}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
        onFileDialogCancel={onBlur}
        size={DIMENSIONS[imageType]}
        label={label({ isEdit: !!value })}
        aria-describedby={error ? helperTextId : undefined}
        complementary={
          <ComplementaryContainer maxWidth={DIMENSIONS[imageType].width}>
            {error && (
              <HelperText id={helperTextId} error>
                {error}
              </HelperText>
            )}
          </ComplementaryContainer>
        }
        actions={actions}
      >
        <Preview
          imageUrl={value?.previewUrl ?? value?.fallbackUrl}
          disabled={disabled}
          error={!!error}
          imageType={imageType}
        >
          {displayPlaceholder && (
            <Placeholder>
              <Icon
                color={error ? 'error' : 'onSurfaceVariant'}
                size="large"
                icon={addPhotoAlternative}
              />
              <Text
                use="bodyMedium"
                color={error ? 'error' : 'onSurface'}
                style={{ marginTop: '0.75rem' }}
              >
                <FormattedMessage {...imageUploadMessages.dragAndDrop} />
              </Text>
            </Placeholder>
          )}
          {uploadLoading && <Loading />}
        </Preview>
      </Dropzone>
    </>
  );
};
