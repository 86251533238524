import {
  Button,
  Section,
  SectionHeader,
  Table,
  Td,
  Th,
  Tr,
} from '@frontend/ui';
import { addCircle } from '@frontend/ui/icons';
import { toEffectiveThrough } from '@frontend/utils';
import {
  employmentActivitiesQuery,
  employmentActivitiesQueryVariables,
  employmentActivityDetails,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import {
  employmentMessages,
  employmentStatusMessages,
} from 'app/messages/employees';
import { EmploymentRouteMatchParams } from 'app/pages/companies/company/employees/employee/employments/employment';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage, FormattedPercent } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import { NoValue } from 'components/NoValue';
import React, { useState } from 'react';
import { FormattedDate } from 'react-intl';
import { useRouteMatch } from 'react-router';

import { AddEmploymentActivityModal } from './add';
import { EditEmploymentActivityModal } from './edit';
import { EMPLOYMENT_ACTIVITIES_QUERY } from './graphql/queries';

const isEmpty = (data?: employmentActivitiesQuery) =>
  !data?.employment?.activities;

export const EmploymentActivities: React.FC = () => {
  const {
    params: { employmentId },
  } = useRouteMatch<EmploymentRouteMatchParams>();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [editActivity, setEditActivity] = useState<
    employmentActivityDetails | undefined
  >();

  const { data } = useQuery<
    employmentActivitiesQuery,
    employmentActivitiesQueryVariables
  >(EMPLOYMENT_ACTIVITIES_QUERY, {
    variables: { id: employmentId },
    errorPolicy: 'all',
    suspend: true,
    isEmpty,
  });

  if (!data?.employment?.activities) {
    return null;
  }

  const { employment } = data;

  const activities =
    employment.activities?.edges?.map(({ node }) => node) || [];

  const employeeName = `${employment.membership.givenName} ${employment.membership.lastName}`;

  return (
    <>
      <AddEmploymentActivityModal
        employeeName={employeeName}
        isOpen={isModalOpen}
        onRequestClose={() => {
          setModalOpen(false);
        }}
      />
      {editActivity && (
        <EditEmploymentActivityModal
          activity={editActivity}
          employeeName={employeeName}
          isOpen
          onRequestClose={() => {
            setEditActivity(undefined);
          }}
        />
      )}
      {activities.length === 0 && (
        <NotificationCard
          title={
            <FormattedMessage {...employmentMessages.employmentActivity} />
          }
          actions={
            <Button
              onClick={() => {
                setModalOpen(true);
              }}
            >
              <FormattedMessage {...employmentMessages.addEmploymentActivity} />
            </Button>
          }
        >
          <FormattedMessage {...employmentMessages.noEmploymentActivities} />
        </NotificationCard>
      )}

      {activities.length > 0 && (
        <Section>
          <SectionHeader>
            <FormattedMessage {...employmentMessages.employmentActivity} />
          </SectionHeader>
          <ChipsWrapper>
            <AssistChip
              onClick={() => {
                setModalOpen(true);
              }}
              text={
                <FormattedMessage
                  {...employmentMessages.addEmploymentActivity}
                />
              }
              leadingIcon={addCircle}
            />
          </ChipsWrapper>
          <Table fixed size="small">
            <colgroup>
              <col style={{ width: '25%' }} />
              <col style={{ width: '25%' }} />
              <col style={{ width: '25%' }} />
              <col style={{ width: '25%' }} />
            </colgroup>
            <thead>
              <Tr>
                <Th>
                  <FormattedMessage {...commonMessages.startingAt} />
                </Th>
                <Th>
                  <FormattedMessage {...commonMessages.endingOn} />
                </Th>
                <Th>
                  <FormattedMessage {...commonMessages.extent} />
                </Th>
                <Th align="left">
                  <FormattedMessage
                    {...employmentMessages.employmentActivityType}
                  />
                </Th>
              </Tr>
            </thead>
            <tbody>
              {activities.map(activity => (
                <Tr
                  key={activity.id}
                  onClick={() => {
                    setEditActivity(activity);
                  }}
                  linked
                >
                  <Td>
                    <FormattedDate value={activity.period[0]} />
                  </Td>
                  <Td>
                    {activity.period[1] ? (
                      <FormattedDate
                        value={toEffectiveThrough(activity.period[1])}
                      />
                    ) : (
                      <NoValue />
                    )}
                  </Td>
                  <Td>
                    <FormattedPercent value={activity.rate} />
                  </Td>
                  <Td align="left">
                    <FormattedMessage
                      select={activity.type}
                      messages={employmentStatusMessages}
                    />
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </Section>
      )}
    </>
  );
};
