import gql from 'graphql-tag';

import { employmentActivityDetails } from '../../graphql/fragments';

export const DELETE_EMPLOYMENT_ACTIVITY_MUTATION = gql`
  mutation deleteEmploymentActivityMutation(
    $input: DeleteEmploymentActivityInput!
  ) {
    deleteEmploymentActivity(input: $input) {
      employmentActivity {
        ...employmentActivityDetails
      }
    }
  }
  ${employmentActivityDetails}
`;
