import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import {
  formatNaturalPersonIdentifier,
  stripSearchParams,
} from '@frontend/utils';
import {
  createRepresentativeModalQuery,
  createRepresentativeModalQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { MatchParams as ProposalMatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { TopLoading } from 'components/TopLoading';
import { smeRepresentativesMessages } from 'features/sme/messages/sme';
import { Form, Formik } from 'formik';
import qs from 'query-string';
import React from 'react';
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';

import {
  FormValues as FieldsFormValues,
  RepresentativeFormFields,
  validationSchema,
} from '../form';
import { CREATE_REPRESENTATIVE_MODAL_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

export interface FormValues extends FieldsFormValues {
  proposalId: string;
}

export const CreateRepresentativeModal = () => {
  const intl = useIntl();

  const history = useHistory();
  const location = useLocation();
  const { params } = useRouteMatch<ProposalMatchParams>();

  const { 'create-representative': createRepresentative } = qs.parse(
    location.search,
  );

  const onRequestClose = () => {
    stripSearchParams(history, location, ['create-representative']);
  };

  const { submit, submissionError } = useSubmit({ onRequestClose });

  const { data, loading, error } = useQuery<
    createRepresentativeModalQuery,
    createRepresentativeModalQueryVariables
  >(CREATE_REPRESENTATIVE_MODAL_QUERY, {
    errorPolicy: 'all',
    variables: { companyId: params.companyId },
  });

  if (createRepresentative && loading) {
    return <TopLoading />;
  }

  if (!data?.proposalContactPerson) {
    return (
      <Modal isOpen={createRepresentative} onRequestClose={onRequestClose}>
        <ModalHeader>
          <FormattedMessage
            {...smeRepresentativesMessages.createRepresentative}
          />
        </ModalHeader>
        <ModalBody>
          <GraphQlError error={error} inModal />
        </ModalBody>
        <ModalFooter>
          <ButtonLayout align="right">
            <Button text onClick={onRequestClose}>
              <FormattedMessage {...commonMessages.cancel} />
            </Button>
          </ButtonLayout>
        </ModalFooter>
      </Modal>
    );
  }

  const { firstName, lastName, personalIdentityNumber } =
    data.proposalContactPerson;

  const contactPerson = {
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    personalIdentityNumber: personalIdentityNumber
      ? formatNaturalPersonIdentifier(personalIdentityNumber)
      : '',
  };

  const initialValues: FormValues = {
    firstName: '',
    identification: '',
    identificationReference: '',
    lastName: '',
    personalIdentityNumber: '',
    proposalId: params.proposalId,
    role: '',
  };

  return (
    <Modal isOpen={createRepresentative} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage
          {...smeRepresentativesMessages.createRepresentative}
        />
      </ModalHeader>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={submit}
        validateOnMount
        validationSchema={validationSchema(intl)}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ModalBody>
              <RepresentativeFormFields contactPerson={contactPerson} />
              {submissionError && (
                <GraphQlError inModal error={submissionError} />
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button
                  disabled={!isValid}
                  loading={isSubmitting}
                  text
                  type="submit"
                >
                  <FormattedMessage {...commonMessages.create} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const getCreateRepresentativeLink = (
  location: RouteComponentProps['location'],
): RouteComponentProps['location'] => ({
  ...location,
  search: qs.stringify({ 'create-representative': true }),
});
