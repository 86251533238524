import { Td, Tr } from '@frontend/ui';
import { companiesQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company as Company } from 'app/apollo/graphql/types';
import { companyName } from 'app/utils/company-name';
import { NoValue } from 'components/NoValue';
import React from 'react';
import { useHistory } from 'react-router';

interface Props {
  company: Company;
}

export const CompaniesTableRow: React.FC<Props> = ({ company }) => {
  const { push } = useHistory();

  return (
    <Tr
      linked
      onClick={() => {
        push(`/companies/${company.id}`);
      }}
    >
      <Td align="left">{companyName(company)}</Td>
      <Td type="number" align="left">
        {company.registrationNumber}
      </Td>
      <Td>{company.id}</Td>
      <Td type="number">
        {company.companyAnalytics?.numberOfActiveEmployments ?? <NoValue />}
      </Td>
    </Tr>
  );
};
