import { Icon } from '@frontend/ui';
import { addCircle, cancel } from '@frontend/ui/icons';
import { EventTypeCategory } from 'app/apollo/graphql/types';
import { advisorMessages } from 'app/messages/advisor';
import { OpenAddHeldMeetingModal } from 'app/pages/advisor';
import { FormattedMessage } from 'components/formats';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import React from 'react';
import styled from 'styled-components';

interface Props {
  employee: {
    userAccountId: string;
    companyId: string;
    meetingDate?: string;
    eventTypeCategory?: EventTypeCategory;
    confirmed?: boolean;
  };
  openAddHeldMeetingModal: OpenAddHeldMeetingModal;
  sendInvitation: () => void;
  cancelMeeting?: () => void;
  deleteMeeting?: () => void;
  disabled?: boolean;
}

const StyledMenuItem = styled(MenuItem)<{ disabled?: boolean }>`
  ${p => p.disabled && 'opacity: 0.5;'}
`;

export const RowOptions: React.FC<Props> = ({
  employee,
  sendInvitation,
  openAddHeldMeetingModal,
  deleteMeeting,
  cancelMeeting,
  disabled,
}) => (
  <KebabMenu
    label={advisorMessages.advisor}
    inClickableArea
    fixed
    disabled={disabled}
  >
    <MenuItem
      title={<FormattedMessage {...advisorMessages.sendInvitation} />}
      onClick={sendInvitation}
      icon={<Icon icon={addCircle} />}
    />
    <StyledMenuItem
      disabled={employee.confirmed}
      title={<FormattedMessage {...advisorMessages.addHeldMeeting} />}
      onClick={() =>
        openAddHeldMeetingModal(
          employee.userAccountId,
          employee.companyId,
          employee.meetingDate,
          employee.eventTypeCategory,
        )
      }
      icon={<Icon icon={addCircle} />}
    />
    {cancelMeeting && (
      <StyledMenuItem
        disabled={employee.confirmed}
        title={<FormattedMessage {...advisorMessages.cancelMeeting} />}
        onClick={cancelMeeting}
        icon={<Icon icon={cancel} />}
      />
    )}
    {deleteMeeting && (
      <MenuItem
        title={<FormattedMessage {...advisorMessages.deleteMeeting} />}
        onClick={deleteMeeting}
        icon={<Icon icon={cancel} />}
      />
    )}
  </KebabMenu>
);
