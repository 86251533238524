import {
  CheckboxField,
  Section,
  SectionHeader,
  Table,
  Td,
  Th,
  Tr,
} from '@frontend/ui';
import { requestDetails as Request } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { salaryExchangeProviderMessages } from 'app/messages/salary-exchange';
import { UnitCodeOptions, unitCodeSuffixMessages } from 'app/utils/constants';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { NoValue } from 'components/NoValue';
import React, { useState } from 'react';

import { companySalaryExchangeMessages } from '../../../messages';
import { RequestSalaryExchangeType } from '../../types';
import { TableActions } from '../TableActions';

interface Props {
  navigation: React.ReactNode;
  requests: Request[];
  type: RequestSalaryExchangeType;
}

export const RequestsTable: React.FC<Props> = ({
  type,
  requests,
  navigation,
}) => {
  const [requestIds, setRequestIds] = useState<string[]>([]);

  const indeterminate =
    requestIds.length < requests.length && requestIds.length !== 0;

  const selectAll = requestIds.length === requests.length;

  const handleRowSelect = (id: string) => {
    const _requestIds = requestIds.some(_id => _id === id)
      ? requestIds.filter(_id => _id !== id)
      : [...requestIds, id];

    setRequestIds(_requestIds);
  };

  const handleAllRowSelect = (checked: boolean) => {
    const _requestIds = checked ? requests.map(({ id }) => id) : [];

    setRequestIds(_requestIds);
  };

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage
          {...(type === RequestSalaryExchangeType.ONGOING
            ? companySalaryExchangeMessages.salaryExchanges
            : companySalaryExchangeMessages.onetimeSalaryExchanges)}
        />
      </SectionHeader>
      <Table
        feedback={{
          actions: <TableActions requestIds={requestIds} />,
          active: !!requestIds.length,
          children: (
            <FormattedMessage
              {...commonMessages.nRowsSelected}
              values={{ count: requestIds.length }}
            />
          ),
        }}
        navigation={navigation}
      >
        <thead>
          <Tr>
            <Th type="input">
              <CheckboxField
                checked={selectAll}
                onChange={handleAllRowSelect}
                indeterminate={indeterminate}
              />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.personalIdentityNumber} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.name} />
            </Th>
            <Th type="number">
              <FormattedMessage
                {...companySalaryExchangeMessages.benefitQualifyingIncome}
              />
            </Th>
            <Th type="number">
              <FormattedMessage {...companySalaryExchangeMessages.deduction} />
            </Th>
            <Th type="number">
              <FormattedMessage {...companySalaryExchangeMessages.premium} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.via} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {requests.map(
            ({
              id,
              membership,
              deductionValue,
              premium,
              provider,
              remuneration,
            }) => (
              <Tr key={id}>
                <Td type="input">
                  <CheckboxField
                    id={id}
                    checked={requestIds.includes(id)}
                    onChange={() => handleRowSelect(id)}
                  />
                </Td>
                <Td type="number" align="left">
                  <FormattedNaturalPersonIdentifier
                    nids={membership.naturalPersonIdentifiers}
                  />
                </Td>
                <Td>
                  {membership.givenName} {membership.lastName}
                </Td>
                <Td type="number">
                  {remuneration ? (
                    <FormattedCurrency
                      currency={remuneration.currency ?? ''}
                      value={remuneration.value}
                      after={
                        remuneration.unitCode &&
                        remuneration.unitCode !== UnitCodeOptions.LS ? (
                          <>
                            /
                            <FormattedMessage
                              select={remuneration.unitCode}
                              messages={unitCodeSuffixMessages}
                            />
                          </>
                        ) : null
                      }
                    />
                  ) : (
                    <NoValue />
                  )}
                </Td>
                <Td type="number">
                  <FormattedCurrency
                    value={deductionValue}
                    currency="SEK"
                    after={
                      type === RequestSalaryExchangeType.ONGOING
                        ? 'PER_MONTH'
                        : undefined
                    }
                  />
                </Td>
                <Td type="number">
                  <FormattedCurrency
                    value={premium}
                    currency="SEK"
                    after={
                      type === RequestSalaryExchangeType.ONGOING
                        ? 'PER_MONTH'
                        : undefined
                    }
                  />
                </Td>
                <Td>
                  <FormattedMessage
                    select={provider}
                    messages={salaryExchangeProviderMessages}
                  />
                </Td>
              </Tr>
            ),
          )}
        </tbody>
      </Table>
    </Section>
  );
};
