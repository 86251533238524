import { Icon, Table, Th, Tr } from '@frontend/ui';
import { addCircle, edit } from '@frontend/ui/icons';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router';

import { costReportingMessages } from '../../messages';

interface TableProps {
  children: React.ReactNode;
  columns?: React.ReactNode[];
  dimensionName?: string;
  navigation?: React.ReactNode;
  perPage?: string;
}

export const CostReportingTable: React.FC<TableProps> = ({
  columns,
  dimensionName,
  perPage,
  navigation,
  children,
}) => {
  const { pathname } = useLocation();

  return (
    <>
      <ChipsWrapper>
        <AssistChip
          text={
            dimensionName ? (
              <FormattedMessage
                {...costReportingMessages.createObject}
                values={{ dimensionName }}
              />
            ) : (
              <FormattedMessage {...costReportingMessages.createDimension} />
            )
          }
          leadingIcon={addCircle}
          to={{
            pathname,
            search: qs.stringify({ create: true }),
            state: {
              perPage,
            },
          }}
        />
      </ChipsWrapper>
      <Table size="xsmall" navigation={navigation}>
        <thead>
          <Tr withInlineMenuTh>
            {columns && columns.map((column, i) => <Th key={i}>{column}</Th>)}
          </Tr>
        </thead>
        <tbody>{children}</tbody>
      </Table>
    </>
  );
};
interface TableRowProps {
  children: React.ReactNode;
  editLink?: string | Partial<RouteComponentProps['location']>;
  primaryLinkAction?: () => void;
}

export const CostReportingTableRow: React.FC<TableRowProps> = ({
  primaryLinkAction,
  editLink,
  children,
}) => (
  <Tr
    linked={!!primaryLinkAction}
    onClick={primaryLinkAction}
    inlineMenu={
      <KebabMenu
        label={costReportingMessages.costReportingEditMenu}
        fixed
        inClickableArea
      >
        <MenuItem
          title={<FormattedMessage {...costReportingMessages.updatelink} />}
          link={editLink}
          icon={<Icon icon={edit} />}
        />
      </KebabMenu>
    }
  >
    {children}
  </Tr>
);
