import { Section, Table, TableSortButton, Td, Th, Tr } from '@frontend/ui';
import {
  benefitPackagesOptionsQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage as BenefitPackage,
  benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit as Benefit,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage, useIntl } from 'components/formats';
import { getBenefitDisplayName } from 'features/companies/company/benefits/utils/get-benefit-display-name';
import React, { useState } from 'react';

import { DetailCell } from './DetailCell';
import { InBenefitPackage } from './InBenefitPackage';

const sortAscending = (a?: string, b?: string): number =>
  !a?.length ? 1 : !b?.length ? -1 : a.localeCompare(b);

type OrderBy = 'name' | 'category';

interface State {
  order?: 'ASC' | 'DESC';
  orderBy?: OrderBy;
}

interface Props {
  benefits: Benefit[];
  companyId: string;
  benefitPackageId?: string;
  benefitPackageOptions?: BenefitPackage[];
}

export const BenefitsTable: React.FC<Props> = ({
  benefits,
  benefitPackageId,
  benefitPackageOptions,
  companyId,
}) => {
  const [{ order, orderBy }, setState] = useState<State>({});
  const intl = useIntl();

  const handleSort = (value: OrderBy) => {
    if (orderBy === value) {
      if (order === 'DESC') {
        setState(() => ({
          orderBy: undefined,
          order: undefined,
        }));

        return;
      }
      setState(state => ({
        ...state,
        order: 'DESC',
      }));
      return;
    }

    setState(() => ({
      orderBy: value,
      order: 'ASC',
    }));
  };

  const sortableBenefits = benefits.map(benefit => ({
    id: benefit.id,
    name: getBenefitDisplayName(benefit, intl),
    category: benefit.categories[0]?.name,
    detail: <DetailCell benefit={benefit} companyId={companyId} />,
    inBenefitPackages: (
      <InBenefitPackage
        benefit={benefit}
        benefitPackageOptions={benefitPackageOptions}
      />
    ),
  }));

  const sortedBenefits = orderBy
    ? sortableBenefits.sort((a, b) => sortAscending(a[orderBy], b[orderBy]))
    : sortableBenefits;

  if (order === 'DESC') {
    sortedBenefits.reverse();
  }

  return (
    <Section>
      {sortedBenefits.length === 0 ? (
        <EmptyState
          title={
            benefitPackageId ? (
              <FormattedMessage
                {...commonBenefitMessages.noBenefitsInBackage}
              />
            ) : (
              <FormattedMessage {...commonBenefitMessages.noBenefits} />
            )
          }
        >
          {benefitPackageId ? (
            <FormattedMessage
              {...commonBenefitMessages.noBenefitsInBackageText}
            />
          ) : (
            <FormattedMessage {...commonBenefitMessages.noBenefitsText} />
          )}
        </EmptyState>
      ) : (
        <Table fixed>
          <colgroup>
            <col style={{ width: '25%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '25%' }} />
          </colgroup>
          <thead>
            <Tr>
              <Th multiline>
                <TableSortButton
                  order={orderBy === 'name' ? order : undefined}
                  onClick={handleSort}
                  value="name"
                >
                  <FormattedMessage {...commonMessages.benefit} />
                </TableSortButton>
              </Th>
              <Th>
                <TableSortButton
                  order={orderBy === 'category' ? order : undefined}
                  onClick={handleSort}
                  value="category"
                >
                  <FormattedMessage {...commonMessages.category} />
                </TableSortButton>
              </Th>
              <Th multiline>
                <FormattedMessage {...commonMessages.detail} />
              </Th>
              <Th multiline>
                <FormattedMessage {...commonBenefitMessages.inBenefitPackage} />
              </Th>
            </Tr>
          </thead>
          <tbody>
            {sortedBenefits.map(benefit => (
              <Tr key={benefit.id}>
                <Th multiline>{benefit.name}</Th>
                <Td>{benefit.category}</Td>
                <Td multiline>{benefit.detail}</Td>
                <Td multiline>{benefit.inBenefitPackages}</Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      )}
    </Section>
  );
};
