import { ApolloError } from '@apollo/client';
import { RadioGroupField, SelectField, TextField } from '@frontend/formik';
import { Button, ButtonLayout, ModalBody, ModalFooter } from '@frontend/ui';
import {
  ProposalAdviceOption,
  ProposalCompanySize,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import {
  smeCompanyProposalsMessages,
  smeCreateProposalMessages,
  smeProposalAdviceOptionMessages,
  smeProposalCompanySizeMessages,
} from 'features/sme/messages/sme';
import { Form, useFormikContext } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { PROPOSAL_ADVICE_OPTIONS } from '../utils/constants';

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    adviceWaived: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    companySize: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    name: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface FormValues {
  adviceWaived: ProposalAdviceOption | '';
  companySize: ProposalCompanySize | '';
  name: string;
}

export const initialValues: FormValues = {
  adviceWaived: '',
  companySize: '',
  name: '',
};

interface Props {
  onRequestClose: () => void;
  submissionError?: ApolloError;
}

export const CreateProposalForm: React.FC<Props> = ({
  onRequestClose,
  submissionError,
}) => {
  const { formatMessage } = useIntl();
  const { isValid, isSubmitting } = useFormikContext<FormValues>();
  return (
    <Form>
      <ModalBody>
        <TextField
          dense
          name="name"
          label={
            <FormattedMessage {...smeCompanyProposalsMessages.proposalName} />
          }
          required
        />
        <SelectField
          dense
          name="companySize"
          label={<FormattedMessage {...smeCreateProposalMessages.nInsured} />}
          required
          fixed
          options={[
            {
              value: ProposalCompanySize.LT_10,
              label: formatMessage({
                select: ProposalCompanySize.LT_10,
                messages: smeProposalCompanySizeMessages,
              }),
            },
            {
              value: ProposalCompanySize.LT_50,
              label: formatMessage({
                select: ProposalCompanySize.LT_50,
                messages: smeProposalCompanySizeMessages,
              }),
            },
            {
              value: ProposalCompanySize.GTE_50,
              label: formatMessage({
                select: ProposalCompanySize.GTE_50,
                messages: smeProposalCompanySizeMessages,
              }),
            },
            {
              value: ProposalCompanySize.GTE_250,
              label: formatMessage({
                select: ProposalCompanySize.GTE_250,
                messages: smeProposalCompanySizeMessages,
              }),
            },
          ]}
        />
        <RadioGroupField
          name="adviceWaived"
          options={PROPOSAL_ADVICE_OPTIONS.map(value => ({
            label: formatMessage({
              select: value,
              messages: smeProposalAdviceOptionMessages,
            }),
            value,
          }))}
        />
        {submissionError && <GraphQlError inModal error={submissionError} />}
      </ModalBody>
      <ModalFooter>
        <ButtonLayout align="right">
          <Button text onClick={onRequestClose}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
          <Button text type="submit" disabled={!isValid} loading={isSubmitting}>
            <FormattedMessage {...commonMessages.create} />
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </Form>
  );
};
