import { TextField } from '@frontend/formik';
import { Body, Subsection, SubsectionHeader } from '@frontend/ui';
import { membershipDetailsQuery_membership_Membership as Membership } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import {
  employeeFormMessages,
  workInformationMessages,
} from 'app/messages/employees';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage } from 'components/formats';
import { ViewField } from 'components/ViewField';
import React from 'react';

import { NidTableField, NidValue } from '../NidTableField';

export interface PersonalDataFormValues {
  email: string | null;
  employeeId: string | null;
  nids: NidValue[];
  phone: string | null;
}

export const personalDataInitialValues = (
  membership: Membership,
): PersonalDataFormValues => ({
  employeeId: membership.employeeNumber ?? '',
  email: membership.email ?? '',
  phone: membership.phone ?? '',
  nids: membership.naturalPersonIdentifiers.map(nid => ({
    value: nid,
    editable: false,
  })),
});

interface Props {
  name: string;
}

export const PersonalDataFields: React.FC<Props> = ({ name }) => (
  <Subsection>
    <SubsectionHeader>
      <FormattedMessage {...workInformationMessages.personalDetails} />
    </SubsectionHeader>
    <DescriptionWrapper>
      <ViewField
        label={<FormattedMessage {...commonMessages.name} />}
        value={name}
      />
    </DescriptionWrapper>
    <Body>
      <FormattedMessage {...employeeFormMessages.nidDescription} />
    </Body>
    <DescriptionWrapper>
      <NidTableField name="nids" />
    </DescriptionWrapper>
    <TextField
      dense
      name="employeeId"
      label={
        <FormattedMessage {...workInformationMessages.employeeNumberLabel} />
      }
    />
    <TextField
      dense
      name="email"
      type="email"
      label={<FormattedMessage {...commonMessages.email} />}
      required
    />
    <TextField
      dense
      name="phone"
      type="tel"
      label={<FormattedMessage {...commonMessages.phone} />}
    />
  </Subsection>
);
