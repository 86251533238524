import { gql } from 'graphql-tag';

const occupationalPensionChangeDetails = gql`
  fragment occupationalPensionChangeDetails on FlexOccupationalPensionChange {
    action
    effectiveDate
    premium
    status
    type
  }
`;

const employeePensionBenefitDetails = gql`
  fragment employeePensionBenefitDetails on FlexOccupationalPensionEntitlement {
    benefit {
      id
      type
      content {
        locale
      }
      configuration {
        data
      }
    }
    status
    grossPremium
  }
`;

export const EMPLOYEE_DETAILS_LAYOUT_QUERY = gql`
  query employeeDetailsLayoutQuery(
    $companyId: ID!
    $userAccountId: ID!
    $salaryExchangeRequeststatuses: [FlexSalaryExchangeRequestStatus!]
    $benefitTypeNames: [String!]
    $entitlementDate: Date
  ) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      givenName
      lastName
    }
    salaryExchangeRequests(
      companyId: $companyId
      userAccountId: $userAccountId
      statuses: $salaryExchangeRequeststatuses
    ) {
      edges {
        node {
          id
        }
      }
    }
    entitlements(
      companyId: $companyId
      userAccountId: $userAccountId
      benefitTypeNames: $benefitTypeNames
      showAll: true
    ) {
      edges {
        node {
          __typename
          ...employeePensionBenefitDetails
        }
      }
    }
    upcomingEntitlements: entitlements(
      companyId: $companyId
      userAccountId: $userAccountId
      benefitTypeNames: $benefitTypeNames
      date: $entitlementDate
      showAll: true
    ) {
      edges {
        node {
          __typename
          ...employeePensionBenefitDetails
        }
      }
    }
    changes(companyId: $companyId, userAccountId: $userAccountId) {
      __typename
      ... on FlexOccupationalPensionChange {
        ...occupationalPensionChangeDetails
      }
    }
  }
  ${employeePensionBenefitDetails}
  ${occupationalPensionChangeDetails}
`;
