import { Dot, Section, SectionHeader } from '@frontend/ui';
import { riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentDeath as Component } from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { insuranceComponentStatusMessages } from 'app/messages/status';
import { MEMBERSHIP_INSURANCE_COMPONENT_STATUS_COLOR } from 'app/utils/constants';
import {
  DescriptionTable,
  DescriptionTableEntry,
} from 'components/DescriptionTable';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';
import { FormattedDate } from 'react-intl';

import { getInsurancePremium } from '../../get-insurance-premium';

interface Props {
  data: Component;
}

export const LifeInsurance: React.FC<Props> = ({ data }) => {
  if (!data) {
    return null;
  }

  const payoutAmount = data.payout?.[0]?.amount;

  const lifeData: DescriptionTableEntry[] = [
    {
      id: 'lifeInsurance.status',
      label: <FormattedMessage {...commonMessages.status} />,
      value: data.status ? (
        <Dot
          colorValue={MEMBERSHIP_INSURANCE_COMPONENT_STATUS_COLOR[data.status]}
          size={0.75}
          label={
            <FormattedMessage
              select={data.status}
              messages={insuranceComponentStatusMessages}
            />
          }
        />
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'lifeInsurance.level',
      label: <FormattedMessage {...commonMessages.level} />,
      value: payoutAmount ? (
        <FormattedCurrency
          currency={payoutAmount.currency}
          value={payoutAmount.value}
          noParse
        />
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'lifeInsurance.taxable',
      label: <FormattedMessage {...commonMessages.taxable} />,
      value: <FormattedMessage {...commonMessages.no} />,
    },
    {
      id: 'lifeInsurance.premium',
      label: <FormattedMessage {...commonMessages.premium} />,
      value: getInsurancePremium(data.insurance),
    },
    {
      id: 'lifeInsurance.insuranceProvider',
      label: <FormattedMessage {...commonMessages.insuranceProvider} />,
      value: data.insurance.institute.name ?? <NoValue />,
    },
    {
      id: 'lifeInsurance.insuranceNumber',
      label: <FormattedMessage {...commonMessages.insuranceNumber} />,
      value: data.insuranceNumber ?? <NoValue />,
    },
    {
      id: 'lifeInsurance.effectiveDate',
      label: <FormattedMessage {...commonMessages.effectiveFrom} />,
      value: data.insurance.effectiveDate ? (
        <FormattedDate value={data.insurance.effectiveDate} />
      ) : (
        <NoValue />
      ),
    },
  ];

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage
          {...(data.typeCode === 'TGL'
            ? commonBenefitMessages.tgl
            : commonBenefitMessages.life)}
        />
      </SectionHeader>
      <DescriptionTable alignLeft entries={lifeData} />
    </Section>
  );
};
