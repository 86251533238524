import { Figure, Icon } from '@frontend/ui';
import { directionsRun } from '@frontend/ui/icons';
import {
  fitnessContributionCardQuery,
  fitnessContributionCardQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { totalCompensationMessages } from 'app/messages/total-compensation';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { CardGridCell } from 'components/GridCell';
import { SnapshotCard } from 'components/SnapshotCard';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { FITNESS_CONTRIBUTION_CARD_QUERY } from './graphql/queries';
import { getFitnessContributionData } from './utils/get-fitness-contribution-card-data';

interface WellnessBenefitCardProps {
  annualAllowance?: string | number;
  balance?: string | number;
}

const WellnessBenefitCard: React.FC<WellnessBenefitCardProps> = ({
  balance,
  annualAllowance,
}) => {
  const balanceFigure =
    balance != null ? (
      <Figure
        size="medium"
        title={
          <FormattedCurrency
            value={parseFloat(balance.toString())}
            currency="SEK"
          />
        }
        description={<FormattedMessage {...commonMessages.available} />}
      />
    ) : null;

  const annualAllowanceFigure = annualAllowance ? (
    <Figure
      size="medium"
      title={
        <FormattedCurrency
          value={parseFloat(annualAllowance.toString())}
          currency="SEK"
        />
      }
      description={<FormattedMessage {...commonMessages.perYear} />}
    />
  ) : null;

  return (
    <CardGridCell>
      <SnapshotCard
        icon={<Icon icon={directionsRun} color="primary" size="medium" />}
        title={
          <FormattedMessage
            {...totalCompensationMessages.fitnessContribution}
          />
        }
        figure1={balanceFigure || annualAllowanceFigure}
        figure2={balanceFigure ? annualAllowanceFigure : null}
      />
    </CardGridCell>
  );
};

export const FitnessContributionCard: React.FC = () => {
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const { data } = useQuery<
    fitnessContributionCardQuery,
    fitnessContributionCardQueryVariables
  >(FITNESS_CONTRIBUTION_CARD_QUERY, {
    suspend: true,
    errorPolicy: 'all',
    variables: {
      userAccountId,
      companyId,
      benefitTypeNames: ['epassi', 'wellness'],
    },
    isEmpty: d => !getFitnessContributionData(d),
  });

  const fitnessContributionData = getFitnessContributionData(data);

  if (!fitnessContributionData) {
    return null;
  }

  const { fitnessContributionBalance, allowance } = fitnessContributionData;

  return (
    <WellnessBenefitCard
      annualAllowance={allowance}
      balance={fitnessContributionBalance ?? undefined}
    />
  );
};
