import { ContentContainer } from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { companyMessages } from 'app/messages/company';
import { FormattedMessage } from 'components/formats';
import { RoutedTab } from 'components/RoutedTabBar';
import { BenefitPackages as BenefitPackagesOverview } from 'features/companies/company/benefit-packages';
import { Benefits } from 'features/companies/company/benefits';
import { Page } from 'features/page';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { MatchParams as CompanyMatchParams } from '..';
import { BenefitPackage } from './benefit-package';

interface Tab extends RoutedTab {
  path: string;
}

export const BenefitPackages: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = ({ match }) => {
  const tabs: Tab[] = [
    {
      title: companyMessages.benefitPackages,
      url: match.url,
      path: match.path,
    },
    {
      title: commonMessages.benefits,
      url: `${match.url}/benefits`,
      path: `${match.path}/benefits`,
    },
  ];

  return (
    <Switch>
      <Route exact path={tabs.map(({ path }) => path)}>
        <Page
          title={<FormattedMessage {...commonMessages.benefits} />}
          parentLink={`/companies/${match.params.companyId}`}
          tabs={tabs}
        >
          <ContentContainer>
            <Route
              exact
              path={match.path}
              component={BenefitPackagesOverview}
            />
            <Route exact path={`${match.path}/benefits`} component={Benefits} />
          </ContentContainer>
        </Page>
      </Route>
      <Route
        path={`${match.path}/:benefitPackageId`}
        component={BenefitPackage}
      />
    </Switch>
  );
};
