import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const administratorDetails = gql`
  fragment administratorDetails on Membership {
    id
    userAccountId
    naturalPersonIdentifiers
    firstName
    lastName
    roles
  }
`;

export const COMPANY_ADMINISTRATORS_QUERY = gql`
  query companyAdministratorsQuery(
    $companyId: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    company(id: $companyId) {
      id
      administrators(
        first: $first
        after: $after
        last: $last
        before: $before
        showInternalAdmins: false
      ) {
        __typename
        pageInfo {
          ...pageInfoDetails
        }
        edges {
          node {
            ...administratorDetails
          }
        }
      }
    }
  }
  ${pageInfoDetails}
  ${administratorDetails}
`;

export const BENEFIT_PACKAGES_OPTIONS_QUERY = gql`
  query benefitPackagesOptionsQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      name
      benefitPackages {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;
