import { Table, Td, Th, Tr } from '@frontend/ui';
import {
  companiesQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company as Company,
  pageInfoDetails,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { companyMessages } from 'app/messages/company';
import { FormattedMessage } from 'components/formats';
import { SearchField } from 'components/SearchField';
import { TableNavigation } from 'components/TableNavigation';
import React from 'react';

import { CompaniesTableRow } from '../CompaniesTableRow';

interface Props {
  companies: Company[];
  onNextPage: () => void;
  onPreviousPage: () => void;
  pageInfo: pageInfoDetails;
}

const CompaniesTableColgroup = () => (
  <colgroup>
    <col style={{ width: '30%' }} />
    <col style={{ width: '20%' }} />
    <col style={{ width: '35%' }} />
    <col style={{ width: '15%' }} />
  </colgroup>
);

export const CompaniesTable: React.FC<Props> = ({
  pageInfo,
  onNextPage,
  onPreviousPage,
  companies,
}) => (
  <>
    <SearchField label={<FormattedMessage {...companyMessages.search} />} />
    <Table
      navigation={
        <TableNavigation
          pageInfo={pageInfo}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
        />
      }
      fixed
    >
      {companies.length === 0 ? (
        <tbody>
          <Tr>
            <Td>
              <FormattedMessage {...companyMessages.noMatch} />
            </Td>
          </Tr>
        </tbody>
      ) : (
        <>
          <CompaniesTableColgroup />
          <thead>
            <Tr>
              <Th>
                <FormattedMessage {...commonMessages.name} />
              </Th>
              <Th>
                <FormattedMessage {...commonMessages.registrationNumber} />
              </Th>
              <Th>
                <FormattedMessage {...commonMessages.internalId} />
              </Th>
              <Th>
                <FormattedMessage {...companyMessages.activeEmployments} />
              </Th>
            </Tr>
          </thead>
          <tbody>
            {companies.map(c => (
              <CompaniesTableRow key={c.id} company={c} />
            ))}
          </tbody>
        </>
      )}
    </Table>
  </>
);
