import {
  smeCompanyCreateBenefitQuery,
  smeCompanyCreateBenefitQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CreateBenefitMatchParams } from 'app/pages/sme/company/proposal/benefits/create';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import { Formik } from 'formik';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  FormValues,
  SalaryExchangeForm,
  validationSchema,
} from '../../form/salary-exchange';
import { SME_COMPANY_CREATE_BENEFIT_QUERY } from '../graphql/queries';
import { useSubmit } from './utils/use-submit';

export const CreateSalaryExchange: React.FC = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<CreateBenefitMatchParams>();
  const { submit, submissionError } = useSubmit({ params });

  const { data, loading, error } = useQuery<
    smeCompanyCreateBenefitQuery,
    smeCompanyCreateBenefitQueryVariables
  >(SME_COMPANY_CREATE_BENEFIT_QUERY, {
    errorPolicy: 'all',
    variables: { id: params.proposalId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.proposal?.benefitPackages) {
    return <EmptyState error={error} />;
  }

  const { benefitPackages } = data.proposal;

  const initialValues: FormValues = {
    automaticApproval: false,
    benefitPackageIds: [],
    incomeMinIbb: '',
    ongoingDeductionShareMax: '',
    ongoingMax: '',
    pensionTaxBenefitShare: '',
    premiumWaiver: false,
    retirementAge: '',
    salaryMultiplier: '',
  };

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={validationSchema(intl)}
    >
      {({ isValid, isSubmitting, values }) => (
        <SalaryExchangeForm
          benefitPackages={benefitPackages}
          isValid={isValid}
          isSubmitting={isSubmitting}
          submissionError={submissionError}
          values={values}
        />
      )}
    </Formik>
  );
};
