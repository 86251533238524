import { Section } from '@frontend/ui';
import { edit } from '@frontend/ui/icons';
import {
  companyDetailsQuery,
  companyDetailsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { DescriptionTable } from 'components/DescriptionTable';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { COMPANY_DETAILS_QUERY } from 'features/companies/graphql/queries';
import React from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router';

// import { KycTable } from '../../components/KycTable';
import { LoginMethodTable } from '../../components/LoginMethodTable';
import { NewsLetterSettings } from '../../components/NewsLetterSettings';
import { getCompanyDetails } from '../../lib/get-company-details';

export interface CompanyDetailRow {
  id: string;
  label: React.ReactNode;
  value: null | React.ReactNode;
  type?: 'number';
}

export const CompanyDetails: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = ({
  match: {
    params: { companyId },
  },
}) => {
  const match = useRouteMatch();
  const { data, loading, error } = useQuery<
    companyDetailsQuery,
    companyDetailsQueryVariables
  >(COMPANY_DETAILS_QUERY, {
    skip: !companyId,
    errorPolicy: 'all',
    variables: { id: companyId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.company) {
    return <GraphQlError error={error} />;
  }

  const { company } = data;
  const companyDetails = getCompanyDetails(company);

  return (
    <>
      {error && <GraphQlError error={error} />}
      <Section>
        <ChipsWrapper>
          <AssistChip
            to={`${match.url}/edit`}
            text={
              <FormattedMessage {...commonMessages.updateInformationDetails} />
            }
            leadingIcon={edit}
          />
        </ChipsWrapper>
        <DescriptionTable
          alignLeft
          entries={companyDetails.map(detail => ({
            id: detail.id,
            label: detail.label,
            value: detail.value,
          }))}
        />
      </Section>
      {/* <KycTable validKyc={company.validKyc} /> */}
      <LoginMethodTable
        id={company.id}
        emailLoginAllowed={company.emailLoginAllowed}
      />
      <NewsLetterSettings
        emailLanguage={company.employeeEmailLanguage}
        newsletterOptOut={company.newsletterOptOut}
        companyId={companyId}
      />
    </>
  );
};
