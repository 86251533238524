import { ApolloError, useMutation } from '@apollo/client';
import {
  PaymentMethod,
  SetProposalCompanyContactPersonInput,
  SetProposalCompanyHeadAdminInput,
  UpdateCompanyInput,
  updateSmeCompanyContactPersonMutation,
  updateSmeCompanyContactPersonMutationVariables,
  updateSmeCompanyHeadAdminMutation,
  updateSmeCompanyHeadAdminMutationVariables,
  updateSmeCompanyMutation,
  updateSmeCompanyMutationVariables,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import {
  UPDATE_SME_COMPANY_CONTACT_PERSON_MUTATION,
  UPDATE_SME_COMPANY_HEAD_ADMIN_MUTATION,
} from 'features/sme/companies/graphql/mutations';
import { smeCompanyMessages } from 'features/sme/messages/sme';
import { useHistory } from 'react-router';

import { SME_COMPANY_QUERY } from '../../graphql/queries';
import { FormValues } from '..';
import { UPDATE_SME_COMPANY_MUTATION } from '../graphql/mutations';

const isPaymentMethod = (value: PaymentMethod | '') => value !== '';

interface Args {
  companyId: string;
}

interface Submit {
  submit: (values: FormValues) => void;
  submissionError?: ApolloError;
}

export const useSubmit = ({ companyId }: Args): Submit => {
  const { push } = useHistory();
  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [updateSmeCompany, { error: updateCompanyError }] = useMutation<
    updateSmeCompanyMutation,
    updateSmeCompanyMutationVariables
  >(UPDATE_SME_COMPANY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SME_COMPANY_QUERY,
        variables: {
          id: companyId,
        },
      },
    ],
  });

  const [updateSmeCompanyContactPerson, { error: updateContactPersonError }] =
    useMutation<
      updateSmeCompanyContactPersonMutation,
      updateSmeCompanyContactPersonMutationVariables
    >(UPDATE_SME_COMPANY_CONTACT_PERSON_MUTATION);

  const [updateSmeCompanyHeadAdmin, { error: updateHeadAdminError }] =
    useMutation<
      updateSmeCompanyHeadAdminMutation,
      updateSmeCompanyHeadAdminMutationVariables
    >(UPDATE_SME_COMPANY_HEAD_ADMIN_MUTATION);

  const submit = async (formValues: FormValues) => {
    try {
      const { separateContactAndAdmin } = formValues;
      const input: UpdateCompanyInput = {
        accountNumber: formValues.accountNumber,
        clearingCode: formValues.clearingSalesOffice?.value,
        id: companyId,
        postalCode: formValues.postalCode,
        line1: formValues.address,
        city: formValues.city,
        paymentMethod: isPaymentMethod(formValues.paymentMethod)
          ? formValues.paymentMethod
          : null,
        salaryReviewMonth: Number(formValues.salaryReviewMonth),
        accountClosureMonth: Number(formValues.accountClosureMonth),
        salesRep: formValues.salesRep,
      };

      const contactPersonInput: SetProposalCompanyContactPersonInput = {
        companyId,
        firstName: formValues.contactFirstName,
        lastName: formValues.contactLastName,
        email: formValues.contactEmail,
        phoneNumber: formValues.contactPhone,
        personalIdentityNumber:
          formValues.contactNaturalPersonIdentifier !== ''
            ? formValues.contactNaturalPersonIdentifier
            : undefined,
      };

      const headAdminInput: SetProposalCompanyHeadAdminInput = {
        companyId,
        firstName: formValues.adminFirstName,
        lastName: formValues.adminLastName,
        email: formValues.adminEmail,
        phoneNumber: formValues.adminPhone,
        personalIdentityNumber: formValues.adminNaturalPersonIdentifier,
      };

      const [companyRes, contactPersonRes, headAdminRes] = await Promise.all([
        updateSmeCompany({
          variables: { input },
        }),
        updateSmeCompanyContactPerson({
          variables: {
            input: separateContactAndAdmin
              ? contactPersonInput
              : headAdminInput,
          },
        }),
        updateSmeCompanyHeadAdmin({
          variables: {
            input: headAdminInput,
          },
        }),
      ]);

      if (
        !companyRes.data?.updateCompany ||
        !contactPersonRes.data?.setProposalContactPerson ||
        !headAdminRes.data?.setProposalHeadAdmin
      ) {
        return;
      }

      send({
        message: formatMessage(
          smeCompanyMessages.editOrganisationInformationSubmitSuccess,
        ),
        type: 'success',
      });
      push(`/sme/${companyId}`);
    } catch (error) {
      // do nothing
    }
  };

  return {
    submit,
    submissionError:
      updateCompanyError || updateContactPersonError || updateHeadAdminError,
  };
};
