import { defineMessages } from 'react-intl';

export const orgUnitMessages = defineMessages({
  name: {
    id: 'orgUnitMessages.name',
    defaultMessage: 'Namn',
  },
  createOrgUnit: {
    id: 'orgUnitMessages.createOrgUnit',
    defaultMessage: 'Skapa avdelning',
  },
  createOrgUnitSuccess: {
    id: 'orgUnitMessages.createOrgUnitSuccess',
    defaultMessage: 'Avdelningen har skapats.',
  },
  noOrgUnitsTitle: {
    id: 'orgUnitMessages.noOrgUnitsTitle',
    defaultMessage: 'Inga avdelningar',
  },
  noOrgUnit: {
    id: 'orgUnitMessages.noOrgUnit',
    defaultMessage: 'Ingen avdelning',
  },
  noOrgUnitsText: {
    id: 'orgUnitMessages.noOrgUnitsText',
    defaultMessage: 'Företaget saknar avdelningar.',
  },
  editOrgUnit: {
    id: 'orgUnitMessages.editOrgUnit',
    defaultMessage: 'Ändra avdelning',
  },
  updateOrgUnitSuccess: {
    id: 'orgUnitMessages.updateOrgUnitSuccess',
    defaultMessage: 'Avdelningen har uppdaterats.',
  },
  deleteOrgUnit: {
    id: 'orgUnitMessages.deleteOrgUnit',
    defaultMessage: 'Ta bort avdelning',
  },
  deleteOrgUnitWithMemberships: {
    id: 'orgUnitMessages.deleteOrgUnitWithMemberships',
    defaultMessage:
      'Avdelning kan inte tas bort om den har kopplade anställningar',
  },
  deleteOrgUnitSuccess: {
    id: 'orgUnitMessages.deleteOrgUnitSuccess',
    defaultMessage: 'Avdelningen har tagits bort',
  },
  deleteOrgUnitConfirmation: {
    id: 'orgUnitMessages.deleteOrgUnitConfirmation',
    defaultMessage: 'Vill du ta bort avdelningen "{name}"?',
  },
  orgUnitEditMenu: {
    id: 'orgUnitMessages.orgUnitEditMenu',
    defaultMessage: 'Avdelningsalternativ',
  },
  cancel: {
    id: 'orgUnitMessages.cancel',
    defaultMessage: 'Avbryt',
  },
  save: {
    id: 'orgUnitMessages.save',
    defaultMessage: 'Spara',
  },
  unitOf: {
    id: 'orgUnitMessages.unitOf',
    defaultMessage: 'Underavdelning till',
  },
  slug: {
    id: 'orgUnitMessages.slug',
    defaultMessage: 'Kod',
  },
});
