import { ApolloError, useMutation } from '@apollo/client';
import {
  duplicateProposalMutation,
  duplicateProposalMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeCompanyProposalsMessages } from 'features/sme/messages/sme';
import { useHistory, useParams } from 'react-router';

import { DUPLICATE_PROPOSAL_MUTATION } from '../graphql/mutations';
import { SME_COMPANY_PROPOSALS_QUERY } from '../graphql/queries';

interface Submit {
  duplicate: (proposalId: string) => Promise<void>;
  duplicateLoading: boolean;
  duplicateError?: ApolloError;
}

export const useDuplicate = (): Submit => {
  const { formatMessage } = useIntl();
  const params = useParams<MatchParams>();
  const history = useHistory();
  const { send, clear } = useNotification();

  const [
    duplicateProposal,
    { loading: duplicateLoading, error: duplicateError },
  ] = useMutation<
    duplicateProposalMutation,
    duplicateProposalMutationVariables
  >(DUPLICATE_PROPOSAL_MUTATION, {
    refetchQueries: [SME_COMPANY_PROPOSALS_QUERY],
    awaitRefetchQueries: true,
  });

  const duplicate = async (proposalId: string) => {
    try {
      const res = await duplicateProposal({
        variables: {
          input: { proposalId },
        },
      });
      const proposal = res.data?.duplicateProposal;
      if (!proposal?.id) {
        return;
      }
      send({
        message: formatMessage(
          smeCompanyProposalsMessages.duplicateProposalNotification,
        ),
        callback: {
          fn: () => {
            history.push(`/sme/${params.companyId}/proposals/${proposal.id}`);
            clear();
          },
          label: formatMessage(
            smeCompanyProposalsMessages.viewDuplicatedProposal,
            { name: proposal.name },
          ),
        },
        type: 'success',
      });
    } catch {
      // Do nothing
    }
  };

  return {
    duplicate,
    duplicateLoading,
    duplicateError,
  };
};
