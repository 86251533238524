import { Chip, Icon } from '@frontend/ui';
import { check } from '@frontend/ui/icons';
import { commonMessages } from 'app/messages/common';
import { employeeFormMessages } from 'app/messages/employees';
import { FormattedMessage } from 'components/formats';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

export const queryParam = 'includeTerminated';

export const IncludeTerminatedFilter: React.FC = () => {
  const { push } = useHistory();
  const location = useLocation();

  const parsed = qs.parse(location.search);
  const includeTerminated = parsed[queryParam] === 'true';

  const setQueryParamValue = (value?: string) => {
    push({
      ...location,
      search: qs.stringify({
        ...parsed,
        [queryParam]: value,
      }),
    });
  };

  return (
    <>
      <Chip
        text={<FormattedMessage {...commonMessages.all} />}
        leadingIcon={includeTerminated ? <Icon icon={check} /> : null}
        onClick={() => setQueryParamValue('true')}
        clickable
        selected={includeTerminated}
      />
      <Chip
        text={<FormattedMessage {...employeeFormMessages.activeEmployment} />}
        leadingIcon={!includeTerminated ? <Icon icon={check} /> : null}
        onClick={() => setQueryParamValue(undefined)}
        clickable
        selected={!includeTerminated}
      />
    </>
  );
};
