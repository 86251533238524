import {
  AnimationDirection,
  Button,
  WizardContainer,
  WizardFooter,
  WizardGrid,
} from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { formSubmitMessages } from 'app/messages/form';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { Modal } from 'components/Modal';
// eslint-disable-next-line
import { WizardHeader } from 'components/WizardHeader';
import { useConfirm } from 'contexts/confirmation';
import { Formik, FormikErrors, FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import * as Yup from 'yup';

/**
 * This component is a MASSIVE hack which resets the Formik
 * isSubmitting prop to false whenever a page in the
 * FormikWizard mounts. This ensures that we do not have a
 * loading spinner on the submission button whenever we re-enter
 * a flow that has once been submitted.
 *
 * See: https://github.com/jaredpalmer/formik/issues/1957
 */
const ResetFormikSubmitOnMount: React.FC = () => {
  const { setSubmitting } = useFormikContext();
  useEffect(() => {
    setSubmitting(false);
  }, []);

  return null;
};

const SUBMIT_BUTTON_ID = 'wizard-submission-button';

/**
 * This interface enforces that all pages in the wizard
 * have a submissionError field which can be used for
 * setting server-side submission errors.
 *
 * All app wizards based on this formik wrapper should extend this interface
 */
export interface SubmissionError {
  submissionError?: string;
}

/**
 * The direction of the animation for the page transition
 * and the Formik errors which are passed as props to each
 * Page component in the wizard.
 */
export interface PageProps<T> {
  direction?: AnimationDirection;
  errors?: FormikErrors<T>;
}

export type SetFieldValue = (
  field: string,
  // eslint-disable-next-line
  value: any,
  shouldValidate?: boolean | undefined,
) => void;

export interface Page<T extends SubmissionError> {
  page: React.FC<PageProps<T> & T>;
  offWhite?: boolean;
  skip?: (values: T) => boolean;
  validationSchema?: (intl: IntlShape) => Yup.ObjectSchema<object>;
}

interface Props<T extends SubmissionError> {
  baseUrl: string;
  initialValues: T;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void;
  pages: Array<Page<T>>;
  parentLink: string;
  submitLabel: React.ReactNode;
  title?: React.ReactNode;
  validateOnMount?: boolean;
}

const WizardForm = <T extends SubmissionError>({
  onSubmit,
  initialValues,
  baseUrl,
  parentLink,
  submitLabel,
  title,
  pages,
  validateOnMount,
}: Props<T>) => {
  const { pathname, state, search } = useLocation();
  const { push } = useHistory();
  const urlSegments = pathname.split('/');
  const currentUrlIndex = Number(urlSegments[urlSegments.length - 1]);
  const lastPageIndex = pages.length;
  const [snapshot, setSnapshot] = useState<T>(initialValues);
  const intl = useIntl();
  const { formatMessage } = intl;
  const stepProgress = pages.length > 1 ? 100 / pages.length : 0;
  const [progress, setProgress] = useState(currentUrlIndex * stepProgress);
  const [direction, setDirection] = useState<AnimationDirection | undefined>(
    undefined,
  );
  const { confirm } = useConfirm();

  useEffect(() => {
    // removes submission button focus
    // which causes button to appear washed out
    document.getElementById(SUBMIT_BUTTON_ID)?.blur();
  }, [pathname]);

  // Redirect to first wizard page on invalid url page index
  if (
    isNaN(currentUrlIndex) ||
    currentUrlIndex < 1 ||
    currentUrlIndex > lastPageIndex
  ) {
    return <Redirect to={{ pathname: `${baseUrl}/1`, state }} />;
  }
  const validationSchema = pages[currentUrlIndex - 1]?.validationSchema;

  const offWhite = pages[currentUrlIndex - 1]?.offWhite;

  // Determine the previous page index as the  number fulfilling
  // index > number > 0 which does not evaluate its potential
  // skip function to true
  const getPreviousPageIndex = (values: T, index: number): number => {
    let previousPageIndex = index - 1;
    while (previousPageIndex >= 1) {
      const skip = pages[previousPageIndex - 1]?.skip;
      if (!skip || (!!skip && !skip(values))) {
        break;
      }
      previousPageIndex -= 1;
    }

    return previousPageIndex;
  };

  const previousPage = (values: T) => {
    const previousPageIndex = getPreviousPageIndex(values, currentUrlIndex);
    setProgress(previousPageIndex * stepProgress);
    setDirection('left');
    push({ pathname: `${baseUrl}/${previousPageIndex}`, search, state });
  };

  // Determine the next page index as the next number fulfilling
  // index <= number <= pages.length which does
  // not evaluate its potential skip function to true
  const getNextPageIndex = (values: T, index: number): number => {
    let nextPageIndex = index;
    while (nextPageIndex <= pages.length) {
      const skip = pages[nextPageIndex - 1]?.skip;
      if (!skip || (!!skip && !skip(values))) {
        break;
      }
      nextPageIndex += 1;
    }

    return nextPageIndex;
  };

  const renderPage = (values: T, errors: FormikErrors<T>) => {
    const nextPageIndex = getNextPageIndex(values, currentUrlIndex);

    if (nextPageIndex !== currentUrlIndex) {
      push({ pathname: `${baseUrl}/${nextPageIndex}`, search, state });
      return null;
    }

    const P = pages[nextPageIndex - 1]?.page;

    return (
      <P
        key={nextPageIndex}
        {...values}
        direction={direction}
        errors={errors}
      />
    );
  };

  const submit = async (values: T, helpers: FormikHelpers<T>) => {
    const { setSubmitting, setTouched } = helpers;
    const nextPageIndex = getNextPageIndex(values, currentUrlIndex + 1);

    if (nextPageIndex <= pages.length) {
      setSubmitting(false);
      setDirection('right');
      push({
        pathname: `${baseUrl}/${nextPageIndex}`,
        state,
        search,
      });
      setProgress(nextPageIndex * stepProgress);

      setSnapshot(values);
      setTouched({});
    } else {
      await onSubmit(values, helpers);
    }
  };

  return (
    <Modal size="full-window">
      <Formik<T>
        initialValues={snapshot}
        validationSchema={!!validationSchema && validationSchema(intl)}
        onSubmit={submit}
        validateOnMount={validateOnMount}
      >
        {({ values, isSubmitting, errors, handleSubmit, setErrors }) => {
          // If the form has no error, or has only submission errors and
          // current page is the last, i.e. the submission step, make the form valid
          const isValid =
            Object.keys(errors).length === 0 ||
            (!!errors?.submissionError &&
              Object.keys(errors).length === 1 &&
              currentUrlIndex === lastPageIndex);

          return (
            <form
              style={{ height: '100%', overflow: 'hidden' }}
              onSubmit={handleSubmit}
            >
              <ResetFormikSubmitOnMount />
              <WizardHeader
                onConfirm={async () => {
                  const confirmed = await confirm({
                    title: formatMessage(formSubmitMessages.discardChanges),
                    description: formatMessage(
                      formSubmitMessages.confirmExitWizard,
                    ),
                  });
                  return confirmed;
                }}
                parentLink={parentLink}
                title={title}
                progress={progress}
              />
              <WizardContainer offWhite={offWhite}>
                <WizardGrid>{renderPage(values, errors)}</WizardGrid>
              </WizardContainer>
              <WizardFooter
                actions={
                  <>
                    {getPreviousPageIndex(values, currentUrlIndex) > 0 && (
                      <Button
                        text
                        onClick={() => {
                          // We reset Formik errors on navigation back to prevent
                          // submission button from being disabled when current
                          // step has validation issues
                          setErrors({});
                          previousPage(values);
                        }}
                      >
                        <FormattedMessage {...commonMessages.back} />
                      </Button>
                    )}
                    <Button
                      id={SUBMIT_BUTTON_ID}
                      filled
                      type="submit"
                      disabled={!isValid}
                      loading={isSubmitting}
                    >
                      {currentUrlIndex !== lastPageIndex ? (
                        <FormattedMessage {...commonMessages.continue} />
                      ) : (
                        submitLabel
                      )}
                    </Button>
                  </>
                }
              />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const Router = <T extends SubmissionError>(props: Props<T>) => {
  const { state, search } = useLocation();
  const { baseUrl } = props;

  return (
    <Switch>
      {/* We need to pass the location state here as wizards may
      be initiated with values from location state  */}
      <Redirect
        exact
        from={baseUrl}
        to={{ pathname: `${baseUrl}/1`, search, state }}
      />
      <Route path={`${baseUrl}/:id`}>
        <WizardForm {...props} />
      </Route>
    </Switch>
  );
};

export { Router as FormikWizard };
