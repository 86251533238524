import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

import { importRecordDetails } from '../../graphql/fragments';

export const PREVIEW_TABLE_QUERY = gql`
  query previewTableQuery(
    $id: ID!
    $first: Int
    $before: String
    $after: String
    $last: Int
    $statuses: [ImportStatus!]
  ) {
    import(id: $id) {
      id
      status
      records(
        first: $first
        before: $before
        after: $after
        last: $last
        statuses: $statuses
      ) {
        __typename
        totalCount
        edges {
          node {
            ...importRecordDetails
          }
        }
        pageInfo {
          ...pageInfoDetails
        }
      }
    }
  }
  ${importRecordDetails}
  ${pageInfoDetails}
`;
