import {
  Figure,
  Grid,
  GridCell,
  Icon,
  RainbowChart,
  RainbowChartLegend,
  Tooltip,
} from '@frontend/ui';
import { info, money } from '@frontend/ui/icons';
import {
  TotalCompensationId2 as TotalCompensationId,
  totalCompensationSummaryCardQuery,
  totalCompensationSummaryCardQueryVariables,
} from 'app/apollo/graphql/types';
import { totalCompensationMessages } from 'app/messages/total-compensation';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import {
  FormattedCurrency,
  FormattedMessage,
  FormattedPercentInteger,
} from 'components/formats';
import { FigureGridCell } from 'components/GridCell';
import { SnapshotCard } from 'components/SnapshotCard';
import React from 'react';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';

import { mapTotalCompLegend } from '../utils/map-total-comp-legend';
import { TOTAL_COMPENSATION_SUMMARY_CARD_QUERY } from './graphql/queries';
import { getSummaryData } from './utils/get-summary-data';

const Title = styled.div`
  display: flex;
  align-items: center;

  span {
    height: 1.75rem;
  }
`;

const StyledGrid = styled(Grid)`
  width: 100%;
`;

const FigureWrapper = styled(Grid)`
  margin-bottom: 1.5rem;
`;

const IconWrapper = styled.span`
  margin-left: 0.25rem;
  opacity: 0.2;
  display: inline-block;

  > svg {
    height: 1.75rem;
  }
`;

const Disclaimer = styled.p`
  max-width: 16rem;
`;

export const SummaryCard: React.FC = () => {
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const { data } = useQuery<
    totalCompensationSummaryCardQuery,
    totalCompensationSummaryCardQueryVariables
  >(TOTAL_COMPENSATION_SUMMARY_CARD_QUERY, {
    suspend: true,
    errorPolicy: 'all',
    variables: {
      companyId,
      userAccountId,
    },
    isEmpty: d => !getSummaryData(d),
  });

  const summaryData = getSummaryData(data);

  if (!summaryData) {
    return null;
  }

  const { totalCompensation, graphData, totalValue } = summaryData;

  const hasSalaryExchange = totalCompensation.some(
    item => item.key === TotalCompensationId.SALARY_EXCHANGE,
  );

  return (
    <GridCell desktop={12} tablet={8} phone={4}>
      <SnapshotCard
        icon={<Icon icon={money} color="primary" size="medium" />}
        title={
          <Title>
            <FormattedMessage
              {...totalCompensationMessages.totalCompensation}
            />
            <Tooltip
              parent={
                <IconWrapper>
                  <Icon icon={info} color="primary" size="small" />
                </IconWrapper>
              }
              tooltipContent={
                <Disclaimer>
                  <FormattedMessage
                    {...totalCompensationMessages.totalCompensationDisclaimer}
                  />
                </Disclaimer>
              }
            />
          </Title>
        }
        figure1={
          <>
            <FigureWrapper>
              <FigureGridCell>
                <Figure
                  size="medium"
                  title={
                    <FormattedCurrency value={totalValue} currency="SEK" />
                  }
                  description={
                    <FormattedMessage
                      {...totalCompensationMessages.totalCompensationPerMonth}
                    />
                  }
                />
              </FigureGridCell>
            </FigureWrapper>
            <RainbowChart data={graphData}>
              <StyledGrid>
                {graphData.map((e, i) => {
                  if (e.value) {
                    return (
                      <FigureGridCell key={i}>
                        <RainbowChartLegend
                          colorValue={e.colorValue}
                          label={mapTotalCompLegend({
                            label: e.label,
                            hasSalaryExchange,
                          })}
                          value={
                            <FormattedCurrency value={e.value} currency="SEK" />
                          }
                          footnote={
                            <FormattedPercentInteger
                              value={e.percentValue / 1e2}
                            />
                          }
                        />
                      </FigureGridCell>
                    );
                  }

                  return null;
                })}
              </StyledGrid>
            </RainbowChart>
          </>
        }
      />
    </GridCell>
  );
};
