import {
  companyAgreementsQuery,
  companyAgreementsQuery_company_Company_agreements as Agreement,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { Formik } from 'formik';
import React from 'react';
import { useRouteMatch } from 'react-router';

import {
  AddAgreementsForm,
  EuroAccidentAgreementType,
  FormValues,
  validationSchema,
} from './form';
import { useSubmit } from './form/use-submit';
import { COMPANY_AGREEMENTS_QUERY } from './graphql';

const getHasNordeaAgreement = (agreements: readonly Agreement[]) =>
  agreements?.some(
    agreement =>
      agreement.__typename === 'AgreementNordea' ||
      agreement.__typename === 'AgreementRequestNordea',
  );
const getHasEuroAccidentGruppAgreement = (agreements: readonly Agreement[]) =>
  agreements?.some(
    agreement =>
      (agreement.__typename === 'AgreementEuroAccident' ||
        agreement.__typename === 'AgreementRequestEuroAccident') &&
      agreement.subAgreementType === 'GRUPP',
  );

const getHasEuroAccidentTjpAgreement = (agreements: readonly Agreement[]) =>
  agreements?.some(
    agreement =>
      (agreement.__typename === 'AgreementEuroAccident' ||
        agreement.__typename === 'AgreementRequestEuroAccident') &&
      agreement.subAgreementType === 'TJP',
  );

export const AddAgreements: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();
  const intl = useIntl();

  const { submit } = useSubmit({ companyId });

  const { loading, data, error } = useQuery<companyAgreementsQuery>(
    COMPANY_AGREEMENTS_QUERY,
    { errorPolicy: 'all', variables: { id: companyId } },
  );

  if (loading) {
    return <TopLoading />;
  }

  if (error) {
    return <GraphQlError error={error} />;
  }

  const agreements = data?.company?.agreements ?? [];

  const hasNordeaAgreement = getHasNordeaAgreement(agreements);
  const hasEuroAccidentGruppAgreement =
    getHasEuroAccidentGruppAgreement(agreements);
  const hasEuroAccidentTjpAgreement =
    getHasEuroAccidentTjpAgreement(agreements);

  const eaAgreementTypes = [
    {
      show: hasEuroAccidentGruppAgreement,
      type: EuroAccidentAgreementType.eaGrupp,
    },
    {
      show: hasEuroAccidentTjpAgreement,
      type: EuroAccidentAgreementType.eaTjp,
    },
  ]
    .filter(item => item.show)
    .map(item => item.type);

  return (
    <Formik<FormValues>
      validationSchema={validationSchema({
        ...intl,
        hasNordeaAgreement,
        hasEuroAccidentGruppAgreement,
        hasEuroAccidentTjpAgreement,
      })}
      initialValues={{
        createNordea: hasNordeaAgreement,
        eaAgreementTypes,
        eaExtendedFcfw: false,
        eaStartDate: '',
        nordeaSalesRep: '',
        nordeaStartDate: '',
        eaCompanySize: '',
        eaGruppAgreementType: '',
        nordeaAnnualFeeFund: '',
        nordeaAnnualFeeGuarantee: '',
        nordeaCapitalFeeFund: '',
        nordeaCapitalFeeGuarantee: '',
        nordeaFundFeeDiscountType: '',
      }}
      onSubmit={(values, helpers) =>
        submit({
          values,
          helpers,
          hasNordeaAgreement,
          hasEuroAccidentGruppAgreement,
          hasEuroAccidentTjpAgreement,
        })
      }
    >
      <AddAgreementsForm
        hasNordeaAgreement={hasNordeaAgreement}
        hasEuroAccidentGruppAgreement={hasEuroAccidentGruppAgreement}
        hasEuroAccidentTjpAgreement={hasEuroAccidentTjpAgreement}
      />
    </Formik>
  );
};
