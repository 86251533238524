import { CheckboxField, FieldInlineSelect } from '@frontend/formik';
import { Td, Tr } from '@frontend/ui';
import { companyFullyCapableForWorkRequestsQuery_company_Company_fcfwRequests_FlexFcfwRequestConnection_edges_FlexFcfwRequestEdge_node_FlexFcfwRequest as FullyCapableForWorkRequest } from 'app/apollo/graphql/types';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { NoValue } from 'components/NoValue';
import React from 'react';

import { FcfwStatus } from '../../components/FcfwStatus';
import { fcfwStatus } from '../../utils/fcfw-status';
import { RequestEmployee } from '../utils/constants';

interface Props {
  checked: boolean;
  employee: RequestEmployee;
  employeeEffectiveDates: Record<string, string>;
  requests: readonly FullyCapableForWorkRequest[];
}

export const TableRow: React.FC<Props> = React.memo(
  ({
    checked,
    employee: {
      userAccountId,
      membership: { naturalPersonIdentifiers, givenName, lastName },
      possibleAssertionDates,
    },
    employeeEffectiveDates,
    requests,
  }: Props) => (
    <Tr>
      <Td type="input">
        <CheckboxField
          withMargin={false}
          name={`includedEmployees[${userAccountId}]`}
          checked={checked}
        />
      </Td>
      <Td type="number" align="left">
        <FormattedNaturalPersonIdentifier nids={naturalPersonIdentifiers} />
      </Td>
      <Td>
        {givenName} {lastName}
      </Td>
      <Td align="left">
        <FieldInlineSelect
          name={`employeeEffectiveDates[${userAccountId}]`}
          options={possibleAssertionDates.map(date => ({
            value: date,
            label: date,
          }))}
        />
      </Td>
      <Td align="left" multiline>
        {employeeEffectiveDates[userAccountId] ? (
          <FcfwStatus
            status={fcfwStatus(
              requests.filter(
                request => request.userAccountId === userAccountId,
              ),
              employeeEffectiveDates[userAccountId],
            )}
          />
        ) : (
          <NoValue />
        )}
      </Td>
    </Tr>
  ),
);
