import { ContentContainer } from '@frontend/ui';
import { companyMessages, companySettingsMessages } from 'app/messages/company';
import { menuMessages } from 'app/messages/menu';
import { FormattedMessage } from 'components/formats';
import { RoutedTab } from 'components/RoutedTabBar';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { CompanyDetails } from 'features/companies/company/details/containers/CompanyDetails';
import { Page } from 'features/page';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { MatchParams as CompanyMatchParams } from '..';
import { AccountPlanRoute } from './account-plan';
import { AdministratorsRoute } from './administrators';
import { AgreementsRoute } from './agreements';
import { AddAgreementsRoute } from './agreements/add';
import { BillingRoute } from './billing';
import { CostReportingRoute } from './cost-reporting';
import { CostReportingDimension } from './cost-reporting/dimension';
import { EditCompany } from './edit';
import { OrganizationalUnitsRoute } from './organizational-units';

interface Tab extends RoutedTab {
  path: string;
}

export const Settings: React.FC<RouteComponentProps<CompanyMatchParams>> = ({
  match,
}) => {
  const tabs: Tab[] = [
    {
      title: companySettingsMessages.information,
      url: match.url,
      path: match.path,
    },
    {
      title: menuMessages.administrators,
      url: `${match.url}/administrators`,
      path: `${match.path}/administrators`,
    },
    {
      title: menuMessages.billing,
      url: `${match.url}/billing`,
      path: `${match.path}/billing`,
    },
    {
      title: menuMessages.organizationalUnits,
      url: `${match.url}/organizational-units`,
      path: `${match.path}/organizational-units`,
    },
    {
      title: menuMessages.costReporting,
      url: `${match.url}/cost-reporting`,
      path: `${match.path}/cost-reporting`,
    },
    {
      title: menuMessages.agreements,
      url: `${match.url}/agreements`,
      path: `${match.path}/agreements`,
    },
    {
      title: menuMessages.accountPlan,
      url: `${match.url}/account-plan`,
      path: `${match.path}/account-plan`,
    },
  ];

  return (
    <Switch>
      <Route exact path={tabs.map(({ path }) => path)}>
        <Page
          title={<FormattedMessage {...companySettingsMessages.settings} />}
          parentLink={`/companies/${match.params.companyId}`}
          tabs={tabs}
        >
          <ContentContainer>
            <Route exact path={match.path} component={CompanyDetails} />
            <Route
              exact
              path={`${match.path}/administrators`}
              component={AdministratorsRoute}
            />
            <Route
              exact
              path={`${match.path}/organizational-units`}
              component={OrganizationalUnitsRoute}
            />
            <Route
              exact
              path={`${match.path}/cost-reporting`}
              component={CostReportingRoute}
            />
            <Route
              exact
              path={`${match.path}/billing`}
              component={BillingRoute}
            />
            <Route
              exact
              path={`${match.path}/agreements`}
              component={AgreementsRoute}
            />
            <Route
              exact
              path={`${match.path}/account-plan`}
              component={AccountPlanRoute}
            />
          </ContentContainer>
        </Page>
      </Route>
      <NavigationAnchor
        title={companyMessages.addAgreements}
        path={`${match.url}/agreements/add`}
      >
        <Route
          exact
          path={`${match.path}/agreements/add`}
          component={AddAgreementsRoute}
        />
      </NavigationAnchor>
      <NavigationAnchor
        title={companyMessages.updateCompanyInformation}
        path={`${match.url}/edit`}
      >
        <Route exact path={`${match.path}/edit`} component={EditCompany} />
      </NavigationAnchor>
      <Route
        exact
        path={`${match.path}/cost-reporting/:dimensionId`}
        component={CostReportingDimension}
      />
    </Switch>
  );
};
