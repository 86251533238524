import gql from 'graphql-tag';

export const COMPANY_AGREEMENTS_QUERY = gql`
  query companyAgreementsQuery($id: ID!) {
    company(id: $id) {
      id
      agreements {
        __typename
        id
        ... on AgreementEuroAccident {
          subAgreementType
        }
        ... on AgreementRequestEuroAccident {
          subAgreementType
        }
      }
    }
  }
`;
