import { ApolloError } from '@apollo/client';
import { ContentContainer } from '@frontend/ui';
import { addCircle } from '@frontend/ui/icons';
import {
  advisorsQuery,
  companiesWithPersonalAdviceBenefitQuery,
  EventTypeCategory,
  eventTypesQuery,
  MembershipKey,
} from 'app/apollo/graphql/types';
import { advisorMessages } from 'app/messages/advisor';
import { EVENT_TYPES_QUERY } from 'app/queries';
import { hasBackstagePermission } from 'app/utils/has-backstage-access';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { useCurrentUser } from 'contexts/current-permissions';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { AddHeldMeetingModal } from 'features/advisor/add-held-meeting';
import {
  ADVISORS_QUERY,
  COMPANIES_WITH_PERSONAL_ADVICE_BENEFIT_QUERY,
} from 'features/advisor/graphql/queries';
import { AdviceMeetings } from 'features/advisor/meeting';
import { AdviceMeetingFilterSideSheet } from 'features/advisor/meeting/components/AdviceMeetingFilterSideSheet';
import { MeetingStatistics } from 'features/advisor/meeting-statistics';
import { MeetingStatisticsFilterSideSheet } from 'features/advisor/meeting-statistics/MeetingStatisticsFilterSideSheet';
import { PensionTransferEmployees } from 'features/advisor/pension-transfer';
import { PensionTransferEmployeeFilterSideSheet } from 'features/advisor/pension-transfer/components/PensionTransferEmployeeFilterSideSheet';
import { PersonalAdviceEmployees } from 'features/advisor/personal-advice';
import { PersonalAdviceEmployeeFilterSideSheet } from 'features/advisor/personal-advice/components/PersonalAdviceEmployeeFilterSideSheet';
import { PtStatistics } from 'features/advisor/pt-statistics';
import { PtStatisticsFilterSideSheet } from 'features/advisor/pt-statistics/PtStatisticsFilterSideSheet';
import { SendInvitationsModal } from 'features/advisor/send-invitations';
import { useFilterParams } from 'features/advisor/utils/use-filter-params';
import { Page } from 'features/page';
import { Suspense } from 'features/Suspense';
import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';

interface TabbedRoutesProps {
  match: { url: string };
}

interface AddHeldModalState {
  isOpen: boolean;
  defaultCompanyId?: string;
  defaultDate?: string;
  defaultEventTypeCategory?: EventTypeCategory;
  defaultUserAccountId?: string;
}

interface SendInvitationsModalState {
  isOpen: boolean;
  membershipKeys?: Array<MembershipKey>;
}

export type OpenAddHeldMeetingModal = (
  defaultUserAccountId?: string,
  defaultCompanyId?: string,
  date?: string,
  eventTypeCategory?: EventTypeCategory,
) => void;

const AdvisorRoute: React.FC<
  TabbedRoutesProps & { errors?: Array<ApolloError> }
> = ({ errors, match }) => {
  const [
    {
      isOpen: isAddHeldModalOpen,
      defaultUserAccountId,
      defaultCompanyId,
      defaultDate,
      defaultEventTypeCategory,
    },
    setAddHeldModalState,
  ] = useState<AddHeldModalState>({
    isOpen: false,
    defaultUserAccountId: undefined,
    defaultCompanyId: undefined,
    defaultDate: undefined,
    defaultEventTypeCategory: undefined,
  });

  const openAddHeldMeetingModal = (
    userAccountId?: string,
    companyId?: string,
    date?: string,
    eventTypeCategory?: EventTypeCategory,
  ) => {
    setAddHeldModalState({
      isOpen: true,
      defaultUserAccountId: userAccountId,
      defaultCompanyId: companyId,
      defaultDate: date,
      defaultEventTypeCategory: eventTypeCategory,
    });
  };

  const [
    { isOpen: isSendInvitationsModalOpen, membershipKeys },
    setSendInvitationsModalState,
  ] = useState<SendInvitationsModalState>({
    isOpen: false,
    membershipKeys: [],
  });

  const openSendInvitationsModal = (keys: Array<MembershipKey>) => {
    setSendInvitationsModalState({
      isOpen: true,
      membershipKeys: keys,
    });
  };

  const [
    {
      isMeetingFilterOpen,
      isPersonalAdviceEmployeeFilterOpen,
      isPensionTransferEmployeeFilterOpen,
      isMeetingStatisticsFilterOpen,
      isPtStatisticsFilterOpen,
    },
    setFilterState,
  ] = useState({
    isMeetingFilterOpen: false,
    isPersonalAdviceEmployeeFilterOpen: false,
    isPensionTransferEmployeeFilterOpen: false,
    isMeetingStatisticsFilterOpen: false,
    isPtStatisticsFilterOpen: false,
  });

  const personalAdviceUrl = `${match.url}`;
  const pensionTransferUrl = `${match.url}/pension-transfer`;
  const meetingHistoryUrl = `${match.url}/meeting-history`;
  const meetingStatisticsUrl = `${match.url}/meeting-statistics`;
  const ptStatisticsUrl = `${match.url}/pt-statistics`;

  const tabs = [
    {
      title: advisorMessages.personalAdvice,
      url: personalAdviceUrl,
    },
    {
      title: advisorMessages.pensionTransfer,
      url: pensionTransferUrl,
    },
    {
      title: advisorMessages.meetingHistory,
      url: meetingHistoryUrl,
    },
    {
      title: advisorMessages.meetingStatistics,
      url: meetingStatisticsUrl,
    },
    {
      title: advisorMessages.ptStatistics,
      url: ptStatisticsUrl,
    },
  ];

  const { filterParams, setFilterParam, setFilterArrayParam, clearFilter } =
    useFilterParams();

  const { permissions } = useCurrentUser();

  const hasAdvisorTabAccess = hasBackstagePermission(
    'backstageApp',
    'advisorTab',
    permissions,
  );

  const { data: companiesData, loading: companiesLoading } =
    useQuery<companiesWithPersonalAdviceBenefitQuery>(
      COMPANIES_WITH_PERSONAL_ADVICE_BENEFIT_QUERY,
      {
        suspend: true,
        skip: !hasAdvisorTabAccess,
      },
    );

  const companiesWithPersonalAdviceBenefits =
    companiesData?.companies?.edges.filter(
      ({ node }) => (node.flexBenefits?.edges.length ?? 0) > 0,
    ) ?? [];

  const companyBenefitPackages =
    companiesData?.companies?.edges.find(
      ({ node }) => node.id === filterParams.companyId,
    )?.node.benefitPackages?.edges ?? [];

  const { data: advisorsData, loading: advisorsLoading } =
    useQuery<advisorsQuery>(ADVISORS_QUERY, {
      suspend: true,
      skip: !hasAdvisorTabAccess,
    });

  const { data: eventTypesData, loading: eventTypesLoading } =
    useQuery<eventTypesQuery>(EVENT_TYPES_QUERY, {
      suspend: true,
      skip: !hasAdvisorTabAccess,
    });

  if (!hasAdvisorTabAccess) {
    return <Redirect to="/" />;
  }

  return (
    <Page
      title={<FormattedMessage {...advisorMessages.advisor} />}
      tabs={tabs}
      actions={[
        {
          text: <FormattedMessage {...advisorMessages.sendInvitation} />,
          leadingIcon: addCircle,
          onClick: () => openSendInvitationsModal([]),
        },
        {
          text: <FormattedMessage {...advisorMessages.addHeldMeeting} />,
          leadingIcon: addCircle,
          onClick: () => openAddHeldMeetingModal(),
        },
      ]}
    >
      {companiesLoading && <TopLoading />}
      <ContentContainer>
        {errors && <GraphQlErrors errors={errors} />}
        <Switch>
          <Route
            exact
            path={personalAdviceUrl}
            render={() => (
              <PersonalAdviceEmployees
                openAddHeldMeetingModal={openAddHeldMeetingModal}
                toggleFilterSideSheet={() =>
                  setFilterState({
                    isMeetingFilterOpen: false,
                    isPersonalAdviceEmployeeFilterOpen:
                      !isPersonalAdviceEmployeeFilterOpen,
                    isMeetingStatisticsFilterOpen: false,
                    isPensionTransferEmployeeFilterOpen: false,
                    isPtStatisticsFilterOpen: false,
                  })
                }
                clearFilter={clearFilter}
                filterParams={filterParams}
                openSendInvitationsModal={openSendInvitationsModal}
              />
            )}
          />
          <Route
            exact
            path={pensionTransferUrl}
            render={() => (
              <PensionTransferEmployees
                openAddHeldMeetingModal={openAddHeldMeetingModal}
                toggleFilterSideSheet={() =>
                  setFilterState({
                    isMeetingFilterOpen: false,
                    isPersonalAdviceEmployeeFilterOpen: false,
                    isMeetingStatisticsFilterOpen: false,
                    isPensionTransferEmployeeFilterOpen:
                      !isPensionTransferEmployeeFilterOpen,
                    isPtStatisticsFilterOpen: false,
                  })
                }
                clearFilter={clearFilter}
                filterParams={filterParams}
                openSendInvitationsModal={openSendInvitationsModal}
              />
            )}
          />
          <Route
            path={meetingHistoryUrl}
            render={() => (
              <AdviceMeetings
                openAddHeldMeetingModal={openAddHeldMeetingModal}
                toggleFilterSideSheet={() => {
                  setFilterState({
                    isMeetingFilterOpen: !isMeetingFilterOpen,
                    isPersonalAdviceEmployeeFilterOpen: false,
                    isMeetingStatisticsFilterOpen: false,
                    isPensionTransferEmployeeFilterOpen: false,
                    isPtStatisticsFilterOpen: false,
                  });
                }}
                clearFilter={() => clearFilter()}
                filterParams={filterParams}
                openSendInvitationsModal={openSendInvitationsModal}
              />
            )}
          />
          <Route
            path={meetingStatisticsUrl}
            render={() => (
              <MeetingStatistics
                toggleFilterSideSheet={() => {
                  setFilterState({
                    isMeetingFilterOpen: false,
                    isPersonalAdviceEmployeeFilterOpen: false,
                    isMeetingStatisticsFilterOpen:
                      !isMeetingStatisticsFilterOpen,
                    isPensionTransferEmployeeFilterOpen: false,
                    isPtStatisticsFilterOpen: false,
                  });
                }}
                clearFilter={() => clearFilter()}
                filterParams={filterParams}
              />
            )}
          />
          <Route
            path={ptStatisticsUrl}
            render={() => (
              <PtStatistics
                toggleFilterSideSheet={() => {
                  setFilterState({
                    isMeetingFilterOpen: false,
                    isPersonalAdviceEmployeeFilterOpen: false,
                    isMeetingStatisticsFilterOpen: false,
                    isPensionTransferEmployeeFilterOpen: false,
                    isPtStatisticsFilterOpen: !isPtStatisticsFilterOpen,
                  });
                }}
                clearFilter={() => clearFilter()}
                filterParams={filterParams}
                advisors={advisorsData?.advisors ?? []}
              />
            )}
          />
        </Switch>
        {!companiesLoading && (
          <PersonalAdviceEmployeeFilterSideSheet
            isOpen={isPersonalAdviceEmployeeFilterOpen}
            setOpen={isOpen =>
              setFilterState({
                isMeetingFilterOpen: false,
                isPersonalAdviceEmployeeFilterOpen: isOpen,
                isMeetingStatisticsFilterOpen: false,
                isPensionTransferEmployeeFilterOpen: false,
                isPtStatisticsFilterOpen: false,
              })
            }
            setFilterParam={setFilterParam}
            clearFilter={clearFilter}
            filterParams={filterParams}
            companies={companiesWithPersonalAdviceBenefits.map(({ node }) => ({
              label: node.displayName,
              value: node.id,
            }))}
            benefitPackages={
              companyBenefitPackages.map(({ node }) => ({
                label: node.name,
                value: node.id,
              })) ?? []
            }
          />
        )}
        {!companiesLoading && (
          <PensionTransferEmployeeFilterSideSheet
            isOpen={isPensionTransferEmployeeFilterOpen}
            setOpen={isOpen =>
              setFilterState({
                isMeetingFilterOpen: false,
                isPersonalAdviceEmployeeFilterOpen: false,
                isMeetingStatisticsFilterOpen: false,
                isPensionTransferEmployeeFilterOpen: isOpen,
                isPtStatisticsFilterOpen: false,
              })
            }
            setFilterParam={setFilterParam}
            clearFilter={clearFilter}
            filterParams={filterParams}
            companies={
              companiesData?.companies?.edges.map(({ node }) => ({
                label: node.displayName,
                value: node.id,
              })) ?? []
            }
            benefitPackages={
              companyBenefitPackages.map(({ node }) => ({
                label: node.name,
                value: node.id,
              })) ?? []
            }
          />
        )}
        {!advisorsLoading && (
          <AdviceMeetingFilterSideSheet
            isOpen={isMeetingFilterOpen}
            setOpen={isOpen =>
              setFilterState({
                isMeetingFilterOpen: isOpen,
                isPersonalAdviceEmployeeFilterOpen: false,
                isMeetingStatisticsFilterOpen: false,
                isPensionTransferEmployeeFilterOpen: false,
                isPtStatisticsFilterOpen: false,
              })
            }
            setFilterParam={setFilterParam}
            setFilterArrayParam={setFilterArrayParam}
            clearFilter={clearFilter}
            filterParams={filterParams}
            advisors={
              advisorsData?.advisors?.map(advisor => ({
                label: `${advisor.firstName} ${advisor.lastName}`,
                value: advisor.id,
              })) ?? []
            }
          />
        )}
        {!advisorsLoading && !companiesLoading && (
          <MeetingStatisticsFilterSideSheet
            isOpen={isMeetingStatisticsFilterOpen}
            setOpen={isOpen =>
              setFilterState({
                isMeetingFilterOpen: false,
                isPersonalAdviceEmployeeFilterOpen: false,
                isMeetingStatisticsFilterOpen: isOpen,
                isPensionTransferEmployeeFilterOpen: false,
                isPtStatisticsFilterOpen: false,
              })
            }
            setFilterParam={setFilterParam}
            setFilterArrayParam={setFilterArrayParam}
            clearFilter={clearFilter}
            filterParams={filterParams}
            advisors={
              advisorsData?.advisors?.map(advisor => ({
                label: `${advisor.firstName} ${advisor.lastName}`,
                value: advisor.id,
              })) ?? []
            }
            companies={companiesWithPersonalAdviceBenefits.map(({ node }) => ({
              label: node.displayName,
              value: node.id,
            }))}
          />
        )}
        {!advisorsLoading && (
          <PtStatisticsFilterSideSheet
            isOpen={isPtStatisticsFilterOpen}
            setOpen={isOpen =>
              setFilterState({
                isMeetingFilterOpen: false,
                isPersonalAdviceEmployeeFilterOpen: false,
                isMeetingStatisticsFilterOpen: false,
                isPensionTransferEmployeeFilterOpen: false,
                isPtStatisticsFilterOpen: isOpen,
              })
            }
            setFilterParam={setFilterParam}
            clearFilter={clearFilter}
            filterParams={filterParams}
            companies={
              companiesData?.companies?.edges.map(({ node }) => ({
                label: node.displayName,
                value: node.id,
              })) ?? []
            }
          />
        )}
      </ContentContainer>
      <AddHeldMeetingModal
        isOpen={isAddHeldModalOpen}
        defaultUserAccountId={defaultUserAccountId}
        defaultCompanyId={defaultCompanyId}
        defaultDate={defaultDate}
        defaultEventTypeCategory={defaultEventTypeCategory}
        onRequestClose={() =>
          setAddHeldModalState(state => ({ ...state, isOpen: false }))
        }
      />
      {!eventTypesLoading && (
        <SendInvitationsModal
          isOpen={isSendInvitationsModalOpen}
          membershipKeys={membershipKeys ?? []}
          onRequestClose={() =>
            setSendInvitationsModalState(state => ({ ...state, isOpen: false }))
          }
          eventTypes={
            eventTypesData?.eventTypes.map(eventType => ({
              label: eventType.name,
              value: eventType.uri,
            })) ?? []
          }
        />
      )}
    </Page>
  );
};

export const AdvisorRouteWithSuspense: React.FC<TabbedRoutesProps> = ({
  match,
}) => (
  <NavigationAnchor title={advisorMessages.advisor} path="/advisor">
    <Suspense batch fallback={<TopLoading />}>
      {({ errors }) => <AdvisorRoute match={match} errors={errors} />}
    </Suspense>
  </NavigationAnchor>
);
