import { ContentContainer } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { Page } from 'features/page';
import { Edit } from 'features/sme/companies/company/edit';
import { smeCompanyMessages } from 'features/sme/messages/sme';
import { Suspense } from 'features/Suspense';
import React from 'react';
import { useParams } from 'react-router';

import { MatchParams } from '..';

export const SmeCompanyEditRoute: React.FC = () => {
  const { companyId } = useParams<MatchParams>();
  return (
    <Page
      title={
        <FormattedMessage {...smeCompanyMessages.editOrganisationInformation} />
      }
      parentLink={`/sme/${companyId}`}
    >
      <Suspense fallback={<TopLoading />}>
        {({ errors }) => (
          <>
            {errors && <GraphQlErrors errors={errors} />}
            <ContentContainer>
              <Edit />
            </ContentContainer>
          </>
        )}
      </Suspense>
    </Page>
  );
};
