import gql from 'graphql-tag';

export const membershipsSearchQueryDetails = gql`
  fragment membershipsSearchQueryDetails on Membership {
    id
    __typename
    userAccountId
    givenName
    lastName
  }
`;

export const MEMBERSHIPS_SEARCH_QUERY = gql`
  query membershipsSearchQuery($id: ID!, $search: String) {
    company(id: $id) {
      id
      memberships(search: $search, includeTerminated: true, first: 10) {
        edges {
          node {
            ...membershipsSearchQueryDetails
          }
        }
      }
    }
  }
  ${membershipsSearchQueryDetails}
`;

export const EMPLOYEE_NAME_SEARCH_QUERY = gql`
  query employeeNameSearchQuery($companyId: ID!, $userAccountId: ID!) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      ...membershipsSearchQueryDetails
    }
  }
  ${membershipsSearchQueryDetails}
`;

export const COMPANY_COST_CENTERS_QUERY = gql`
  query companyCostCentersQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      costCenters {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;
