import { Dot, Table, TableSortButton, Td, Th, Tr } from '@frontend/ui';
import { ThemeColor } from '@frontend/ui/theme';
import { formatRegistrationNumber, select } from '@frontend/utils';
import {
  CompaniesOrderByFields,
  pageInfoDetails,
  ProposalStatus,
  ProposalStatusFilter,
  smeCompaniesQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company as Company,
} from 'app/apollo/graphql/types';
import { TableSort } from 'app/utils/use-table-sort';
import { report } from 'components/ErrorBoundary/lib/report';
import { FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import { TableNavigation } from 'components/TableNavigation';
import {
  smeCompanyMessages,
  smeLatestProposalStatusMessages,
} from 'features/sme/messages/sme';
import React from 'react';
import { useHistory } from 'react-router';

import { SortableColumns } from '../..';

const getLatestProposalStatus = (
  status?: ProposalStatus | null,
): ProposalStatusFilter => {
  if (!status) {
    return ProposalStatusFilter.NO_PROPOSALS;
  }

  const proposalStatus = select({
    params: {
      key: status,
      record: ProposalStatusFilter,
      user: 'backstage',
    },
    report,
    shouldReportError: window.env.ERROR_REPORTING_ACTIVE === 'true',
  });

  return proposalStatus ?? ProposalStatusFilter.NO_PROPOSALS;
};

const STATUS_COLORS: Record<ProposalStatusFilter, ThemeColor> = {
  NO_PROPOSALS: 'lightGray',
  STARTED: 'orange',
  GENERATED: 'orange',
  IN_PRODUCTION: 'green',
};

interface Props {
  companies: Company[];
  onNextPage: () => void;
  onPreviousPage: () => void;
  pageInfo: pageInfoDetails;
  sort: TableSort<SortableColumns>;
}

export const CompanyTable: React.FC<Props> = ({
  companies,
  onNextPage,
  onPreviousPage,
  pageInfo,
  sort,
}) => {
  const { push } = useHistory();
  return (
    <Table
      navigation={
        <TableNavigation
          pageInfo={pageInfo}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
        />
      }
    >
      <>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...smeCompanyMessages.registrationNumber} />
            </Th>
            <Th>
              <TableSortButton
                onClick={sort.handleSort}
                order={
                  sort.orderBy === CompaniesOrderByFields.NAME
                    ? sort.order
                    : undefined
                }
                value={CompaniesOrderByFields.NAME}
              >
                <FormattedMessage {...smeCompanyMessages.companyName} />
              </TableSortButton>
            </Th>
            <Th>
              <FormattedMessage {...smeCompanyMessages.seller} />
            </Th>
            <Th align="left">
              <TableSortButton
                onClick={sort.handleSort}
                order={
                  sort.orderBy === CompaniesOrderByFields.PROPOSAL_STATUS
                    ? sort.order
                    : undefined
                }
                value={CompaniesOrderByFields.PROPOSAL_STATUS}
              >
                <FormattedMessage {...smeCompanyMessages.latestQuote} />
              </TableSortButton>
            </Th>
          </Tr>
        </thead>
        <tbody>
          {companies.length === 0 ? (
            <Tr>
              <Td>
                <FormattedMessage {...smeCompanyMessages.noSearchResults} />
              </Td>
            </Tr>
          ) : (
            companies.map(
              ({
                id,
                latestProposalStatus,
                name,
                registrationNumber,
                nlpSalesRep,
              }) => (
                <Tr key={id} linked onClick={() => push(`/sme/${id}`)}>
                  <Td type="number" align="left">
                    {formatRegistrationNumber(registrationNumber)}
                  </Td>
                  <Td>{name}</Td>
                  <Td>
                    {nlpSalesRep ? (
                      `${nlpSalesRep.firstName} ${nlpSalesRep.lastName}`
                    ) : (
                      <NoValue />
                    )}
                  </Td>
                  <Td align="left">
                    <Dot
                      colorValue={
                        STATUS_COLORS[
                          getLatestProposalStatus(latestProposalStatus)
                        ]
                      }
                      size={0.75}
                      label={
                        <FormattedMessage
                          select={getLatestProposalStatus(latestProposalStatus)}
                          messages={smeLatestProposalStatusMessages}
                        />
                      }
                    />
                  </Td>
                </Tr>
              ),
            )
          )}
        </tbody>
      </>
    </Table>
  );
};
