import { A, Table, Td, Th, Tr } from '@frontend/ui';
import { invoiceDetails } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';
import { FormattedDate } from 'react-intl';

import { invoiceMessages } from '../../messages';
import { InvoiceDownloadMenu } from '../InvoiceDownloadMenu';
import { InvoiceStatusMenu } from '../InvoiceStatusMenu';

interface TableProps {
  companyId: string;
  invoices: invoiceDetails[];
  navigation?: React.ReactNode;
}

interface RowProps {
  companyId: string;
  invoice: invoiceDetails;
}

const InvoiceTableRow: React.FC<RowProps> = ({ companyId, invoice }) => (
  <Tr
    inlineMenu={
      <InvoiceDownloadMenu
        sie4Url={invoice.sie4Url}
        peppolUrl={invoice.peppolUrl}
        pdfUrl={invoice.pdfUrl}
        excelUrl={invoice.excelUrl}
        csvUrl={invoice.csvUrl}
      />
    }
  >
    <Td>
      {invoice.htmlUrl ? (
        <A href={invoice.htmlUrl} target="_blank">
          <span title={invoice.supplier}>{invoice.supplier}</span>
        </A>
      ) : invoice.pdfUrl ? (
        <A href={invoice.pdfUrl} target="_blank">
          <span title={invoice.supplier}>{invoice.supplier}</span>
        </A>
      ) : (
        <span title={invoice.supplier}>{invoice.supplier}</span>
      )}
    </Td>
    <Td>
      <InvoiceStatusMenu companyId={companyId} invoice={invoice} />
    </Td>
    <Td type="number" align="left">
      {invoice.invoiceNumber || <NoValue />}
    </Td>
    <Td type="number">
      {invoice.issueDate ? (
        <FormattedDate value={invoice.issueDate} />
      ) : (
        <NoValue />
      )}
    </Td>
    <Td type="number">
      {invoice.dueDate ? (
        <FormattedDate value={invoice.dueDate} />
      ) : (
        <NoValue />
      )}
    </Td>
    <Td type="number">
      {invoice.payableAmount ? (
        <FormattedCurrency
          value={invoice.payableAmount}
          noSuffix
          currency="SEK"
        />
      ) : (
        <NoValue />
      )}
    </Td>
  </Tr>
);

export const InvoicesTable: React.FC<TableProps> = ({
  companyId,
  invoices,
  navigation,
}) => (
  <Table navigation={navigation}>
    <colgroup>
      <col style={{ width: 'auto' }} />
      <col style={{ width: '8rem' }} />
      <col style={{ width: '8rem' }} />
      <col style={{ width: '8rem' }} />
      <col style={{ width: '8rem' }} />
      <col style={{ width: '6rem' }} />
    </colgroup>
    <thead>
      <Tr withInlineMenuTh>
        <Th>
          <FormattedMessage {...invoiceMessages.supplier} />
        </Th>
        <Th>
          <FormattedMessage {...commonMessages.status} />
        </Th>
        <Th>
          <FormattedMessage {...invoiceMessages.invoiceNumber} />
        </Th>
        <Th type="number">
          <FormattedMessage {...invoiceMessages.invoiceDate} />
        </Th>
        <Th type="number">
          <FormattedMessage {...invoiceMessages.dueDate} />
        </Th>
        <Th type="number">
          <FormattedMessage {...commonMessages.amountLabel} />
        </Th>
      </Tr>
    </thead>
    <tbody>
      {!invoices.length ? (
        <Tr>
          <Td colSpan={6}>
            <FormattedMessage {...invoiceMessages.noInvoiceMatch} />
          </Td>
        </Tr>
      ) : (
        invoices.map(invoice => (
          <InvoiceTableRow
            key={invoice.id}
            companyId={companyId}
            invoice={invoice}
          />
        ))
      )}
    </tbody>
  </Table>
);
