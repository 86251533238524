import gql from 'graphql-tag';

export const INSURANCES_QUERY = gql`
  query insurancesQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $search: String
  ) {
    insurances(
      first: $first
      last: $last
      before: $before
      after: $after
      search: $search
      includeDeleted: true
    ) {
      __typename
      edges {
        node {
          id
          institute {
            id
            name
          }
          insuranceNumber
          insuredParty
          policyholderName
          totalValue {
            value
            currency
          }
          deletedAt
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
