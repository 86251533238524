import { Table, TableSortButton, Td, Tfoot, Th, Tr } from '@frontend/ui';
import {
  smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_cost_BenefitPackageCost as Total,
  smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_memberships_ProposalMembership as Employee,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { useTableSort } from 'app/utils/use-table-sort';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { NoValue } from 'components/NoValue';
import { smeCompilationMessages } from 'features/sme/messages/sme';
import React from 'react';

import { by, SortableColumns } from './utils';

interface Props {
  employees: readonly Employee[];
  summary?: Total | null;
}

export const EmployeesTable: React.FC<Props> = ({ employees, summary }) => {
  const hasRisk = summary?.riskTotal != null;

  const totalPension =
    summary?.employersPension ?? summary?.occupationalPension;
  const hasPension = totalPension != null;

  const { handleSort, order, orderBy } = useTableSort<SortableColumns>();

  const sortedEmployees =
    order && orderBy ? [...employees].sort(by({ order, orderBy })) : employees;

  return (
    <Table size="xsmall">
      <thead>
        <Tr>
          <Th>
            <TableSortButton
              order={orderBy === 'firstName' ? order : undefined}
              onClick={handleSort}
              value="firstName"
            >
              <FormattedMessage {...commonMessages.name} />
            </TableSortButton>
          </Th>
          <Th align="left" type="number">
            <FormattedMessage {...commonMessages.personalIdentityNumber} />
          </Th>
          <Th type="number">
            <FormattedMessage {...commonMessages.monthlySalary} />
          </Th>
          {hasRisk && (
            <Th type="number">
              <FormattedMessage {...smeCompilationMessages.riskInsurances} />
            </Th>
          )}
          {hasPension && (
            <Th type="number">
              <FormattedMessage {...smeCompilationMessages.pensionPremium} />
            </Th>
          )}
          <Th type="number">
            <FormattedMessage {...smeCompilationMessages.total} />
          </Th>
        </Tr>
      </thead>
      <tbody>
        {sortedEmployees.length === 0 ? (
          <Tr>
            <Td colSpan={4 + ((hasRisk ? 1 : 0) + (hasPension ? 1 : 0))}>
              <FormattedMessage {...smeCompilationMessages.noEmployees} />
            </Td>
          </Tr>
        ) : (
          sortedEmployees.map(employee => {
            const pensionCost =
              employee.cost?.employersPension ??
              employee.cost?.occupationalPension;
            return (
              <Tr>
                <Td>{`${employee.firstName} ${employee.lastName}`} </Td>
                <Td align="left" type="number">
                  <FormattedNaturalPersonIdentifier
                    nid={employee.personalIdentityNumber}
                  />
                </Td>
                <Td type="number">
                  {employee.monthlySalary != null ? (
                    <FormattedCurrency
                      currency="SEK"
                      value={employee.monthlySalary}
                    />
                  ) : (
                    <NoValue />
                  )}
                </Td>
                {hasRisk && (
                  <Td type="number">
                    {employee.cost?.riskTotal != null ? (
                      <FormattedCurrency
                        currency="SEK"
                        value={employee.cost.riskTotal}
                      />
                    ) : (
                      <NoValue />
                    )}
                  </Td>
                )}
                {hasPension && (
                  <Td type="number">
                    {pensionCost != null ? (
                      <FormattedCurrency currency="SEK" value={pensionCost} />
                    ) : (
                      <NoValue />
                    )}
                  </Td>
                )}
                <Td type="number">
                  {employee.cost?.total != null ? (
                    <FormattedCurrency
                      currency="SEK"
                      value={employee.cost.total}
                    />
                  ) : (
                    <NoValue />
                  )}
                </Td>
              </Tr>
            );
          })
        )}
      </tbody>
      <Tfoot>
        <Tr>
          <Td colSpan={3} />
          {hasRisk && (
            <Td type="number">
              {summary.riskTotal != null ? (
                <FormattedCurrency currency="SEK" value={summary.riskTotal} />
              ) : (
                <NoValue />
              )}
            </Td>
          )}
          {hasPension && (
            <Td type="number">
              {totalPension != null ? (
                <FormattedCurrency currency="SEK" value={totalPension} />
              ) : (
                <NoValue />
              )}
            </Td>
          )}
          <Td align="right" type="number">
            {summary?.total != null ? (
              <FormattedCurrency currency="SEK" value={summary.total} />
            ) : (
              <NoValue />
            )}
          </Td>
        </Tr>
      </Tfoot>
    </Table>
  );
};
