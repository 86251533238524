import { ApolloError, useMutation } from '@apollo/client';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import {
  organizationMembership_Membership_organizationalUnit_OrganizationalUnit as OrganizationalUnit,
  updateMembershipMutation,
  updateMembershipMutationVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { employeeFormMessages } from 'app/messages/employees';
import { formMessages } from 'app/messages/form';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { useConfirm } from 'contexts/confirmation';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { UPDATE_MEMBERSHIP_MUTATION } from '../../../graphql/mutations';
import { OrgUnitSelectField } from '../OrgUnitSelectField';

interface Props {
  isOpen: boolean;
  membershipId: string;
  onRequestClose: () => void;
  organizationalUnit: OrganizationalUnit | null;
}

interface FormValues {
  organizationalUnitId: string | null;
}

export const UpdateMembershipOrgUnitModal: React.FC<Props> = ({
  isOpen,
  organizationalUnit,
  onRequestClose,
  membershipId,
}) => {
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();
  const { confirm } = useConfirm();

  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const intl = useIntl();
  const [updateMembership] = useMutation<
    updateMembershipMutation,
    updateMembershipMutationVariables
  >(UPDATE_MEMBERSHIP_MUTATION, {
    awaitRefetchQueries: true,
    update: cache => {
      cache.evict({
        id: `Membership:${membershipId}`,
        fieldName: 'organizationalUnit',
      });
      cache.gc();
    },
  });

  return (
    <Modal size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
      <Formik<FormValues>
        initialValues={{
          organizationalUnitId: organizationalUnit
            ? organizationalUnit.id
            : null,
        }}
        onSubmit={async ({ organizationalUnitId }) => {
          setError(undefined);

          if (organizationalUnitId === 'NO_ORG_UNIT') {
            // this looks very strange. we should look into this
            // eslint-disable-next-line
            organizationalUnitId = null;
          }

          const confirmed = await confirm({
            title: intl.formatMessage(
              employeeFormMessages.confirmSwitchOrganisationTitle,
            ),
            description: intl.formatMessage(
              employeeFormMessages.confirmSwitchOrganisation,
            ),
          });

          if (confirmed) {
            try {
              const res = await updateMembership({
                variables: {
                  input: {
                    organizationalUnitId,
                    companyId,
                    userAccountId,
                  },
                },
              });
              if (!res?.data?.updateMembership) {
                throw new Error();
              }
              onRequestClose();
            } catch (err) {
              setError(err);
            }
          }
        }}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader>
              <FormattedMessage {...formMessages.editOrgUnit} />
            </ModalHeader>
            <ModalBody>
              <p>
                <FormattedMessage
                  {...employeeFormMessages.updateMembershipOrgUnitDescription}
                />
              </p>
              <OrgUnitSelectField
                dense
                name="organizationalUnitId"
                label={
                  <FormattedMessage {...commonMessages.organizationalUnit} />
                }
              />
              {error && <GraphQlError inModal error={error} />}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button
                  type="submit"
                  text
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  <FormattedMessage {...formMessages.save} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
