import {
  CheckboxField,
  DatePickerField,
  RadioGroupField,
} from '@frontend/formik';
import {
  Section,
  SectionHeader,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { validationMessages } from 'app/messages/common';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import { useFormikContext } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { jobOfferFormMessages } from '../../messages';

export const JOB_OFFER_VALID_DATE_INPUT = 'job-offer-valid-date-input';

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    untilFurtherNotice: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface FormValues {
  validUntil: string;
  untilFurtherNotice?: string | null;
  visible?: boolean | null;
}

export const initialValues: FormValues = {
  validUntil: '',
};

export const SettingsFields: React.FC = () => {
  const { formatMessage } = useIntl();
  const { values } = useFormikContext<FormValues>();
  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...jobOfferFormMessages.settings} />
      </SectionHeader>
      <Subsection>
        <SubsectionHeader>
          <FormattedMessage {...jobOfferFormMessages.validity} />
        </SubsectionHeader>
        <RadioGroupField
          name="untilFurtherNotice"
          options={[
            {
              value: 'yes',
              label: formatMessage(jobOfferFormMessages.untilFurtherNotice),
            },
            {
              value: 'no',
              label: formatMessage(jobOfferFormMessages.until),
            },
          ]}
          required
        />
      </Subsection>
      {values.untilFurtherNotice === 'no' && (
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...jobOfferFormMessages.validityPeriod} />
          </SubsectionHeader>
          <DatePickerField
            id={JOB_OFFER_VALID_DATE_INPUT}
            dense
            label={
              <FormattedMessage {...jobOfferFormMessages.untilHelperText} />
            }
            name="validUntil"
            min={format(addDays(new Date(), 1), 'yyyy-MM-dd')}
          />
        </Subsection>
      )}
      <DescriptionWrapper>
        <FormattedMessage {...jobOfferFormMessages.visibleHelperText} />
      </DescriptionWrapper>
      <CheckboxField
        name="visible"
        label={<FormattedMessage {...jobOfferFormMessages.visible} />}
      />
    </Section>
  );
};
