import {
  smeCompanyEditTglQuery,
  smeCompanyEditTglQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as BenefitMatchParams } from 'app/pages/sme/company/proposal/benefits/benefit';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { TopLoading } from 'components/TopLoading';
import { Formik } from 'formik';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { FormValues, TglForm } from '../../form/tgl';
import { SME_COMPANY_EDIT_TGL_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

interface Config {
  __typename: string;
}

interface TglConfig {
  __typename: 'ProposalTglBenefitConfig';
}

export const isTglConfig = (config: Config): config is TglConfig =>
  config.__typename === 'ProposalTglBenefitConfig';

export const EditTgl: React.FC = () => {
  const { params } = useRouteMatch<BenefitMatchParams>();
  const { submit, submissionError } = useSubmit({ params });

  const { data, loading, error } = useQuery<
    smeCompanyEditTglQuery,
    smeCompanyEditTglQueryVariables
  >(SME_COMPANY_EDIT_TGL_QUERY, {
    errorPolicy: 'all',
    variables: { benefitId: params.benefitId, proposalId: params.proposalId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.proposal?.benefit) {
    return <EmptyState error={error} />;
  }

  const { benefit, benefitPackages } = data.proposal;

  if (!benefit?.configuration || !isTglConfig(benefit.configuration)) {
    return null;
  }

  const initialValues: FormValues = {
    benefitPackageIds: benefit.benefitPackages.map(({ id }) => id),
  };

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      onSubmit={submit}
    >
      {({ isValid, isSubmitting }) => (
        <TglForm
          benefitPackages={benefitPackages}
          isValid={isValid}
          isSubmitting={isSubmitting}
          submissionError={submissionError}
        />
      )}
    </Formik>
  );
};
