import { SelectField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import { getEffectiveDateOptions } from '@frontend/utils';
import {
  benefitPackagesOptionsQuery,
  benefitPackagesOptionsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { employeeFormMessages } from 'app/messages/employees';
import { formMessages } from 'app/messages/form';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { TopLoading } from 'components/TopLoading';
import { ViewField } from 'components/ViewField';
import startOfMonth from 'date-fns/startOfMonth';
import { BENEFIT_PACKAGES_OPTIONS_QUERY } from 'features/companies/graphql/queries';
import { Form, Formik } from 'formik';
import React from 'react';
import { useRouteMatch } from 'react-router';
import * as Yup from 'yup';

import { useSubmit } from './utils/use-submit';

export interface FormValues {
  benefitPackageId: string;
  effectiveDate: string;
}

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    benefitPackageId: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    effectiveDate: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

interface BenefitPackage {
  from: string;
  id: string;
  name: string | null;
  to: string | null;
}

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  benefitPackage?: BenefitPackage;
}

export const UpdateMembershipBenefitPackageModal: React.FC<Props> = ({
  isOpen,
  benefitPackage,
  onRequestClose,
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const {
    params: { companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const {
    data: benefitPackageData,
    loading: benefiPackageLoading,
    error: benefitPackageError,
  } = useQuery<
    benefitPackagesOptionsQuery,
    benefitPackagesOptionsQueryVariables
  >(BENEFIT_PACKAGES_OPTIONS_QUERY, {
    errorPolicy: 'all',
    variables: {
      companyId,
    },
  });

  const benefitPackageOptions =
    benefitPackageData?.company?.benefitPackages?.edges.map(
      ({ node: { name, id } }) => ({ label: name, value: id }),
    ) ?? [];

  const notBefore = benefitPackage
    ? new Date(startOfMonth(new Date()))
    : undefined;

  const effectiveDateOptions = getEffectiveDateOptions(new Date(), notBefore);

  const { submit, submissionError } = useSubmit({
    benefitPackageOptions,
    onCompleted: onRequestClose,
  });

  if (benefiPackageLoading) {
    return <TopLoading />;
  }

  return (
    <Modal size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalHeader>
        {benefitPackage ? (
          <FormattedMessage {...formMessages.changeBenefitPackage} />
        ) : (
          <FormattedMessage {...formMessages.benefitPackagesPlaceholder} />
        )}
      </ModalHeader>
      <Formik<FormValues>
        validateOnMount
        validationSchema={validationSchema(intl)}
        initialValues={{ benefitPackageId: '', effectiveDate: '' }}
        onSubmit={submit}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ModalBody>
              {benefitPackageError && (
                <GraphQlError inModal error={benefitPackageError} />
              )}
              <p>
                {benefitPackage ? (
                  <FormattedMessage
                    {...employeeFormMessages.changeEmployeeBenefitPackagesDescription}
                  />
                ) : (
                  <FormattedMessage
                    {...employeeFormMessages.addEmployeeBenefitPackagesDescription}
                  />
                )}
              </p>
              {benefitPackage && (
                <ViewField
                  label={formatMessage(formMessages.currentBenefitPackage)}
                  value={benefitPackage.name}
                />
              )}
              <SelectField
                dense
                fixed
                name="benefitPackageId"
                options={benefitPackageOptions}
                label={formatMessage(formMessages.benefitPackagesPlaceholder)}
                required
              />
              <SelectField
                dense
                fixed
                name="effectiveDate"
                options={effectiveDateOptions}
                label={formatMessage(commonMessages.effectiveDate)}
                required
              />
              {submissionError && (
                <GraphQlError inModal error={submissionError} />
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button
                  loading={isSubmitting}
                  text
                  type="submit"
                  disabled={!isValid}
                >
                  <FormattedMessage {...formMessages.approve} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
