import { companyDetailsQuery_company_Company as Company } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import {
  FormattedAddress,
  FormattedIdentityNumber,
  FormattedMessage,
} from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';

import { CompanyDetailRow } from '../containers/CompanyDetails';

export const getCompanyDetails = (company: Company) => {
  const companyDetails: CompanyDetailRow[] = [
    {
      id: 'registrationNumber',
      label: <FormattedMessage {...commonMessages.registrationNumber} />,
      value: <FormattedIdentityNumber value={company.registrationNumber} />,
    },
    {
      id: 'companyId',
      label: <FormattedMessage {...commonMessages.companyId} />,
      value: company.id,
    },
    {
      id: 'companyName',
      label: <FormattedMessage {...commonMessages.companyName} />,
      value: company.name,
    },
    {
      id: 'displayName',
      label: <FormattedMessage {...commonMessages.displayName} />,
      value: company.displayName,
    },
    {
      id: 'address',
      label: <FormattedMessage {...commonMessages.address} />,
      value: (
        <FormattedAddress
          address={company.address.line1 ?? ''}
          careOf={company.address.co}
          postalCode={company.address.postalCode}
          city={company.address.city}
        />
      ),
    },
    {
      id: 'phone',
      label: <FormattedMessage {...commonMessages.phone} />,
      value: company.phone ? company.phone : <NoValue />,
      type: 'number',
    },
    {
      id: 'email',
      label: <FormattedMessage {...commonMessages.email} />,
      value: company.email ? company.email : <NoValue />,
    },
  ];

  return companyDetails;
};
