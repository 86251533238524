import { FieldInlineEditTd } from '@frontend/formik';
import { Icon, Table, Td, Th, Tr } from '@frontend/ui';
import { chatBubble } from '@frontend/ui/icons';
import { salaryExchangeRequestsQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest as Request } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { UnitCodeOptions, unitCodeSuffixMessages } from 'app/utils/constants';
import {
  FormattedCurrency,
  FormattedMessage,
  useIntl,
} from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { NoValue } from 'components/NoValue';
import { companySalaryExchangeMessages } from 'features/companies/company/salary-exchanges/messages';
import React from 'react';
import styled from 'styled-components';

import { RequestSalaryExchangeType } from '../../../types';

const StyledIcon = styled(Icon)`
  margin-right: 0.5rem;
  vertical-align: middle;
`;

interface ModalTableProps {
  navigation: React.ReactNode;
  requests: Request[];
  type: RequestSalaryExchangeType;
}

export const RejectTable: React.FC<ModalTableProps> = ({
  type,
  requests,
  navigation,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Table size="small" fixed navigation={navigation}>
      <colgroup>
        <col style={{ width: '20%' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '25%' }} />
      </colgroup>
      <thead>
        <Tr>
          <Th>
            <FormattedMessage {...commonMessages.personalIdentityNumber} />
          </Th>
          <Th multiline>
            <FormattedMessage {...commonMessages.name} />
          </Th>
          <Th type="number">
            <FormattedMessage
              {...companySalaryExchangeMessages.benefitQualifyingIncome}
            />
          </Th>
          <Th type="number">
            <FormattedMessage {...companySalaryExchangeMessages.deduction} />
          </Th>
          <Th align="left">
            <StyledIcon icon={chatBubble} />
            <FormattedMessage {...companySalaryExchangeMessages.comments} />
          </Th>
        </Tr>
      </thead>
      <tbody>
        {requests.map(({ id, membership, deductionValue, remuneration }) => (
          <Tr key={id}>
            <Td type="number" align="left">
              <FormattedNaturalPersonIdentifier
                nids={membership.naturalPersonIdentifiers}
              />
            </Td>
            <Td multiline>
              {membership.givenName} {membership.lastName}
            </Td>
            <Td type="number">
              {remuneration ? (
                <FormattedCurrency
                  currency={remuneration.currency ?? ''}
                  value={remuneration.value}
                  after={
                    remuneration.unitCode &&
                    remuneration.unitCode !== UnitCodeOptions.LS ? (
                      <>
                        /
                        <FormattedMessage
                          select={remuneration.unitCode}
                          messages={unitCodeSuffixMessages}
                        />
                      </>
                    ) : null
                  }
                />
              ) : (
                <NoValue />
              )}
            </Td>
            <Td type="number">
              <FormattedCurrency
                value={deductionValue}
                currency="SEK"
                after={
                  type === RequestSalaryExchangeType.ONGOING
                    ? 'PER_MONTH'
                    : undefined
                }
              />
            </Td>
            <FieldInlineEditTd
              name={`requests.${id}.reason`}
              label={formatMessage(companySalaryExchangeMessages.comments)}
            />
          </Tr>
        ))}
      </tbody>
    </Table>
  );
};
