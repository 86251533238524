import {
  MenuItem as MenuItemComponent,
  MenuItemImage,
  MenuItemLabel,
} from '@frontend/ui';
import type { MenuItemProps as _MenuItemProps } from '@frontend/ui/components/Menu';
import React from 'react';
import {
  LinkProps,
  match as Match,
  NavLink,
  RouteComponentProps,
} from 'react-router-dom';
import styled from 'styled-components';

const StyledNavLink = styled(NavLink)`
  width: 100%;
  display: block;
  &:hover {
    text-decoration: none;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: block;
  cursor: pointer;
`;

export interface MenuItemProps
  extends Omit<_MenuItemProps, 'tooltip' | 'multiline' | 'title'> {
  description?: React.ReactNode;
  exact?: boolean;
  image?: {
    image?: string | null;
  };
  isActive?: (
    match: Match<object> | null,
    location: RouteComponentProps['location'],
  ) => boolean;
  link?: LinkProps['to'];
  noIcon?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
  title?: React.ReactNode;
  tooltip?: boolean;
}
export const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  title,
  link,
  image,
  disabled,
  exact = false,
  isActive,
  onClick,
  noIcon,
  description,
  tooltip,
  ...props
}) =>
  disabled ? (
    <MenuItemComponent
      icon={icon}
      disabled={disabled}
      multiline={!!description}
      tooltip={tooltip ? { line1: title, line2: description } : undefined}
      {...props}
    >
      {!icon && <MenuItemImage image={image?.image} />}
      <MenuItemLabel title={title} description={description} />
    </MenuItemComponent>
  ) : link ? (
    !title && icon ? (
      <NavLink
        isActive={isActive}
        to={link}
        exact={exact}
        activeClassName={props.navigation ? 'activeMenu' : undefined}
      >
        <MenuItemComponent icon={icon} {...props} />
      </NavLink>
    ) : (
      <StyledNavLink
        isActive={isActive}
        to={link}
        exact={exact}
        activeClassName={props.navigation ? 'activeMenu' : undefined}
      >
        <MenuItemComponent
          icon={icon}
          multiline={!!description}
          tooltip={tooltip ? { line1: title, line2: description } : undefined}
          {...props}
        >
          {!icon && !noIcon && <MenuItemImage image={image?.image} />}
          <MenuItemLabel title={title} description={description} />
        </MenuItemComponent>
      </StyledNavLink>
    )
  ) : (
    <Wrapper>
      <MenuItemComponent
        icon={icon}
        multiline={!!description}
        onClick={onClick}
        tooltip={tooltip ? { line1: title, line2: description } : undefined}
        {...props}
      >
        {!icon && !noIcon && <MenuItemImage image={image?.image} />}
        <MenuItemLabel title={title} description={description} />
      </MenuItemComponent>
    </Wrapper>
  );
