import { Chip, Icon, SelectField, SelectOption, TextField } from '@frontend/ui';
import { check } from '@frontend/ui/icons';
import { commonMessages } from 'app/messages/common';
import { employeeFormMessages } from 'app/messages/employees';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { ScrollableSet } from 'components/scrollable/Set';
import { FilterSection } from 'features/filter-sheet/components/FilterSection';
import { SelectionFilter } from 'features/filter-sheet/components/SelectionFilter';
import { useDebouncedFilters } from 'features/filter-sheet/utils/use-debounced-filters';
import React from 'react';

interface Props {
  companyOptions?: SelectOption[];
}

interface Filters {
  companyId: string;
  includeTerminated: string;
  userAccountId: string;
}

export const Filters: React.FC<Props> = ({ companyOptions }) => {
  const { clearFilters, filters, setFilterValue } =
    useDebouncedFilters<Filters>();

  const companyFilter = companyOptions?.find(
    c => c.value === filters.companyId,
  );

  // The filter for terminated employees is a special
  // case and is not part of the total count of filters
  const count = [filters.companyId, filters.userAccountId].filter(
    f => f != null && f !== '',
  ).length;

  const includeTerminated = filters.includeTerminated === 'true';

  return (
    <ChipsWrapper belowSearch>
      <ScrollableSet type="chip">
        <Chip
          text={<FormattedMessage {...commonMessages.all} />}
          leadingIcon={includeTerminated ? <Icon icon={check} /> : null}
          onClick={() =>
            setFilterValue('true', 'includeTerminated', { skipDebounce: true })
          }
          clickable
          selected={includeTerminated}
        />
        <Chip
          text={<FormattedMessage {...employeeFormMessages.activeEmployment} />}
          leadingIcon={!includeTerminated ? <Icon icon={check} /> : null}
          onClick={() =>
            setFilterValue('', 'includeTerminated', { skipDebounce: true })
          }
          clickable
          selected={!includeTerminated}
        />
        <SelectionFilter clearFilters={clearFilters} nSelected={count}>
          {companyOptions && (
            <FilterSection
              header={
                <FormattedMessage {...employeeFormMessages.selectCompany} />
              }
            >
              <SelectField
                dense
                label={<FormattedMessage {...commonMessages.company} />}
                options={companyOptions}
                value={companyFilter?.value ?? ''}
                onChange={e => {
                  setFilterValue(e.detail.value, 'companyId');
                }}
              />
            </FilterSection>
          )}
          <FilterSection
            header={<FormattedMessage {...employeeFormMessages.searchUserId} />}
          >
            <TextField
              dense
              value={filters.userAccountId ?? ''}
              label={<FormattedMessage {...commonMessages.userId} />}
              onChange={e => {
                setFilterValue(e.target.value, 'userAccountId');
              }}
            />
          </FilterSection>
        </SelectionFilter>
      </ScrollableSet>
    </ChipsWrapper>
  );
};
