import { Table, Td, Tr } from '@frontend/ui';
import { smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit as Benefit } from 'app/apollo/graphql/types';
import {
  DescriptionTable,
  DescriptionTableEntry,
} from 'components/DescriptionTable';
import { FormattedMessage } from 'components/formats';
import { smeBenefitsMessages } from 'features/sme/messages/sme';
import React from 'react';

import {
  getAccidentEntry,
  getDisabilityEntry,
  getHealthcareEntry,
  getLifeEntry,
  getOccupationalPensionEntry,
  getPensionEntry,
  getPremiumWaiverEntry,
  getSalaryExchangeEntry,
  getTglEntry,
} from '../BenefitsTableEntry';
import {
  by,
  isAccidentConfig,
  isDisabilityConfig,
  isHealthcareConfig,
  isLifeConfig,
  isOccupationalPensionConfig,
  isPensionBenefit,
  isPensionConfig,
  isSalaryExchangeConfig,
  isTglConfig,
} from './utils';

interface Props {
  benefits: readonly Benefit[];
}

const getEntries = (benefits: readonly Benefit[]): DescriptionTableEntry[] => {
  const sortedBenefits = [...benefits].sort(by());

  return sortedBenefits.reduce<DescriptionTableEntry[]>((acc, curr) => {
    const config = curr.configuration;

    if (isPensionConfig(config)) {
      return [
        ...acc,
        getPensionEntry(config),
        ...(isOccupationalPensionConfig(config)
          ? [getOccupationalPensionEntry(config)]
          : []),
        ...(config.premiumWaiver ? [getPremiumWaiverEntry(config)] : []),
      ];
    }
    if (isDisabilityConfig(config)) {
      const pensionConfig = benefits.find(isPensionBenefit)?.configuration;

      return [...acc, getDisabilityEntry(config, pensionConfig)];
    }
    if (isHealthcareConfig(config)) {
      return [...acc, getHealthcareEntry(config)];
    }
    if (isAccidentConfig(config)) {
      return [...acc, getAccidentEntry(config)];
    }
    if (isLifeConfig(config)) {
      return [...acc, getLifeEntry(config)];
    }
    if (isTglConfig(config)) {
      return [...acc, getTglEntry()];
    }
    if (isSalaryExchangeConfig(config)) {
      return [...acc, getSalaryExchangeEntry(config)];
    }
    return acc;
  }, []);
};

export const BenefitsTable: React.FC<Props> = ({ benefits }) => {
  const entries = getEntries(benefits);

  if (!entries.length) {
    return (
      <Table size="small">
        <tbody>
          <Tr>
            <Td>
              <FormattedMessage
                {...smeBenefitsMessages.noBenefitsInBenefitPackage}
              />
            </Td>
          </Tr>
        </tbody>
      </Table>
    );
  }

  return <DescriptionTable alignLeft entries={entries} />;
};
