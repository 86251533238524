import gql from 'graphql-tag';

export const RISK_INSURANCES_ADMIN_QUERY = gql`
  query riskInsurancesAdminQuery($userAccountId: ID!, $companyId: ID!) {
    membership(userAccountId: $userAccountId, companyId: $companyId) {
      id
      insurances {
        edges {
          node {
            id
            components {
              id
              insuranceNumber
              __typename
              insurance {
                id
                brokeredByAdvinans
                effectiveDate
                institute {
                  id
                  name
                }
                nextPremiumPayment {
                  value
                  currency
                }
                premiumPaymentFrequency
              }
              status
              payout {
                amount {
                  currency
                  value
                }
                payoutEvent
              }
              ... on InsuranceComponentDeath {
                typeCode
              }
              ... on InsuranceComponentSickness {
                sicknessLevel
                typeCode
              }
              ... on InsuranceComponentAccident {
                coverage
              }
              ... on InsuranceComponentHealthCare {
                copayment {
                  value
                  currency
                }
              }
            }
          }
        }
      }
    }
  }
`;
