import { BenefitType } from '@frontend/benefit-types';
import { Figure, Icon } from '@frontend/ui';
import { beachAccess } from '@frontend/ui/icons';
import { startOfNextMonth } from '@frontend/utils';
import {
  pensionCardQuery,
  pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node as Entitlement,
  pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement as OccupationalPensionEntitlement,
  pensionCardQueryVariables,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { totalCompensationMessages } from 'app/messages/total-compensation';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { CardGridCell } from 'components/GridCell';
import { SnapshotCard } from 'components/SnapshotCard';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { PENSION_CARD_QUERY } from './graphql/queries';
import { getPensionCardData } from './utils/get-pension-card-data';

const advinansOccupationalPensionType: BenefitType =
  'advinans_occupational_pension';

export const isOccupationalPensionEntitlement = (
  entitlement: Entitlement,
): entitlement is OccupationalPensionEntitlement =>
  entitlement.benefit.type === advinansOccupationalPensionType;

const benefitTypeNames: BenefitType[] = ['advinans_occupational_pension'];

export const PensionCard: React.FC = () => {
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const nextMonthStartDate = startOfNextMonth();

  const { data } = useQuery<pensionCardQuery, pensionCardQueryVariables>(
    PENSION_CARD_QUERY,
    {
      suspend: true,
      errorPolicy: 'all',
      skip: !companyId,
      variables: {
        userAccountId,
        companyId,
        benefitTypeNames,
        date: nextMonthStartDate,
      },
      isEmpty: d => !getPensionCardData(d),
    },
  );

  const pensionCardData = getPensionCardData(data);

  if (!pensionCardData) {
    return null;
  }

  const { pensionPremium, nextPensionPremium, showNextPensionPremium } =
    pensionCardData;

  return (
    <CardGridCell>
      <SnapshotCard
        icon={<Icon icon={beachAccess} color="primary" size="medium" />}
        title={
          <FormattedMessage {...commonBenefitMessages.occupationalPension} />
        }
        figure1={
          <Figure
            size="medium"
            title={
              <FormattedCurrency
                value={pensionPremium}
                currency="SEK"
                after="PER_MONTH"
              />
            }
            description={
              <FormattedMessage {...totalCompensationMessages.currentPremium} />
            }
          />
        }
        figure2={
          showNextPensionPremium && nextPensionPremium ? (
            <Figure
              size="medium"
              title={
                <FormattedCurrency
                  value={nextPensionPremium}
                  currency="SEK"
                  after="PER_MONTH"
                />
              }
              description={
                <FormattedMessage
                  {...totalCompensationMessages.fromNextMonth}
                />
              }
            />
          ) : null
        }
      />
    </CardGridCell>
  );
};
