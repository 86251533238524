import { ApolloError } from '@apollo/client';
import { CheckboxField, SelectField, TextField } from '@frontend/formik';
import { Button, Grid, WizardContentCard } from '@frontend/ui';
import { capitalize } from '@frontend/utils';
import {
  createSmeCompanyQuery_nlpSalesReps_AdminUser as AdminUser,
  PaymentMethod,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell50 } from 'components/GridCell';
import { SimpleWizard } from 'components/SimpleWizard';
import { SelectClearingField } from 'features/sme/companies/company/select-clearing';
import {
  smeAccountNumberMessages,
  smeCompanyMessages,
  smeCreateCompanyMessages,
  smePaymentMethodMessages,
} from 'features/sme/messages/sme';
import { Form, useFormikContext } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';

import { FormValues } from '..';

interface Props {
  nlpSalesReps: readonly AdminUser[];
  error?: ApolloError;
  submissionError?: ApolloError;
}

export const CreateCompanyForm: React.FC<Props> = ({
  nlpSalesReps,
  error,
  submissionError,
}) => {
  const { formatMessage, formatDate } = useIntl();
  const { isSubmitting, isValid, values } = useFormikContext<FormValues>();
  const history = useHistory();

  const monthOptions = [...Array(12).keys()].map(i => ({
    label: capitalize(formatDate(new Date().setMonth(i), { month: 'long' })),
    value: i + 1,
  }));

  const salesRepOptions = nlpSalesReps.map(
    ({ id, firstName, lastName, externalId }) => ({
      label: `${firstName} ${lastName} (${externalId})`,
      value: id,
    }),
  );

  const handleClose = () => history.push('/sme');

  const withForm = (content: React.ReactNode) => <Form>{content}</Form>;

  return (
    <SimpleWizard
      actions={
        <>
          <Button text onClick={handleClose}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
          <Button
            filled
            type="submit"
            disabled={!isValid}
            loading={isSubmitting}
          >
            <FormattedMessage {...smeCreateCompanyMessages.addCompany} />
          </Button>
        </>
      }
      offWhite
      parentLink="/sme"
      title={<FormattedMessage {...smeCreateCompanyMessages.addCompany} />}
      withForm={withForm}
    >
      <WizardContentCard
        header={
          <FormattedMessage {...smeCreateCompanyMessages.customerInformation} />
        }
        description={
          <FormattedMessage
            {...smeCreateCompanyMessages.customerInformationDescription}
          />
        }
      >
        {error && <GraphQlError error={error} />}
        <TextField
          name="registrationNumber"
          dense
          required
          label={formatMessage(smeCompanyMessages.registrationNumber)}
        />
        {/* TODO: Add field for companies that are part of a group */}
        <SelectField
          name="salesRep"
          dense
          required
          options={salesRepOptions}
          label={formatMessage(smeCompanyMessages.salesRep)}
        />
        <Grid>
          <GridCell50>
            <TextField
              name="adminFirstName"
              dense
              required
              label={formatMessage(smeCompanyMessages.adminFirstName)}
            />
          </GridCell50>
          <GridCell50>
            <TextField
              name="adminLastName"
              dense
              required
              label={formatMessage(smeCompanyMessages.adminLastName)}
            />
          </GridCell50>
        </Grid>
        <TextField
          name="adminNaturalPersonIdentifier"
          dense
          required
          label={formatMessage(smeCompanyMessages.adminPid)}
        />
        <TextField
          name="adminEmail"
          dense
          required
          label={formatMessage(smeCompanyMessages.adminEmail)}
          type="email"
        />
        <TextField
          name="adminPhone"
          dense
          required
          label={formatMessage(smeCompanyMessages.adminPhone)}
          type="tel"
        />
        <CheckboxField
          name="separateContactAndAdmin"
          label={formatMessage(smeCompanyMessages.separateContactAndAdmin)}
        />
        {values.separateContactAndAdmin && (
          <>
            <Grid>
              <GridCell50>
                <TextField
                  name="contactFirstName"
                  dense
                  required
                  label={formatMessage(smeCompanyMessages.contactFirstName)}
                />
              </GridCell50>
              <GridCell50>
                <TextField
                  name="contactLastName"
                  dense
                  required
                  label={formatMessage(smeCompanyMessages.contactLastName)}
                />
              </GridCell50>
            </Grid>
            <TextField
              name="contactNaturalPersonIdentifier"
              dense
              label={formatMessage(smeCompanyMessages.contactPid)}
            />
            <TextField
              name="contactEmail"
              dense
              required
              label={formatMessage(smeCompanyMessages.contactEmail)}
              type="email"
            />
            <TextField
              name="contactPhone"
              dense
              required
              label={formatMessage(smeCompanyMessages.contactPhone)}
              type="tel"
            />
          </>
        )}
        <SelectClearingField name="clearingSalesOffice" />
        <SelectField
          name="accountClosureMonth"
          dense
          required
          options={monthOptions}
          label={formatMessage(smeCompanyMessages.accountClosureMonth)}
        />
        <SelectField
          name="salaryReviewMonth"
          dense
          required
          options={monthOptions}
          label={formatMessage(smeCompanyMessages.salaryReviewMonth)}
        />
        <SelectField
          dense
          label={<FormattedMessage {...smeCompanyMessages.paymentMethod} />}
          name="paymentMethod"
          options={Object.values(PaymentMethod).map(value => ({
            label: formatMessage({
              messages: smePaymentMethodMessages,
              select: value,
            }),
            value,
          }))}
        />
        {values.paymentMethod &&
          values.paymentMethod !== PaymentMethod.INVOICE && (
            <TextField
              dense
              label={
                <FormattedMessage
                  select={values.paymentMethod}
                  messages={smeAccountNumberMessages}
                />
              }
              name="accountNumber"
            />
          )}
        {submissionError && <GraphQlError error={submissionError} />}
      </WizardContentCard>
    </SimpleWizard>
  );
};
