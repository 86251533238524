import {
  Button,
  ContentContainer,
  headlineLargeCSS,
  headlineMediumCSS,
  media,
} from '@frontend/ui';
import { accountBalance, mediation } from '@frontend/ui/icons';
import { loginMessages } from 'app/messages/login';
import { DefaultA } from 'components/DefaultA';
import { FlexCenter } from 'components/FlexCenter';
import { FormattedMessage } from 'components/formats';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import qs from 'query-string';
import React from 'react';
import styled from 'styled-components';

export const AuthContainer = styled.div`
  > * {
    margin-bottom: 0.5rem;
  }
`;

export const AuthTitle = styled.h1`
  ${headlineLargeCSS};
  text-align: center;
  ${media.lessThan('tablet')`
    ${headlineMediumCSS};
  `}
  margin-bottom: 1rem;
`;

const redirectUrl =
  window.env.HOST === 'admin.l.dev.nordeanode.se'
    ? `http://${window.env.HOST}:3000`
    : `https://${window.env.HOST}`;

export const LoginRoute: React.FC = () => (
  <NavigationAnchor title={loginMessages.title} path="/login">
    <FlexCenter>
      <ContentContainer>
        <AuthContainer>
          <AuthTitle>
            <FormattedMessage {...loginMessages.welcome} />
          </AuthTitle>
          <DefaultA
            href={`${window.env.SAML_SSO_URL}?${qs.stringify({ binding: 'google', next: redirectUrl })}`}
          >
            <Button icon={mediation}>
              <FormattedMessage {...loginMessages.googleSSO} />
            </Button>
          </DefaultA>
          <DefaultA
            href={`${window.env.SAML_SSO_URL}?${qs.stringify({ binding: 'ninaa', next: redirectUrl })}`}
          >
            <Button icon={accountBalance}>
              <FormattedMessage {...loginMessages.nordeaSSO} />
            </Button>
          </DefaultA>
        </AuthContainer>
      </ContentContainer>
    </FlexCenter>
  </NavigationAnchor>
);
