import { BenefitType, DisabilityLevel } from '@frontend/benefit-types';
import { PensionBenefitType } from 'features/companies/company/benefits/utils/constants';
import { defineMessages } from 'react-intl';

export const commonBenefitMessages = defineMessages({
  pension: {
    id: 'commonBenefitMessages.pension',
    description: 'Ålderspension',
    defaultMessage: 'Ålderspension',
  },
  plus: {
    id: 'commonBenefitMessages.plus',
    description: 'Plus benefit',
    defaultMessage: 'Pensionsrådgivning',
  },
  occupationalPension: {
    id: 'commonBenefitMessages.occupationalPension',
    description: 'Tjänstepension',
    defaultMessage: 'Tjänstepension',
  },
  salaryExchange: {
    id: 'commonBenefitMessages.salaryExchange',
    description: 'Löneväxling',
    defaultMessage: 'Löneväxling',
  },
  health: {
    id: 'commonBenefitMessages.health',
    description: 'Sjukvårdsförsäkring (PrivateAccess Silver)',
    defaultMessage: 'Sjukvårdsförsäkring',
  },
  healthShort: {
    id: 'commonBenefitMessages.healthShort',
    description: 'Health (short)',
    defaultMessage: 'Sjukvård',
  },
  sickness: {
    id: 'commonBenefitMessages.sickness',
    description: 'Sjukförsäkring (PlanSjuk)',
    defaultMessage: 'Sjukförsäkring',
  },
  accident: {
    id: 'commonBenefitMessages.accidentInsurance',
    description: 'Olycksfallsförsäkring',
    defaultMessage: 'Olycksfallsförsäkring',
  },
  sicknessShort: {
    id: 'commonBenefitMessages.sicknessShort',
    description: 'Sickness (short)',
    defaultMessage: 'Sjuk',
  },
  sicknessTitle: {
    id: 'commonBenefitMessages.sicknessTitle',
    description: 'Sickness (title)',
    defaultMessage: 'Sjukersättning',
  },
  tgl: {
    id: 'commonBenefitMessages.tgl',
    description: 'TGL-försäkring',
    defaultMessage: 'Tjänstegrupplivförsäkring',
  },
  insuranceCoverage: {
    id: 'commonBenefitMessages.insuranceCoverage',
    description: 'Insurance coverage',
    defaultMessage: 'Försäkringsskydd',
  },
  shortfall: {
    id: 'commonBenefitMessages.shortfall',
    description: 'Shortfall',
    defaultMessage: 'Underskott',
  },
  eyewear: {
    id: 'commonBenefitMessages.eyewear',
    description: 'Eyewear',
    defaultMessage: 'Bildskärms- och skyddsglasögon',
  },
  premiumWaiverDescription: {
    id: 'commonBenefitMessages.premiumWaiverDescription',
    description: 'Jag vill teckna premiebefrielseförsäkring',
    defaultMessage: 'Jag vill teckna premiebefrielseförsäkring',
  },
  life: {
    id: 'commonBenefitMessages.lifeInsurance',
    description: 'Extra livförsäkring',
    defaultMessage: 'Livförsäkring',
  },
  lifeShort: {
    id: 'commonBenefitMessages.lifeShort',
    description: 'Life insurance (short)',
    defaultMessage: 'Liv',
  },
  noBenefitsTitle: {
    id: 'commonBenefitMessages.noBenefitsTitle',
    description: 'No benefits in backage title',
    defaultMessage: 'Inga förmåner',
  },
  noBenefits: {
    id: 'commonBenefitMessages.noBenefits',
    description: 'No benefits title',
    defaultMessage: 'Det finns inga förmåner att visa',
  },
  noBenefitsText: {
    id: 'commonBenefitMessages.noBenefitsText',
    description: 'No benefits in backage text',
    defaultMessage: 'Förmåner som läggs till kommer att visas här.',
  },
  noBenefitsInBackage: {
    id: 'commonBenefitMessages.noBenefitsInBackage',
    description: 'No benefits in backage title',
    defaultMessage: 'Det finns inga förmåner i denna förmånsgrupp',
  },
  noBenefitsInBackageText: {
    id: 'commonBenefitMessages.noBenefitsInBackageText',
    description: 'No benefits in backage text',
    defaultMessage:
      'Förmåner som läggs till i förmånsgruppen kommer att visas här.',
  },
  noBenefitPackages: {
    id: 'commonBenefitMessages.noBenefitPackages',
    description: 'No benefit packages',
    defaultMessage: 'Inga förmånsgrupper',
  },
  noBenefitPackagesTitle: {
    id: 'commonBenefitMessages.noBenefitPackagesTitle',
    description: 'There is no benefit packages',
    defaultMessage: 'Det finns inga förmånsgrupper att visa',
  },
  noBenefitPackagesText: {
    id: 'commonBenefitMessages.noBenefitPackagesText',
    description: 'There is no benefit packages text',
    defaultMessage: 'Förmånsgrupper som skapas kommer att visas här.',
  },
  createBenefitPackageDescription: {
    id: 'commonBenefitMessages.createBenefitPackageDescription',
    description:
      'Ge förmånsgruppen ett beskrivande namn. I nästa steg konfigurerar du\n förmånerna som anställda i den här förmånsgruppen kommer få ta del av.',
    defaultMessage:
      'Alla anställda som hör till förmånsgruppen får tillgång till förmånerna i förmånsgruppen',
  },
  levelLabel: {
    id: 'commonBenefitMessages.levelLabel',
    description: 'Välj mellan följande nivåer:',
    defaultMessage: 'Välj nivå',
  },
  benefitPackagesFieldLabel: {
    id: 'commonBenefitMessages.benefitPackagesFieldLabel',
    description: 'Vilka grupper skulle du vilja tilldela denna förmån till?',
    defaultMessage: 'Välj förmånsgrupper',
  },
  isActiveOnParentalLeaveLabel: {
    id: 'commonBenefitMessages.isActiveOnParentalLeaveLabel',
    description:
      'Label for checkbox if the insurance should be active when the employee is on parental leave',
    defaultMessage: 'Försäkringen gäller även vid föräldraledighet',
  },
  parentalLeaveDescription: {
    id: 'commonBenefitMessages.parentalLeaveDescription',
    defaultMessage:
      'Det innebär att pensionspremier betalas in till anställdas pensionsförsäkringar under pågående föräldraledighet.',
  },
  editBenefitPresentation: {
    id: 'commonBenefitMessages.editBenefitPresentation',
    defaultMessage: 'Redigera presentation',
  },
  interval: {
    id: 'commonBenefitMessages.interval',
    defaultMessage: 'Intervall',
  },
  remunerationType: {
    id: 'commonBenefitMessages.remunerationType',
    defaultMessage: 'Löneart',
  },
  annualFactor: {
    id: 'commonBenefitMessages.annualFactor',
    defaultMessage: 'Årslönefaktor',
  },
  benefitQualifyingIncome: {
    id: 'commonBenefitMessages.benefitQualifyingIncome',
    defaultMessage: 'Förmånsgrundande löneart',
  },
  benefitQualifyingIncomeDescription: {
    id: 'commonBenefitMessages.benefitQualifyingIncomeDescription',
    defaultMessage:
      'Välj vilken löneart som ska vara förmånsgrundande för denna förmån. Lönearten måste räknas om till en årslön. För att ta hänsyn till semestertillägg på samma sätt som ITP 2, använd 12,2 som årslönefaktor. Om den förmånsgrundande lönearten redan är en årslön, använd 1,00 som årslönefaktor.',
  },
  benefitQualifyingIncomeTableDescription: {
    id: 'commonBenefitMessages.benefitQualifyingIncomeTableDescription',
    defaultMessage: `Förmånsgrundande löneart: {annualFactor, number} ∙ {remunerationType}`,
  },
  numberOfEmployees: {
    id: 'commonBenefitMessages.numberOfEmployees',
    defaultMessage: 'Antal anställda',
  },
  activateBenefitPortal: {
    id: 'commonBenefitMessages.activateBenefitPortal',
    defaultMessage: 'Aktivera förmånsportal',
  },
  showTotalCompensation: {
    id: 'commonBenefitMessages.showTotalCompensation',
    defaultMessage: 'Aktivera total ersättning',
  },
  optionalSuppliers: {
    id: 'commonBenefitMessages.optionalSuppliers',
    defaultMessage: 'Valbara leverantörer',
  },
  optionalSuppliersDesc: {
    id: 'commonBenefitMessages.optionalSuppliersDesc',
    defaultMessage:
      'Välj vilka försäkringslösningar som ska vara tillgängliga för era medarbetare. Medarbetare kan själva välja försäkringslösning i portalen.',
  },
  searchCategory: {
    id: 'commonBenefitMessages.searchCategory',
    defaultMessage: 'Sök kategori',
  },
  confirmLanguageSwitch: {
    id: 'commonBenefitMessages.confirmLanguageSwitch',
    defaultMessage:
      'Är du säker på att du vill byta språk?\nDu har ändringar som inte är sparade, dessa kommer att försvinna om du inte först sparar formuläret',
  },
  changeCoverImage: {
    id: 'commonBenefitMessages.changeCoverImage',
    defaultMessage: 'Ändra omslagsbild',
  },
  coverImagePlaceholder: {
    id: 'commonBenefitMessages.coverImagePlaceholder',
    defaultMessage: 'Dra och släpp en bild i formatet .jpg eller .png',
  },
  changeBenefitPackageNameLabel: {
    id: 'commonBenefitMessages.changeBenefitPackageNameLabel',
    defaultMessage: 'Byt namn',
  },
  changeBenefitPackageNameHeader: {
    id: 'commonBenefitMessages.changeBenefitPackageNameHeader',
    defaultMessage: 'Byt namn på {name}',
  },
  benefitPackageNameUpdateSuccess: {
    id: 'commonBenefitMessages.benefitPackageNameUpdateSuccess',
    defaultMessage: 'Förmånsgruppens namn uppdaterades till {name}',
  },
  inBenefitPackage: {
    id: 'commonBenefitMessages.inBenefitPackage',
    defaultMessage: 'I förmånsgrupp',
  },
  benefitSubmitSuccess: {
    id: 'formSubmitMessages.benefitSubmitSuccess',
    defaultMessage: 'Förmånen har sparats',
  },
  benefitPortal: {
    id: 'commonBenefitMessages.benefitPortal',
    defaultMessage: 'Förmånsportal',
  },
  digitalAdvice: {
    id: 'commonBenefitMessages.digitalAdvice',
    defaultMessage: 'Digital rådgivning',
  },
});

export const salaryExchangeBenefitMessages = defineMessages({
  premiumPerMonth: {
    id: 'salaryExchangeBenefitMessages.premiumPerMonth',
    description: 'Premie (kr/mån)',
    defaultMessage: 'Premie (kr/mån)',
  },
  salaryExchangeDeductionValue: {
    id: 'salaryExchangeBenefitMessages.salaryExchangeDeductionValue',
    description: 'Max. bruttolönevadrag:',
    defaultMessage:
      'Max. avdrag: <b>{amount, number, ::currency/SEK .}</b>, <b>{share, number, ::percent}</b> ',
  },
  oneTimeSalaryExchangeDeductionValue: {
    id: 'salaryExchangeBenefitMessages.oneTimeSalaryExchangeDeductionValue',
    defaultMessage:
      'Max. avdrag bonuxväxling: <b>{amount, number, ::currency/SEK .}</b>, <b>{share, number, ::percent}</b> ',
  },
  informationAboutSalaryExchange: {
    id: 'salaryExchangeBenefitMessages.informationAboutSalaryExchange',
    defaultMessage: 'Information om löneväxling',
  },
  beneficialSaving: {
    id: 'salaryExchangeBenefitMessages.beneficialSaving',
    defaultMessage: 'Ett förmånligt sparande',
  },
});

export const healthBenefitMessages = defineMessages({
  healthLevel: {
    id: 'healthBenefitMessages.healthLevel',
    description: 'Deductible',
    defaultMessage: 'Självrisk',
  },
});

export const pensionBenefitMessages = defineMessages({
  ageIntervalError: {
    id: 'pensionBenefitMessages.ageIntervalError',
    description: 'Åldersintervallerna måste vara i stigande ordning',
    defaultMessage: 'Åldersintervallen måste vara i stigande ordning',
  },
  baseAmountIntervalError: {
    id: 'pensionBenefitMessages.baseAmountIntervalError',
    description: 'Basbeloppsintervallerna måste vara i stigande ordning',
    defaultMessage: 'Basbeloppsintervallen måste vara i stigande ordning',
  },
  deductionsTableMessage: {
    id: 'pensionBenefitMessages.deductionsTableMessage',
    defaultMessage: 'Avdrag inom premieramen: {deductions}',
  },
  pensionProviders: {
    id: 'pensionBenefitMessages.pensionProviders',
    defaultMessage: `{count, plural,
      =1 {Leverantör}
      other {Leverantörer}}`,
  },
  fixedExtraPremium: {
    id: 'pensionBenefitMessages.fixedExtraPremium',
    defaultMessage: 'Fast extra premie',
  },
});

export const wellnessBenefitMessages = defineMessages({
  wellness: {
    id: 'wellnessBenefitMessages.wellness',
    description: 'Wellness',
    defaultMessage: 'Friskvård',
  },
  viaEpassi: {
    id: 'wellnessBenefitMessages.viaEpassi',
    description: 'Via Epassi',
    defaultMessage: 'Via Epassi',
  },
});

export const benefitTypeNamesMessages = defineMessages<BenefitType>({
  advinans_occupational_pension: {
    id: 'benefitTypeNamesMessages.advinans_occupational_pension',
    defaultMessage: 'Ålderspension',
  },
  advinans_salary_exchange: {
    id: 'benefitTypeNamesMessages.advinans_salary_exchange',
    defaultMessage: 'Löneväxling',
  },
  epassi: {
    id: 'benefitTypeNamesMessages.epassi',
    defaultMessage: 'Epassi',
  },
  epassi_bike: {
    id: 'benefitTypeNamesMessages.epassi_bike',
    defaultMessage: 'EpassiBIKE',
  },
  euro_accident_accident: {
    id: 'benefitTypeNamesMessages.euro_accident_accident',
    defaultMessage: 'Olycksfallsförsäkring',
  },
  euro_accident_life: {
    id: 'benefitTypeNamesMessages.euro_accident_life',
    defaultMessage: 'Livförsäkring',
  },
  euro_accident_plansjuk: {
    id: 'benefitTypeNamesMessages.euro_accident_plansjuk',
    defaultMessage: 'Sjukförsäkring',
  },
  euro_accident_privataccess: {
    id: 'benefitTypeNamesMessages.euro_accident_privataccess',
    defaultMessage: 'Sjukvårdsförsäkring',
  },
  euro_accident_sickness_and_accident: {
    id: 'benefitTypeNamesMessages.euro_accident_sickness_and_accident',
    defaultMessage: 'Sjuk- och olycksfallsförsäkring',
  },
  euro_accident_tgl: {
    id: 'benefitTypeNamesMessages.euro_accident_tgl',
    defaultMessage: 'Tjänstegrupplivförsäkring',
  },
  nordea_node_digital_advice: {
    id: 'benefitTypeNamesMessages.nordea_node_digital_advice',
    defaultMessage: 'Pensionsrådgivning',
  },
  nordea_node_personal_advice: {
    id: 'benefitTypeNamesMessages.nordea_node_personal_advice',
    defaultMessage: 'Personlig rådgivning',
  },
  synsam: {
    id: 'benefitTypeNamesMessages.synsam',
    defaultMessage: 'Synsam',
  },
  wellness: {
    id: 'benefitTypeNamesMessages.wellness',
    defaultMessage: 'Friskvård',
  },
});

export const benefitFilterMessages = defineMessages({
  chooseCategory: {
    id: 'benefitFilterMessages.chooseCategory',
    defaultMessage: 'Välj kategori',
  },
  chooseType: {
    id: 'benefitFilterMessages.chooseType',
    defaultMessage: 'Välj förmån',
  },
});

export const pensionBenefitDeductionMessages = defineMessages({
  ADVINANS_MEMBERSHIP_FEE: {
    id: 'pensionBenefitDeductionMessages.ADVINANS_MEMBERSHIP_FEE',
    defaultMessage: 'Nordea Nodes plattformsavgift',
  },
  ADVINANS_ADVICE_FEE: {
    id: 'pensionBenefitDeductionMessages.ADVINANS_ADVICE_FEE',
    defaultMessage: 'Personlig rådgivning',
  },
  PREMIUM_WAIVER: {
    id: 'pensionBenefitDeductionMessages.PREMIUM_WAIVER',
    defaultMessage: 'Premiebefrielseförsäkring',
  },
  SICKNESS_INSURANCE: {
    id: 'pensionBenefitDeductionMessages.SICKNESS_INSURANCE',
    defaultMessage: 'Sjukförsäkring',
  },
});

export const pensionBenefitTypeMessages = defineMessages<PensionBenefitType>({
  INDIVIDUAL: {
    id: 'pensionBenefitTypeMessages.INDIVIDUAL',
    defaultMessage: 'Fast individuell premie',
  },
  ITP: {
    id: 'pensionBenefitTypeMessages.ITP',
    defaultMessage: 'ITP-trappa',
  },
  PREMIUM_MATRIX: {
    id: 'pensionBenefitTypeMessages.PREMIUM_MATRIX',
    defaultMessage: 'Egen premietrappa',
  },
});

export const sicknessBenefitMessages = defineMessages<DisabilityLevel>({
  ITP: {
    id: 'sicknessBenefitMessages.ITP',
    description: 'ITP-Sjuk',
    defaultMessage: 'ITP-sjuk',
  },
  MAX: {
    id: 'sicknessBenefitMessages.MAX',
    description: 'Max-Sjuk',
    defaultMessage: 'MAX-sjuk',
  },
  KOMP: {
    id: 'sicknessBenefitMessages.KOMP',
    defaultMessage: 'ITP-komplettering',
  },
  HYBRID: {
    id: 'sicknessBenefitMessages.HYBRID',
    defaultMessage: 'Hybrid-sjuk (ITP/MAX)',
  },
});
