import { CheckboxField, Table, Th, Tr } from '@frontend/ui';
import { MembershipKey, pageInfoDetails } from 'app/apollo/graphql/types';
import { advisorMessages } from 'app/messages/advisor';
import { commonMessages } from 'app/messages/common';
import { OpenAddHeldMeetingModal } from 'app/pages/advisor';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { SearchField } from 'components/SearchField';
import { TableNavigation } from 'components/TableNavigation';
import { AdviceFilterChips } from 'features/advisor/components/Filter';
import { useCheckboxRows } from 'features/advisor/utils/use-checkbox-rows';
import React from 'react';

import { EmptyTable } from '../../../../components/EmptyTable';
import { EmployeeWithMeeting } from '..';
import { AdviceMeetingTableRow } from '../components/AdviceMeetingTableRow';

interface Props {
  cancelMeeting: (meetingId: string) => void;
  clearFilter: () => void;
  deleteMeeting: (meetingId: string) => void;
  meetings: Array<EmployeeWithMeeting>;
  onNextPage: () => void;
  onPreviousPage: () => void;
  openAddHeldMeetingModal: OpenAddHeldMeetingModal;
  openSendInvitationsModal: (membershipKeys: Array<MembershipKey>) => void;
  pageInfo: pageInfoDetails;
  toggleFilterSideSheet: () => void;
}

const AdviceMeetingTableColgroup = () => (
  <colgroup>
    <col style={{ width: '5%' }} />
    <col style={{ width: '12%' }} />
    <col style={{ width: 'auto' }} />
    <col style={{ width: '10%' }} />
    <col style={{ width: '10%' }} />
    <col style={{ width: '15%' }} />
    <col style={{ width: '10%' }} />
    <col style={{ width: '15%' }} />
  </colgroup>
);

const AdviceMeetingTableThead = ({
  allSelected,
  indeterminate,
  toggleSelectAll,
}: {
  allSelected: boolean;
  toggleSelectAll: (checked: boolean) => void;
  indeterminate: boolean;
}) => (
  <thead>
    <Tr withInlineMenuTh>
      <Th type="input">
        <CheckboxField
          checked={allSelected}
          onChange={toggleSelectAll}
          indeterminate={indeterminate}
        />
      </Th>
      <Th align="left">
        <FormattedMessage {...commonMessages.id} />
      </Th>
      <Th align="left">
        <FormattedMessage {...commonMessages.name} />
      </Th>
      <Th align="left">
        <FormattedMessage {...commonMessages.company} />
      </Th>
      <Th align="right">
        <FormattedMessage {...commonMessages.date} />
      </Th>
      <Th align="left">
        <FormattedMessage {...advisorMessages.category} />
      </Th>
      <Th align="left">
        <FormattedMessage {...commonMessages.confirmed} />
      </Th>
      <Th align="right">
        <FormattedMessage {...advisorMessages.latestEmailSentAt} />
      </Th>
    </Tr>
  </thead>
);

export const PaginatedAdviceMeetingTable: React.FC<Props> = ({
  pageInfo,
  onNextPage,
  onPreviousPage,
  meetings,
  openAddHeldMeetingModal,
  clearFilter,
  toggleFilterSideSheet,
  openSendInvitationsModal,
  deleteMeeting,
  cancelMeeting,
}) => {
  const rowKeys = meetings.map(meeting => ({
    userAccountId: meeting.userAccountId,
    companyId: meeting.companyId,
    rowId: meeting.id,
  }));

  const {
    checkedRowKeys,
    allSelected,
    indeterminate,
    toggleSelectAll,
    toggleSelectRow,
  } = useCheckboxRows(rowKeys);

  return (
    <>
      <SearchField
        label={<FormattedMessage {...advisorMessages.searchMeeting} />}
      />
      <ChipsWrapper belowSearch>
        <AdviceFilterChips
          clearFilter={clearFilter}
          toggleFilterSideSheet={toggleFilterSideSheet}
        />
      </ChipsWrapper>
      <Table
        navigation={
          <TableNavigation
            pageInfo={pageInfo}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
          />
        }
        fixed
      >
        {meetings.length === 0 ? (
          <EmptyTable noMatchMessage={advisorMessages.noMeetingMatch} />
        ) : (
          <>
            <AdviceMeetingTableColgroup />
            <AdviceMeetingTableThead
              allSelected={allSelected}
              indeterminate={indeterminate}
              toggleSelectAll={toggleSelectAll}
            />
            <tbody>
              {meetings.map(meeting => (
                <AdviceMeetingTableRow
                  key={meeting.id}
                  meeting={meeting}
                  sendInvitation={() =>
                    openSendInvitationsModal([
                      {
                        userAccountId: meeting.userAccountId,
                        companyId: meeting.companyId,
                      },
                    ])
                  }
                  openAddHeldMeetingModal={openAddHeldMeetingModal}
                  checked={checkedRowKeys.some(key => key.rowId === meeting.id)}
                  onCheckboxChange={checked => {
                    toggleSelectRow(checked, {
                      userAccountId: meeting.userAccountId,
                      companyId: meeting.companyId,
                      rowId: meeting.id,
                    });
                  }}
                  deleteMeeting={() => deleteMeeting(meeting.id)}
                  cancelMeeting={() => cancelMeeting(meeting.id)}
                />
              ))}
            </tbody>
          </>
        )}
      </Table>
    </>
  );
};
