import { useIntl } from 'components/formats';
import { Formik } from 'formik';
import React from 'react';

import {
  CompanySize,
  EAGruppAgreementType,
  Language,
  NLPFundFeeDiscountType,
} from '../../../app/apollo/graphql/types';
import { CreateCompanyForm } from './form';
import { useSubmit } from './utils/use-submit';
import { validationSchema } from './utils/validations';

export interface FormValues {
  createEaGrupp: boolean;
  createEaTjp: boolean;
  createNordea: boolean;
  eaExtendedFcfw: boolean;
  eaStartDate: string | null;
  email: string;
  firstName: string;
  isOwner: boolean;
  language: Language;
  lastName: string;
  nodeAdminPid: string | null;
  nordeaAnnualFeeFund: number | null;
  nordeaAnnualFeeGuarantee: number | null;
  nordeaCapitalFeeFund: number | null;
  nordeaCapitalFeeGuarantee: number | null;
  nordeaSalesRep: string;
  nordeaStartDate: string | null;
  phone: string;
  pid: string;
  registrationNumber: string;
  startDate: string;
  eaCompanySize?: CompanySize;
  eaGruppAgreementType?: EAGruppAgreementType;
  nordeaFundFeeDiscountType?: NLPFundFeeDiscountType;
}

export const initialValues: FormValues = {
  language: Language.SV_SE,
  eaExtendedFcfw: false,
  isOwner: false,
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  pid: '',
  nordeaCapitalFeeFund: null,
  nordeaAnnualFeeFund: null,
  nordeaCapitalFeeGuarantee: null,
  nordeaAnnualFeeGuarantee: null,
  registrationNumber: '',
  createNordea: false,
  startDate: new Date().toISOString().substring(0, 10),
  createEaGrupp: false,
  createEaTjp: false,
  eaStartDate: '',
  nordeaStartDate: '',
  nordeaSalesRep: 'N499090',
  nodeAdminPid: '',
};

export const Create: React.FC = () => {
  const intl = useIntl();
  const { submit, submissionError } = useSubmit();

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={validationSchema(intl)}
    >
      {({ values }) => (
        <CreateCompanyForm submissionError={submissionError} values={values} />
      )}
    </Formik>
  );
};
