import gql from 'graphql-tag';

export const DOWNLOAD_PRICE_PROPOSAL_LAYOUT_QUERY = gql`
  query downloadPriceProposalLayoutQuery($id: ID!) {
    proposal(id: $id) {
      validity {
        isPriceProposalDownloadAllowed
      }
    }
  }
`;

export const DOWNLOAD_PRICE_PROPOSAL_FILE_QUERY = gql`
  query downloadPriceProposalFileQuery($input: GeneratePriceProposalInput!) {
    getPriceProposal(input: $input) {
      url
      name
    }
  }
`;
