import { updateProposalQuery } from 'app/apollo/graphql/types';

export interface Validity {
  validBenefits: boolean;
  validCompilation: boolean;
  validEmployees: boolean;
  validFees: boolean;
  validNeed: boolean;
  validProductKnowledge: boolean;
  validProposal: boolean;
  validSummary: boolean;
}

export const getIsValid = (data?: updateProposalQuery): Validity => {
  const {
    validBenefits = false,
    validCompilation = false,
    validEmployees = false,
    validFees = false,
    validNeed = false,
    validProductKnowledge = false,
    validProposal = false,
    validSummary = false,
  } = data?.proposal?.validity ?? {};

  return {
    validBenefits,
    validCompilation,
    validEmployees,
    validFees,
    validNeed,
    validProductKnowledge,
    validProposal,
    validSummary,
  };
};
