import { RadioGroup, Subsection, SubsectionHeader } from '@frontend/ui';
import { CollectiveAgreementOption } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage, useIntl } from 'components/formats';
import { TextGrid } from 'components/GridCell';
import { TopLoading } from 'components/TopLoading';
import { TemplateSelect } from 'features/sme/companies/components/TemplateSelect';
import { smeNeedsMessages } from 'features/sme/messages/sme';
import React from 'react';

import { useProposalReadonly } from '../../utils/use-proposal-readonly';
import { AutosaveStatus, useAutosave } from '../contexts/autosave';
import { getFormValues, getOnChangeHandlers } from './utils';

export const Needs: React.FC = () => {
  const { loading, data, onChange, status } = useAutosave();
  const { formatMessage } = useIntl();
  const isReadonly = useProposalReadonly();

  if (loading) {
    return <TopLoading />;
  }

  const formValues = getFormValues(data);
  const _onChange = getOnChangeHandlers(onChange);

  const disabled = isReadonly || status === AutosaveStatus.RECONNECTING;

  return (
    <>
      <Subsection>
        <TextGrid>
          <TemplateSelect
            characterLimit={4100}
            disabled={disabled}
            label={formatMessage(smeNeedsMessages.situationTemplateSelectLabel)}
            options={[
              {
                label: formatMessage(smeNeedsMessages.lacksExistingInsurances),
                value: formatMessage(
                  smeNeedsMessages.lacksExistingInsurancesPlaceholder,
                ),
              },
              {
                label: formatMessage(
                  smeNeedsMessages.insurancesWithOtherCompany,
                ),
                value: formatMessage(
                  smeNeedsMessages.insurancesWithOtherCompanyPlaceholder,
                ),
              },
            ]}
            placeholder={formatMessage(
              smeNeedsMessages.situationTemplateEditorPlaceholder,
            )}
            setValue={_onChange.text}
            value={formValues.text}
          />
        </TextGrid>
      </Subsection>
      <Subsection>
        <TextGrid>
          <SubsectionHeader>
            <FormattedMessage
              {...smeNeedsMessages.collectiveAgreementBlueCollarDescription}
            />
          </SubsectionHeader>
          <RadioGroup
            disabled={disabled}
            checked={formValues.collectiveAgreementBlueCollar}
            onChange={_onChange.collectiveAgreementBlueCollar}
            options={[
              {
                label: formatMessage(commonMessages.yes),
                value: CollectiveAgreementOption.YES,
              },
              {
                label: formatMessage(commonMessages.no),
                value: CollectiveAgreementOption.NO,
              },
              {
                label: formatMessage(commonMessages.notApplicable),
                value: CollectiveAgreementOption.NOT_APPLICABLE,
              },
            ]}
            withoutMargin
          />
        </TextGrid>
      </Subsection>
      <Subsection>
        <TextGrid>
          <SubsectionHeader>
            <FormattedMessage
              {...smeNeedsMessages.collectiveAgreementWhiteCollarDescription}
            />
          </SubsectionHeader>
          <RadioGroup
            disabled={disabled}
            checked={formValues.collectiveAgreementWhiteCollar}
            onChange={_onChange.collectiveAgreementWhiteCollar}
            options={[
              {
                label: formatMessage(commonMessages.yes),
                value: CollectiveAgreementOption.YES,
              },
              {
                label: formatMessage(commonMessages.no),
                value: CollectiveAgreementOption.NO,
              },
              {
                label: formatMessage(commonMessages.notApplicable),
                value: CollectiveAgreementOption.NOT_APPLICABLE,
              },
            ]}
            withoutMargin
          />
        </TextGrid>
      </Subsection>
      <Subsection>
        <TextGrid>
          <SubsectionHeader>
            <FormattedMessage
              {...smeNeedsMessages.documentationProvidedDescription}
            />
          </SubsectionHeader>
          <RadioGroup
            disabled={disabled}
            checked={formValues.documentationProvided}
            onChange={_onChange.documentationProvided}
            options={[
              { label: formatMessage(commonMessages.yes), value: 'true' },
              { label: formatMessage(commonMessages.no), value: 'false' },
            ]}
            withoutMargin
          />
        </TextGrid>
      </Subsection>
    </>
  );
};
