import { useMutation } from '@apollo/client';
import {
  emptyStringToNull,
  objectMap,
  toMoney,
  toNumber,
} from '@frontend/utils';
import {
  CreateJobOfferInput,
  createJobOfferMutation,
  createJobOfferMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { FormikHelpers } from 'formik';
import { useHistory, useRouteMatch } from 'react-router';

import { MONTHLY_SALARY_REMUNERATION_TYPE_ID } from '../../utils/constants';
import { FormValues } from '../form';
import { CREATE_JOB_OFFER_MUTATION } from '../graphql/mutations';
import { jobOfferMessages } from '../messages';

export const useSubmit = () => {
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();
  const { push } = useHistory();
  const intl = useIntl();
  const { send } = useNotification();

  const [createJobOffer] = useMutation<
    createJobOfferMutation,
    createJobOfferMutationVariables
  >(CREATE_JOB_OFFER_MUTATION, {
    update: cache => {
      cache.evict({
        id: `Company:${companyId}`,
        fieldName: 'jobOffers',
      });
      cache.gc();
    },
  });

  return async (
    _formValues: FormValues,
    formikHelpers: FormikHelpers<FormValues>,
  ) => {
    const formValues = objectMap(_formValues, emptyStringToNull);
    try {
      const input: CreateJobOfferInput = {
        companyId,
        title: formValues.title,
        body: formValues.body,
        prospectName: formValues.prospectName,
        birthdate: formValues.birthdate,
        profilePictureFile: formValues.profilePicture?.uploadId,
        coverPictureFile: formValues.coverPicture?.uploadId,
        yearlyVacationDays: toNumber(formValues.yearlyVacationDays),
        collectiveAgreement:
          formValues.collectiveAgreement === 'null' ||
          formValues.collectiveAgreement === ''
            ? null
            : formValues.collectiveAgreement,
        monthlyLunchAllowance: toMoney(formValues.monthlyLunchAllowance),
        yearlyWellnessAllowance: toMoney(formValues.yearlyWellnessAllowance),
        monthlyCompanyCarValue: toMoney(formValues.monthlyCompanyCarValue),
        hidden: !formValues.visible,
        validUntil:
          formValues.untilFurtherNotice === 'no' ? formValues.validUntil : null,
        workLocation: formValues.workLocation,
        workSetting: formValues.workSetting,
        showDiscount: formValues.showDiscount,
        optionsProgram: formValues.hasOptionsProgram
          ? formValues.optionsProgram
          : null,
        benefitPackageId: formValues.benefitPackageId,
        remunerations: [
          {
            idCode: MONTHLY_SALARY_REMUNERATION_TYPE_ID,
            value: toMoney(formValues.monthlySalary) ?? '',
          },
          ...(formValues.remunerations?.map(r => ({
            idCode: r.idCode,
            value: toMoney(r.value) ?? '',
          })) ?? []),
        ],
        attachmentFiles: formValues.attachmentFiles?.map(file => file.id),
        optionsProgrammeFiles: formValues.optionsProgrammeFiles?.map(
          file => file.id,
        ),
      };

      const createJobOfferResult = await createJobOffer({
        variables: { input },
      });

      if (!createJobOfferResult.data?.createJobOffer?.jobOffer) {
        throw new Error();
      }

      const { jobOffer } = createJobOfferResult.data.createJobOffer;

      send({
        message: intl.formatMessage(jobOfferMessages.created),
        type: 'success',
      });

      push(`/companies/${companyId}/job-offers/edit/${jobOffer?.id}`);
    } catch (error) {
      formikHelpers.setErrors({
        submissionError: error?.message,
      });
    }
  };
};
