import { useMutation } from '@apollo/client';
import { DatePickerField, SelectField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import { withinDateRangeExcludeEndDate } from '@frontend/utils';
import {
  accountingObjectsQuery,
  accountingObjectsQueryVariables,
  addMembershipAccountingItemMutation,
  addMembershipAccountingItemMutationVariables,
  organizationMembership as Membership,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { costReportingMessages } from 'app/messages/cost-reporting';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { LinkButton } from 'components/LinkButton';
import { Modal } from 'components/Modal';
import { TopLoading } from 'components/TopLoading';
import { ACCOUNTING_OBJECTS_QUERY } from 'features/companies/graphql/queries';
import { useNotification } from 'features/notifications';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import * as Yup from 'yup';

import { ADD_MEMBERSHIP_ACCOUNTING_ITEM_MUTATION } from '../graphql/mutations';

const createValidationSchema = (
  intl: IntlShape,
  currentEffectiveDate?: string,
) =>
  Yup.object().shape({
    accountingObjectId: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    effectiveDate: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid effective date',
        intl.formatMessage(validationMessages.accountingItemEffectiveDateError),
        value =>
          currentEffectiveDate
            ? new Date(value) > new Date(currentEffectiveDate)
            : true,
      ),
  });

interface FormValues {
  accountingObjectId: string;
  effectiveDate: string;
}
interface Props {
  isOpen: boolean;
  membership: Membership;
  onRequestClose: () => void;
  assignedObjects?: Array<{
    from: string;
    id: string;
    name: string;
    to: string | null;
  }>;
  dimensionId?: string;
  dimensionName?: string;
}

export const UpdateMembershipAccountingDimensionModal: React.FC<Props> = ({
  isOpen,
  membership,
  onRequestClose,
  dimensionId,
  dimensionName,
  assignedObjects,
}) => {
  const { send } = useNotification();
  const intl = useIntl();
  const { formatMessage } = intl;

  const currentAccountingObject = useMemo(() => {
    const currentDate = new Date();
    return assignedObjects?.find(({ from, to }) =>
      withinDateRangeExcludeEndDate(currentDate.toISOString().substr(0, 10), [
        from,
        to,
      ]),
    );
  }, [assignedObjects]);

  const {
    company: { id: companyId },
  } = membership;
  const { userAccountId } = membership;
  const { firstName } = membership;
  const { lastName } = membership;

  const onCompleted = (data: addMembershipAccountingItemMutation) => {
    if (data?.addMembershipAccountingItem) {
      send({
        type: 'success',
        message: (
          <FormattedMessage
            {...costReportingMessages.updateEmployeeObjectSuccess}
            values={{
              dimensionName: dimensionName ?? '',
            }}
          />
        ),
      });
      onRequestClose();
    }
  };

  const [addMembershipAccountingItem, { loading, error: mutationError }] =
    useMutation<
      addMembershipAccountingItemMutation,
      addMembershipAccountingItemMutationVariables
    >(ADD_MEMBERSHIP_ACCOUNTING_ITEM_MUTATION, {
      onCompleted,
      awaitRefetchQueries: true,
      update: cache => {
        cache.evict({
          id: `Membership:${membership.id}`,
          fieldName: 'accountingCost',
        });
        cache.gc();
      },
    });

  const {
    data: accountingObjectsData,
    loading: accountingObjectsLoading,
    error: accountingObjectsError,
  } = useQuery<accountingObjectsQuery, accountingObjectsQueryVariables>(
    ACCOUNTING_OBJECTS_QUERY,
    {
      variables: { companyId, dimensionId },
      errorPolicy: 'all',
      skip: !dimensionId,
    },
  );

  if (accountingObjectsLoading) {
    return <TopLoading />;
  }

  if (!accountingObjectsData?.accountingObjects) {
    return dimensionId ? (
      <GraphQlError inModal error={accountingObjectsError} />
    ) : null;
  }

  const accountingObjects = accountingObjectsData.accountingObjects.edges.map(
    edge => edge.node,
  );

  const currentAccountingObjectEffectiveDate = currentAccountingObject?.from;
  const currentAccountingObjectId = currentAccountingObject?.id;

  if (!accountingObjects.length) {
    return (
      <Modal size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
        <ModalHeader>
          <FormattedMessage
            {...costReportingMessages.updateEmployeeObjectEmptyStateTitle}
            values={{ dimensionName: dimensionName?.toLowerCase() }}
          />
        </ModalHeader>
        <ModalBody>
          <p>
            <FormattedMessage
              {...costReportingMessages.updateEmployeeObjectEmptyStateDescription}
              values={{ dimensionName }}
            />
          </p>
        </ModalBody>
        <ModalFooter>
          <ButtonLayout align="right">
            <Button text onClick={onRequestClose}>
              <FormattedMessage {...commonMessages.cancel} />
            </Button>
            <LinkButton
              text
              to={`/companies/${companyId}/settings/cost-reporting/${dimensionId}?create=true`}
            >
              <FormattedMessage
                {...costReportingMessages.createObject}
                values={{ dimensionName }}
              />
            </LinkButton>
          </ButtonLayout>
        </ModalFooter>
      </Modal>
    );
  }

  const validationSchema = createValidationSchema(
    intl,
    currentAccountingObjectEffectiveDate,
  );

  return (
    <Modal size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
      <Formik<FormValues>
        validationSchema={validationSchema}
        initialValues={{
          accountingObjectId: currentAccountingObjectId ?? '',
          effectiveDate: '',
        }}
        onSubmit={({ accountingObjectId, effectiveDate }) =>
          addMembershipAccountingItem({
            variables: {
              input: {
                companyId,
                userAccountId,
                effectiveDate,
                dimensionId: dimensionId ?? '',
                accountingObjectId,
              },
            },
          })
        }
      >
        {({ isValid }) => (
          <Form>
            <ModalHeader>
              <FormattedMessage
                {...costReportingMessages.updateEmployeeObjectTitle}
                values={{
                  dimensionName: dimensionName?.toLowerCase(),
                  firstName,
                  lastName,
                }}
              />
            </ModalHeader>
            <ModalBody>
              <p>
                <FormattedMessage
                  {...costReportingMessages.updateEmployeeObjectDescription}
                />
              </p>
              {!!mutationError && (
                <GraphQlError inModal error={mutationError} />
              )}
              <SelectField
                fixed
                dense
                name="accountingObjectId"
                label={dimensionName}
                options={accountingObjects.map(({ id, name }) => ({
                  label:
                    id === currentAccountingObjectId
                      ? formatMessage(
                          costReportingMessages.updateEmployeeObjectCurrent,
                          { label: name ?? '' },
                        )
                      : name ?? '',
                  value: id,
                }))}
              />
              <DatePickerField
                name="effectiveDate"
                dense
                label={
                  <FormattedMessage
                    {...costReportingMessages.updateEmployeeObjectDateLabel}
                  />
                }
              />
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button
                  loading={loading}
                  text
                  type="submit"
                  disabled={!isValid}
                >
                  <FormattedMessage {...commonMessages.confirm} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
